import { CustomerEntity } from '../graphql/generated';

export const getCustomerNameAddressList = (data?: CustomerEntity) => {
  return [
    {
      label: 'Customer',
      title: data?.firstName || '',
    },
    {
      label: 'Country',
      title: data?.country || '',
    },
  ];
};
