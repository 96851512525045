import { Stack, StackProps, styled } from '@mui/material';
import { ConversationProviderEnum } from '../../../../graphql/generated';

interface WrapperProps extends StackProps {
  provider?: ConversationProviderEnum;
}

export const Wrapper = styled(Stack)<WrapperProps>(({ provider, theme }) => {
  const palette = theme.vars.palette;
  const backgroundColor = {
    default: palette.white.main,
    [ConversationProviderEnum.Sms]: palette.green.main,
    [ConversationProviderEnum.Email]: palette.mail.main,
    [ConversationProviderEnum.LiveChat]: palette.red.main,
  }[provider || 'default'];

  return {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 32px 12px 12px',
    borderRadius: '1000px',
    gap: '12px',
    background: backgroundColor,
    color: palette.white.main,
  };
});

export const IconBlockCSS = styled(Stack)(({ theme }) => {
  return {
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    background: theme.vars.palette.white.main,
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  };
});
