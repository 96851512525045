import { Stack, Typography, useTheme } from '@mui/material';
import { routes } from '../../router/Router';
import { CustomHeaderLinkCSS } from './styles';
import { useAuthContext } from '../../context';

export const HeaderLinks = () => {
  const { roles } = useAuthContext();

  const headersRoutes = routes.filter((route) =>
    roles?.isSuperAdmin
      ? route.isHeaderNavigate
      : roles?.isAdmin
      ? route.isHeaderNavigate && route.isManagerRoute
      : route.isHeaderNavigate && !route.isSuperAdminRoute,
  );

  const theme = useTheme();
  const iconColor = theme.vars.palette.blackWhite.main;

  return (
    <Stack direction={'row'} sx={{ gap: '12px' }}>
      {headersRoutes.map(({ path, icon: Icon, label }) => {
        return (
          <CustomHeaderLinkCSS to={path} key={path}>
            <Typography>{Icon && <Icon color={iconColor} />}</Typography>
            <Typography component={'span'}>{label}</Typography>
          </CustomHeaderLinkCSS>
        );
      })}
    </Stack>
  );
};
