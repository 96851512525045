import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => {
  return {
    marginBottom: '36px',
    overflow: 'auto',
    height: '118px',
    gap: '16px',
    '::-webkit-scrollbar': {
      width: '20px',
    },
    '::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 20px 20px ${theme.vars.palette.whiteBlack.main}`,
      border: 'solid 6px transparent',
      borderRadius: '100px',
    },
  };
});
