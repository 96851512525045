import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => {
  return {
    width: '100%',
    minWidth: '458px',
    height: '68px',
    borderRadius: '10000px',
    background: theme.vars.palette.black.main,
    alignItems: 'center',
    padding: '0 22px 0 12px',
    marginRight: '8px',
  };
});

export const IconBlockCSS = styled(Stack)(({ theme }) => {
  return {
    width: '44px',
    height: '44px',
    background: theme.vars.palette.white.main,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: '8px',
  };
});
