import { styled } from '@mui/material/styles';
import { Stack, StackProps } from '@mui/material';

interface WrapperProps extends StackProps {
  ispage?: string;
}

export const Wrapper = styled(Stack)<WrapperProps>(({ ispage }) => {
  const isPage = ispage === 'true';
  return {
    position: 'relative',
    flex: 1,
  };
});

export const SubWrapperCSS = styled(Stack)(({ theme }) => {
  return {
    overflow: 'auto',
    background: theme.vars.palette.whiteBlack.main,
    zIndex: 1000,
    flex: 1,
    borderRadius: '16px',
    padding: '16px',
  };
});

export const ItemWrapper = styled(Stack)(({ theme }) => {
  return {
    background: theme.vars.palette.silver20silver200.main,
    padding: '24px',
    borderRadius: '32px',
    flex: 1,
  };
});
