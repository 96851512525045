import { IconProps } from './types';

export const ShopsIcon = ({ color = '#fff' }: IconProps) => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.70288 0.9375C7.88026 0.9375 6.37732 2.30621 6.16584 4.07186C5.87056 4.12842 5.59853 4.21017 5.33759 4.32979C4.58351 4.67548 3.94514 5.23132 3.499 5.93067C3.22897 6.35396 3.06874 6.82747 2.94287 7.40511C2.81955 7.97104 2.72156 8.67659 2.5969 9.5742L2.58999 9.62391C2.42956 10.779 2.30415 11.6819 2.26606 12.4093C2.22738 13.1477 2.27417 13.7631 2.49632 14.3281C2.85941 15.2515 3.52983 16.0215 4.39451 16.5081C4.92355 16.8059 5.52666 16.9369 6.26342 17.0002C6.98907 17.0625 7.90069 17.0625 9.06684 17.0625H10.3389C11.5051 17.0625 12.4167 17.0625 13.1423 17.0002C13.8791 16.9369 14.4822 16.8059 15.0112 16.5081C15.8759 16.0215 16.5464 15.2515 16.9094 14.3281C17.1316 13.7631 17.1784 13.1477 17.1397 12.4093C17.1016 11.6819 16.9762 10.7789 16.8158 9.62383L16.8089 9.57418C16.6842 8.67657 16.5862 7.97103 16.4629 7.40511C16.337 6.82747 16.1768 6.35396 15.9068 5.93067C15.4606 5.23132 14.8222 4.67548 14.0682 4.32979C13.8072 4.21017 13.5352 4.12842 13.2399 4.07186C13.0284 2.30621 11.5255 0.9375 9.70288 0.9375ZM9.05984 3.9375C8.38328 3.93749 7.81379 3.93749 7.3262 3.9565C7.57319 2.87193 8.54351 2.0625 9.70288 2.0625C10.8623 2.0625 11.8326 2.87193 12.0796 3.9565C11.592 3.93749 11.0225 3.93749 10.3459 3.9375H9.05984ZM7.10067 7.85225C6.881 7.63258 6.52484 7.63258 6.30517 7.85225C6.0855 8.07192 6.0855 8.42808 6.30517 8.64775L6.60517 8.94775C8.31601 10.6586 11.0898 10.6586 12.8007 8.94775L13.1007 8.64775C13.3203 8.42808 13.3203 8.07192 13.1007 7.85225C12.881 7.63258 12.5248 7.63258 12.3052 7.85225L12.0052 8.15225C10.7337 9.42375 8.67217 9.42375 7.40067 8.15225L7.10067 7.85225Z'
        fill={color}
      />
    </svg>
  );
};
