import { gql } from '@apollo/client';
import { FRAGMENT_MESSAGE } from '../fragments';

export const GET_CONVERSATION = gql`
  ${FRAGMENT_MESSAGE}
  query GetConversation($conversationId: Float!) {
    getConversation(conversationId: $conversationId) {
      id
      shopId
      customerId
      assignedUserId
      status
      tag
      provider
      updatedAt
      lastMessageReceived
      lastMessage
      shop {
        id
        name
        email
        phone
        smsAvailable
        emailAvailable
        liveChatAvailable
        tone
        agentFirstName
        notes
        deactivated
        deactivatedAt
        url
        domainId
        createdAt
        verifiedSender
        templates {
          id
          name
          text
          shortcut
          type
        }
      }
      assignedUser {
        id
        email
        firstName
        lastName
        role
        avatar
        blocked
        blockedAt
        refreshToken
        createdAt
        updatedAt
      }
      messages {
        ...Message
      }
      tags
      customer {
        country
        email
        firstName
        lastName
        id
        phone
      }
      createdAt
      subject
    }
  }
`;
