import { styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const DatePickerCSS = styled(DatePicker)(({ theme }) => {
  return {
    width: '160px',
    minWidth: 'fit-content',
    justifyContent: 'center',
    borderRadius: '18px',
    background: theme.vars.palette.blackWhite.main,
    input: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16px',
      color: theme.vars.palette.whiteBlack.main,
      padding: '8px 16px',
      paddingRight: 0,
    },
    '.MuiButtonBase-root': {
      color: theme.vars.palette.whiteBlack.main,
      width: '36px',
      height: '36px',
    },
    fieldset: {
      borderRadius: '18px', // for fieldset? when error date
      borderWidth: '2px',
    },
  };
});
