import { Stack } from '@mui/material';
import { styled } from '@mui/system';

export const Wrapper = styled(Stack)(() => ({
  height: 'calc(100vh - 112px)',
}));

export const InnerBlockCSS = styled(Stack)(() => ({
  gap: '16px',
  alignItems: 'center',
  marginTop: '24vh',
}));
