import { capitalizeFirstLetter } from '../../utils';
import { Wrapper } from './OrderInformationStatuses.styled';
import { OrderRowItem } from '../Containers/ToChatsPage';

interface OrderInformationStatusesProps {
  financialStatus: string | undefined | null;
  fulfillmentStatus: string | undefined | null;
  shipmentStatus: string[] | undefined | null;
  isCancelled: boolean | undefined;
}

export const OrderInformationStatuses = ({
  financialStatus,
  fulfillmentStatus,
  shipmentStatus,
  isCancelled,
}: OrderInformationStatusesProps) => {
  return (
    <Wrapper>
      {isCancelled && <OrderRowItem label={'Order status'} data={'Cancelled'} isBadge />}
      {financialStatus && (
        <OrderRowItem
          label={'Financial status'}
          data={capitalizeFirstLetter(financialStatus)}
          isBadge
        />
      )}
      {fulfillmentStatus && (
        <OrderRowItem
          label={'Fulfillment status'}
          data={capitalizeFirstLetter(fulfillmentStatus)}
          isBadge
        />
      )}
      {shipmentStatus?.map((status, i) => {
        return (
          <OrderRowItem
            key={i}
            label={'Shipment status'}
            data={capitalizeFirstLetter(status)}
            isBadge
          />
        );
      })}
    </Wrapper>
  );
};
