import { IconButton, ListItem, Stack, styled } from '@mui/material';

export const ListItemCSS = styled(ListItem)(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'canter',
    padding: '13px 24px 13px 26px',
    border: `1px solid ${theme.vars.palette.silver.main}`,
    borderRadius: '36px',
  };
});

export const ButtonsBlockCSS = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 'auto',
});

export const TemplateButtonCSS = styled(IconButton)(({ theme }) => ({
  '& svg path': {
    fill: theme.vars.palette.primary.main,
  },
}));
