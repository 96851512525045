export function formatMilliseconds(ms: number) {
  if (ms < 1000) {
    return ms + ' ms';
  } else if (ms < 60000) {
    return Math.round(ms / 1000) + ' sec';
  } else {
    const hours = Math.floor(ms / 3600000);
    const minutes = Math.floor((ms % 3600000) / 60000);
    // const seconds = Math.floor((ms % 60000) / 1000);
    let result = '';

    if (hours > 0) {
      result += hours + ' h ';
    }

    if (minutes > 0) {
      result += minutes + ' min ';
    }
    //
    // if (seconds > 0) {
    //   result += seconds + ' sec';
    // }

    return result;
  }
}
