import { IconProps } from './types';

export const HomeIcon = ({ color = '#fff' }: IconProps) => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.42313 3.72417C3.42302 5.17734 2.42296 5.90392 2.03018 6.97718C1.99868 7.06328 1.97033 7.1505 1.94522 7.23868C1.63214 8.33784 2.01412 9.51347 2.7781 11.8647C3.54207 14.216 3.92406 15.3917 4.82342 16.0969C4.89557 16.1534 4.96976 16.2073 5.04586 16.2585C5.36585 16.4735 5.71855 16.616 6.14038 16.7104V13.3647C6.14038 11.3972 7.73537 9.80225 9.70288 9.80225C11.6704 9.80225 13.2654 11.3972 13.2654 13.3647V16.7104C13.6872 16.616 14.0399 16.4735 14.3599 16.2585C14.436 16.2073 14.5102 16.1534 14.5823 16.0969C15.4817 15.3917 15.8637 14.216 16.6277 11.8647C17.3916 9.51347 17.7736 8.33784 17.4605 7.23868C17.4354 7.1505 17.4071 7.06328 17.3756 6.97718C16.9828 5.90392 15.9828 5.17734 13.9826 3.72418C11.9825 2.27101 10.9825 1.54442 9.84036 1.50252C9.74874 1.49916 9.65703 1.49916 9.56541 1.50252C8.4233 1.54442 7.42324 2.271 5.42313 3.72417Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.1404 16.8549V13.3647C12.1404 12.0186 11.0491 10.9272 9.70288 10.9272C8.35669 10.9272 7.26538 12.0186 7.26538 13.3647V16.8549C7.9201 16.8959 8.71226 16.8959 9.70288 16.8959C10.6935 16.8959 11.4857 16.8959 12.1404 16.8549ZM10.2654 13.3647C10.2654 13.0541 10.0135 12.8022 9.70288 12.8022C9.39222 12.8022 9.14038 13.0541 9.14038 13.3647V14.8647C9.14038 15.1754 9.39222 15.4272 9.70288 15.4272C10.0135 15.4272 10.2654 15.1754 10.2654 14.8647V13.3647Z'
        fill={color}
      />
    </svg>
  );
};
