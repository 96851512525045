import { Box, Stack, StackProps, styled } from '@mui/material';

interface EditProps extends StackProps {
  iseditor?: string;
}

export const Wrapper = styled(Stack)<EditProps>(({ iseditor, theme }) => {
  const isEditor = iseditor === 'true';

  return {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '16px',
    padding: '12px 16px',
    background: theme.vars.palette.lightSilverDark.main,
    borderRadius: '32px',

    '&:first-of-type': {
      marginTop: 0,
    },

    ...(isEditor && {
      padding: '0 0 16px 0',
      background: 'transparent',
      borderBottom: `1px solid ${theme.vars.palette.white.main}`,
      borderRadius: '0',
    }),
  };
});

export const OrderItemListCSS = styled(Box)({
  // flex: 1,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr auto',
  // justifyContent: 'space-between',
  // flexDirection: 'row',
});
