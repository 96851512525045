import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const Wrapper = styled(Stack)({
  position: 'relative',
  minHeight: '24px',
  alignItems: 'center',
});

export const IconBlockCSS = styled(Stack)(({ theme }) => {
  return {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '12px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    background: theme.vars.palette.silver20silver200.main,
  };
});

export const IconButtonBlockCSS = styled(Stack)({
  position: 'absolute',
  right: '0px',
});
