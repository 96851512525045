import { useState } from 'react';
import { AddNewButton } from '../../../UI';
import { ChatTemplateEditingItem } from '../ChatTemplateEditingItem/ChatTemplateEditingItem';
import { ChatTemplatesForm } from '../ChatTemplatesForm/ChatTemplatesForm';
import {
  TemplateSchema,
  TemplateTypeEnum,
  useDeleteShopTemplateMutation,
} from '../../../../graphql/generated';
import { toast } from 'react-toastify';
import { ModalFormWrapperCSS, ModalWrapper } from '../../../Modal/ModalController/styles';
import { TemplateChangesConfirmationModal } from '../../../Modal';

interface ChatTemplateEditingListProps {
  templateData?: TemplateSchema[];
  shopId: number;
}

export const ChatTemplateEditingList = ({ templateData, shopId }: ChatTemplateEditingListProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [editId, setEditId] = useState(0);
  const [isNewTemplate, setIsNewTemplate] = useState(false);

  const [tempGeneralRemoveId, setTempGeneralRemoveId] = useState(0);

  const handleEdit = (id: number) => {
    setEditId(id);
    if (isNewTemplate) {
      setIsNewTemplate(false);
    }
  };

  const handleNewTemplate = () => {
    setIsNewTemplate(true);
    if (editId) {
      setEditId(0);
    }
  };

  const handleBack = () => {
    setEditId(0);
    setIsNewTemplate(false);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const [deleteTemplate] = useDeleteShopTemplateMutation({
    onCompleted: () => {
      toast.success('Template has been removed');
    },
    onError: (error) => {
      toast.error(error.message);
    },
    update: (cache) => {
      cache.evict({ id: `ShopEntity:${shopId}` });
    },
  });

  const handleRemove = (id: number) => {
    deleteTemplate({
      variables: {
        input: {
          globalDelete: !!tempGeneralRemoveId,
          templateId: +id,
          shopId: Number(shopId),
        },
      },
    });
  };

  const handlePreDelete = (id: number) => {
    const targetTemplate = templateData?.find((template) => +template.id === +id);
    if (targetTemplate?.type === TemplateTypeEnum.General) {
      setIsOpenModal(true);
      setTempGeneralRemoveId(id);
    } else {
      handleRemove(id);
      setTempGeneralRemoveId(0);
    }
  };

  const handleOnApplyToModal = () => {
    handleRemove(tempGeneralRemoveId);
    setIsOpenModal(false);
  };

  return (
    <>
      {!isNewTemplate ? (
        <AddNewButton onClick={handleNewTemplate}>Add New</AddNewButton>
      ) : (
        <ChatTemplatesForm onClose={handleBack} shopId={shopId} />
      )}
      {templateData?.map((template) => {
        if (editId === template.id) {
          return (
            <ChatTemplatesForm
              key={template.id}
              onClose={handleBack}
              template={template}
              shopId={shopId}
            />
          );
        }
        return (
          <ChatTemplateEditingItem
            key={template.id}
            {...template}
            onEdit={handleEdit}
            onRemove={handlePreDelete}
          />
        );
      })}
      <ModalWrapper open={isOpenModal} onClose={handleCloseModal}>
        <ModalFormWrapperCSS>
          <TemplateChangesConfirmationModal
            onClose={handleCloseModal}
            onApply={handleOnApplyToModal}
          />
        </ModalFormWrapperCSS>
      </ModalWrapper>
    </>
  );
};
