import { parsePhoneNumber } from 'awesome-phonenumber';

type getIntlPhoneNumberArguments = string | null | undefined;
export const getIntlPhoneNumber = (
  phoneNumber: getIntlPhoneNumberArguments = '',
): { intlPhone?: string } => {
  if (!phoneNumber) return { intlPhone: '' };
  const incomingPH = phoneNumber[0] !== '+' ? `+${phoneNumber}` : phoneNumber;
  const pn = parsePhoneNumber(incomingPH);

  return { intlPhone: pn.number?.international || pn.number?.input };
};
