import { Badge } from '@mui/material';
import { styled } from '@mui/material/styles';

interface BadgeWrapperProps {
  bg?: string;
}

export const BadgeWrapper = styled(Badge)<BadgeWrapperProps>(({ theme, bg }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    width: 'fit-content',
    alignItems: 'center',
    height: '20px',
    padding: '0 8px',
    color: theme.vars.palette.white.main,
    borderRadius: '100px',
    background: bg,
  };
});
