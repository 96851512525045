import { styled } from '@mui/material';
import { Link, useMatch } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';

export const CustomHeaderLinkCSS = styled(Link)(({ theme, to }) => {
  const isActive = !!useMatch(to as string);

  const colorSvg =
    to === ROUTES.users
      ? { stroke: theme.vars.palette.whiteBlack.main }
      : { fill: theme.vars.palette.whiteBlack.main };

  return {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '60px',
    height: '60px',
    borderRadius: '50%',
    background: theme.vars.palette.whiteSilver.main,
    gap: '6px',
    textDecoration: 'none',
    span: {
      color: theme.vars.palette.blackWhite.main,
    },
    position: 'relative',
    p: {
      position: 'absolute',
      left: '20px',
      top: '20px',
    },
    transition: '0.3s',
    'span:last-of-type': {
      transition: '0.3s',
      opacity: 0,
    },
    ...(isActive && {
      background: theme.vars.palette.primary.main,

      width: 'auto',
      padding: '0 26px 0 20px',
      borderRadius: '1000px',
      'span:last-of-type': {
        display: 'inline',
        color: theme.vars.palette.whiteBlack.main,
        marginLeft: '24px',
      },
      svg: {
        path: colorSvg,
      },
    }),
    '&:hover': {
      width: 'auto',
      padding: '0 26px 0 20px',
      borderRadius: '1000px',
      'span:last-of-type': {
        opacity: 1,
        marginLeft: '24px',
      },
    },
  };
});
