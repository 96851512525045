import { IconButton, Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => {
  return {
    flexDirection: 'row',
    padding: '8px 16px 8px 24px',
    borderRadius: '36px',
    border: `1px solid ${theme.vars.palette.silver20silver200.main}`,
  };
});

export const ButtonsBlockCSS = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 'auto',
});

export const TemplateButtonCSS = styled(IconButton)(({ theme }) => ({
  '& svg path': {
    fill: theme.vars.palette.primary.main,
  },
}));
