import { gql } from '@apollo/client';

export const GET_CONVERSATIONS = gql`
  query GetConversations($input: GetConversationsOptions!) {
    getConversations(input: $input) {
      rows {
        id
        shopId
        assignedUserId
        status
        tag
        provider
        updatedAt
        shop {
          id
          name
          phone
          email
          agentFirstName
        }
        customer {
          phone
          lastName
          id
          firstName
          email
          country
        }
        assignedUser {
          id
          email
          firstName
          lastName
          role
          avatar
          blocked
          blockedAt
          refreshToken
          createdAt
          updatedAt
        }
        lastMessageReceived
        lastMessage
      }
      count
    }
  }
`;
