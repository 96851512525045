import { InfoFieldListOption } from '../components/InformationBlock/InfoFieldsList';

export const getFormatTextWithIndents = (item: InfoFieldListOption | { content: string }) => {
  const match = item.content.match('\n');
  if (!match) return item.content;
  return item.content.split('\n');
};

// export const getFormatTextWithIndents = (item: InfoFieldListOption | { content: string }) => {
//   const { content } = item;
//   const lines = content.split(/\n/);
//   return lines;
// };
