import { KeyArgsFunction, KeySpecifier } from '@apollo/client/cache/inmemory/policies';
import { ConversationsWithCountSchema } from '../generated';

export const paginationMergeGetMetrics = (
  keyArguments?: KeySpecifier | KeyArgsFunction | false,
) => {
  return {
    ['getMetrics']: {
      keyArgs: keyArguments,
      merge(
        existing: ConversationsWithCountSchema,
        incoming: ConversationsWithCountSchema,
        { args }: Record<string, any>,
      ) {
        return {
          ...incoming,
          rows:
            args?.input?.offset === 0
              ? [...incoming.rows]
              : [...(existing?.rows || []), ...incoming.rows],
        };
      },
    },
  };
};
