import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme: { palette } }) => ({
  justifyContent: 'center',
  borderRadius: '1000px',
  background: palette.white.main,
  backdropFilter: 'blur(3px)',
  padding: '0 26px 0 32px',
  gap: '2px',
}));
