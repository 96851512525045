import { Button, Stack } from '@mui/material';
import { ChevronBottomIcon, TriangleToModalHeader } from '../../../Icons';
import { HeaderBlockCSS, RightBlockCSS, TriangleIconBlockCSS } from './styles';

import {
  ModalShopHeaderSettings,
  ModalShopHeaderSettingsProps,
} from '../ModalShopHeaderSettings/ModalShopHeaderSettings';
import { usePalette } from '../../../../hooks';

interface ModalShopHeaderProps extends ModalShopHeaderSettingsProps {
  goBack?: () => void;
}

export const ModalShopHeader = ({
  isActive,
  companyName,
  onChange,
  goBack,
  linkUrl,
}: ModalShopHeaderProps) => {
  const { palette } = usePalette();
  return (
    <HeaderBlockCSS direction={'row'}>
      <Stack direction={'row'} gap={'8px'}>
        <Button
          variant={'back'}
          onClick={goBack}
          startIcon={<ChevronBottomIcon color={'black'} sx={{ rotate: '90deg' }} />}
        >
          Back
        </Button>
        <ModalShopHeaderSettings
          isActive={isActive}
          companyName={companyName}
          onChange={onChange}
          linkUrl={linkUrl}
        />
      </Stack>
      <RightBlockCSS>
        <TriangleIconBlockCSS>
          <TriangleToModalHeader color={palette.silver20silver200.main} />
        </TriangleIconBlockCSS>
      </RightBlockCSS>
    </HeaderBlockCSS>
  );
};
