import { ContentBlockCSS, InnerCSS, Wrapper } from './styles';
import { ModalShopHeader } from '../ModalComponents';
import { useModalContext } from '../../../context';
import { InformationBlock } from '../../InformationBlock';
import { CompanyTemplates } from '../../CompanyTemplates';
import {
  ConversationProviderEnum,
  useEditShopMutation,
  useGetShopQuery,
} from '../../../graphql/generated';
import { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { SHOP_SWITCHER_OPTIONS } from '../../../constants/constants';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { shopsEditInformationSchema } from '../../../validation';
import {
  CheckedSchema,
  getFieldListSchema,
  getInfoFieldsListHorizontalSchema,
  ShopEditFormData,
} from './types';
import { getIntlPhoneNumber } from '../../../utils/getIntlPhoneNumber';

const getInfoFieldsListHorizontal = ({ phone, email }: getInfoFieldsListHorizontalSchema) => {
  const data = [
    { title: 'Phone', content: phone },
    { title: 'Email', content: email },
  ];
  return data.filter((it) => it.content);
};

const getFieldList = ({ tone, agentFirstName, notes }: getFieldListSchema) => {
  const data = [
    { title: 'Tone', content: tone },
    { title: 'Agent Name', content: agentFirstName },
    { title: 'Company notes', content: notes },
  ];
  return data.filter((it) => it.content);
};

export const ShopSettingsModal = () => {
  const { infoModal, onCloseModal } = useModalContext();
  const shopId = infoModal.content?.shopId;
  const modalData = infoModal.content?.data;

  const { data: shopData, loading: shopLoading } = useGetShopQuery({
    variables: {
      getShopId: Number(shopId),
    },
  });

  const shopInfo = shopData?.getShop;
  const companyName = shopInfo?.name;
  const agentFirstName = shopInfo?.agentFirstName;
  const phone = shopInfo?.phone;
  const email = shopInfo?.email;
  const tone = shopInfo?.tone;
  const notes = shopInfo?.notes;
  const shopUrl = shopInfo?.url;

  const isActive = !modalData?.deactivated;
  const smsAvailable = modalData?.smsAvailable;
  const emailAvailable = modalData?.emailAvailable;
  const liveChatAvailable = modalData?.liveChatAvailable;
  const { intlPhone } = getIntlPhoneNumber(phone);

  const shopTemplate = shopData?.getShop.templates;

  const [isActiveChecked, setIsActiveChecked] = useState(isActive as boolean);

  const [checked, setChecked] = useState<CheckedSchema>({
    [ConversationProviderEnum.Sms]: smsAvailable as boolean,
    [ConversationProviderEnum.Email]: emailAvailable as boolean,
    [ConversationProviderEnum.LiveChat]: liveChatAvailable as boolean,
  });

  const [editShop, { loading: editLoading }] = useEditShopMutation({
    onCompleted: () => {
      toast.success('Information has been changed');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const infoFieldsListHorizontal = getInfoFieldsListHorizontal({
    phone: intlPhone || '',
    email: email || '',
  });

  const infoFieldsList = getFieldList({
    agentFirstName: agentFirstName || '',
    tone: tone || '',
    notes: notes || '',
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>, name?: string) => {
    setChecked((prevState) => {
      return {
        ...prevState,
        [name as ConversationProviderEnum]: event.target.checked,
      };
    });
  };

  const handleActiveChecked = () => {
    setIsActiveChecked((prevState) => !prevState);
  };

  useEffect(() => {
    if (!shopId) return;
    // эта проверка необходима для того, чтобы у нас не отправлялся лишний запрос на первом рендере,
    // когда данные ещё не изменены
    if (
      checked[ConversationProviderEnum.Sms] === smsAvailable &&
      checked[ConversationProviderEnum.Email] === emailAvailable &&
      checked[ConversationProviderEnum.LiveChat] === liveChatAvailable &&
      isActiveChecked === isActive
    )
      return;
    editShop({
      variables: {
        input: {
          id: Number(shopId),
          smsAvailable: checked[ConversationProviderEnum.Sms],
          emailAvailable: checked[ConversationProviderEnum.Email],
          liveChatAvailable: checked[ConversationProviderEnum.LiveChat],
          deactivated: !isActiveChecked,
        },
      },
    });
  }, [
    checked[ConversationProviderEnum.Sms],
    checked[ConversationProviderEnum.Email],
    checked[ConversationProviderEnum.LiveChat],
    isActiveChecked,
  ]);

  const INITIAL_VALUES = {
    isEdit: false,
    tone: tone || '',
    phone: intlPhone || '',
    email: email || '',
    agentName: agentFirstName || '',
    notes: notes || '',
  };

  const methods = useForm<ShopEditFormData>({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(shopsEditInformationSchema),
  });

  const { watch, setValue, reset } = methods;
  const [isEdit] = watch(['isEdit', 'tone']);

  useEffect(() => {
    reset(INITIAL_VALUES);
  }, [shopData]);

  const handleEditForm = () => {
    setValue('isEdit', !isEdit);
  };

  const listName = {
    tone: 'tone',
    phone: 'phone',
    email: 'email',
    agentName: 'agentName',
    notes: 'notes',
  };

  const onSubmit = async (value: ShopEditFormData) => {
    await editShop({
      variables: {
        input: {
          id: Number(shopId),
          tone: value.tone,
          phone: value.phone,
          email: value.email,
          agentFirstName: value.agentName,
          notes: value.notes,
        },
      },
    });
    setValue('isEdit', false);
  };

  const handleGoBack = () => {
    onCloseModal();
  };

  return (
    <Wrapper>
      <ModalShopHeader
        isActive={isActiveChecked}
        companyName={companyName}
        onChange={handleActiveChecked}
        goBack={handleGoBack}
        linkUrl={shopUrl}
      />
      <ContentBlockCSS>
        <InnerCSS>
          <InformationBlock
            infoFieldsListHorizontal={infoFieldsListHorizontal}
            infoFieldsList={infoFieldsList}
            informationSwitcher={SHOP_SWITCHER_OPTIONS}
            checked={checked}
            onChangeChecked={handleChange}
            onClick={handleEditForm}
            isEdit={isEdit}
            methods={methods}
            listName={listName}
            onSubmit={onSubmit}
            onClickCancel={handleEditForm}
            isLoading={editLoading || shopLoading}
          />
        </InnerCSS>
        <InnerCSS>
          <CompanyTemplates shopId={shopId} shopTemplate={shopTemplate} />
        </InnerCSS>
      </ContentBlockCSS>
    </Wrapper>
  );
};
