import { Stack } from '@mui/material';
import { ActionButton, Wrapper } from './styles';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { TextFieldRHF } from '../../../UI';
import { ListNameSchema, ShopEditFormData } from '../../../Modal/ShopSettingsModal/types';

interface ShopsEditInformationFormProps {
  methods: UseFormReturn<ShopEditFormData>;
  listName: ListNameSchema;
  onSubmit: (value: ShopEditFormData) => void;
  onClickCancel: () => void;
  isLoading: boolean;
}

export const ShopsEditInformationForm = ({
  methods,
  listName,
  onSubmit,
  onClickCancel,
  isLoading,
}: ShopsEditInformationFormProps) => {
  const {
    formState: { isDirty },
  } = methods;
  return (
    <Wrapper mt={'16px'}>
      <FormProvider {...methods}>
        <Stack component={'form'} gap={'16px'} onSubmit={methods.handleSubmit(onSubmit)}>
          <TextFieldRHF
            name={listName.tone}
            label={'Tone'}
            size={'medium'}
            placeholder={'Tone'}
            type={'text'}
          />
          <TextFieldRHF
            name={listName.phone}
            label={'Phone'}
            size={'medium'}
            placeholder={'Phone'}
            type={'text'}
          />
          <TextFieldRHF
            name={listName.email}
            label={'Email'}
            size={'medium'}
            placeholder={'Email'}
            type={'text'}
          />
          <TextFieldRHF
            name={listName.agentName}
            label={'Agent name'}
            size={'medium'}
            placeholder={'Agent name'}
            type={'text'}
          />
          <TextFieldRHF
            name={listName.notes}
            label={'Notes'}
            size={'medium'}
            placeholder={'Notes'}
            type={'text'}
            multiline
            minRows={2}
            maxRows={5}
          />
          <Stack direction={'row'} gap={'10px'}>
            <ActionButton iscancel={'true'} onClick={onClickCancel}>
              Cancel
            </ActionButton>
            <ActionButton type={'submit'} disabled={isLoading || !isDirty}>
              Save
            </ActionButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Wrapper>
  );
};
