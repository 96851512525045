import { BadgeWrapper } from './styles';
import { Typography } from '@mui/material';
import { getBadgeData } from '../../../utils';

interface IBadgeStatus {
  status: string;
}

export const BadgeStatus = ({ status }: IBadgeStatus) => {
  const isStatus = getBadgeData(status);

  return (
    <BadgeWrapper bg={isStatus.color}>
      <Typography variant='caption' component='p' fontWeight='500' whiteSpace={'nowrap'}>
        {isStatus.title}
      </Typography>
    </BadgeWrapper>
  );
};
