import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => {
  return {
    alignItems: 'center',
    width: '100%',
    marginTop: '16px',
    padding: '12px 16px',
    background: theme.vars.palette.lightSilverDark.main,
    borderRadius: '32px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 0.4fr 0.4fr 100px',
    '> div:not(:first-of-type)': {
      borderLeft: `1px solid ${theme.vars.palette.silverMidWhite.main}`,
      paddingLeft: '16px',
    },
  };
});

export const OrderItemDividerCSS = styled('span')(({ theme }) => {
  return {
    height: 'auto',
    marginRight: '16px',
    border: `1px solid ${theme.vars.palette.silverMidWhite.main}`,
  };
});
