import { LinkCSS, OrderItemCSS, OrderItemDividerCSS } from './styles';
import { IconButton, Stack, Typography } from '@mui/material';
import { BadgeStatus } from '../UI/BadgeStatus/BadgeStatus';
import { copyTextToClipboard } from '../../utils/copyTextToClipboard';
import { CopyIcon } from '../Icons/CopyIcon';
import { LABEL_WITH_SMALL_WIDTH, ORDER_LABEL_ENUM } from '../../constants/constants';
import { SelectOrder } from '../UI';
import { capitalizeFirstLetter } from '../../utils';

interface OrderItemProps {
  label: ORDER_LABEL_ENUM;
  status?: string[] | null;
  content?: string | string[];
}

export const OrderItem = ({ label, status, content }: OrderItemProps) => {
  const isSmallWidthItem = LABEL_WITH_SMALL_WIDTH.includes(label as ORDER_LABEL_ENUM);

  if (label === ORDER_LABEL_ENUM.Status && !status?.length) return null;

  return (
    <OrderItemCSS isSmallWidth={isSmallWidthItem}>
      <OrderItemDividerCSS />
      <Stack position={'relative'} width={'100%'}>
        <Typography variant='caption' component='p' color={'lightSilverWhite.main'} mb='2px'>
          {label}
        </Typography>
        {label === ORDER_LABEL_ENUM.Status ? (
          <Stack direction={'row'} gap={'14px'}>
            {status?.map((status) => (
              <BadgeStatus status={capitalizeFirstLetter(status)} />
            ))}
          </Stack>
        ) : label === ORDER_LABEL_ENUM.TrackingUrl || label === ORDER_LABEL_ENUM.TrackingId ? (
          Array.isArray(content) && content.length > 1 ? (
            <SelectOrder option={content} />
          ) : (
            <Stack direction={'row'}>
              {label === ORDER_LABEL_ENUM.TrackingUrl ? (
                <LinkCSS href={content as string} target='_blank' rel='noopener noreferrer'>
                  <Typography variant='body4' component='p' color={'mail.main'}>
                    {content}
                  </Typography>
                </LinkCSS>
              ) : (
                <Typography variant='body4' component='p' color={'mail.main'}>
                  {content}
                </Typography>
              )}
              {!!String(content) && (
                <IconButton
                  sx={{ padding: 0, marginLeft: 'auto' }}
                  onClick={() => copyTextToClipboard(String(content))}
                >
                  <CopyIcon />
                </IconButton>
              )}
            </Stack>
          )
        ) : (
          <>
            {label === ORDER_LABEL_ENUM.Link || label === ORDER_LABEL_ENUM.ItemLink ? (
              <LinkCSS href={content as string} target='_blank' rel='noopener noreferrer'>
                <Typography variant='body4' component='p'>
                  {content}
                </Typography>
              </LinkCSS>
            ) : (
              <Typography variant='body4' component='p'>
                {content}
              </Typography>
            )}
          </>
        )}
      </Stack>
    </OrderItemCSS>
  );
};
