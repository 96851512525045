import { styled, Box, Typography, FormGroup, Stack } from '@mui/material';

export const InformationBlockWrapper = styled(Stack)({
  overflow: 'hidden',
  flex: 1,
});

export const InfoWrapperCSS = styled(Stack)({
  overflowY: 'auto',
  gap: '16px',
});

export const SwitchersGroupCSS = styled(FormGroup)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '82px',
    minHeight: '82px',
    border: `1px solid ${theme.vars.palette.silver.main}`,
    borderRadius: '100px',
  };
});

export const InfoRowCSS = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 0',
    borderBottom: `1px solid ${theme.vars.palette.silver.main}`,

    '&:last-of-type': {
      borderBottom: 'none',
    },
  };
});

export const InfoFieldCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 20px',
});

export const InfoFieldLabelCSS = styled(Typography)(({ theme }) => {
  return {
    marginBottom: '4px',
    color: theme.vars.palette.midSilver.main,
  };
});
