export const LogoIcon = ({ color = '#fff' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='255'
      height='36'
      viewBox='0 0 255 36'
      fill='none'
    >
      <path
        d='M5.70268 0C5.8982 0.570265 6.06928 1.05906 6.23221 1.55193C6.47488 2.29552 6.89418 2.96921 7.4542 3.51527L9.79636 5.78004C10.0957 5.98674 10.4509 6.09744 10.8147 6.09744C11.1785 6.09744 11.5337 5.98674 11.833 5.78004L14.1752 3.52342C14.7309 2.98827 15.1499 2.32753 15.3972 1.59674C15.5683 1.09165 15.7434 0.590631 15.9471 0C15.9983 0.103952 16.0419 0.211501 16.0774 0.321792C16.3951 1.86558 16.7129 3.40937 17.0102 4.95723C17.0513 5.1813 17.0301 5.41235 16.9491 5.62525C16.4603 6.79837 15.9389 7.95926 15.442 9.12831C15.2953 9.47454 15.0917 9.58859 14.7292 9.43788C13.7801 9.03055 12.8228 8.67617 11.8778 8.27698C11.7024 8.18686 11.5504 8.05717 11.4338 7.89816C11.0265 7.40529 10.6192 7.40937 10.2118 7.89816C10.0702 8.07238 9.89041 8.21174 9.68638 8.3055C8.78211 8.71283 7.86154 9.04277 6.95726 9.42159C6.89102 9.4668 6.81468 9.49502 6.73496 9.50377C6.65524 9.51251 6.5746 9.5015 6.50014 9.47172C6.42568 9.44194 6.35969 9.39429 6.30799 9.33298C6.25629 9.27167 6.22048 9.19858 6.20369 9.12016C5.71082 7.95112 5.19351 6.79022 4.70064 5.61711C4.62339 5.43328 4.5994 5.23143 4.63139 5.03462C4.94504 3.40529 5.27498 1.80041 5.60492 0.187373C5.63027 0.121399 5.66307 0.0585342 5.70268 0ZM9.26683 6.8391L6.88801 5.70265C7.0428 6.03258 7.18536 6.4277 7.3931 6.78208C7.943 7.73931 8.6762 7.73931 9.26683 6.84725V6.8391ZM12.3626 6.8391C12.7251 7.41344 13.2383 7.65377 13.6864 7.38085C14.2077 6.98382 14.5539 6.39944 14.6518 5.75153L12.3626 6.8391ZM15.666 5.03462C16.4807 4.09369 16.2485 3.16904 15.9023 2.23218C15.8249 3.14053 15.7475 4.04888 15.666 5.03462ZM5.73934 2.24033C5.4094 3.17312 5.13649 4.10183 5.96337 4.99796C5.88598 4.03259 5.81266 3.13646 5.73934 2.24033Z'
        fill={color}
      />
      <path
        d='M16.7251 6.45215C16.8391 6.88392 16.9124 7.20979 17.0143 7.51936C17.1329 7.76697 17.1808 8.04253 17.1528 8.31566C17.1248 8.58878 17.0219 8.84886 16.8554 9.06722C16.7658 9.24738 16.7345 9.45095 16.7658 9.6497C16.8084 10.3048 16.8084 10.962 16.7658 11.6171C16.6986 11.5289 16.6374 11.4364 16.5825 11.3401C16.2811 10.7006 15.9756 10.0652 15.6864 9.4216C15.6176 9.29749 15.5986 9.15184 15.6334 9.01427C15.9593 8.1996 16.3218 7.38494 16.7251 6.45215Z'
        fill={color}
      />
      <path
        d='M8.33405 6.99385H8.39922L8.02448 6.81055C8.10005 6.90942 8.21102 6.97513 8.33405 6.99385Z'
        fill={color}
      />
      <path
        d='M13.613 6.80664L13.2424 6.97772H13.3035C13.3525 6.97702 13.4004 6.96294 13.442 6.93699C13.5045 6.90129 13.562 6.85746 13.613 6.80664Z'
        fill={color}
      />
      <path
        d='M17.2017 6.29325V6.32584L17.3035 6.73317C17.3646 6.96943 17.4175 7.17716 17.4827 7.37268C17.6264 7.69453 17.6825 8.04864 17.6453 8.39914C17.6081 8.74964 17.479 9.08409 17.2709 9.36861C17.2646 9.45811 17.2646 9.54795 17.2709 9.63745C17.2709 9.75965 17.2709 9.86555 17.2709 9.97553C17.2709 10.0203 17.2709 10.0611 17.2709 10.1059C17.6976 10.6583 17.9462 11.3274 17.9837 12.0244H21.4949C21.4637 10.8791 21.1488 9.75943 20.5784 8.76576C19.8455 7.4882 18.7279 6.47465 17.385 5.86963L17.2017 6.29325Z'
        fill={color}
      />
      <path
        d='M11.4909 5.42558C11.556 5.3604 11.6212 5.29523 11.6905 5.23413H9.94708L10.1507 5.42558C10.347 5.55869 10.5823 5.62182 10.8188 5.6048C11.0564 5.62086 11.2928 5.55784 11.4909 5.42558Z'
        fill={color}
      />
      <path
        d='M11.6741 8.7374C11.4229 8.61811 11.2048 8.4391 11.0387 8.21601C10.8961 8.04494 10.8228 8.03271 10.8228 8.03271C10.8228 8.03271 10.7413 8.03272 10.5866 8.22416C10.4094 8.44019 10.1887 8.61646 9.9389 8.74147H11.6741V8.7374Z'
        fill={color}
      />
      <path
        d='M14.5458 15.6416H7.5723C7.37271 15.6416 7.16497 15.613 6.96945 15.613H6.61507C5.98613 15.5476 5.38725 15.3106 4.88388 14.9279C4.3805 14.5452 3.99199 14.0315 3.76077 13.443C3.52955 12.8544 3.46453 12.2137 3.57281 11.5907C3.68109 10.9677 3.9585 10.3864 4.37474 9.9104C4.4057 9.74585 4.40251 9.5767 4.36537 9.41344C4.32824 9.25017 4.25794 9.09628 4.15886 8.96132C4.06807 8.83587 4.00512 8.69249 3.97421 8.54076C3.9433 8.38902 3.94515 8.23244 3.97963 8.08148C4.06925 7.67415 4.17515 7.26682 4.2892 6.85948C4.32994 6.69655 4.37882 6.5214 4.42362 6.34217V6.30959L4.22403 5.83301C3.32782 6.2198 2.52292 6.79064 1.86151 7.50851C1.2001 8.22638 0.696965 9.07525 0.384721 10.0001C0.0724773 10.9249 -0.0418988 11.905 0.0490097 12.8769C0.139918 13.8488 0.434081 14.7907 0.912423 15.6416C1.20485 16.1492 1.55923 16.6185 1.96741 17.0387C2.61496 17.707 3.39057 18.2379 4.24789 18.5997C5.10522 18.9616 6.02668 19.1469 6.95723 19.1446H14.5458C15.4641 19.1446 16.3447 19.5094 16.9941 20.1587C17.6434 20.808 18.0081 21.6887 18.0081 22.6069C18.0081 23.5252 17.6434 24.4059 16.9941 25.0552C16.3447 25.7045 15.4641 26.0693 14.5458 26.0693H6.96945C6.08668 26.0678 5.23765 25.73 4.59518 25.1246C3.9527 24.5192 3.56508 23.6917 3.5112 22.8106H0C0.0561367 24.621 0.815252 26.3383 2.11636 27.5983C3.41746 28.8583 5.15822 29.562 6.96945 29.5601H14.5458C15.4739 29.5825 16.3971 29.4191 17.2611 29.0795C18.125 28.7399 18.9124 28.2308 19.5767 27.5824C20.241 26.9339 20.7689 26.1592 21.1294 25.3036C21.4898 24.4481 21.6755 23.5292 21.6755 22.6008C21.6755 21.6725 21.4898 20.7535 21.1294 19.898C20.7689 19.0425 20.241 18.2677 19.5767 17.6193C18.9124 16.9708 18.125 16.4618 17.2611 16.1221C16.3971 15.7825 15.4739 15.6191 14.5458 15.6416Z'
        fill={color}
      />
      <path
        d='M4.78206 11.6458C4.78206 11.1774 4.81464 10.77 4.84316 10.3668C4.88796 9.75985 4.98165 9.14478 4.54173 8.63154C4.46333 8.49451 4.44142 8.33233 4.48063 8.1794C4.60283 7.6295 4.75354 7.09183 4.92055 6.44824C5.32788 7.36881 5.68226 8.19977 6.03257 9.03073C6.0487 9.06945 6.05701 9.11098 6.05701 9.15293C6.05701 9.19487 6.0487 9.23641 6.03257 9.27513C5.68226 10.0328 5.32517 10.7877 4.96128 11.5399C4.94092 11.5684 4.88796 11.5806 4.78206 11.6458Z'
        fill={color}
      />
      <path
        d='M33.8615 18.7984H30.281V25.723C30.2598 25.9533 30.2849 26.1854 30.3549 26.4058C30.4248 26.6262 30.5382 26.8303 30.6884 27.0061C31.0217 27.2911 31.4475 27.4446 31.8859 27.4379C32.429 27.465 33.0889 27.465 33.8656 27.4379V30.2363C31.4786 30.5268 29.7665 30.3204 28.7291 29.6171C27.6918 28.9138 27.1717 27.6198 27.169 25.7352V18.8106H24.5092V15.7963H27.165V12.6803L30.281 11.7556V15.7963H33.8615V18.7984Z'
        fill={color}
      />
      <path
        d='M44.281 15.4216C45.0232 15.3904 45.7636 15.5158 46.4541 15.7895C47.1446 16.0631 47.7699 16.4791 48.2892 17.0102C49.3129 18.0692 49.8234 19.522 49.8207 21.3686V30.2281H46.7046V21.6741C46.7347 21.2229 46.6733 20.7702 46.5242 20.3433C46.3751 19.9163 46.1414 19.5239 45.837 19.1894C45.521 18.8938 45.1484 18.6653 44.7416 18.5176C44.3349 18.3699 43.9025 18.306 43.4704 18.3299C42.9814 18.3097 42.4934 18.3901 42.0366 18.5659C41.5798 18.7418 41.1639 19.0094 40.8146 19.3523C40.1412 20.0366 39.8044 21.0808 39.8044 22.4847V30.224H36.6884V10.0244H39.8044V17.6456C40.7495 16.1629 42.2416 15.4216 44.281 15.4216Z'
        fill={color}
      />
      <path
        d='M55.6211 24.342C55.8023 25.3583 56.3721 26.2641 57.2097 26.8675C58.1016 27.4735 59.1625 27.7815 60.2403 27.7473C61.8941 27.7473 63.1351 27.1499 63.9633 25.955L66.5295 27.4581C65.1065 29.5572 63.0006 30.6054 60.2118 30.6027C57.8628 30.6027 55.9619 29.8858 54.5091 28.452C53.7938 27.743 53.2322 26.8943 52.8594 25.9587C52.4866 25.0231 52.3105 24.0207 52.3421 23.0141C52.3156 22.0143 52.4914 21.0194 52.8589 20.0891C53.2263 19.1589 53.778 18.3125 54.4806 17.6007C55.1937 16.8775 56.0493 16.3104 56.9931 15.9352C57.9369 15.5601 58.9483 15.3851 59.9633 15.4214C60.941 15.392 61.9135 15.5756 62.8131 15.9597C63.7128 16.3437 64.518 16.919 65.1731 17.6455C66.5254 19.1128 67.2556 21.0477 67.2097 23.0426C67.2012 23.4778 67.1631 23.912 67.0957 24.342H55.6211ZM55.5926 21.8002H64.0774C63.9622 20.7982 63.4734 19.8764 62.7086 19.2188C61.9438 18.5611 60.9591 18.2161 59.9511 18.2524C58.9063 18.2159 57.8826 18.5523 57.0631 19.2015C56.2783 19.8644 55.7567 20.7862 55.5926 21.8002Z'
        fill={color}
      />
      <path
        d='M79.9796 19.7801C79.9881 20.0293 80.0699 20.2704 80.2149 20.4733C80.3598 20.6762 80.5613 20.8318 80.7943 20.9206C81.411 21.2307 82.0598 21.4725 82.7291 21.6416C83.4868 21.8453 84.2512 22.0815 85.0224 22.3503C85.7865 22.6157 86.461 23.0899 86.9694 23.719C87.5159 24.4136 87.7974 25.2799 87.7637 26.163C87.7867 26.7961 87.6525 27.425 87.3731 27.9936C87.0938 28.5622 86.678 29.0528 86.1629 29.4216C84.984 30.2423 83.5699 30.657 82.1344 30.6029C80.8541 30.639 79.5877 30.3294 78.4684 29.7068C77.4778 29.144 76.6983 28.2729 76.2485 27.2261L78.9124 25.662C79.4148 27.0523 80.482 27.7461 82.1141 27.7434C83.7434 27.7434 84.558 27.2017 84.558 26.1141C84.5459 25.8679 84.4624 25.6305 84.3178 25.4309C84.1732 25.2313 83.9736 25.0781 83.7434 24.9899C83.1306 24.6669 82.4809 24.4193 81.8086 24.2526C81.0468 24.0489 80.2851 23.8127 79.5153 23.5438C78.7557 23.2817 78.0814 22.8186 77.5642 22.2037C77.0205 21.5315 76.7396 20.6847 76.7739 19.8208C76.7554 19.2054 76.8831 18.5943 77.1465 18.0377C77.4099 17.4812 77.8015 16.995 78.2892 16.6192C79.3626 15.7848 80.6942 15.3525 82.053 15.3972C83.1692 15.3835 84.2694 15.6645 85.2424 16.2119C86.1578 16.7242 86.9049 17.4911 87.3931 18.4196L84.7658 19.8901C84.2634 18.8038 83.3591 18.2607 82.053 18.2607C81.5263 18.2412 81.0053 18.3756 80.554 18.6477C80.3676 18.7698 80.2166 18.9386 80.1158 19.1373C80.015 19.336 79.9681 19.5576 79.9796 19.7801Z'
        fill={color}
      />
      <path
        d='M100.037 15.7964H103.157V30.2282H100.037V28.3789C99.0944 29.8616 97.6008 30.6029 95.556 30.6029C94.8134 30.6328 94.0728 30.5069 93.3817 30.2333C92.6907 29.9597 92.0646 29.5445 91.5438 29.0143C90.5282 27.9607 90.0204 26.5079 90.0204 24.6559V15.7964H93.1365V24.3504C93.1072 24.8006 93.1685 25.2521 93.3168 25.6782C93.4652 26.1043 93.6975 26.4963 94 26.831C94.3156 27.1289 94.6885 27.3595 95.0961 27.5087C95.5037 27.6578 95.9373 27.7225 96.3707 27.6986C96.8599 27.7205 97.3484 27.641 97.8055 27.465C98.2625 27.2891 98.6782 27.0205 99.0265 26.6762C99.7027 25.9919 100.037 24.9491 100.037 23.5438V15.7964Z'
        fill={color}
      />
      <path
        d='M114.957 15.4216C115.912 15.4105 116.858 15.6014 117.733 15.9819C118.609 16.3624 119.394 16.9239 120.037 17.6293C120.727 18.3431 121.268 19.1864 121.63 20.1107C121.993 21.035 122.168 22.0218 122.147 23.0143C122.171 24.0089 121.997 24.9984 121.634 25.925C121.272 26.8516 120.729 27.6969 120.037 28.4114C119.392 29.1133 118.606 29.6713 117.731 30.0489C116.855 30.4265 115.91 30.6153 114.957 30.6029C112.766 30.6029 111.082 29.795 109.906 28.1792V36H106.79V15.7963H109.906V17.8737C111.082 16.239 112.766 15.4216 114.957 15.4216ZM111.202 26.3177C112.079 27.1573 113.246 27.6259 114.46 27.6259C115.674 27.6259 116.842 27.1573 117.719 26.3177C118.147 25.8833 118.483 25.3666 118.705 24.7988C118.928 24.231 119.033 23.6239 119.014 23.0143C119.033 22.4041 118.928 21.7964 118.705 21.228C118.482 20.6595 118.147 20.142 117.719 19.7067C116.842 18.8672 115.674 18.3986 114.46 18.3986C113.246 18.3986 112.079 18.8672 111.202 19.7067C110.773 20.1418 110.437 20.6592 110.214 21.2276C109.99 21.7961 109.884 22.4038 109.902 23.0143C109.884 23.6241 109.99 24.2313 110.214 24.7991C110.437 25.3669 110.773 25.8835 111.202 26.3177Z'
        fill={color}
      />
      <path
        d='M127.426 24.342C127.607 25.3583 128.177 26.2641 129.014 26.8675C129.906 27.4735 130.967 27.7815 132.045 27.7473C133.699 27.7473 134.94 27.1499 135.768 25.955L138.334 27.4581C136.911 29.5572 134.805 30.6054 132.016 30.6027C129.665 30.6027 127.764 29.8858 126.314 28.452C125.599 27.7427 125.038 26.8938 124.666 25.9582C124.294 25.0226 124.119 24.0204 124.151 23.0141C124.124 22.0145 124.299 21.0198 124.666 20.0896C125.032 19.1594 125.583 18.3128 126.285 17.6007C126.998 16.8775 127.854 16.3104 128.798 15.9352C129.741 15.5601 130.753 15.3851 131.768 15.4214C132.746 15.392 133.718 15.5756 134.618 15.9597C135.517 16.3437 136.323 16.919 136.978 17.6455C138.33 19.1128 139.06 21.0477 139.014 23.0426C139.006 23.4778 138.968 23.912 138.9 24.342H127.426ZM127.397 21.8002H135.882C135.767 20.7982 135.278 19.8764 134.513 19.2188C133.748 18.5611 132.764 18.2161 131.756 18.2524C130.711 18.2166 129.687 18.5529 128.868 19.2015C128.083 19.8644 127.561 20.7862 127.397 21.8002Z'
        fill={color}
      />
      <path
        d='M144.888 18.2198C145.675 16.433 147.157 15.5396 149.332 15.5396V18.9163C148.23 18.818 147.129 19.1202 146.232 19.7677C145.344 20.4112 144.904 21.4744 144.904 22.953V30.2279H141.752V15.7962H144.872L144.888 18.2198Z'
        fill={color}
      />
      <path
        d='M159.23 15.4216C159.973 15.3911 160.714 15.5168 161.405 15.7904C162.096 16.064 162.722 16.4796 163.242 17.0102C164.261 18.0692 164.77 19.522 164.77 21.3686V30.2281H161.654V21.6741C161.683 21.2232 161.622 20.771 161.474 20.3442C161.325 19.9174 161.093 19.5247 160.79 19.1894C160.474 18.8942 160.101 18.6659 159.695 18.5182C159.288 18.3706 158.856 18.3065 158.424 18.3299C157.934 18.309 157.446 18.389 156.989 18.5649C156.532 18.7408 156.117 19.0088 155.768 19.3523C155.092 20.0366 154.758 21.0794 154.758 22.4847V30.224H151.641V10.0244H154.758V17.6456C155.7 16.1629 157.191 15.4216 159.23 15.4216Z'
        fill={color}
      />
      <path
        d='M177.845 15.7964H180.961V30.2282H177.845V28.3789C176.904 29.8616 175.401 30.6029 173.365 30.6029C172.622 30.6333 171.881 30.5077 171.19 30.2341C170.499 29.9604 169.873 29.5449 169.352 29.0143C168.331 27.9607 167.821 26.5079 167.821 24.6559V15.7964H170.937V24.3504C170.908 24.8006 170.969 25.2521 171.117 25.6782C171.266 26.1043 171.498 26.4963 171.8 26.831C172.117 27.13 172.491 27.3611 172.9 27.5103C173.309 27.6595 173.744 27.7236 174.179 27.6986C174.668 27.7205 175.157 27.641 175.614 27.465C176.071 27.2891 176.487 27.0205 176.835 26.6762C177.506 25.9919 177.842 24.9478 177.845 23.5438V15.7964Z'
        fill={color}
      />
      <path
        d='M200.358 15.4216C201.092 15.3914 201.824 15.5174 202.505 15.7913C203.186 16.0651 203.801 16.4806 204.31 17.0102C205.312 18.0693 205.813 19.4936 205.813 21.2832V30.2445H202.697V21.4827C202.749 20.6412 202.48 19.8111 201.943 19.161C201.682 18.8862 201.365 18.6711 201.014 18.5305C200.662 18.3899 200.284 18.327 199.906 18.3463C199.477 18.3269 199.05 18.4022 198.653 18.567C198.257 18.7317 197.902 18.9818 197.613 19.2994C197.047 19.9349 196.762 20.884 196.762 22.1508V30.2282H193.646V21.4827C193.699 20.6485 193.447 19.8233 192.937 19.161C192.685 18.8836 192.375 18.6663 192.028 18.5253C191.681 18.3843 191.307 18.3231 190.933 18.3463C190.497 18.3263 190.062 18.4017 189.658 18.567C189.254 18.7323 188.891 18.9836 188.595 19.3035C188 19.9471 187.699 20.8962 187.699 22.1548V30.2282H184.582V15.7964H187.699V17.5153C188.625 16.1141 190.001 15.4121 191.829 15.4094C192.658 15.3685 193.483 15.5619 194.207 15.9675C194.932 16.373 195.528 16.9744 195.927 17.7027C196.899 16.182 198.376 15.4216 200.358 15.4216Z'
        fill={color}
      />
      <path
        d='M220.586 15.7963H223.707V30.2281H220.586V28.1507C219.413 29.78 217.73 30.5947 215.536 30.5947C214.581 30.6051 213.635 30.4138 212.76 30.0333C211.885 29.6529 211.1 29.0918 210.456 28.387C209.769 27.6742 209.229 26.8327 208.867 25.9107C208.506 24.9887 208.33 24.0044 208.35 23.0143C208.326 22.02 208.5 21.0308 208.861 20.1042C209.223 19.1777 209.765 18.3322 210.456 17.6171C211.101 16.9153 211.887 16.3571 212.762 15.9788C213.637 15.6005 214.582 15.4107 215.536 15.4216C217.73 15.4216 219.413 16.2363 220.586 17.8656V15.7963ZM212.766 26.3177C213.643 27.1572 214.81 27.6259 216.024 27.6259C217.239 27.6259 218.406 27.1572 219.283 26.3177C219.711 25.8833 220.047 25.3666 220.269 24.7987C220.492 24.2309 220.597 23.6239 220.578 23.0143C220.597 22.404 220.492 21.7964 220.269 21.228C220.046 20.6595 219.711 20.142 219.283 19.7067C218.406 18.8672 217.239 18.3986 216.024 18.3986C214.81 18.3986 213.643 18.8672 212.766 19.7067C212.337 20.1418 212.001 20.6592 211.778 21.2276C211.554 21.796 211.449 22.4038 211.466 23.0143C211.448 23.6241 211.554 24.2313 211.778 24.7991C212.001 25.3669 212.337 25.8835 212.766 26.3177Z'
        fill={color}
      />
      <path
        d='M234.933 15.4216C235.675 15.3916 236.416 15.5175 237.107 15.7911C237.798 16.0647 238.424 16.48 238.945 17.0101C239.963 18.0692 240.472 19.522 240.472 21.3686V30.2281H237.356V21.6741C237.386 21.2229 237.325 20.7702 237.176 20.3433C237.027 19.9163 236.793 19.5239 236.489 19.1894C236.173 18.8936 235.8 18.665 235.393 18.5172C234.987 18.3695 234.554 18.3058 234.122 18.3299C233.634 18.3093 233.146 18.3895 232.69 18.5654C232.234 18.7413 231.819 19.0091 231.47 19.3523C230.794 20.0366 230.46 21.0794 230.46 22.4847V30.224H227.34V15.7963H230.46V17.6456C231.403 16.1629 232.893 15.4216 234.933 15.4216Z'
        fill={color}
      />
      <path
        d='M246.619 19.78C246.626 20.0268 246.705 20.2663 246.846 20.4689C246.987 20.6715 247.184 20.8285 247.413 20.9205C248.03 21.2306 248.679 21.4724 249.348 21.6415C250.106 21.8452 250.87 22.0814 251.642 22.3503C252.406 22.6156 253.08 23.0898 253.589 23.7189C254.142 24.4106 254.431 25.2772 254.403 26.1629C254.426 26.796 254.292 27.4249 254.013 27.9935C253.733 28.5621 253.318 29.0527 252.802 29.4215C251.625 30.2417 250.212 30.6563 248.778 30.6028C247.498 30.6399 246.231 30.3303 245.112 29.7067C244.12 29.1441 243.339 28.2731 242.888 27.226L245.552 25.6619C246.052 27.0522 247.12 27.746 248.758 27.7433C250.387 27.7433 251.202 27.2016 251.202 26.114C251.189 25.8678 251.106 25.6304 250.961 25.4308C250.817 25.2312 250.617 25.078 250.387 24.9898C249.78 24.6739 249.137 24.4319 248.472 24.2688C247.715 24.0651 246.95 23.8289 246.179 23.56C245.421 23.298 244.748 22.8348 244.232 22.2199C243.68 21.551 243.39 20.7039 243.417 19.837C243.399 19.2216 243.527 18.6105 243.79 18.0539C244.053 17.4974 244.445 17.0112 244.933 16.6354C246.007 15.8004 247.34 15.3681 248.701 15.4134C249.816 15.4002 250.914 15.6812 251.886 16.2281C252.802 16.7393 253.55 17.5065 254.037 18.4358L251.413 19.9063C250.911 18.82 250.007 18.2769 248.701 18.2769C248.173 18.2568 247.65 18.3913 247.198 18.6639C247.013 18.7841 246.863 18.9501 246.761 19.1457C246.66 19.3413 246.611 19.5598 246.619 19.78Z'
        fill={color}
      />
    </svg>
  );
};
