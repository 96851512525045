import { styled, Typography, Box, Switch, SwitchProps } from '@mui/material';
import moon from '../../assets/icons/MoonIcon.svg';
import sun from '../../assets/icons/SunIcon.svg';

export const SwitcherContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const SwitcherInfoCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '12px',
});

export const SwitcherCaptionCSS = styled(Typography)(({ theme }) => {
  return {
    marginBottom: '4px',
    color: theme.vars.palette.midSilver.main,
    textTransform: 'uppercase',
  };
});

interface SwitcherProps extends SwitchProps {
  defaultswitcher?: string;
}

export const SwitcherCSS = styled(Switch)<SwitcherProps>(({ defaultswitcher, theme }) => {
  const isDefaultSwitcher = defaultswitcher === 'true';

  return {
    display: 'flex',
    width: '48px',
    height: '28px',
    padding: 0,
    border: `4px solid ${theme.vars.palette.silver.main}`,
    background: theme.vars.palette.silver.main,
    borderRadius: '100px',

    '&:active': {
      '& .MuiSwitch-thumb': {
        width: '16px',
      },

      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },

    '& .MuiSwitch-switchBase': {
      padding: '2px',
      color: theme.vars.palette.black.main,

      '&.Mui-checked': {
        color: theme.vars.palette.white.main,
        transform: 'translateX(20px)',

        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.vars.palette.black.main,
        },
      },
    },

    '& .MuiSwitch-thumb': {
      width: '16px',
      height: '16px',
      borderRadius: '100px',
      boxShadow: 'none',
      transitionDuration: 200,
    },

    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.vars.palette.white.main,
      boxSizing: 'border-box',
    },

    // Theme Switcher
    ...(isDefaultSwitcher && {
      display: 'flex',
      width: '48px',
      height: '28px',
      padding: '2px',
      background: theme.vars.palette.whiteBlack.main,
      border: `4px solid ${theme.vars.palette.silver.main}`,
      borderRadius: '100px',

      '& .MuiSwitch-switchBase': {
        padding: '2px',
        background: theme.vars.palette.whiteBlack.main,

        '&.Mui-checked': {
          color: theme.vars.palette.blackWhite.main,
          transform: 'translateX(20px)',
          background: theme.vars.palette.whiteBlack.main,

          '& + .MuiSwitch-track': {
            opacity: 1,
            background: theme.vars.palette.whiteBlack.main,
          },
        },
      },

      '& .MuiSwitch-thumb': {
        width: '16px',
        height: '16px',
        borderRadius: '100px',
        boxShadow: 'none',
        transitionDuration: 200,
        background: theme.vars.palette.blackWhite.main,
      },

      '& .MuiSwitch-track': {
        opacity: 1,
        background: theme.vars.palette.whiteBlack.main,
        boxSizing: 'border-box',

        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          width: '12px',
          height: '12px',
          transform: 'translateY(-50%)',
        },

        '&:before': {
          left: '6px',
          width: '10px',
          background: `url('${moon}')`,
          backgroundSize: 'contain',
        },

        '&:after': {
          right: '4px',
          background: `url('${sun}')`,
          backgroundSize: 'contain',
        },
      },
    }),
  };
});
