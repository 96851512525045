import { memo, useEffect, useRef, useState } from 'react';
import { ConversationEntity, TemplateSchema } from '../../graphql/generated';
import { ChatMessage } from '../ChatMessage/ChatMessage';
import { getFormatDateToMessage, stringConcat } from '../../utils';

import { ChatInnerBlockCSS, ChatWrapper, TFBlockCSS } from './styles';
import { client } from '../../graphql/client';
import { TextFieldChatContentRow } from '../Containers/ToChatsPage/TextFieldChatContentRow/TextFieldChatContentRow';
import { NewMessage } from '../../types';
import { useChatSubscription } from '../../hooks';

interface ChatProps {
  allData?: ConversationEntity;
  height?: number;
}

export const Chat = memo(({ allData }: ChatProps) => {
  const [messagesTimers, setMessagesTimers] = useState<NewMessage[]>([]);
  const scrollBlockMessageRef = useRef<HTMLDivElement>(null);

  const isMessageSending = !!messagesTimers.length;
  const conversationId = Number(allData?.id);

  useChatSubscription({ conversationId });

  // прокрутить чаты к низу списка при отправке сообщения
  useEffect(() => {
    if (!scrollBlockMessageRef.current) return;
    scrollBlockMessageRef.current.scrollTo({
      top: scrollBlockMessageRef.current.scrollHeight,
    });
  }, [messagesTimers.length, allData?.messages]);

  useEffect(() => {
    if (!scrollBlockMessageRef.current) return;
    scrollBlockMessageRef.current.scrollTo({
      top: 0,
    });
  }, [conversationId]);

  const handleDelete = (id: number) => {
    const newMessageTimersListWithoutThisMessageId = messagesTimers.filter((messageData) => {
      return messageData.id !== id;
    });
    setMessagesTimers(newMessageTimersListWithoutThisMessageId);

    const myMessageData = messagesTimers.find((messageData) => messageData.id === id);
    clearTimeout(myMessageData?.timer);

    const cache = client.cache;
    cache.modify({
      id: `ConversationEntity:${conversationId}`,
      fields: {
        messages(existingTemplates, { readField }) {
          return existingTemplates.filter(
            (template: TemplateSchema) => readField('id', template) !== id,
          );
        },
      },
    });
  };
  const customerFullName = stringConcat([allData?.customer.firstName, allData?.customer.lastName]);

  return (
    <ChatWrapper>
      <ChatInnerBlockCSS ref={scrollBlockMessageRef}>
        {allData?.messages?.map((message) => {
          const messageDate = getFormatDateToMessage(Number(message.createdAt));
          const isPendingMessage = messagesTimers.find((m) => {
            return m.id === message.id;
          });

          return (
            <ChatMessage
              key={message.id}
              id={message.id}
              name={!message.authorId ? customerFullName || '' : String(message.authorName || '')}
              date={messageDate}
              messageData={message}
              isConsumer={!message.authorId}
              isPending={Boolean(isPendingMessage)}
              {...(isPendingMessage && { onDelete: handleDelete })}
            />
          );
        })}
      </ChatInnerBlockCSS>
      <TFBlockCSS>
        <TextFieldChatContentRow
          conversationId={conversationId}
          allData={allData}
          setMessagesTimers={setMessagesTimers}
          isMessageSending={isMessageSending}
          customerFullName={customerFullName}
        />
      </TFBlockCSS>
    </ChatWrapper>
  );
});
