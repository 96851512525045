import { Button, Stack } from '@mui/material';
import { PlayIcon } from '../../../Icons';

interface HomeAgentMainContentProps {
  messageCount?: string | number;
  handleStartMessage?: () => void;
}

export const HomeAgentMainContent = ({
  messageCount = 0,
  handleStartMessage,
}: HomeAgentMainContentProps) => {
  return (
    <Stack alignItems={'center'}>
      <Button
        variant={'startMessage'}
        startIcon={<PlayIcon height={'18px'} width={'18px'} />}
        disabled={!messageCount}
        sx={{ marginTop: '24vh' }}
        onClick={handleStartMessage}
      >
        Start messaging ({messageCount})
      </Button>
    </Stack>
  );
};
