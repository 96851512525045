import {
  useCountUnprocessedSubscriptionSubscription,
  useGetGeneralMetricQuery,
} from '../graphql/generated';
import { useAuthContext } from '../context';
import { ClockIcon, MessageIcon } from '../components/Icons';
import { formatMilliseconds } from '../utils';
import { InfoBlockIdEnum } from '../components/InfoBlock/types';
import { useFilterContext } from '../context/FilterContext';

export const useMetricAndMessage = () => {
  const { userData } = useAuthContext();
  const { date } = useFilterContext();

  const { data } = useGetGeneralMetricQuery({
    variables: {
      input: {
        dateTo: date?.dateTo || null,
        dateFrom: date?.dateFrom || null,
      },
    },
    skip: !userData?.id,
  });

  const minutes = data ? formatMilliseconds(data?.getGeneralMetric.averageResponseTime) : '-';
  const { data: messageCountData } = useCountUnprocessedSubscriptionSubscription();

  const messageCount = messageCountData?.countUnprocessedConversations || 0;

  const metricAndMessageData = [
    {
      id: InfoBlockIdEnum.Time,
      icon: ClockIcon,
      title: 'Average Response Time',
      subTitle: `${minutes}`,
    },
    {
      id: InfoBlockIdEnum.Message,
      icon: MessageIcon,
      title: 'Avaliable message',
      subTitle: messageCount,
    },
  ];

  return { metricAndMessageData, messageCount };
};
