import { EditButtonCSS, TopContentIconCSS, TopContentTitleCSS, TopContentWrapper } from './styles';
import Typography from '@mui/material/Typography';
import { NumericalStarIcon, PencilIcon } from '../Icons';

interface ITopContent {
  isEdit?: boolean;
  title?: string;
  isHasButton?: boolean;
  buttonTitle?: string;
  onClick?: () => void;
}

const TopContent = ({ title, isHasButton, buttonTitle, onClick, isEdit }: ITopContent) => {
  return (
    <TopContentWrapper>
      <TopContentTitleCSS>
        <TopContentIconCSS>
          <NumericalStarIcon />
        </TopContentIconCSS>
        <Typography variant='h2'>{title}</Typography>
      </TopContentTitleCSS>
      {isHasButton && !isEdit && (
        <EditButtonCSS
          type='button'
          variant='contained'
          size='small'
          onClick={onClick}
          startIcon={<PencilIcon />}
        >
          {buttonTitle}
        </EditButtonCSS>
      )}
    </TopContentWrapper>
  );
};
export default TopContent;
