import { Stack, Typography } from '@mui/material';
import { IconBlockCSS, SubtitleBlockCSS, TypographyCSS, Wrapper } from './styles';
import { InfoBlockIdEnum, InfoBlockProps } from './types';

export const InfoBlock = ({ id, icon: Icon, title, subTitle }: InfoBlockProps) => {
  const isTimeBlock = id === InfoBlockIdEnum.Time;

  return (
    <Wrapper>
      <Stack>
        <Stack direction={'row'} gap={'16px'} alignItems={'center'}>
          <IconBlockCSS>
            <Icon />
          </IconBlockCSS>
          <Stack sx={{ maxWidth: isTimeBlock ? '110px' : '68px' }}>
            <TypographyCSS variant={'body3'}>{title}</TypographyCSS>
          </Stack>
        </Stack>
        <SubtitleBlockCSS direction={'row'}>
          <Typography variant={'big2'} ml={'40px'} whiteSpace={'nowrap'}>
            {subTitle}
          </Typography>
        </SubtitleBlockCSS>
      </Stack>
    </Wrapper>
  );
};
