import { useState } from 'react';
import { DEFAULT_PAGINATION_OPTIONS, START_LIMIT } from '../constants/constants';

interface handleFetchMoreArguments {
  fetchMore: (params: Record<string, any>) => Promise<unknown>;
  dataCount: number;
  isInput?: boolean;
  options?: Record<string, any>;
}

export const usePagination = () => {
  const [paginationOptions, setPaginationOptions] = useState(DEFAULT_PAGINATION_OPTIONS);
  const [pending, setPending] = useState(false);

  const handleFetchMore = async ({
    fetchMore,
    dataCount,
    isInput = true,
    options,
  }: handleFetchMoreArguments) => {
    const option = {
      limit: paginationOptions.limit,
      offset: paginationOptions.offset + START_LIMIT,
    };

    if (option.offset >= dataCount) return;
    setPending(true);
    setPaginationOptions(() => ({
      ...option,
    }));

    await fetchMore({
      variables: {
        [isInput ? 'input' : 'options']: {
          ...options,
          ...option,
        },
      },
    });
    setPending(false);
  };

  return {
    paginationOptions,
    setPaginationOptions,
    handleFetchMore,
    pending,
  };
};
