import { styled } from '@mui/material/styles';
import { Stack, StackProps } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { ButtonPropsVariantOverrides } from '@mui/material/Button/Button';

interface PointCSSProps extends StackProps {
  variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>;
  ismorecolored?: string;
}

export const PointCSS = styled(Stack)<PointCSSProps>(
  ({ theme, variant = 'contained', ismorecolored }) => {
    const isContainedVariant = variant === 'contained';
    const isMoreColored = ismorecolored === 'true';

    return {
      width: '8px',
      height: '8px',
      borderRadius: '50px',
      background: isContainedVariant ? theme.vars.palette.whiteBlack.main : 'transparent',
      ...(isMoreColored && { background: theme.vars.palette.white.main }),
      ...(!isContainedVariant && {
        border: '1px solid',
        borderColor: theme.vars.palette.midSilverWhite.main,
      }),
    };
  },
);
