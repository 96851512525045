import { usePalette } from '../../../../hooks';
import { Stack, Typography } from '@mui/material';
import { ChatTemplateIcon } from '../../../Icons';
import { Wrapper } from './styles';
import { TemplateRowItemProps } from './types';
import { useChatContext } from '../../../../context';
import { memo } from 'react';

export const TemplateRowItem = memo(({ text, shortcut, id }: TemplateRowItemProps) => {
  const { palette } = usePalette();
  const { onClickToTemplate } = useChatContext();

  const handleClickToTemplate = (data: TemplateRowItemProps) => {
    onClickToTemplate(data);
  };

  return (
    <Wrapper onClick={() => handleClickToTemplate({ text, shortcut, id })}>
      <Stack>
        <Typography variant={'body3'} color={palette.blackWhite.main}>
          {text}
        </Typography>
        <Typography variant={'caption'} color={palette.silver100White.main}>
          {shortcut}
        </Typography>
      </Stack>
      <ChatTemplateIcon
        bgcolor={palette.silver100White.main}
        color={palette.whiteBlack.main}
        sx={{ flexShrink: 0 }}
      />
    </Wrapper>
  );
});
