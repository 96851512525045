import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => {
  return {
    alignItems: 'center',
    flexDirection: 'row',
    background: theme.vars.palette.silver20.main,
    height: '100%',
    borderRadius: '1000px',
    padding: '6px 24px',
    gap: '16px',
  };
});
