import { styled } from '@mui/material/styles';
import { Box, BoxProps, Stack, Typography } from '@mui/material';

interface WrapperProps extends BoxProps {
  height?: string;
}

export const Wrapper = styled(Box)<WrapperProps>(({ height }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: height,
    width: 'auto',
    flex: 1,
  };
});

export const ErrorMessageWrapperCSS = styled(Stack)({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

export const TypographyCSS = styled(Typography)(({ theme }) => {
  return {
    color: theme.vars.palette.jasper.main,
  };
});
