import { IconProps } from './types';
import { CSSProperties } from 'react';

interface ChevronBottomIconProps extends IconProps {
  isOpen?: boolean;
  sx?: CSSProperties;
}

export const ChevronBottomIcon = ({
  color = '#fff',
  isOpen = false,
  sx,
}: ChevronBottomIconProps) => {
  return (
    <svg
      style={{ flexShrink: 0, ...sx }}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.6885 7.01872C11.8829 6.82259 11.8814 6.50601 11.6853 6.31162C11.4892 6.11723 11.1726 6.11864 10.9782 6.31478L9.81334 7.49009C9.34058 7.96708 9.01522 8.29429 8.73953 8.51569C8.47174 8.73075 8.29973 8.80453 8.14567 8.82417C8.04894 8.8365 7.95107 8.8365 7.85434 8.82417C7.70028 8.80453 7.52827 8.73075 7.26048 8.51569C6.98479 8.29429 6.65943 7.96708 6.18667 7.49009L5.0218 6.31478C4.82741 6.11864 4.51083 6.11723 4.3147 6.31162C4.11857 6.50601 4.11716 6.82259 4.31154 7.01872L5.4974 8.21521C5.94403 8.66586 6.30906 9.03417 6.63431 9.29538C6.97253 9.567 7.31582 9.76362 7.7279 9.81614C7.90858 9.83917 8.09143 9.83917 8.27211 9.81614C8.68419 9.76362 9.02748 9.567 9.3657 9.29538C9.69095 9.03417 10.056 8.66586 10.5026 8.21521L11.6885 7.01872Z'
        fill={color}
      />
    </svg>
  );
};
