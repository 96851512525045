/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AuthResponseSchema = {
  __typename?: 'AuthResponseSchema';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  user: UserEntity;
};

export type AverageResponseTimeSchema = {
  __typename?: 'AverageResponseTimeSchema';
  averageResponseTime: Scalars['Float'];
};

export type CancelOrderInput = {
  conversationId: Scalars['Int'];
  orderId: Scalars['String'];
};

export type ConversationEntity = {
  __typename?: 'ConversationEntity';
  assignedUser?: Maybe<UserEntity>;
  assignedUserId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  customer: CustomerEntity;
  customerId: Scalars['Int'];
  id: Scalars['Int'];
  lastMessage?: Maybe<Scalars['String']>;
  lastMessageReceived?: Maybe<Scalars['String']>;
  messageCount?: Maybe<Scalars['Int']>;
  messages?: Maybe<Array<MessageEntity>>;
  provider: ConversationProviderEnum;
  shop: ShopEntity;
  shopId: Scalars['Int'];
  status: ConversationStatusEnum;
  subject?: Maybe<Scalars['String']>;
  tag: ConversationTagEnum;
  tags: Array<ConversationTagEnum>;
  updatedAt: Scalars['String'];
};

export enum ConversationProviderEnum {
  Email = 'EMAIL',
  LiveChat = 'LIVE_CHAT',
  Sms = 'SMS',
}

export enum ConversationStatusEnum {
  Answered = 'ANSWERED',
  Awaiting = 'AWAITING',
  Blocked = 'BLOCKED',
  InProcess = 'IN_PROCESS',
  NoAnswerNeeded = 'NO_ANSWER_NEEDED',
}

export enum ConversationTagEnum {
  Discount = 'DISCOUNT',
  Exchange = 'EXCHANGE',
  GeneralQuestion = 'GENERAL_QUESTION',
  NegativeFeedback = 'NEGATIVE_FEEDBACK',
  PositiveFeedback = 'POSITIVE_FEEDBACK',
  Refund = 'REFUND',
  Return = 'RETURN',
  ShippingInfo = 'SHIPPING_INFO',
}

export type ConversationsWithCountSchema = {
  __typename?: 'ConversationsWithCountSchema';
  count: Scalars['Int'];
  rows: Array<ConversationEntity>;
  totalPages: Scalars['Int'];
};

export type CreateMessageInput = {
  conversationId: Scalars['Int'];
  provider: ConversationProviderEnum;
  text: Scalars['String'];
};

export type CreateTemplateInput = {
  name: Scalars['String'];
  shopId?: InputMaybe<Scalars['Int']>;
  shortcut?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
  type: TemplateTypeEnum;
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: UserRoleEnum;
};

export type CreateUserResponseSchema = {
  __typename?: 'CreateUserResponseSchema';
  password: Scalars['String'];
  user: UserEntity;
};

export type CustomerEntity = {
  __typename?: 'CustomerEntity';
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type DateFilterInput = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
};

export type DeleteTemplateInput = {
  globalDelete: Scalars['Boolean'];
  shopId: Scalars['Int'];
  templateId: Scalars['Int'];
};

export type DeleteTemplateResponceSchema = {
  __typename?: 'DeleteTemplateResponceSchema';
  deletedTemplateId: Scalars['Int'];
};

export type EditCustomerInput = {
  country?: InputMaybe<Scalars['String']>;
  customerId: Scalars['Int'];
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type EditNotesInput = {
  notes: Scalars['String'];
  shopId: Scalars['Int'];
};

export type EditShopInput = {
  agentFirstName?: InputMaybe<Scalars['String']>;
  deactivated?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  emailAvailable?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  liveChatAvailable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  smsAvailable?: InputMaybe<Scalars['Boolean']>;
  tone?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type EditTemplateInput = {
  globalEdit: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  shopId: Scalars['Int'];
  shortcut?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type EditUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRoleEnum>;
};

export type FindAndCountManyUsersInput = {
  /** Date in milliseconds */
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  /** Date in milliseconds */
  dateTo?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<UserRoleEnum>>;
  search?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<UserSortFieldsEnum>;
  sortOrder?: InputMaybe<OrderEnum>;
};

export type GeneratedTwoFactorAuthenticationResponse = {
  __typename?: 'GeneratedTwoFactorAuthenticationResponse';
  qrCodeSVG: Scalars['String'];
  secret: Scalars['String'];
};

export type GetConversationsOptions = {
  agentIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Date in milliseconds */
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  /** Date in milliseconds */
  dateTo?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  providers?: InputMaybe<Array<ConversationProviderEnum>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<ConversationStatusEnum>>;
};

export type GetMetricsInput = {
  agentIds?: InputMaybe<Array<Scalars['Int']>>;
  /** Date in milliseconds */
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  /** Date in milliseconds */
  dateTo?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  providers?: InputMaybe<Array<ConversationProviderEnum>>;
};

export type GetOrderByIdInput = {
  conversationId: Scalars['Int'];
  itemCursor?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
  take?: Scalars['Int'];
};

export type GetOrdersInput = {
  conversationId: Scalars['Int'];
  cursorLineItems?: InputMaybe<Scalars['String']>;
  cursorOrders?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type GetShopsOptions = {
  /** Date in milliseconds */
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  /** Date in milliseconds */
  dateTo?: InputMaybe<Scalars['DateTime']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type GoogleAuthInput = {
  token: Scalars['String'];
};

export type ImageEntity = {
  __typename?: 'ImageEntity';
  filename: Scalars['String'];
  id: Scalars['Int'];
  messageId: Scalars['Int'];
};

export type ItemSchema = {
  __typename?: 'ItemSchema';
  id: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  price: Scalars['String'];
  quantity: Scalars['Int'];
  title: Scalars['String'];
};

export type MessageEntity = {
  __typename?: 'MessageEntity';
  authorId?: Maybe<Scalars['Int']>;
  authorName?: Maybe<Scalars['String']>;
  conversationId: Scalars['Int'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  images: Array<ImageEntity>;
  text: Scalars['String'];
};

export type MetricsWithCountSchema = {
  __typename?: 'MetricsWithCountSchema';
  count: Scalars['Int'];
  rows: Array<UserMetric>;
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelOrder: Scalars['Boolean'];
  createMessage: MessageEntity;
  createShopTemplate: TemplateSchema;
  createUser: CreateUserResponseSchema;
  deleteShopTemplate: DeleteTemplateResponceSchema;
  editCustomer: CustomerEntity;
  editNotes: ShopEntity;
  editShop: ShopEntity;
  editShopTemplate: TemplateSchema;
  editUser: UserEntity;
  generate: GeneratedTwoFactorAuthenticationResponse;
  getAndAssignNextConversation: ConversationEntity;
  googleAuth: AuthResponseSchema;
  markAsAnswered: ConversationEntity;
  markAsBlocked: Scalars['Boolean'];
  markAsNoNeedToReply: Scalars['Boolean'];
  setTag: ConversationEntity;
  unassignUser: Scalars['Boolean'];
  updateOrder: UpdateOrderResponseSchema;
  verify: VerifyTwoFactorAuthenticationResponse;
};

export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};

export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};

export type MutationCreateShopTemplateArgs = {
  input: CreateTemplateInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteShopTemplateArgs = {
  input: DeleteTemplateInput;
};

export type MutationEditCustomerArgs = {
  input: EditCustomerInput;
};

export type MutationEditNotesArgs = {
  input: EditNotesInput;
};

export type MutationEditShopArgs = {
  input: EditShopInput;
};

export type MutationEditShopTemplateArgs = {
  input: EditTemplateInput;
  templateId: Scalars['Float'];
};

export type MutationEditUserArgs = {
  input: EditUserInput;
};

export type MutationGenerateArgs = {
  userId: Scalars['Float'];
};

export type MutationGoogleAuthArgs = {
  input: GoogleAuthInput;
};

export type MutationMarkAsAnsweredArgs = {
  conversationId: Scalars['Float'];
};

export type MutationMarkAsBlockedArgs = {
  conversationId: Scalars['Float'];
};

export type MutationMarkAsNoNeedToReplyArgs = {
  conversationId: Scalars['Float'];
};

export type MutationSetTagArgs = {
  input: SetTagInput;
};

export type MutationUnassignUserArgs = {
  conversationId: Scalars['Float'];
};

export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};

export type MutationVerifyArgs = {
  input: VerifyTwoFactorAuthenticationInput;
};

export enum OrderEnum {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export type OrderResponseSchema = {
  __typename?: 'OrderResponseSchema';
  email: Scalars['String'];
  financialStatus?: Maybe<Scalars['String']>;
  fulfillmentStatus: Scalars['String'];
  id: Scalars['String'];
  item: Array<ItemSchema>;
  itemCursor?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  netPayment: Scalars['Float'];
  phone?: Maybe<Scalars['String']>;
  shipment: ShippingSchema;
  shipmentStatus?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  trackingId?: Maybe<Array<Scalars['String']>>;
  trackingUrl?: Maybe<Array<Scalars['String']>>;
};

export type OrdersResponseSchema = {
  __typename?: 'OrdersResponseSchema';
  cursor?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  financialStatus?: Maybe<Scalars['String']>;
  fulfillmentStatus: Scalars['String'];
  id: Scalars['String'];
  item: Array<ItemSchema>;
  link: Scalars['String'];
  netPayment: Scalars['Float'];
  pageInfo?: Maybe<PageInfoSchema>;
  phone?: Maybe<Scalars['String']>;
  shipment: ShippingSchema;
  shipmentStatus?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  trackingId?: Maybe<Array<Scalars['String']>>;
  trackingUrl?: Maybe<Array<Scalars['String']>>;
};

export type PageInfoSchema = {
  __typename?: 'PageInfoSchema';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  findAndCountManyUsers: UsersWithCountSchema;
  getConversation: ConversationEntity;
  getConversations: ConversationsWithCountSchema;
  getCurrentConversation: ConversationEntity;
  getCurrentUser: UserEntity;
  getGeneralMetric: AverageResponseTimeSchema;
  getLastOrder: Array<OrdersResponseSchema>;
  getMetric: AverageResponseTimeSchema;
  getMetrics: MetricsWithCountSchema;
  getMyMetric: AverageResponseTimeSchema;
  getOrder: OrderResponseSchema;
  getOrders: Array<OrdersResponseSchema>;
  getShop: ShopEntity;
  getShops: ShopWithCountSchema;
};

export type QueryFindAndCountManyUsersArgs = {
  input: FindAndCountManyUsersInput;
};

export type QueryGetConversationArgs = {
  conversationId: Scalars['Float'];
};

export type QueryGetConversationsArgs = {
  input: GetConversationsOptions;
};

export type QueryGetGeneralMetricArgs = {
  input?: InputMaybe<DateFilterInput>;
};

export type QueryGetLastOrderArgs = {
  conversationId: Scalars['Float'];
};

export type QueryGetMetricArgs = {
  userId: Scalars['Float'];
};

export type QueryGetMetricsArgs = {
  input?: InputMaybe<GetMetricsInput>;
};

export type QueryGetOrderArgs = {
  input: GetOrderByIdInput;
};

export type QueryGetOrdersArgs = {
  input: GetOrdersInput;
};

export type QueryGetShopArgs = {
  id: Scalars['Int'];
};

export type QueryGetShopsArgs = {
  options: GetShopsOptions;
};

export type RefundBodyInput = {
  currency?: InputMaybe<Scalars['String']>;
  fullRefund?: InputMaybe<Scalars['Boolean']>;
  fullRefundItems?: InputMaybe<Array<RefundLineItemInput>>;
  note?: InputMaybe<Scalars['String']>;
  notify?: InputMaybe<Scalars['String']>;
  refundLineItems?: InputMaybe<Array<RefundLineItemInput>>;
  shipping?: InputMaybe<ShippingRefundInput>;
  transactions?: InputMaybe<Array<TransactionInput>>;
};

export type RefundLineItemInput = {
  lineItemId: Scalars['String'];
  quantity?: Scalars['Int'];
};

export type SetTagInput = {
  conversationId: Scalars['Int'];
  tag: ConversationTagEnum;
};

export type ShippingInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  provinceCode?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type ShippingRefundInput = {
  amount?: InputMaybe<Scalars['String']>;
  fullRefund?: InputMaybe<Scalars['Boolean']>;
};

export type ShippingSchema = {
  __typename?: 'ShippingSchema';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ShopEntity = {
  __typename?: 'ShopEntity';
  agentFirstName: Scalars['String'];
  createdAt: Scalars['String'];
  deactivated: Scalars['Boolean'];
  deactivatedAt?: Maybe<Scalars['String']>;
  domainId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailAvailable: Scalars['Boolean'];
  id: Scalars['ID'];
  liveChatAvailable: Scalars['Boolean'];
  myshopifyDomain: Scalars['String'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  smsAvailable: Scalars['Boolean'];
  templates: Array<TemplateSchema>;
  tone?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  verifiedSender: Scalars['Boolean'];
};

export type ShopEntityTemplatesArgs = {
  search?: InputMaybe<Scalars['String']>;
};

export type ShopWithCountSchema = {
  __typename?: 'ShopWithCountSchema';
  count: Scalars['Int'];
  rows: Array<ShopEntity>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatMessage: MessageEntity;
  countUnprocessedConversations: Scalars['Int'];
};

export type SubscriptionChatMessageArgs = {
  conversationId: Scalars['Float'];
};

export type TemplateEntity = {
  __typename?: 'TemplateEntity';
  id: Scalars['Int'];
  name: Scalars['String'];
  text: Scalars['String'];
};

export type TemplateSchema = {
  __typename?: 'TemplateSchema';
  id: Scalars['Int'];
  name: Scalars['String'];
  shortcut?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  type: TemplateTypeEnum;
};

export enum TemplateTypeEnum {
  General = 'General',
  Personal = 'Personal',
  Shopify = 'Shopify',
}

export type TransactionInput = {
  amount: Scalars['String'];
  gateway: Scalars['String'];
  kind: Scalars['String'];
  orderId: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderBodyInput = {
  email?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<ShippingInput>;
};

export type UpdateOrderError = {
  __typename?: 'UpdateOrderError';
  field?: Maybe<Array<Scalars['String']>>;
  message: Scalars['String'];
};

export type UpdateOrderInput = {
  conversationId: Scalars['Int'];
  orderId: Scalars['String'];
  refundBody?: InputMaybe<RefundBodyInput>;
  updateBody?: InputMaybe<UpdateOrderBodyInput>;
};

export type UpdateOrderResponseSchema = {
  __typename?: 'UpdateOrderResponseSchema';
  email?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<ShippingSchema>;
  userErrors: Array<UpdateOrderError>;
};

export type UserEntity = {
  __typename?: 'UserEntity';
  assignedConversation?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['String']>;
  blocked: Scalars['Boolean'];
  blockedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Float'];
  lastName?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  role: UserRoleEnum;
  updatedAt: Scalars['String'];
};

export type UserMetric = {
  __typename?: 'UserMetric';
  avatar?: Maybe<Scalars['String']>;
  averageResponseTime: Scalars['Float'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Default value: MANAGER */
export enum UserRoleEnum {
  Admin = 'ADMIN',
  Agent = 'AGENT',
  Manager = 'MANAGER',
}

export enum UserSortFieldsEnum {
  CreatedAt = 'CreatedAt',
}

export type UsersWithCountSchema = {
  __typename?: 'UsersWithCountSchema';
  count: Scalars['Int'];
  rows: Array<UserEntity>;
};

export type VerifyTwoFactorAuthenticationInput = {
  code: Scalars['String'];
  userId: Scalars['Int'];
};

export type VerifyTwoFactorAuthenticationResponse = {
  __typename?: 'VerifyTwoFactorAuthenticationResponse';
  verified: Scalars['String'];
};

export type MessageFragment = {
  __typename?: 'MessageEntity';
  id: number;
  authorId?: number | null;
  authorName?: string | null;
  conversationId: number;
  createdAt: string;
  text: string;
  images: Array<{ __typename?: 'ImageEntity'; filename: string; id: number; messageId: number }>;
};

export type FragmentOrderFragment = {
  __typename?: 'OrderResponseSchema';
  id: string;
  link: string;
  email: string;
  phone?: string | null;
  trackingId?: Array<string> | null;
  trackingUrl?: Array<string> | null;
  status?: string | null;
  financialStatus?: string | null;
  fulfillmentStatus: string;
  shipmentStatus?: Array<string> | null;
  total: number;
  netPayment: number;
  itemCursor?: string | null;
  item: Array<{
    __typename?: 'ItemSchema';
    id: string;
    title: string;
    link?: string | null;
    quantity: number;
    price: string;
  }>;
  shipment: {
    __typename?: 'ShippingSchema';
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    company?: string | null;
    country?: string | null;
    countryCode?: string | null;
    firstName?: string | null;
    id?: string | null;
    lastName?: string | null;
    phone?: string | null;
    province?: string | null;
    provinceCode?: string | null;
    zip?: string | null;
  };
};

export type FragmentShopFragment = {
  __typename?: 'ShopEntity';
  id: string;
  name: string;
  email?: string | null;
  phone?: string | null;
  smsAvailable: boolean;
  emailAvailable: boolean;
  liveChatAvailable: boolean;
  tone?: string | null;
  agentFirstName: string;
  notes?: string | null;
  deactivated: boolean;
  deactivatedAt?: string | null;
  url?: string | null;
  domainId?: string | null;
  createdAt: string;
  templates: Array<{
    __typename?: 'TemplateSchema';
    id: number;
    name: string;
    text: string;
    shortcut?: string | null;
    type: TemplateTypeEnum;
  }>;
};

export type CancelOrderMutationVariables = Exact<{
  input: CancelOrderInput;
}>;

export type CancelOrderMutation = { __typename?: 'Mutation'; cancelOrder: boolean };

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput;
}>;

export type CreateMessageMutation = {
  __typename?: 'Mutation';
  createMessage: {
    __typename?: 'MessageEntity';
    authorId?: number | null;
    conversationId: number;
    createdAt: string;
    id: number;
    text: string;
  };
};

export type CreateShopTemplateMutationVariables = Exact<{
  input: CreateTemplateInput;
}>;

export type CreateShopTemplateMutation = {
  __typename?: 'Mutation';
  createShopTemplate: {
    __typename?: 'TemplateSchema';
    id: number;
    name: string;
    text: string;
    shortcut?: string | null;
    type: TemplateTypeEnum;
  };
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'CreateUserResponseSchema';
    password: string;
    user: {
      __typename?: 'UserEntity';
      id: number;
      email: string;
      firstName: string;
      lastName?: string | null;
      role: UserRoleEnum;
      avatar?: string | null;
      blocked: boolean;
      blockedAt?: string | null;
      refreshToken?: string | null;
      createdAt: string;
      updatedAt: string;
      assignedConversation?: number | null;
    };
  };
};

export type DeleteShopTemplateMutationVariables = Exact<{
  input: DeleteTemplateInput;
}>;

export type DeleteShopTemplateMutation = {
  __typename?: 'Mutation';
  deleteShopTemplate: { __typename?: 'DeleteTemplateResponceSchema'; deletedTemplateId: number };
};

export type EditCustomerMutationVariables = Exact<{
  input: EditCustomerInput;
}>;

export type EditCustomerMutation = {
  __typename?: 'Mutation';
  editCustomer: { __typename?: 'CustomerEntity'; email?: string | null; phone?: string | null };
};

export type EditShopMutationVariables = Exact<{
  input: EditShopInput;
}>;

export type EditShopMutation = {
  __typename?: 'Mutation';
  editShop: {
    __typename?: 'ShopEntity';
    id: string;
    name: string;
    email?: string | null;
    phone?: string | null;
    smsAvailable: boolean;
    emailAvailable: boolean;
    liveChatAvailable: boolean;
    tone?: string | null;
    agentFirstName: string;
    notes?: string | null;
    deactivated: boolean;
    deactivatedAt?: string | null;
    url?: string | null;
    domainId?: string | null;
    createdAt: string;
    templates: Array<{
      __typename?: 'TemplateSchema';
      id: number;
      name: string;
      text: string;
      shortcut?: string | null;
      type: TemplateTypeEnum;
    }>;
  };
};

export type EditShopTemplateMutationVariables = Exact<{
  templateId: Scalars['Float'];
  input: EditTemplateInput;
}>;

export type EditShopTemplateMutation = {
  __typename?: 'Mutation';
  editShopTemplate: {
    __typename?: 'TemplateSchema';
    id: number;
    name: string;
    text: string;
    shortcut?: string | null;
    type: TemplateTypeEnum;
  };
};

export type EditUserMutationVariables = Exact<{
  input: EditUserInput;
}>;

export type EditUserMutation = {
  __typename?: 'Mutation';
  editUser: {
    __typename?: 'UserEntity';
    id: number;
    email: string;
    firstName: string;
    lastName?: string | null;
    role: UserRoleEnum;
    avatar?: string | null;
    blocked: boolean;
    blockedAt?: string | null;
    refreshToken?: string | null;
    createdAt: string;
    updatedAt: string;
  };
};

export type GetAndAssignNextConversationMutationVariables = Exact<{ [key: string]: never }>;

export type GetAndAssignNextConversationMutation = {
  __typename?: 'Mutation';
  getAndAssignNextConversation: {
    __typename?: 'ConversationEntity';
    id: number;
    shopId: number;
    customerId: number;
    assignedUserId?: number | null;
    status: ConversationStatusEnum;
    tag: ConversationTagEnum;
    provider: ConversationProviderEnum;
    updatedAt: string;
    tags: Array<ConversationTagEnum>;
    createdAt: string;
    subject?: string | null;
    shop: {
      __typename?: 'ShopEntity';
      id: string;
      name: string;
      email?: string | null;
      phone?: string | null;
      smsAvailable: boolean;
      emailAvailable: boolean;
      liveChatAvailable: boolean;
      tone?: string | null;
      agentFirstName: string;
      notes?: string | null;
      deactivated: boolean;
      deactivatedAt?: string | null;
      url?: string | null;
      domainId?: string | null;
      createdAt: string;
      templates: Array<{
        __typename?: 'TemplateSchema';
        id: number;
        name: string;
        text: string;
        shortcut?: string | null;
        type: TemplateTypeEnum;
      }>;
    };
    assignedUser?: {
      __typename?: 'UserEntity';
      id: number;
      email: string;
      firstName: string;
      lastName?: string | null;
      role: UserRoleEnum;
      avatar?: string | null;
      blocked: boolean;
      blockedAt?: string | null;
      refreshToken?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    messages?: Array<{
      __typename?: 'MessageEntity';
      id: number;
      authorId?: number | null;
      conversationId: number;
      text: string;
      createdAt: string;
      images: Array<{
        __typename?: 'ImageEntity';
        filename: string;
        id: number;
        messageId: number;
      }>;
    }> | null;
    customer: {
      __typename?: 'CustomerEntity';
      country?: string | null;
      email?: string | null;
      firstName?: string | null;
      id: string;
      phone?: string | null;
      lastName?: string | null;
    };
  };
};

export type GoogleAuthMutationVariables = Exact<{
  input: GoogleAuthInput;
}>;

export type GoogleAuthMutation = {
  __typename?: 'Mutation';
  googleAuth: {
    __typename?: 'AuthResponseSchema';
    accessToken: string;
    refreshToken: string;
    user: {
      __typename?: 'UserEntity';
      avatar?: string | null;
      blocked: boolean;
      createdAt: string;
      blockedAt?: string | null;
      email: string;
      firstName: string;
      id: number;
      lastName?: string | null;
      refreshToken?: string | null;
      role: UserRoleEnum;
      updatedAt: string;
    };
  };
};

export type MarkAsAnsweredMutationVariables = Exact<{
  conversationId: Scalars['Float'];
}>;

export type MarkAsAnsweredMutation = {
  __typename?: 'Mutation';
  markAsAnswered: { __typename?: 'ConversationEntity'; id: number };
};

export type MarkAsBlockedMutationVariables = Exact<{
  conversationId: Scalars['Float'];
}>;

export type MarkAsBlockedMutation = { __typename?: 'Mutation'; markAsBlocked: boolean };

export type MarkAsNoNeedToReplyMutationVariables = Exact<{
  conversationId: Scalars['Float'];
}>;

export type MarkAsNoNeedToReplyMutation = { __typename?: 'Mutation'; markAsNoNeedToReply: boolean };

export type SetTagMutationVariables = Exact<{
  input: SetTagInput;
}>;

export type SetTagMutation = {
  __typename?: 'Mutation';
  setTag: {
    __typename?: 'ConversationEntity';
    id: number;
    tag: ConversationTagEnum;
    tags: Array<ConversationTagEnum>;
    updatedAt: string;
    status: ConversationStatusEnum;
    shopId: number;
    provider: ConversationProviderEnum;
    customerId: number;
    assignedUserId?: number | null;
  };
};

export type UnassignUserMutationVariables = Exact<{
  conversationId: Scalars['Float'];
}>;

export type UnassignUserMutation = { __typename?: 'Mutation'; unassignUser: boolean };

export type UpdateOrderMutationVariables = Exact<{
  input: UpdateOrderInput;
}>;

export type UpdateOrderMutation = {
  __typename?: 'Mutation';
  updateOrder: {
    __typename?: 'UpdateOrderResponseSchema';
    email?: string | null;
    shippingAddress?: {
      __typename?: 'ShippingSchema';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      company?: string | null;
      country?: string | null;
      countryCode?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      phone?: string | null;
      province?: string | null;
      provinceCode?: string | null;
      zip?: string | null;
    } | null;
    userErrors: Array<{
      __typename?: 'UpdateOrderError';
      field?: Array<string> | null;
      message: string;
    }>;
  };
};

export type FindAndCountManyUsersQueryVariables = Exact<{
  input: FindAndCountManyUsersInput;
}>;

export type FindAndCountManyUsersQuery = {
  __typename?: 'Query';
  findAndCountManyUsers: {
    __typename?: 'UsersWithCountSchema';
    count: number;
    rows: Array<{
      __typename?: 'UserEntity';
      id: number;
      email: string;
      firstName: string;
      lastName?: string | null;
      role: UserRoleEnum;
      avatar?: string | null;
      blocked: boolean;
      blockedAt?: string | null;
      refreshToken?: string | null;
      createdAt: string;
      updatedAt: string;
    }>;
  };
};

export type GetConversationQueryVariables = Exact<{
  conversationId: Scalars['Float'];
}>;

export type GetConversationQuery = {
  __typename?: 'Query';
  getConversation: {
    __typename?: 'ConversationEntity';
    id: number;
    shopId: number;
    customerId: number;
    assignedUserId?: number | null;
    status: ConversationStatusEnum;
    tag: ConversationTagEnum;
    provider: ConversationProviderEnum;
    updatedAt: string;
    lastMessageReceived?: string | null;
    lastMessage?: string | null;
    tags: Array<ConversationTagEnum>;
    createdAt: string;
    subject?: string | null;
    shop: {
      __typename?: 'ShopEntity';
      id: string;
      name: string;
      email?: string | null;
      phone?: string | null;
      smsAvailable: boolean;
      emailAvailable: boolean;
      liveChatAvailable: boolean;
      tone?: string | null;
      agentFirstName: string;
      notes?: string | null;
      deactivated: boolean;
      deactivatedAt?: string | null;
      url?: string | null;
      domainId?: string | null;
      createdAt: string;
      verifiedSender: boolean;
      templates: Array<{
        __typename?: 'TemplateSchema';
        id: number;
        name: string;
        text: string;
        shortcut?: string | null;
        type: TemplateTypeEnum;
      }>;
    };
    assignedUser?: {
      __typename?: 'UserEntity';
      id: number;
      email: string;
      firstName: string;
      lastName?: string | null;
      role: UserRoleEnum;
      avatar?: string | null;
      blocked: boolean;
      blockedAt?: string | null;
      refreshToken?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    messages?: Array<{
      __typename?: 'MessageEntity';
      id: number;
      authorId?: number | null;
      authorName?: string | null;
      conversationId: number;
      createdAt: string;
      text: string;
      images: Array<{
        __typename?: 'ImageEntity';
        filename: string;
        id: number;
        messageId: number;
      }>;
    }> | null;
    customer: {
      __typename?: 'CustomerEntity';
      country?: string | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      id: string;
      phone?: string | null;
    };
  };
};

export type GetConversationsQueryVariables = Exact<{
  input: GetConversationsOptions;
}>;

export type GetConversationsQuery = {
  __typename?: 'Query';
  getConversations: {
    __typename?: 'ConversationsWithCountSchema';
    count: number;
    rows: Array<{
      __typename?: 'ConversationEntity';
      id: number;
      shopId: number;
      assignedUserId?: number | null;
      status: ConversationStatusEnum;
      tag: ConversationTagEnum;
      provider: ConversationProviderEnum;
      updatedAt: string;
      lastMessageReceived?: string | null;
      lastMessage?: string | null;
      shop: {
        __typename?: 'ShopEntity';
        id: string;
        name: string;
        phone?: string | null;
        email?: string | null;
        agentFirstName: string;
      };
      customer: {
        __typename?: 'CustomerEntity';
        phone?: string | null;
        lastName?: string | null;
        id: string;
        firstName?: string | null;
        email?: string | null;
        country?: string | null;
      };
      assignedUser?: {
        __typename?: 'UserEntity';
        id: number;
        email: string;
        firstName: string;
        lastName?: string | null;
        role: UserRoleEnum;
        avatar?: string | null;
        blocked: boolean;
        blockedAt?: string | null;
        refreshToken?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null;
    }>;
  };
};

export type GetCurrentConversationQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentConversationQuery = {
  __typename?: 'Query';
  getCurrentConversation: {
    __typename?: 'ConversationEntity';
    id: number;
    shopId: number;
    customerId: number;
    assignedUserId?: number | null;
    status: ConversationStatusEnum;
    tag: ConversationTagEnum;
    provider: ConversationProviderEnum;
    updatedAt: string;
    createdAt: string;
    tags: Array<ConversationTagEnum>;
    subject?: string | null;
    shop: {
      __typename?: 'ShopEntity';
      id: string;
      name: string;
      email?: string | null;
      phone?: string | null;
      smsAvailable: boolean;
      emailAvailable: boolean;
      liveChatAvailable: boolean;
      tone?: string | null;
      agentFirstName: string;
      notes?: string | null;
      deactivated: boolean;
      deactivatedAt?: string | null;
      url?: string | null;
      domainId?: string | null;
      createdAt: string;
      verifiedSender: boolean;
      templates: Array<{
        __typename?: 'TemplateSchema';
        id: number;
        name: string;
        text: string;
        shortcut?: string | null;
        type: TemplateTypeEnum;
      }>;
    };
    customer: {
      __typename?: 'CustomerEntity';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
      country?: string | null;
    };
    assignedUser?: {
      __typename?: 'UserEntity';
      id: number;
      email: string;
      firstName: string;
      lastName?: string | null;
      role: UserRoleEnum;
      avatar?: string | null;
      blocked: boolean;
      blockedAt?: string | null;
      refreshToken?: string | null;
      createdAt: string;
      updatedAt: string;
      assignedConversation?: number | null;
    } | null;
    messages?: Array<{
      __typename?: 'MessageEntity';
      id: number;
      authorId?: number | null;
      authorName?: string | null;
      conversationId: number;
      createdAt: string;
      text: string;
      images: Array<{
        __typename?: 'ImageEntity';
        filename: string;
        id: number;
        messageId: number;
      }>;
    }> | null;
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  getCurrentUser: {
    __typename?: 'UserEntity';
    avatar?: string | null;
    blocked: boolean;
    blockedAt?: string | null;
    createdAt: string;
    email: string;
    firstName: string;
    id: number;
    lastName?: string | null;
    refreshToken?: string | null;
    role: UserRoleEnum;
    updatedAt: string;
    assignedConversation?: number | null;
  };
};

export type GetGeneralMetricQueryVariables = Exact<{
  input?: InputMaybe<DateFilterInput>;
}>;

export type GetGeneralMetricQuery = {
  __typename?: 'Query';
  getGeneralMetric: { __typename?: 'AverageResponseTimeSchema'; averageResponseTime: number };
};

export type GetLastOrderQueryVariables = Exact<{
  conversationId: Scalars['Float'];
}>;

export type GetLastOrderQuery = {
  __typename?: 'Query';
  getLastOrder: Array<{
    __typename?: 'OrdersResponseSchema';
    id: string;
    link: string;
    email: string;
    phone?: string | null;
    trackingId?: Array<string> | null;
    trackingUrl?: Array<string> | null;
    status?: string | null;
    financialStatus?: string | null;
    fulfillmentStatus: string;
    shipmentStatus?: Array<string> | null;
    total: number;
    netPayment: number;
    cursor?: string | null;
    item: Array<{
      __typename?: 'ItemSchema';
      id: string;
      title: string;
      link?: string | null;
      quantity: number;
      price: string;
    }>;
    shipment: {
      __typename?: 'ShippingSchema';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      company?: string | null;
      country?: string | null;
      countryCode?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      phone?: string | null;
      province?: string | null;
      provinceCode?: string | null;
      zip?: string | null;
    };
    pageInfo?: {
      __typename?: 'PageInfoSchema';
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    } | null;
  }>;
};

export type GetMetricQueryVariables = Exact<{
  userId: Scalars['Float'];
}>;

export type GetMetricQuery = {
  __typename?: 'Query';
  getMetric: { __typename?: 'AverageResponseTimeSchema'; averageResponseTime: number };
};

export type GetMetricsQueryVariables = Exact<{
  input?: InputMaybe<GetMetricsInput>;
}>;

export type GetMetricsQuery = {
  __typename?: 'Query';
  getMetrics: {
    __typename?: 'MetricsWithCountSchema';
    count: number;
    rows: Array<{
      __typename?: 'UserMetric';
      averageResponseTime: number;
      userId: number;
      quantity: number;
      firstName: string;
      lastName?: string | null;
    }>;
  };
};

export type GetOrderQueryVariables = Exact<{
  input: GetOrderByIdInput;
}>;

export type GetOrderQuery = {
  __typename?: 'Query';
  getOrder: {
    __typename?: 'OrderResponseSchema';
    id: string;
    link: string;
    email: string;
    phone?: string | null;
    trackingId?: Array<string> | null;
    trackingUrl?: Array<string> | null;
    status?: string | null;
    financialStatus?: string | null;
    fulfillmentStatus: string;
    shipmentStatus?: Array<string> | null;
    total: number;
    netPayment: number;
    itemCursor?: string | null;
    item: Array<{
      __typename?: 'ItemSchema';
      id: string;
      title: string;
      link?: string | null;
      quantity: number;
      price: string;
    }>;
    shipment: {
      __typename?: 'ShippingSchema';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      company?: string | null;
      country?: string | null;
      countryCode?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      phone?: string | null;
      province?: string | null;
      provinceCode?: string | null;
      zip?: string | null;
    };
  };
};

export type GetOrdersQueryVariables = Exact<{
  input: GetOrdersInput;
}>;

export type GetOrdersQuery = {
  __typename?: 'Query';
  getOrders: Array<{
    __typename?: 'OrdersResponseSchema';
    id: string;
    link: string;
    email: string;
    phone?: string | null;
    trackingId?: Array<string> | null;
    trackingUrl?: Array<string> | null;
    status?: string | null;
    financialStatus?: string | null;
    fulfillmentStatus: string;
    shipmentStatus?: Array<string> | null;
    total: number;
    netPayment: number;
    cursor?: string | null;
    item: Array<{
      __typename?: 'ItemSchema';
      id: string;
      title: string;
      link?: string | null;
      quantity: number;
      price: string;
    }>;
    shipment: {
      __typename?: 'ShippingSchema';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      company?: string | null;
      country?: string | null;
      countryCode?: string | null;
      firstName?: string | null;
      id?: string | null;
      lastName?: string | null;
      phone?: string | null;
      province?: string | null;
      provinceCode?: string | null;
      zip?: string | null;
    };
    pageInfo?: {
      __typename?: 'PageInfoSchema';
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    } | null;
  }>;
};

export type GetShopQueryVariables = Exact<{
  getShopId: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
}>;

export type GetShopQuery = {
  __typename?: 'Query';
  getShop: {
    __typename?: 'ShopEntity';
    id: string;
    name: string;
    email?: string | null;
    phone?: string | null;
    smsAvailable: boolean;
    emailAvailable: boolean;
    liveChatAvailable: boolean;
    tone?: string | null;
    agentFirstName: string;
    notes?: string | null;
    deactivated: boolean;
    deactivatedAt?: string | null;
    url?: string | null;
    domainId?: string | null;
    createdAt: string;
    templates: Array<{
      __typename?: 'TemplateSchema';
      id: number;
      name: string;
      text: string;
      shortcut?: string | null;
      type: TemplateTypeEnum;
    }>;
  };
};

export type GetShopsQueryVariables = Exact<{
  options: GetShopsOptions;
}>;

export type GetShopsQuery = {
  __typename?: 'Query';
  getShops: {
    __typename?: 'ShopWithCountSchema';
    count: number;
    rows: Array<{
      __typename?: 'ShopEntity';
      id: string;
      name: string;
      email?: string | null;
      phone?: string | null;
      smsAvailable: boolean;
      emailAvailable: boolean;
      liveChatAvailable: boolean;
      tone?: string | null;
      agentFirstName: string;
      notes?: string | null;
      deactivated: boolean;
      deactivatedAt?: string | null;
      url?: string | null;
      domainId?: string | null;
      createdAt: string;
      templates: Array<{
        __typename?: 'TemplateSchema';
        id: number;
        name: string;
        text: string;
        shortcut?: string | null;
        type: TemplateTypeEnum;
      }>;
    }>;
  };
};

export type CountUnprocessedSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CountUnprocessedSubscriptionSubscription = {
  __typename?: 'Subscription';
  countUnprocessedConversations: number;
};

export type SubscriptionSubscriptionVariables = Exact<{
  conversationId: Scalars['Float'];
}>;

export type SubscriptionSubscription = {
  __typename?: 'Subscription';
  chatMessage: {
    __typename?: 'MessageEntity';
    id: number;
    authorId?: number | null;
    conversationId: number;
    text: string;
    createdAt: string;
  };
};

export const MessageFragmentDoc = gql`
  fragment Message on MessageEntity {
    id
    authorId
    authorName
    conversationId
    createdAt
    text
    images {
      filename
      id
      messageId
    }
  }
`;
export const FragmentOrderFragmentDoc = gql`
  fragment FragmentOrder on OrderResponseSchema {
    id
    link
    email
    phone
    trackingId
    trackingUrl
    status
    financialStatus
    fulfillmentStatus
    shipmentStatus
    total
    netPayment
    item {
      id
      title
      link
      quantity
      price
    }
    shipment {
      address1
      address2
      city
      company
      country
      countryCode
      firstName
      id
      lastName
      phone
      province
      provinceCode
      zip
    }
    itemCursor
  }
`;
export const FragmentShopFragmentDoc = gql`
  fragment FragmentShop on ShopEntity {
    id
    name
    email
    phone
    smsAvailable
    emailAvailable
    liveChatAvailable
    tone
    agentFirstName
    notes
    deactivated
    deactivatedAt
    url
    domainId
    createdAt
    templates {
      id
      name
      text
      shortcut
      type
    }
  }
`;
export const CancelOrderDocument = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input)
  }
`;
export type CancelOrderMutationFn = Apollo.MutationFunction<
  CancelOrderMutation,
  CancelOrderMutationVariables
>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(
    CancelOrderDocument,
    options,
  );
}
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelOrderMutation,
  CancelOrderMutationVariables
>;
export const CreateMessageDocument = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      authorId
      conversationId
      createdAt
      id
      text
    }
  }
`;
export type CreateMessageMutationFn = Apollo.MutationFunction<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(
    CreateMessageDocument,
    options,
  );
}
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateMessageMutation,
  CreateMessageMutationVariables
>;
export const CreateShopTemplateDocument = gql`
  mutation CreateShopTemplate($input: CreateTemplateInput!) {
    createShopTemplate(input: $input) {
      id
      name
      text
      shortcut
      type
    }
  }
`;
export type CreateShopTemplateMutationFn = Apollo.MutationFunction<
  CreateShopTemplateMutation,
  CreateShopTemplateMutationVariables
>;

/**
 * __useCreateShopTemplateMutation__
 *
 * To run a mutation, you first call `useCreateShopTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopTemplateMutation, { data, loading, error }] = useCreateShopTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShopTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateShopTemplateMutation,
    CreateShopTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateShopTemplateMutation, CreateShopTemplateMutationVariables>(
    CreateShopTemplateDocument,
    options,
  );
}
export type CreateShopTemplateMutationHookResult = ReturnType<typeof useCreateShopTemplateMutation>;
export type CreateShopTemplateMutationResult = Apollo.MutationResult<CreateShopTemplateMutation>;
export type CreateShopTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateShopTemplateMutation,
  CreateShopTemplateMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      password
      user {
        id
        email
        firstName
        lastName
        role
        avatar
        blocked
        blockedAt
        refreshToken
        createdAt
        updatedAt
        assignedConversation
      }
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteShopTemplateDocument = gql`
  mutation DeleteShopTemplate($input: DeleteTemplateInput!) {
    deleteShopTemplate(input: $input) {
      deletedTemplateId
    }
  }
`;
export type DeleteShopTemplateMutationFn = Apollo.MutationFunction<
  DeleteShopTemplateMutation,
  DeleteShopTemplateMutationVariables
>;

/**
 * __useDeleteShopTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteShopTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShopTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShopTemplateMutation, { data, loading, error }] = useDeleteShopTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteShopTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteShopTemplateMutation,
    DeleteShopTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteShopTemplateMutation, DeleteShopTemplateMutationVariables>(
    DeleteShopTemplateDocument,
    options,
  );
}
export type DeleteShopTemplateMutationHookResult = ReturnType<typeof useDeleteShopTemplateMutation>;
export type DeleteShopTemplateMutationResult = Apollo.MutationResult<DeleteShopTemplateMutation>;
export type DeleteShopTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteShopTemplateMutation,
  DeleteShopTemplateMutationVariables
>;
export const EditCustomerDocument = gql`
  mutation EditCustomer($input: EditCustomerInput!) {
    editCustomer(input: $input) {
      email
      phone
    }
  }
`;
export type EditCustomerMutationFn = Apollo.MutationFunction<
  EditCustomerMutation,
  EditCustomerMutationVariables
>;

/**
 * __useEditCustomerMutation__
 *
 * To run a mutation, you first call `useEditCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomerMutation, { data, loading, error }] = useEditCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<EditCustomerMutation, EditCustomerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditCustomerMutation, EditCustomerMutationVariables>(
    EditCustomerDocument,
    options,
  );
}
export type EditCustomerMutationHookResult = ReturnType<typeof useEditCustomerMutation>;
export type EditCustomerMutationResult = Apollo.MutationResult<EditCustomerMutation>;
export type EditCustomerMutationOptions = Apollo.BaseMutationOptions<
  EditCustomerMutation,
  EditCustomerMutationVariables
>;
export const EditShopDocument = gql`
  mutation EditShop($input: EditShopInput!) {
    editShop(input: $input) {
      id
      name
      email
      phone
      smsAvailable
      emailAvailable
      liveChatAvailable
      tone
      agentFirstName
      notes
      deactivated
      deactivatedAt
      url
      domainId
      createdAt
      templates {
        id
        name
        text
        shortcut
        type
      }
    }
  }
`;
export type EditShopMutationFn = Apollo.MutationFunction<
  EditShopMutation,
  EditShopMutationVariables
>;

/**
 * __useEditShopMutation__
 *
 * To run a mutation, you first call `useEditShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopMutation, { data, loading, error }] = useEditShopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditShopMutation(
  baseOptions?: Apollo.MutationHookOptions<EditShopMutation, EditShopMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditShopMutation, EditShopMutationVariables>(EditShopDocument, options);
}
export type EditShopMutationHookResult = ReturnType<typeof useEditShopMutation>;
export type EditShopMutationResult = Apollo.MutationResult<EditShopMutation>;
export type EditShopMutationOptions = Apollo.BaseMutationOptions<
  EditShopMutation,
  EditShopMutationVariables
>;
export const EditShopTemplateDocument = gql`
  mutation EditShopTemplate($templateId: Float!, $input: EditTemplateInput!) {
    editShopTemplate(templateId: $templateId, input: $input) {
      id
      name
      text
      shortcut
      type
    }
  }
`;
export type EditShopTemplateMutationFn = Apollo.MutationFunction<
  EditShopTemplateMutation,
  EditShopTemplateMutationVariables
>;

/**
 * __useEditShopTemplateMutation__
 *
 * To run a mutation, you first call `useEditShopTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopTemplateMutation, { data, loading, error }] = useEditShopTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditShopTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditShopTemplateMutation,
    EditShopTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditShopTemplateMutation, EditShopTemplateMutationVariables>(
    EditShopTemplateDocument,
    options,
  );
}
export type EditShopTemplateMutationHookResult = ReturnType<typeof useEditShopTemplateMutation>;
export type EditShopTemplateMutationResult = Apollo.MutationResult<EditShopTemplateMutation>;
export type EditShopTemplateMutationOptions = Apollo.BaseMutationOptions<
  EditShopTemplateMutation,
  EditShopTemplateMutationVariables
>;
export const EditUserDocument = gql`
  mutation EditUser($input: EditUserInput!) {
    editUser(input: $input) {
      id
      email
      firstName
      lastName
      role
      avatar
      blocked
      blockedAt
      refreshToken
      createdAt
      updatedAt
    }
  }
`;
export type EditUserMutationFn = Apollo.MutationFunction<
  EditUserMutation,
  EditUserMutationVariables
>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<
  EditUserMutation,
  EditUserMutationVariables
>;
export const GetAndAssignNextConversationDocument = gql`
  mutation GetAndAssignNextConversation {
    getAndAssignNextConversation {
      id
      shopId
      customerId
      assignedUserId
      status
      tag
      provider
      updatedAt
      shop {
        id
        name
        email
        phone
        smsAvailable
        emailAvailable
        liveChatAvailable
        tone
        agentFirstName
        notes
        deactivated
        deactivatedAt
        url
        domainId
        createdAt
        templates {
          id
          name
          text
          shortcut
          type
        }
      }
      assignedUser {
        id
        email
        firstName
        lastName
        role
        avatar
        blocked
        blockedAt
        refreshToken
        createdAt
        updatedAt
      }
      messages {
        id
        authorId
        conversationId
        text
        createdAt
        images {
          filename
          id
          messageId
        }
      }
      tags
      customer {
        country
        email
        firstName
        id
        phone
        lastName
      }
      createdAt
      subject
    }
  }
`;
export type GetAndAssignNextConversationMutationFn = Apollo.MutationFunction<
  GetAndAssignNextConversationMutation,
  GetAndAssignNextConversationMutationVariables
>;

/**
 * __useGetAndAssignNextConversationMutation__
 *
 * To run a mutation, you first call `useGetAndAssignNextConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAndAssignNextConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAndAssignNextConversationMutation, { data, loading, error }] = useGetAndAssignNextConversationMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetAndAssignNextConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetAndAssignNextConversationMutation,
    GetAndAssignNextConversationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetAndAssignNextConversationMutation,
    GetAndAssignNextConversationMutationVariables
  >(GetAndAssignNextConversationDocument, options);
}
export type GetAndAssignNextConversationMutationHookResult = ReturnType<
  typeof useGetAndAssignNextConversationMutation
>;
export type GetAndAssignNextConversationMutationResult =
  Apollo.MutationResult<GetAndAssignNextConversationMutation>;
export type GetAndAssignNextConversationMutationOptions = Apollo.BaseMutationOptions<
  GetAndAssignNextConversationMutation,
  GetAndAssignNextConversationMutationVariables
>;
export const GoogleAuthDocument = gql`
  mutation GoogleAuth($input: GoogleAuthInput!) {
    googleAuth(input: $input) {
      accessToken
      refreshToken
      user {
        avatar
        blocked
        createdAt
        blockedAt
        email
        firstName
        id
        lastName
        refreshToken
        role
        updatedAt
      }
    }
  }
`;
export type GoogleAuthMutationFn = Apollo.MutationFunction<
  GoogleAuthMutation,
  GoogleAuthMutationVariables
>;

/**
 * __useGoogleAuthMutation__
 *
 * To run a mutation, you first call `useGoogleAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleAuthMutation, { data, loading, error }] = useGoogleAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoogleAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<GoogleAuthMutation, GoogleAuthMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GoogleAuthMutation, GoogleAuthMutationVariables>(
    GoogleAuthDocument,
    options,
  );
}
export type GoogleAuthMutationHookResult = ReturnType<typeof useGoogleAuthMutation>;
export type GoogleAuthMutationResult = Apollo.MutationResult<GoogleAuthMutation>;
export type GoogleAuthMutationOptions = Apollo.BaseMutationOptions<
  GoogleAuthMutation,
  GoogleAuthMutationVariables
>;
export const MarkAsAnsweredDocument = gql`
  mutation MarkAsAnswered($conversationId: Float!) {
    markAsAnswered(conversationId: $conversationId) {
      id
    }
  }
`;
export type MarkAsAnsweredMutationFn = Apollo.MutationFunction<
  MarkAsAnsweredMutation,
  MarkAsAnsweredMutationVariables
>;

/**
 * __useMarkAsAnsweredMutation__
 *
 * To run a mutation, you first call `useMarkAsAnsweredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsAnsweredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsAnsweredMutation, { data, loading, error }] = useMarkAsAnsweredMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useMarkAsAnsweredMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkAsAnsweredMutation, MarkAsAnsweredMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkAsAnsweredMutation, MarkAsAnsweredMutationVariables>(
    MarkAsAnsweredDocument,
    options,
  );
}
export type MarkAsAnsweredMutationHookResult = ReturnType<typeof useMarkAsAnsweredMutation>;
export type MarkAsAnsweredMutationResult = Apollo.MutationResult<MarkAsAnsweredMutation>;
export type MarkAsAnsweredMutationOptions = Apollo.BaseMutationOptions<
  MarkAsAnsweredMutation,
  MarkAsAnsweredMutationVariables
>;
export const MarkAsBlockedDocument = gql`
  mutation MarkAsBlocked($conversationId: Float!) {
    markAsBlocked(conversationId: $conversationId)
  }
`;
export type MarkAsBlockedMutationFn = Apollo.MutationFunction<
  MarkAsBlockedMutation,
  MarkAsBlockedMutationVariables
>;

/**
 * __useMarkAsBlockedMutation__
 *
 * To run a mutation, you first call `useMarkAsBlockedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsBlockedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsBlockedMutation, { data, loading, error }] = useMarkAsBlockedMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useMarkAsBlockedMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkAsBlockedMutation, MarkAsBlockedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkAsBlockedMutation, MarkAsBlockedMutationVariables>(
    MarkAsBlockedDocument,
    options,
  );
}
export type MarkAsBlockedMutationHookResult = ReturnType<typeof useMarkAsBlockedMutation>;
export type MarkAsBlockedMutationResult = Apollo.MutationResult<MarkAsBlockedMutation>;
export type MarkAsBlockedMutationOptions = Apollo.BaseMutationOptions<
  MarkAsBlockedMutation,
  MarkAsBlockedMutationVariables
>;
export const MarkAsNoNeedToReplyDocument = gql`
  mutation MarkAsNoNeedToReply($conversationId: Float!) {
    markAsNoNeedToReply(conversationId: $conversationId)
  }
`;
export type MarkAsNoNeedToReplyMutationFn = Apollo.MutationFunction<
  MarkAsNoNeedToReplyMutation,
  MarkAsNoNeedToReplyMutationVariables
>;

/**
 * __useMarkAsNoNeedToReplyMutation__
 *
 * To run a mutation, you first call `useMarkAsNoNeedToReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsNoNeedToReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsNoNeedToReplyMutation, { data, loading, error }] = useMarkAsNoNeedToReplyMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useMarkAsNoNeedToReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAsNoNeedToReplyMutation,
    MarkAsNoNeedToReplyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkAsNoNeedToReplyMutation, MarkAsNoNeedToReplyMutationVariables>(
    MarkAsNoNeedToReplyDocument,
    options,
  );
}
export type MarkAsNoNeedToReplyMutationHookResult = ReturnType<
  typeof useMarkAsNoNeedToReplyMutation
>;
export type MarkAsNoNeedToReplyMutationResult = Apollo.MutationResult<MarkAsNoNeedToReplyMutation>;
export type MarkAsNoNeedToReplyMutationOptions = Apollo.BaseMutationOptions<
  MarkAsNoNeedToReplyMutation,
  MarkAsNoNeedToReplyMutationVariables
>;
export const SetTagDocument = gql`
  mutation SetTag($input: SetTagInput!) {
    setTag(input: $input) {
      id
      tag
      tags
      updatedAt
      status
      shopId
      provider
      customerId
      assignedUserId
    }
  }
`;
export type SetTagMutationFn = Apollo.MutationFunction<SetTagMutation, SetTagMutationVariables>;

/**
 * __useSetTagMutation__
 *
 * To run a mutation, you first call `useSetTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTagMutation, { data, loading, error }] = useSetTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTagMutation(
  baseOptions?: Apollo.MutationHookOptions<SetTagMutation, SetTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetTagMutation, SetTagMutationVariables>(SetTagDocument, options);
}
export type SetTagMutationHookResult = ReturnType<typeof useSetTagMutation>;
export type SetTagMutationResult = Apollo.MutationResult<SetTagMutation>;
export type SetTagMutationOptions = Apollo.BaseMutationOptions<
  SetTagMutation,
  SetTagMutationVariables
>;
export const UnassignUserDocument = gql`
  mutation UnassignUser($conversationId: Float!) {
    unassignUser(conversationId: $conversationId)
  }
`;
export type UnassignUserMutationFn = Apollo.MutationFunction<
  UnassignUserMutation,
  UnassignUserMutationVariables
>;

/**
 * __useUnassignUserMutation__
 *
 * To run a mutation, you first call `useUnassignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignUserMutation, { data, loading, error }] = useUnassignUserMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useUnassignUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UnassignUserMutation, UnassignUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnassignUserMutation, UnassignUserMutationVariables>(
    UnassignUserDocument,
    options,
  );
}
export type UnassignUserMutationHookResult = ReturnType<typeof useUnassignUserMutation>;
export type UnassignUserMutationResult = Apollo.MutationResult<UnassignUserMutation>;
export type UnassignUserMutationOptions = Apollo.BaseMutationOptions<
  UnassignUserMutation,
  UnassignUserMutationVariables
>;
export const UpdateOrderDocument = gql`
  mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      shippingAddress {
        address1
        address2
        city
        company
        country
        countryCode
        firstName
        id
        lastName
        phone
        province
        provinceCode
        zip
      }
      userErrors {
        field
        message
      }
      email
    }
  }
`;
export type UpdateOrderMutationFn = Apollo.MutationFunction<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(
    UpdateOrderDocument,
    options,
  );
}
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderMutation,
  UpdateOrderMutationVariables
>;
export const FindAndCountManyUsersDocument = gql`
  query FindAndCountManyUsers($input: FindAndCountManyUsersInput!) {
    findAndCountManyUsers(input: $input) {
      rows {
        id
        email
        firstName
        lastName
        role
        avatar
        blocked
        blockedAt
        refreshToken
        createdAt
        updatedAt
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyUsersQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAndCountManyUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyUsersQuery,
    FindAndCountManyUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAndCountManyUsersQuery, FindAndCountManyUsersQueryVariables>(
    FindAndCountManyUsersDocument,
    options,
  );
}
export function useFindAndCountManyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyUsersQuery,
    FindAndCountManyUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAndCountManyUsersQuery, FindAndCountManyUsersQueryVariables>(
    FindAndCountManyUsersDocument,
    options,
  );
}
export type FindAndCountManyUsersQueryHookResult = ReturnType<typeof useFindAndCountManyUsersQuery>;
export type FindAndCountManyUsersLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManyUsersLazyQuery
>;
export type FindAndCountManyUsersQueryResult = Apollo.QueryResult<
  FindAndCountManyUsersQuery,
  FindAndCountManyUsersQueryVariables
>;
export const GetConversationDocument = gql`
  query GetConversation($conversationId: Float!) {
    getConversation(conversationId: $conversationId) {
      id
      shopId
      customerId
      assignedUserId
      status
      tag
      provider
      updatedAt
      lastMessageReceived
      lastMessage
      shop {
        id
        name
        email
        phone
        smsAvailable
        emailAvailable
        liveChatAvailable
        tone
        agentFirstName
        notes
        deactivated
        deactivatedAt
        url
        domainId
        createdAt
        verifiedSender
        templates {
          id
          name
          text
          shortcut
          type
        }
      }
      assignedUser {
        id
        email
        firstName
        lastName
        role
        avatar
        blocked
        blockedAt
        refreshToken
        createdAt
        updatedAt
      }
      messages {
        ...Message
      }
      tags
      customer {
        country
        email
        firstName
        lastName
        id
        phone
      }
      createdAt
      subject
    }
  }
  ${MessageFragmentDoc}
`;

/**
 * __useGetConversationQuery__
 *
 * To run a query within a React component, call `useGetConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetConversationQuery(
  baseOptions: Apollo.QueryHookOptions<GetConversationQuery, GetConversationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConversationQuery, GetConversationQueryVariables>(
    GetConversationDocument,
    options,
  );
}
export function useGetConversationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConversationQuery, GetConversationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetConversationQuery, GetConversationQueryVariables>(
    GetConversationDocument,
    options,
  );
}
export type GetConversationQueryHookResult = ReturnType<typeof useGetConversationQuery>;
export type GetConversationLazyQueryHookResult = ReturnType<typeof useGetConversationLazyQuery>;
export type GetConversationQueryResult = Apollo.QueryResult<
  GetConversationQuery,
  GetConversationQueryVariables
>;
export const GetConversationsDocument = gql`
  query GetConversations($input: GetConversationsOptions!) {
    getConversations(input: $input) {
      rows {
        id
        shopId
        assignedUserId
        status
        tag
        provider
        updatedAt
        shop {
          id
          name
          phone
          email
          agentFirstName
        }
        customer {
          phone
          lastName
          id
          firstName
          email
          country
        }
        assignedUser {
          id
          email
          firstName
          lastName
          role
          avatar
          blocked
          blockedAt
          refreshToken
          createdAt
          updatedAt
        }
        lastMessageReceived
        lastMessage
      }
      count
    }
  }
`;

/**
 * __useGetConversationsQuery__
 *
 * To run a query within a React component, call `useGetConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetConversationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetConversationsQuery, GetConversationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConversationsQuery, GetConversationsQueryVariables>(
    GetConversationsDocument,
    options,
  );
}
export function useGetConversationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConversationsQuery, GetConversationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetConversationsQuery, GetConversationsQueryVariables>(
    GetConversationsDocument,
    options,
  );
}
export type GetConversationsQueryHookResult = ReturnType<typeof useGetConversationsQuery>;
export type GetConversationsLazyQueryHookResult = ReturnType<typeof useGetConversationsLazyQuery>;
export type GetConversationsQueryResult = Apollo.QueryResult<
  GetConversationsQuery,
  GetConversationsQueryVariables
>;
export const GetCurrentConversationDocument = gql`
  query GetCurrentConversation {
    getCurrentConversation {
      id
      shopId
      customerId
      assignedUserId
      status
      tag
      provider
      updatedAt
      createdAt
      shop {
        id
        name
        email
        phone
        smsAvailable
        emailAvailable
        liveChatAvailable
        tone
        agentFirstName
        notes
        deactivated
        deactivatedAt
        url
        domainId
        createdAt
        verifiedSender
        templates {
          id
          name
          text
          shortcut
          type
        }
      }
      customer {
        id
        firstName
        lastName
        phone
        email
        country
      }
      assignedUser {
        id
        email
        firstName
        lastName
        role
        avatar
        blocked
        blockedAt
        refreshToken
        createdAt
        updatedAt
        assignedConversation
      }
      messages {
        id
        authorId
        authorName
        conversationId
        createdAt
        text
        images {
          filename
          id
          messageId
        }
      }
      tags
      subject
    }
  }
`;

/**
 * __useGetCurrentConversationQuery__
 *
 * To run a query within a React component, call `useGetCurrentConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentConversationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentConversationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentConversationQuery,
    GetCurrentConversationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentConversationQuery, GetCurrentConversationQueryVariables>(
    GetCurrentConversationDocument,
    options,
  );
}
export function useGetCurrentConversationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentConversationQuery,
    GetCurrentConversationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentConversationQuery, GetCurrentConversationQueryVariables>(
    GetCurrentConversationDocument,
    options,
  );
}
export type GetCurrentConversationQueryHookResult = ReturnType<
  typeof useGetCurrentConversationQuery
>;
export type GetCurrentConversationLazyQueryHookResult = ReturnType<
  typeof useGetCurrentConversationLazyQuery
>;
export type GetCurrentConversationQueryResult = Apollo.QueryResult<
  GetCurrentConversationQuery,
  GetCurrentConversationQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    getCurrentUser {
      avatar
      blocked
      blockedAt
      createdAt
      email
      firstName
      id
      lastName
      refreshToken
      role
      updatedAt
      assignedConversation
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetGeneralMetricDocument = gql`
  query GetGeneralMetric($input: DateFilterInput) {
    getGeneralMetric(input: $input) {
      averageResponseTime
    }
  }
`;

/**
 * __useGetGeneralMetricQuery__
 *
 * To run a query within a React component, call `useGetGeneralMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralMetricQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGeneralMetricQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGeneralMetricQuery, GetGeneralMetricQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGeneralMetricQuery, GetGeneralMetricQueryVariables>(
    GetGeneralMetricDocument,
    options,
  );
}
export function useGetGeneralMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGeneralMetricQuery, GetGeneralMetricQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGeneralMetricQuery, GetGeneralMetricQueryVariables>(
    GetGeneralMetricDocument,
    options,
  );
}
export type GetGeneralMetricQueryHookResult = ReturnType<typeof useGetGeneralMetricQuery>;
export type GetGeneralMetricLazyQueryHookResult = ReturnType<typeof useGetGeneralMetricLazyQuery>;
export type GetGeneralMetricQueryResult = Apollo.QueryResult<
  GetGeneralMetricQuery,
  GetGeneralMetricQueryVariables
>;
export const GetLastOrderDocument = gql`
  query GetLastOrder($conversationId: Float!) {
    getLastOrder(conversationId: $conversationId) {
      id
      link
      email
      phone
      trackingId
      trackingUrl
      status
      financialStatus
      fulfillmentStatus
      shipmentStatus
      total
      netPayment
      item {
        id
        title
        link
        quantity
        price
      }
      shipment {
        address1
        address2
        city
        company
        country
        countryCode
        firstName
        id
        lastName
        phone
        province
        provinceCode
        zip
      }
      cursor
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

/**
 * __useGetLastOrderQuery__
 *
 * To run a query within a React component, call `useGetLastOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastOrderQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetLastOrderQuery(
  baseOptions: Apollo.QueryHookOptions<GetLastOrderQuery, GetLastOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLastOrderQuery, GetLastOrderQueryVariables>(
    GetLastOrderDocument,
    options,
  );
}
export function useGetLastOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLastOrderQuery, GetLastOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLastOrderQuery, GetLastOrderQueryVariables>(
    GetLastOrderDocument,
    options,
  );
}
export type GetLastOrderQueryHookResult = ReturnType<typeof useGetLastOrderQuery>;
export type GetLastOrderLazyQueryHookResult = ReturnType<typeof useGetLastOrderLazyQuery>;
export type GetLastOrderQueryResult = Apollo.QueryResult<
  GetLastOrderQuery,
  GetLastOrderQueryVariables
>;
export const GetMetricDocument = gql`
  query GetMetric($userId: Float!) {
    getMetric(userId: $userId) {
      averageResponseTime
    }
  }
`;

/**
 * __useGetMetricQuery__
 *
 * To run a query within a React component, call `useGetMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetMetricQuery(
  baseOptions: Apollo.QueryHookOptions<GetMetricQuery, GetMetricQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMetricQuery, GetMetricQueryVariables>(GetMetricDocument, options);
}
export function useGetMetricLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMetricQuery, GetMetricQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMetricQuery, GetMetricQueryVariables>(GetMetricDocument, options);
}
export type GetMetricQueryHookResult = ReturnType<typeof useGetMetricQuery>;
export type GetMetricLazyQueryHookResult = ReturnType<typeof useGetMetricLazyQuery>;
export type GetMetricQueryResult = Apollo.QueryResult<GetMetricQuery, GetMetricQueryVariables>;
export const GetMetricsDocument = gql`
  query GetMetrics($input: GetMetricsInput) {
    getMetrics(input: $input) {
      count
      rows {
        averageResponseTime
        userId
        quantity
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetMetricsQuery__
 *
 * To run a query within a React component, call `useGetMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetricsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMetricsQuery, GetMetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMetricsQuery, GetMetricsQueryVariables>(GetMetricsDocument, options);
}
export function useGetMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMetricsQuery, GetMetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMetricsQuery, GetMetricsQueryVariables>(
    GetMetricsDocument,
    options,
  );
}
export type GetMetricsQueryHookResult = ReturnType<typeof useGetMetricsQuery>;
export type GetMetricsLazyQueryHookResult = ReturnType<typeof useGetMetricsLazyQuery>;
export type GetMetricsQueryResult = Apollo.QueryResult<GetMetricsQuery, GetMetricsQueryVariables>;
export const GetOrderDocument = gql`
  query GetOrder($input: GetOrderByIdInput!) {
    getOrder(input: $input) {
      ...FragmentOrder
    }
  }
  ${FragmentOrderFragmentDoc}
`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
}
export function useGetOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetOrdersDocument = gql`
  query GetOrders($input: GetOrdersInput!) {
    getOrders(input: $input) {
      id
      link
      email
      phone
      trackingId
      trackingUrl
      status
      financialStatus
      fulfillmentStatus
      shipmentStatus
      total
      netPayment
      item {
        id
        title
        link
        quantity
        price
      }
      shipment {
        address1
        address2
        city
        company
        country
        countryCode
        firstName
        id
        lastName
        phone
        province
        provinceCode
        zip
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      cursor
    }
  }
`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
}
export function useGetOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetShopDocument = gql`
  query GetShop($getShopId: Int!, $search: String) {
    getShop(id: $getShopId) {
      id
      name
      email
      phone
      smsAvailable
      emailAvailable
      liveChatAvailable
      tone
      agentFirstName
      notes
      deactivated
      deactivatedAt
      url
      domainId
      createdAt
      templates(search: $search) {
        id
        name
        text
        shortcut
        type
      }
    }
  }
`;

/**
 * __useGetShopQuery__
 *
 * To run a query within a React component, call `useGetShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopQuery({
 *   variables: {
 *      getShopId: // value for 'getShopId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetShopQuery(
  baseOptions: Apollo.QueryHookOptions<GetShopQuery, GetShopQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetShopQuery, GetShopQueryVariables>(GetShopDocument, options);
}
export function useGetShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetShopQuery, GetShopQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetShopQuery, GetShopQueryVariables>(GetShopDocument, options);
}
export type GetShopQueryHookResult = ReturnType<typeof useGetShopQuery>;
export type GetShopLazyQueryHookResult = ReturnType<typeof useGetShopLazyQuery>;
export type GetShopQueryResult = Apollo.QueryResult<GetShopQuery, GetShopQueryVariables>;
export const GetShopsDocument = gql`
  query GetShops($options: GetShopsOptions!) {
    getShops(options: $options) {
      rows {
        id
        name
        email
        phone
        smsAvailable
        emailAvailable
        liveChatAvailable
        tone
        agentFirstName
        notes
        deactivated
        deactivatedAt
        url
        domainId
        createdAt
        templates {
          id
          name
          text
          shortcut
          type
        }
      }
      count
    }
  }
`;

/**
 * __useGetShopsQuery__
 *
 * To run a query within a React component, call `useGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetShopsQuery(
  baseOptions: Apollo.QueryHookOptions<GetShopsQuery, GetShopsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetShopsQuery, GetShopsQueryVariables>(GetShopsDocument, options);
}
export function useGetShopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetShopsQuery, GetShopsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetShopsQuery, GetShopsQueryVariables>(GetShopsDocument, options);
}
export type GetShopsQueryHookResult = ReturnType<typeof useGetShopsQuery>;
export type GetShopsLazyQueryHookResult = ReturnType<typeof useGetShopsLazyQuery>;
export type GetShopsQueryResult = Apollo.QueryResult<GetShopsQuery, GetShopsQueryVariables>;
export const CountUnprocessedSubscriptionDocument = gql`
  subscription CountUnprocessedSubscription {
    countUnprocessedConversations
  }
`;

/**
 * __useCountUnprocessedSubscriptionSubscription__
 *
 * To run a query within a React component, call `useCountUnprocessedSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCountUnprocessedSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountUnprocessedSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCountUnprocessedSubscriptionSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CountUnprocessedSubscriptionSubscription,
    CountUnprocessedSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CountUnprocessedSubscriptionSubscription,
    CountUnprocessedSubscriptionSubscriptionVariables
  >(CountUnprocessedSubscriptionDocument, options);
}
export type CountUnprocessedSubscriptionSubscriptionHookResult = ReturnType<
  typeof useCountUnprocessedSubscriptionSubscription
>;
export type CountUnprocessedSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<CountUnprocessedSubscriptionSubscription>;
export const SubscriptionDocument = gql`
  subscription Subscription($conversationId: Float!) {
    chatMessage(conversationId: $conversationId) {
      id
      authorId
      conversationId
      text
      createdAt
    }
  }
`;

/**
 * __useSubscriptionSubscription__
 *
 * To run a query within a React component, call `useSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionSubscription({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscriptionSubscription,
    SubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<SubscriptionSubscription, SubscriptionSubscriptionVariables>(
    SubscriptionDocument,
    options,
  );
}
export type SubscriptionSubscriptionHookResult = ReturnType<typeof useSubscriptionSubscription>;
export type SubscriptionSubscriptionResult = Apollo.SubscriptionResult<SubscriptionSubscription>;
