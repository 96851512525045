import { styled } from '@mui/material/styles';
import { Button, ButtonProps, Stack, StackProps } from '@mui/material';
import { getBadgeData } from '../../../utils';

interface WrapperProps extends StackProps {
  asbuttonsgroup?: string;
}
export const Wrapper = styled(Stack)<WrapperProps>(({ theme, asbuttonsgroup }) => {
  const asButtonsGroup = asbuttonsgroup === 'true';
  if (!asButtonsGroup) {
    return {
      padding: '2px',
      background: theme.vars.palette.white.main,
      width: 'fit-content',
      borderRadius: '20px',
    };
  } else {
    return {
      flexDirection: 'row',
      gap: '12px',
    };
  }
});

interface ButtonCSSProps extends ButtonProps {
  asbuttonsgroup?: string;
  ismorecolored?: string;
}

export const ButtonCSS = styled(Button)<ButtonCSSProps>(
  ({ theme, asbuttonsgroup, variant, ismorecolored, id }) => {
    const asButtonsGroup = asbuttonsgroup === 'true';
    const isContainedVariant = variant === 'contained';
    const palette = theme.vars.palette;

    const infoStatus = getBadgeData(id || '');
    const isMoreColored = ismorecolored === 'true';

    if (asButtonsGroup)
      return {
        'svg path': {
          fill: isContainedVariant ? palette.whiteBlack.main : palette.blackWhite.main,
        },
        ...(isContainedVariant &&
          isMoreColored && {
            background: infoStatus.color,
            ':hover': {
              background: infoStatus.color,
            },
          }),
      };
    return {
      padding: '0 22px',
      outline: 'none',
      boxShadow: 'none',
      '&.MuiButton-contained': {
        background: theme.vars.palette.black.main,
        color: theme.vars.palette.white.main,
      },
      '&.MuiButton-outlined': {
        color: theme.vars.palette.black.main,
      },
    };
  },
);
