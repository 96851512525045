import { useState } from 'react';
import { ConversationProviderEnum } from '../graphql/generated';

interface useFiltersProps {
  generalOption: string;
}

export const useFilters = ({ generalOption }: useFiltersProps) => {
  const [filterState, setFilterState] = useState([generalOption]);

  const onClick = (id: string | ConversationProviderEnum) => {
    if (id === generalOption) {
      return setFilterState([generalOption]);
    }
    setFilterState((prev) => {
      if (prev.includes(id)) {
        if (prev.length === 1) return prev;
        return prev.filter((item) => item !== id);
      } else return [...prev.filter((item) => item !== generalOption), id];
    });
  };
  return { filterState, onClick };
};
