import { EmptyText, TableBodyCSS, TableContainerCSS, TableCSS, TableListWrapper } from './styles';
import { TableListRow } from './TableListRow/TableListRow';
import { TableListHeader } from './TableListHeader/TableListHeader';
import { EMPTY_TABLES } from '../../constants/constants';
import { RequestHandler } from '../RequestHandler/RequestHandler';
import { SxProps } from '@mui/material';
import { ShopEntity } from '../../graphql/generated';
import { MutableRefObject } from 'react';

export interface ITableOption {
  id: string;
  label: string;
  minWidth?: string;
}

export interface TableRowProps {
  id?: string | number;
  name?: string;
  company?: string;
  phone?: string | null;
  email?: string | null;
  lockedBy?: string;
  messageReceived?: string;
  lastMessage?: string;
  chanel?: string;
  control?: string;
  active?: boolean;
  messagesHandled?: string | number;
  averageResponseTime?: string;
  futureFeature?: string;
  shop?: ShopEntity;
  isBlocked?: boolean;
}

interface TableListProps {
  isLoading: boolean;
  headCells: ITableOption[];
  rows: TableRowProps[];
  rowAction?: (id: number) => void;
  isHasPagination: boolean;
  handleOpen?: (row: TableRowProps) => void;
  handleBlock?: (row: TableRowProps) => void;
  openButtonTitle?: string;
  isHasControlBadge?: boolean;
  active?: boolean;
  tableContainerSx?: SxProps;
  tableBodyRef?: MutableRefObject<HTMLTableSectionElement | null>;
}

export const TableList = ({
  isLoading,
  headCells,
  rows,
  rowAction,
  handleOpen,
  handleBlock,
  openButtonTitle,
  isHasControlBadge,
  tableContainerSx,
  tableBodyRef,
}: TableListProps) => {
  return (
    <TableListWrapper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainerCSS
        sx={{
          height: 'calc(100vh - 416px)',
          ...tableContainerSx,
        }}
      >
        <TableCSS stickyHeader aria-label='sticky table'>
          <TableListHeader headCells={headCells} />
          <RequestHandler loading={isLoading}>
            {!rows.length ? (
              <EmptyText variant='h2'>{EMPTY_TABLES}</EmptyText>
            ) : (
              <TableBodyCSS ref={tableBodyRef}>
                {rows?.map((row, index) => {
                  return (
                    <TableListRow
                      key={index}
                      row={row}
                      rowAction={rowAction}
                      headCells={headCells}
                      handleOpen={handleOpen}
                      handleBlock={handleBlock}
                      openButtonTitle={openButtonTitle}
                      isHasControlBadge={isHasControlBadge}
                    />
                  );
                })}
              </TableBodyCSS>
            )}
          </RequestHandler>
        </TableCSS>
      </TableContainerCSS>
    </TableListWrapper>
  );
};
