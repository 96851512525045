import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';
import { ButtonCSS } from './styles';

export const NotFound = () => {
  const navigate = useNavigate();
  const goHome = () => {
    navigate(ROUTES.home);
  };

  return (
    <Stack sx={{ alignItems: 'center', marginTop: '10vh' }}>
      <Typography variant={'h1'} fontSize={'200px'} lineHeight={'100%'}>
        404
      </Typography>
      <Typography variant={'h1'} fontSize={'100px'} lineHeight={'100%'}>
        Page Not Found
      </Typography>
      <ButtonCSS onClick={goHome}>GO HOME</ButtonCSS>
    </Stack>
  );
};
