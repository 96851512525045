import { Box, BoxProps, styled } from '@mui/material';

interface WrapperCSSProps extends BoxProps {
  ispage?: string;
}

export const Wrapper = styled(Box)<WrapperCSSProps>(({ theme, ispage }) => {
  const isPage = ispage === 'true';
  const palette = theme.vars.palette;

  return {
    position: 'relative',
    display: 'flex',
    minHeight: '100vh',
    background: !isPage ? palette.backgroundDef.main : palette.silver20silver200.main,
    justifyContent: 'center',
    overflow: 'hidden',
  };
});

export const ContentCSS = styled(Box)<WrapperCSSProps>(({ ispage }) => {
  const isPage = ispage === 'true';

  return {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 26px',
    position: 'relative',
    maxWidth: !isPage ? '1512px' : '100%',
  };
});
