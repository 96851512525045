import { getFormatTextWithIndents } from '../../../../utils';
import { Typography } from '@mui/material';
import { MessageEntity } from '../../../../graphql/generated';

interface MessageProps {
  messageData: MessageEntity;
  contentColor: string;
}
export const MessageText = ({ messageData, contentColor }: MessageProps) => {
  const textMessage = getFormatTextWithIndents({ content: messageData.text });
  return (
    <>
      <Typography variant='body3' color={contentColor}>
        {Array.isArray(textMessage) ? (
          <>
            {textMessage.map((message, i) => {
              return (
                <Typography
                  component={'p'}
                  variant={'body3'}
                  key={i}
                  sx={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}
                >
                  {message}
                  <br />
                </Typography>
              );
            })}
          </>
        ) : (
          <Typography component={'p'} variant={'body3'}>
            {textMessage}
          </Typography>
        )}
      </Typography>
      {!!messageData.images.length &&
        messageData.images.map((imageData) => {
          return (
            <img
              src={`${process.env.REACT_APP_API_HOST}/uploads/attachments/${imageData.filename}`}
              alt={String(imageData.id)}
            />
          );
        })}
    </>
  );
};
