import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      avatar
      blocked
      blockedAt
      createdAt
      email
      firstName
      id
      lastName
      refreshToken
      role
      updatedAt
      assignedConversation
    }
  }
`;
