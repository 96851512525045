export const ShopTabIcon = ({ color = '#0E0E0E' }) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.03394 1.6875C10.4113 1.68749 11.491 1.68749 12.3476 1.7803C13.2218 1.875 13.9301 2.07174 14.5348 2.51111C14.9009 2.77711 15.2229 3.09907 15.4889 3.46518C15.9283 4.06992 16.125 4.7782 16.2197 5.65236C16.3125 6.50894 16.3125 7.58862 16.3125 8.96599V9.03395C16.3125 10.4113 16.3125 11.4911 16.2197 12.3476C16.125 13.2218 15.9283 13.9301 15.4889 14.5348C15.2229 14.9009 14.9009 15.2229 14.5348 15.4889C13.9301 15.9283 13.2218 16.125 12.3476 16.2197C11.4911 16.3125 10.4114 16.3125 9.03401 16.3125H8.96605C7.58868 16.3125 6.50894 16.3125 5.65236 16.2197C4.7782 16.125 4.06992 15.9283 3.46518 15.4889C3.09907 15.2229 2.77711 14.9009 2.51111 14.5348C2.07174 13.9301 1.875 13.2218 1.7803 12.3476C1.68749 11.491 1.68749 10.4113 1.6875 9.03394V8.96606C1.68749 7.58866 1.68749 6.50896 1.7803 5.65236C1.875 4.7782 2.07174 4.06992 2.51111 3.46518C2.77711 3.09907 3.09907 2.77711 3.46518 2.51111C4.06992 2.07174 4.7782 1.875 5.65236 1.7803C6.50896 1.68749 7.58866 1.68749 8.96606 1.6875H9.03394ZM13.255 7.10486C13.3918 6.82595 13.2766 6.48894 12.9977 6.35213C12.7188 6.21531 12.3818 6.3305 12.245 6.60942L11.1497 8.8422C10.8103 9.53427 9.81249 9.50047 9.52062 8.78702C8.86125 7.17523 6.60716 7.09888 5.84024 8.66234L4.74499 10.8951C4.60817 11.174 4.72336 11.5111 5.00228 11.6479C5.28119 11.7847 5.6182 11.6695 5.75501 11.3906L6.85026 9.15779C7.18974 8.46572 8.18751 8.49953 8.47938 9.21298C9.13875 10.8248 11.3928 10.9011 12.1598 9.33765L13.255 7.10486Z'
        fill={color}
      />
    </svg>
  );
};
