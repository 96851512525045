import { Button, Stack } from '@mui/material';
import { ChevronBottomIcon } from '../../../Icons';
import { HeaderChannel, HeaderProfile } from '../../index';
import { SelectCustom } from '../../../UI';
import { useAuthContext, useModalContext } from '../../../../context';
import { ModalChatHeaderProps } from './types';
import { ThemeSwitcher } from '../../../ThemeSwitcher/ThemeSwitcher';
import { ConversationProviderEnum, useUnassignUserMutation } from '../../../../graphql/generated';
import { ChatHeaderSubject } from '../ChatHeaderSubject/ChatHeaderSubject';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/constants';
import { toast } from 'react-toastify';

export const ChatHeader = ({
  tag,
  optionsTag,
  changeTag,
  profileDate,
  profileTime,
  chatModalInfo,
  isEditOrder,
  onEditOrder,
  customer,
  isPage,
  conversationId,
  subjectText,
}: ModalChatHeaderProps) => {
  const { onCloseModal, infoModal } = useModalContext();
  const { userData } = useAuthContext();
  const currentUserId = userData?.id;
  const assignedConversation = userData?.assignedConversation;

  const navigate = useNavigate();

  const [unassignUser] = useUnassignUserMutation({
    variables: {
      conversationId,
    },
    update: (cache) => {
      cache.modify({
        id: `UserEntity:${currentUserId}`,
        fields: {
          assignedConversation() {
            return null;
          },
        },
      });
    },
    onCompleted: () => {
      navigate(ROUTES.home);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleBack = async () => {
    if (isPage && !isEditOrder) {
      if (assignedConversation) {
        await unassignUser();
      }
    }
    if (isEditOrder) {
      onEditOrder();
    } else {
      if (isPage) {
        navigate(ROUTES.home);
      } else {
        onCloseModal();
      }
    }
  };

  return (
    <Stack direction={'row'} gap={'8px'}>
      <Button
        variant={'back'}
        startIcon={<ChevronBottomIcon color={'black'} sx={{ rotate: '90deg' }} />}
        onClick={handleBack}
      >
        Back
      </Button>
      <HeaderChannel chatModalInfo={chatModalInfo} />
      <HeaderProfile date={profileDate} time={profileTime} customer={customer} />
      {chatModalInfo.provider === ConversationProviderEnum.Email && (
        <ChatHeaderSubject text={subjectText} />
      )}
      <Stack>
        <SelectCustom
          placeholder={'Add tag'}
          options={optionsTag}
          value={tag}
          onChange={changeTag}
          size={'big'}
        />
      </Stack>
      {!infoModal.open && <ThemeSwitcher ml={'auto'} />}
    </Stack>
  );
};
