import { usePalette } from '../hooks';
import { OPTION_ORDER_STATUS_TABS_ENUM } from '../constants/constants';
// import { OrderStatusEnum } from '../graphql/generated';

export const getBadgeData = (status: string) => {
  const { palette } = usePalette();
  switch (status) {
    case 'Refunded':
      return { title: 'Refunded', color: palette.orange.main };
    case 'refunded':
      return { title: 'Refunded', color: palette.orange.main };
    case 'Delivered':
      return { title: 'Delivered', color: palette.green.main };
    case 'delivered':
      return { title: 'Delivered', color: palette.green.main };
    case OPTION_ORDER_STATUS_TABS_ENUM.DELIVERED:
      return { title: 'Delivered', color: palette.green.main };
    case 'partialRefunded':
      return { title: 'Partial refunded', color: palette.yellow.main };
    case OPTION_ORDER_STATUS_TABS_ENUM.PARTIAL_REFUNDED:
      return { title: 'Partial refunded', color: palette.yellow.main };
    case 'fullRefunded':
      return { title: 'Full refunded', color: palette.orange.main };
    case OPTION_ORDER_STATUS_TABS_ENUM.FULL_REFUNDED:
      return { title: 'Full refunded', color: palette.orange.main };
    case OPTION_ORDER_STATUS_TABS_ENUM.CANCELLED:
      return { title: 'Cancelled', color: palette.red.main };
    case 'cancelled':
      return { title: 'Cancelled', color: palette.red.main };
    default:
      return { title: status, color: palette.orange.main };
  }
};
