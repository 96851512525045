import { Stack } from '@mui/material';
import { useRef } from 'react';
import { ResizableBlock } from '../../../../ResizableBlock/ResizableBlock';
import { BottomBlockWrapperCSS, TransparentDividerCSS } from '../styles';
import { useResize } from '../../../../../hooks';
import { CompanyNotesMain } from '../../CompanyNotesMain/CompanyNotesMain';
import { TemplatesMain } from '../../TemplatesMain/TemplatesMain';
import { ShopEntity } from '../../../../../graphql/generated';

interface RightContentBlockProps {
  shopData?: ShopEntity;
}

export const RightContentBlock = ({ shopData }: RightContentBlockProps) => {
  const refParentBlock = useRef<HTMLDivElement>(null);
  const { maxHeight, height, handleTopResize } = useResize({ refParentBlock });

  return (
    <Stack ref={refParentBlock} sx={{ overflow: 'hidden', height: '100%' }}>
      <Stack sx={{ position: 'relative' }}>
        <ResizableBlock onResize={handleTopResize} height={215} maxHeight={maxHeight}>
          <CompanyNotesMain
            notes={shopData?.notes}
            tone={shopData?.tone}
            preferredName={shopData?.agentFirstName}
          />
        </ResizableBlock>
        <TransparentDividerCSS />
      </Stack>
      <BottomBlockWrapperCSS style={{ minHeight: `${height}px` }}>
        <TemplatesMain shopData={shopData} />
      </BottomBlockWrapperCSS>
    </Stack>
  );
};
