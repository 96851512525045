import React, {
  ChangeEvent,
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  useState,
} from 'react';
import { Controller, FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form';

import {
  FormLabel,
  IconButton,
  InputAdornment,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { SearchIcon } from '../../Icons';

export interface ITextFieldCustomProps {
  value?: string;
  onChange?: (val: ChangeEvent<HTMLInputElement>) => void;

  label?: string;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl<object>>;
  readOnly?: boolean;
  size?: 'medium' | 'small';
  type?: React.InputHTMLAttributes<unknown>['type'];
  mb?: string | number;
  sx?: SxProps;
  multiline?: boolean;
  fullWidth?: boolean;
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
  offSearchIcon?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
  endAdornment?: JSX.Element;
  isAbsoluteErrorText?: boolean;
  inputProps?: any;
  InputProps?: any;
}

interface ITextFieldRHFProps extends Omit<ITextFieldCustomProps, 'value' | 'onChange'> {
  name: string;
  value?: string;
}

export const TextFieldCustom = forwardRef(
  (
    {
      value,
      onChange,
      label,
      minRows,
      multiline,
      readOnly,
      size = 'medium',
      type = 'text',
      mb,
      sx,
      maxRows,
      fullWidth = true,
      errorMessage,
      placeholder,
      offSearchIcon,
      onKeyDown,
      onFocus,
      onBlur,
      disabled,
      endAdornment,
      isAbsoluteErrorText,
      inputProps,
      InputProps,
    }: ITextFieldCustomProps,
    ref,
  ) => {
    const [viewPassword, setViewPassword] = useState(false);

    return (
      <Stack mb={mb} sx={sx} justifyContent={'center'}>
        {label ? (
          <FormLabel aria-label={`input-${size}`}>
            <Typography>{label}</Typography>
          </FormLabel>
        ) : null}
        <TextField
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          inputRef={ref}
          value={value}
          onChange={onChange}
          size={size}
          type={viewPassword ? 'text' : type}
          error={!!errorMessage}
          fullWidth={fullWidth}
          multiline={multiline}
          minRows={minRows}
          maxRows={maxRows}
          placeholder={placeholder}
          helperText={errorMessage as ReactNode}
          label={''}
          disabled={disabled}
          inputProps={inputProps}
          sx={{
            '.MuiInputBase-root': {
              border: errorMessage ? '1px solid red' : '',
            },
            ...(isAbsoluteErrorText && {
              '.MuiFormHelperText-root': {
                position: 'absolute',
                bottom: '-15px',
              },
            }),
          }}
          InputProps={{
            readOnly: readOnly,
            endAdornment: endAdornment || (
              <InputAdornment position='start'>
                {type === 'password' ? (
                  <IconButton
                    onClick={() => {
                      setViewPassword(!viewPassword);
                    }}
                  ></IconButton>
                ) : null}
                {type === 'search' && !offSearchIcon ? <SearchIcon /> : null}
              </InputAdornment>
            ),
            ...InputProps,
          }}
        />
      </Stack>
    );
  },
);

export const TextFieldRHF = ({ name, ...rest }: ITextFieldRHFProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        return (
          <TextFieldCustom
            value={field.value}
            onChange={field.onChange}
            errorMessage={formState.errors?.[name]?.message}
            {...rest}
          />
        );
      }}
    />
  );
};
