import { SwitcherCaptionCSS, SwitcherContainer, SwitcherCSS, SwitcherInfoCSS } from './styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTheme } from '@mui/material';

interface ISwitch {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, name: string | undefined) => void;
  name?: string;
  title?: string;
  isLabel?: boolean;
  defaultSwitcher?: boolean;
  label?: string;
}

export const Switcher = ({
  onChange,
  checked,
  name,
  title,
  isLabel,
  defaultSwitcher,
  label = '',
}: ISwitch) => {
  const theme = useTheme();
  return (
    <SwitcherContainer>
      {isLabel && (
        <SwitcherInfoCSS>
          <SwitcherCaptionCSS variant='caption'>{title}</SwitcherCaptionCSS>
          <Typography variant='body1'>{checked ? 'On' : 'Off'}</Typography>
        </SwitcherInfoCSS>
      )}
      <FormControlLabel
        control={
          <SwitcherCSS
            defaultswitcher={String(defaultSwitcher)}
            checked={checked}
            onChange={(event) => onChange(event, name)}
          />
        }
        labelPlacement='start'
        label={label}
        slotProps={{
          typography: {
            color: theme.vars.palette.white.main,
            marginRight: label ? '12px' : '0',
          },
        }}
      />
    </SwitcherContainer>
  );
};
