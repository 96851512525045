import { Stack, styled } from '@mui/material';

export const IconBlockCSS = styled(Stack)(({ theme }) => {
  return {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.vars.palette.whiteBlack.main,
    'svg path': {
      fill: theme.vars.palette.blackWhite.main,
    },
  };
});
