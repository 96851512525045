import { IconButton, Stack, Typography } from '@mui/material';
import { InnerWrapperCSS, Wrapper } from './styles';
import { usePalette } from '../../../hooks';
import { CopyIcon } from '../../Icons/CopyIcon';
import { copyTextToClipboard } from '../../../utils/copyTextToClipboard';

type TrackingUrlDropMenuProps = {
  data: string[];
  label?: string;
};

export const TrackingMultiDropMenu = ({ data, label }: TrackingUrlDropMenuProps) => {
  return (
    <Wrapper>
      <InnerWrapperCSS>
        {data.map((item, i) => {
          const isLastElement = i === data.length - 1;
          return (
            <TrackingMultiDropMenuItem
              key={i}
              isLastElement={isLastElement}
              index={i}
              text={item}
              label={label}
            />
          );
        })}
      </InnerWrapperCSS>
    </Wrapper>
  );
};

interface TrackingUrlDropMenuItemProps {
  isLastElement?: boolean;
  index: number;
  text: string;
  label?: string;
}

export const TrackingMultiDropMenuItem = ({
  isLastElement,
  index,
  text,
  label,
}: TrackingUrlDropMenuItemProps) => {
  const { palette } = usePalette();

  return (
    <Stack
      {...(!isLastElement && {
        sx: {
          paddingBottom: '8px',
          borderBottom: `1px solid #E3E3E3`,
        },
      })}
    >
      <Typography variant={'caption'} mb={'4px'} color={palette.silver100.main}>
        {label} {index + 1}
      </Typography>
      <Stack direction={'row'} alignItems={'center'}>
        <Typography
          variant={'body8'}
          color={palette.mail.main}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: 'auto',
          }}
        >
          {text}
        </Typography>
        <IconButton onClick={() => copyTextToClipboard(text)}>
          <CopyIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
