import { ItemSchema, Maybe, MessageEntity, ShippingSchema } from '../graphql/generated';
import { ORDER_LABEL_ENUM } from '../constants/constants';

export type BoolAlias = 'true' | 'false';

export enum DateSelectEnum {
  Today = '0',
  Yesterday = '1',
  LastWeek = '7',
  Custom = 'CUSTOM',
}

export type TemplateChatEntity = {
  id: number;
  label: string;
  name?: string;
  shortcut: string;
  isAllCompanies: boolean;
};

export type AccordionItemType = {
  id: number;
  label: ORDER_LABEL_ENUM;
  content?: number | Maybe<string> | ItemSchema[] | ShippingSchema | string[];
  status?: string[] | null;
};

export type NewMessage = MessageEntity & {
  timer?: NodeJS.Timeout;
};

export type variantSizeAlias = 'standard' | 'big';
