import { PlusIcon } from '../../Icons';
import { AddNewBlockCSS, AddNewButtonCSS } from './styles';
import { ReactNode } from 'react';

interface AddNewButtonProps {
  onClick?: () => void;
  children?: ReactNode;
}

export const AddNewButton = ({ onClick, children }: AddNewButtonProps) => {
  return (
    <AddNewBlockCSS>
      <AddNewButtonCSS type='button' onClick={onClick}>
        <PlusIcon />
        {children}
      </AddNewButtonCSS>
    </AddNewBlockCSS>
  );
};
