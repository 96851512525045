import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { chatTemplateFormSchema } from '../../../../validation/schema/chatTemplateForm.schema';
import { TextFieldRHF } from '../../../UI';
import { Button, Stack } from '@mui/material';
import { ChatTemplatesFormWrapper } from './styles';
import { CheckboxRHF } from '../../../UI/CheckboxCustom/CheckboxCustom';
import { useState } from 'react';
import { TemplateChangesConfirmationModal } from '../../../Modal';
import { ModalFormWrapperCSS, ModalWrapper } from '../../../Modal/ModalController/styles';
import {
  TemplateSchema,
  TemplateTypeEnum,
  useCreateShopTemplateMutation,
  useEditShopTemplateMutation,
} from '../../../../graphql/generated';
import { toast } from 'react-toastify';
import { ChatTemplateForm, ChatTemplatesFormProps } from './types';
import { usePalette } from '../../../../hooks';

export const ChatTemplatesForm = ({ onClose, template, shopId }: ChatTemplatesFormProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { palette } = usePalette();

  const methods = useForm<ChatTemplateForm>({
    defaultValues: {
      name: template?.name || '',
      text: template?.text || '',
      shortcut: template?.shortcut || '',
      isAllCompanies: template?.type === TemplateTypeEnum.General,
    },
    resolver: yupResolver(chatTemplateFormSchema),
  });

  const {
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = methods;

  const [createTemplate, { loading: isCreating }] = useCreateShopTemplateMutation({
    onCompleted: () => {
      toast.success('Template has been created');
    },
    onError: (error) => {
      toast.error(error.message);
    },
    update: (cache, result) => {
      const newTemplate = result.data?.createShopTemplate;
      cache.modify({
        id: `ShopEntity:${shopId}`,
        fields: {
          templates(existingTemplates = []) {
            // Добавляем новый шаблон в массив существующих шаблонов
            return [...existingTemplates, newTemplate];
          },
        },
      });
    },
  });

  const [editTemplate, { loading: isEditing }] = useEditShopTemplateMutation({
    onCompleted: () => {
      toast.success('Template has been changed');
    },
    onError: (error) => {
      toast.error(error.message);
    },
    update: (cache, result) => {
      const updatedTemplate = result.data?.editShopTemplate; // Получаем обновленный шаблон
      cache.modify({
        id: `ShopEntity:${shopId}`,
        fields: {
          templates(existingTemplates = []) {
            // Обновляем массив templates, заменяя старый шаблон на обновленный
            return existingTemplates.map((template: TemplateSchema) =>
              template.id === updatedTemplate?.id ? updatedTemplate : template,
            );
          },
        },
      });
    },
  });

  const onSubmitRHF = async (value: ChatTemplateForm) => {
    const isAllCompanies = value.isAllCompanies;
    if (!template) {
      await createTemplate({
        variables: {
          input: {
            shopId: Number(shopId),
            name: value.name,
            shortcut: value.shortcut,
            text: value.text,
            type: isAllCompanies ? TemplateTypeEnum.General : TemplateTypeEnum.Personal,
          },
        },
      });
      onClose();
    } else {
      if (isAllCompanies) {
        return setIsOpenModal(true);
      } else {
        await editTemplate({
          variables: {
            templateId: Number(template?.id),
            input: {
              globalEdit: isAllCompanies,
              shopId: Number(shopId),
              name: value.name,
              shortcut: value.shortcut,
              text: value.text,
            },
          },
        });
        onClose();
      }
    }
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleApply = async () => {
    const value = getValues();
    if (value.isAllCompanies) {
      await editTemplate({
        variables: {
          templateId: Number(template?.id),
          input: {
            globalEdit: value.isAllCompanies,
            shopId: Number(shopId),
            name: value.name,
            shortcut: value.shortcut,
            text: value.text,
          },
        },
      });
    } else {
      await createTemplate({
        variables: {
          input: {
            shopId: Number(shopId),
            name: value.name,
            shortcut: value.shortcut,
            text: value.text,
            type: TemplateTypeEnum.Personal,
          },
        },
      });
    }
    onClose();
    setIsOpenModal(false);
  };

  return (
    <ChatTemplatesFormWrapper>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitRHF)}>
          <TextFieldRHF
            name={'name'}
            label={'Template Name'}
            size={'medium'}
            placeholder={'Enter Template Name'}
            type={'text'}
            mb={2}
          />
          <TextFieldRHF
            name={'text'}
            label={'Template Text'}
            size={'medium'}
            placeholder={'Enter Template Text'}
            type={'text'}
            mb={2}
            multiline
          />
          <TextFieldRHF
            name={'shortcut'}
            label={'Shortcut'}
            size={'medium'}
            placeholder={'Exp. shrtct'}
            type={'text'}
            mb={2}
          />
          <CheckboxRHF
            name={'isAllCompanies'}
            label={'Apply for all companies'}
            disabled={template && template?.type !== TemplateTypeEnum.General} // for edit case
            mb={'16px'}
          />
          <Stack direction={'row'} gap={'10px'} width={'100%'}>
            <Button variant={'cancelChatForm'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant={'createChatForm'}
              type={'submit'}
              disabled={!isDirty || isEditing || isCreating}
              sx={{
                '&:disabled': {
                  color: palette.silver100.main,
                  opacity: 0.5,
                },
              }}
            >
              {!template ? 'Create' : 'Edit'}
            </Button>
          </Stack>
        </form>
      </FormProvider>
      <ModalWrapper open={isOpenModal} onClose={handleCloseModal}>
        <ModalFormWrapperCSS>
          <TemplateChangesConfirmationModal onClose={handleCloseModal} onApply={handleApply} />
        </ModalFormWrapperCSS>
      </ModalWrapper>
    </ChatTemplatesFormWrapper>
  );
};
