import { FormProvider, UseFormReturn } from 'react-hook-form';
import { MetricsFormData } from '../../../../pages';
import { IconButton, Stack } from '@mui/material';
import { DatePickerRHF, FilterButtonsGroup, OptionType, SelectRHF } from '../../../UI';
import { ConversationProviderEnum } from '../../../../graphql/generated';
import { OPTIONS_FILTER } from '../../../../constants/constants';
import { RefreshIcon } from '../../../Icons/RefreshIcon';

interface MetricsPageFiltersGroupProps {
  methods: UseFormReturn<MetricsFormData>;
  nameAgentSelect: keyof MetricsFormData;
  nameStartDateField: keyof MetricsFormData;
  nameEndDateField: keyof MetricsFormData;
  startDate?: string | null;
  filterState: string[];
  onFilterClick: (id: string | ConversationProviderEnum) => void;
  optionAgentData?: OptionType[];
  onReset: () => void;
}

export const MetricsPageFiltersGroup = ({
  methods,
  nameAgentSelect,
  nameStartDateField,
  nameEndDateField,
  startDate,
  filterState,
  onFilterClick,
  optionAgentData,
  onReset,
}: MetricsPageFiltersGroupProps) => {
  return (
    <FormProvider {...methods}>
      <Stack component={'form'} direction={'row'} gap={'26px'} alignItems={'end'}>
        <Stack direction={'row'} gap={'12px'}>
          <DatePickerRHF
            name={nameStartDateField}
            label={'Start Date'}
            placeholder={'Start Date'}
          />
          <DatePickerRHF
            name={nameEndDateField}
            label={'End Date'}
            placeholder={'End Date'}
            minDate={startDate}
          />
        </Stack>
        <FilterButtonsGroup
          optionsFilter={OPTIONS_FILTER}
          onClick={onFilterClick}
          activeFilter={filterState}
          label={'Channel:'}
        />
        <Stack width={'180px'}>
          <SelectRHF
            name={nameAgentSelect}
            label={'Agent:'}
            placeholder={'Agent'}
            options={optionAgentData || []}
            multiple
          />
        </Stack>
        <Stack ml={'auto'} height={'40px'} justifyContent={'center'}>
          <IconButton type={'reset'} size={'medium'} onClick={onReset}>
            <RefreshIcon />
          </IconButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
