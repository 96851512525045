import { CalendarIcon } from '../../../Icons';
import { Stack, Typography } from '@mui/material';
import { usePalette } from '../../../../hooks';
import { IconBlockCSS } from './styles';

interface DateBlockProps {
  date?: string;
  time?: string;
}

export const DateBlock = ({ date, time }: DateBlockProps) => {
  const { palette } = usePalette();
  return (
    <Stack direction={'row'} gap={'14px'} alignItems={'center'}>
      <IconBlockCSS>
        <CalendarIcon />
      </IconBlockCSS>
      <Stack gap={'4px'}>
        <Typography variant={'sm'} color={palette.black.main}>
          {date}
        </Typography>
        <Typography variant={'sm'} color={palette.ser.main}>
          {time}
        </Typography>
      </Stack>
    </Stack>
  );
};
