import { Button, ButtonProps, Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => ({
  flex: 1,
  padding: '26px',
  background: theme.vars.palette.silver20silver200.main,
  borderRadius: '36px',
}));

interface ActionButtonProps extends ButtonProps {
  iscancel?: string;
}

export const ActionButton = styled(Button)<ActionButtonProps>(({ theme, iscancel }) => {
  const isCancelBtn = iscancel === 'true';
  const palette = theme.vars.palette;
  return {
    background: palette[isCancelBtn ? 'whiteBlack' : 'blackWhite'].main,
    color: palette[isCancelBtn ? 'blackWhite' : 'whiteBlack'].main,
    height: '56px',
    flex: 1,
    ':hover': {
      background: palette[isCancelBtn ? 'whiteBlack' : 'blackWhite'].main,
      color: palette[isCancelBtn ? 'blackWhite' : 'whiteBlack'].main,
    },
  };
});
