import { DatePickerRHF, TabsRHF, TextFieldRHF } from '../../../UI';
import { OPTION_SHOPS_TABS } from '../../../../constants/constants';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { ShopsPageFormData } from '../../../../pages';
import { ShopTabIcon } from '../../../Icons/ShopTabIcon';
import { IconButton, Stack } from '@mui/material';
import { RefreshIcon } from '../../../Icons/RefreshIcon';

interface ShopsPageFilterGroupProps {
  nameTabsField: keyof ShopsPageFormData;
  nameStartDateField: keyof ShopsPageFormData;
  nameEndDateField: keyof ShopsPageFormData;
  nameSearchField: keyof ShopsPageFormData;
  methods: UseFormReturn<ShopsPageFormData>;
  onReset: () => void;
  startDate?: string | null;
}

export const ShopsPageFilterGroup = ({
  methods,
  onReset,
  startDate,
  nameTabsField,
  nameStartDateField,
  nameEndDateField,
  nameSearchField,
}: ShopsPageFilterGroupProps) => {
  return (
    <FormProvider {...methods}>
      <Stack component={'form'} mb={'12px'}>
        <TabsRHF
          name={nameTabsField}
          options={OPTION_SHOPS_TABS}
          asButtonsGroup
          iconComponent={<ShopTabIcon />}
          mb={'12px'}
        />
        <Stack direction={'row'} gap={'12px'} alignItems={'end'}>
          <DatePickerRHF
            name={nameStartDateField}
            label={'Start Date'}
            placeholder={'Start Date'}
          />
          <DatePickerRHF
            name={nameEndDateField}
            label={'End Date'}
            placeholder={'End Date'}
            minDate={startDate}
          />
          <Stack width={'240px'}>
            <TextFieldRHF
              name={nameSearchField}
              size={'small'}
              placeholder={'Search'}
              type={'search'}
            />
          </Stack>
          <Stack ml={'auto'} height={'40px'} justifyContent={'center'}>
            <IconButton type={'reset'} size={'medium'} onClick={onReset}>
              <RefreshIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
