import { styled, Box, Badge, Button } from '@mui/material';

export const TopContentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '26px',
});

export const TopContentTitleCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const TopContentIconCSS = styled(Badge)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    marginRight: '12px',
    backgroundColor: theme.vars.palette.silverDark.main,
    borderRadius: '50%',

    '& svg path': {
      fill: theme.vars.palette.blackWhite.main,
    },
  };
});

export const EditButtonCSS = styled(Button)(({ theme }) => {
  return {
    width: '80px',
    height: '40px',

    '& svg': {
      '& path': {
        fill: theme.vars.palette.whiteBlack.main,
      },
    },
  };
});
