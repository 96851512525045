import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePickerCSS } from './styles';
import dayjs from 'dayjs';
import { Controller, FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, SxProps, Typography } from '@mui/material';
import { DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';

interface DateRangePickerCustomProps {
  value: string;
  onChange: (value: unknown, context: PickerChangeHandlerContext<DateValidationError>) => void;
  minDate?: string | null;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl<object>>;
  label?: string;
  mb?: string;
  sx?: SxProps;
  placeholder?: string;
}

interface DateRangePickerRHFProps extends Omit<DateRangePickerCustomProps, 'value' | 'onChange'> {
  name: string;
}

export const DatePickerCustom = ({
  value,
  onChange,
  minDate,
  label,
  mb,
  sx,
  placeholder,
}: DateRangePickerCustomProps) => {
  return (
    <DemoContainer components={['DatePicker']} sx={{ padding: 0, overflow: 'visible' }}>
      <FormControl fullWidth sx={{ marginBottom: mb, ...sx }}>
        {label ? (
          <FormLabel focused={false} aria-label={`select-small`}>
            <Typography>{label}</Typography>
          </FormLabel>
        ) : null}
        <DatePickerCSS
          {...(minDate && {
            minDate: dayjs(minDate),
          })}
          value={value}
          onChange={onChange}
          format='DD/MM/YYYY'
          slotProps={{
            textField: {
              placeholder: placeholder,
            },
            desktopPaper: {
              sx: ({ vars }) => ({
                background: `${vars.palette.whiteSilver100.main}`,
                '.MuiTypography-root': {
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '18px',
                },
                '.MuiButtonBase-root': {
                  color: vars.palette.silver100Black.main,
                  fontWeight: 500,
                  '&.Mui-selected': {
                    background: `${vars.palette.black.main} !important`,
                    color: vars.palette.white.main,
                  },
                },
              }),
            },
          }}
        />
      </FormControl>
    </DemoContainer>
  );
};

export const DatePickerRHF = ({ name, ...rest }: DateRangePickerRHFProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        return (
          <DatePickerCustom
            value={field.value}
            onChange={field.onChange}
            errorMessage={formState.errors?.[name]?.message}
            {...rest}
          />
        );
      }}
    />
  );
};
