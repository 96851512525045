import { HOT_KEYS } from '../../constants/constants';
import { ListItem, Typography } from '@mui/material';
import { ListCSS, Wrapper } from './styles';

export const HelperHotKeysBlock = () => {
  return (
    <Wrapper>
      <ListCSS disablePadding>
        {HOT_KEYS.map(({ hotKey, desc }, i) => {
          return (
            <ListItem key={i}>
              <Typography>
                <span>{hotKey}</span> {desc}
              </Typography>
            </ListItem>
          );
        })}
      </ListCSS>
    </Wrapper>
  );
};
