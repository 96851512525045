import { Header } from '../Header/Header';
import { ContentCSS, Wrapper } from './styles';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import '../../fonts/font.css';
import { ModalProvider, useAuthContext } from '../../context';
import { HelperHotKeysBlock } from '../HelperHotKeysBlock/HelperHotKeysBlock';
import { useEffect } from 'react';
import { ROUTES } from '../../constants/constants';

export const Layout = () => {
  const location = useLocation();
  const isChatPage = location.pathname.split('/').includes('chat');

  const { userData, roles } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    const assignedConversation = userData?.assignedConversation;
    if (!roles?.isAgent && assignedConversation) {
      navigate(`${ROUTES.chat}/${assignedConversation}`);
    }
  }, [userData]);

  return (
    <ModalProvider>
      <Wrapper ispage={String(isChatPage)}>
        <ContentCSS ispage={String(isChatPage)}>
          {!isChatPage && <Header />}
          <Outlet />
        </ContentCSS>
        <HelperHotKeysBlock />
      </Wrapper>
    </ModalProvider>
  );
};
