import { ITableOption, TableRowProps } from '../TableList';
import {
  ControlBadgeCSS,
  HandleBlockButtonCSS,
  HandleOpenButtonCSS,
  TableCellCSS,
  TableRowCSS,
} from '../styles';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Box from '@mui/material/Box';

interface ITableListRow {
  row: TableRowProps;
  rowAction?: (id: number) => void;
  headCells: Array<ITableOption>;
  handleOpen?: (row: TableRowProps) => void;
  handleBlock?: (row: TableRowProps) => void;
  openButtonTitle?: string;
  isHasControlBadge?: boolean;
  isBlocked?: boolean;
}

export const TableListRow = ({
  row,
  rowAction,
  headCells,
  handleOpen,
  handleBlock,
  openButtonTitle,
  isHasControlBadge,
}: ITableListRow) => {
  const TABLE_COLUMN_CONTROL = {
    control: 'control',
  };

  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const isBlocked = row.isBlocked;

  return (
    <>
      <TableRowCSS
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        hover={!!rowAction}
        role='checkbox'
        tabIndex={-1}
        key={row.id}
        sx={{ cursor: rowAction ? 'pointer' : 'auto' }}
      >
        {headCells.map((column) => {
          const value = (row as Record<string, string>)[column.id];

          return (
            <TableCellCSS key={column.id} width={column.minWidth} isblocked={String(isBlocked)}>
              {/* Handle Open Button */}
              {column.id === TABLE_COLUMN_CONTROL.control ? (
                <>
                  {isHover && (handleOpen || handleBlock) ? (
                    <Box>
                      {handleOpen && !isBlocked ? (
                        <HandleOpenButtonCSS
                          onClick={() => {
                            handleOpen(row);
                          }}
                        >
                          {openButtonTitle}
                        </HandleOpenButtonCSS>
                      ) : (
                        <Typography variant='body1'>{value}</Typography>
                      )}
                      {handleBlock && !isBlocked ? (
                        <HandleBlockButtonCSS
                          onClick={() => {
                            handleBlock(row);
                          }}
                        >
                          Block
                        </HandleBlockButtonCSS>
                      ) : null}
                    </Box>
                  ) : (
                    <>
                      {isHasControlBadge ? (
                        <ControlBadgeCSS variant='body1'>
                          {row?.active ? 'Active' : 'Deactivated'}
                        </ControlBadgeCSS>
                      ) : (
                        <Typography variant='body1'>{value}</Typography>
                      )}
                    </>
                  )}
                </>
              ) : (
                <Typography variant='body1'>{value}</Typography>
              )}
            </TableCellCSS>
          );
        })}
      </TableRowCSS>
    </>
  );
};
