import { List, Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => {
  const palette = theme.vars.palette;
  return {
    position: 'absolute',
    right: '-226px',
    bottom: 0,
    padding: '0 0 0 24px',
    width: 'fit-content',
    height: 'auto',
    transition: '.3s',
    cursor: 'pointer',
    maxWidth: '250px',
    zIndex: 1301,
    ':hover': {
      right: 0,
      transition: '.3s',
    },
    '.MuiListItem-root': {
      span: {
        color: palette.jasper.main,
      },
    },
    background: 'transparent',
  };
});

export const ListCSS = styled(List)(({ theme }) => {
  return {
    borderRadius: '24px 0 0 0 ',
    background: theme.vars.palette.backgroundDef.main + '!important',
  };
});
