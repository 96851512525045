import { Stack, Typography } from '@mui/material';
import { BagIcon } from '../../../Icons';
import { TabsCustom, TabsOptionType } from '../../../UI';
import { IconBlockCSS } from './styles';
import { memo } from 'react';

interface MainContentHeaderProps {
  label?: string;
  value: string;
  setValue: (value: string) => void;
  options: TabsOptionType[];
}

export const MainContentHeader = memo(
  ({ options, value, setValue, label }: MainContentHeaderProps) => {
    return (
      <Stack direction={'row'} alignItems={'center'} mb={'16px'}>
        <IconBlockCSS>
          <BagIcon />
        </IconBlockCSS>
        <Typography variant={'subtitle2'}>{label}</Typography>
        <Stack ml={'auto'}>
          <TabsCustom options={options} value={value} setValue={setValue} />
        </Stack>
      </Stack>
    );
  },
);
