import { Stack } from '@mui/material';
import { AccordionItem } from '../AccordionItem/AccordionItem';
import { AccordionItemType } from '../../../../types';
import { createItemsOrderData } from '../../../../utils';
import { OrdersResponseSchema } from '../../../../graphql/generated';
import { ORDER_LABEL_ENUM } from '../../../../constants/constants';

export type ItemsData = Record<'items', AccordionItemType[]>;

interface AccordionListProps {
  data?: OrdersResponseSchema;
}

const createShipmentData = (data?: OrdersResponseSchema) => {
  return {
    items: [
      { id: 1, label: ORDER_LABEL_ENUM.TrackingId, content: data?.trackingId || [''] },
      { id: 2, label: ORDER_LABEL_ENUM.TrackingUrl, content: data?.trackingUrl || [''] },
      { id: 3, label: ORDER_LABEL_ENUM.ShippingAddress, content: data?.shipment.address1 || '' },
      { id: 4, label: ORDER_LABEL_ENUM.Status, status: data?.shipmentStatus || [''] },
    ],
  };
};

export const AccordionList = ({ data }: AccordionListProps) => {
  const itemsData = createItemsOrderData(data?.item);
  const shipmentData = createShipmentData(data);

  return (
    <Stack>
      {!!itemsData?.length && (
        <AccordionItem
          accordionItemData={itemsData}
          additionalInfo={data?.total}
          netPayment={data?.netPayment}
          label={'Items'}
        />
      )}
      {!!shipmentData && <AccordionItem accordionItemData={[shipmentData]} label={'Shipping'} />}
    </Stack>
  );
};
