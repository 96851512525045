import { AddNewBlockCSS, AddNewButtonCSS, CompanyTemplatesWrapper } from './styles';

import TopContent from '../TopContent';
import { PlusIcon } from '../Icons';
import { useState } from 'react';
import { TemplateForm } from '../TemplateForm';
import {
  TemplateSchema,
  TemplateTypeEnum,
  useDeleteShopTemplateMutation,
} from '../../graphql/generated';
import { GET_SHOP } from '../../graphql/queries/getShop.gql';
import { toast } from 'react-toastify';
import { InfoWrapperCSS } from '../InformationBlock/styles';
import { ModalFormWrapperCSS, ModalWrapper } from '../Modal/ModalController/styles';
import { TemplateChangesConfirmationModal } from '../Modal';
import { CompanyTemplateRow } from './CompanyTemplateRow';

interface CompanyTemplatesProps {
  shopId?: string | number;
  shopTemplate?: TemplateSchema[];
}

export const CompanyTemplates = ({ shopId, shopTemplate }: CompanyTemplatesProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [editId, setEditId] = useState(0);

  const [tempGeneralRemoveId, setTempGeneralRemoveId] = useState(0);

  const handleEdit = (id: number) => {
    setIsNewTemplate(false);
    setEditId(id);
  };

  const handleBack = () => {
    setEditId(0);
    setIsNewTemplate(false);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleAddNew = () => {
    setIsNewTemplate(true);
    setEditId(0);
  };

  const [deleteTemplate] = useDeleteShopTemplateMutation({
    refetchQueries: [{ query: GET_SHOP, variables: { getShopId: Number(shopId) } }],
    onCompleted: () => {
      toast.success('Template has been removed');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleDelete = (id: string | number) => {
    deleteTemplate({
      variables: {
        input: {
          globalDelete: !!tempGeneralRemoveId,
          templateId: +id,
          shopId: Number(shopId),
        },
      },
    });
  };

  const handlePreDelete = (id: number) => {
    const targetTemplate = shopTemplate?.find((template) => +template.id === +id);
    if (targetTemplate?.type === TemplateTypeEnum.General) {
      setIsOpenModal(true);
      setTempGeneralRemoveId(id);
    } else {
      handleDelete(id);
      setTempGeneralRemoveId(0);
    }
  };

  const handleOnApplyToModal = () => {
    handleDelete(tempGeneralRemoveId);
    setIsOpenModal(false);
  };

  return (
    <CompanyTemplatesWrapper>
      <TopContent title='Company Templates' />
      <InfoWrapperCSS>
        {isNewTemplate ? (
          <TemplateForm shopId={shopId} onClose={handleBack} />
        ) : (
          <AddNewBlockCSS>
            <AddNewButtonCSS type='button' onClick={handleAddNew}>
              <PlusIcon />
              Add New
            </AddNewButtonCSS>
          </AddNewBlockCSS>
        )}
        {shopTemplate?.map((template) => {
          if (editId === template.id) {
            return (
              <TemplateForm
                key={template.id}
                template={template}
                shopId={shopId}
                onClose={handleBack}
              />
            );
          }
          return (
            <CompanyTemplateRow
              id={template.id}
              key={template.id}
              name={template.name}
              text={template.text}
              onEdit={handleEdit}
              onRemove={handlePreDelete}
            />
          );
        })}
      </InfoWrapperCSS>
      <ModalWrapper open={isOpenModal} onClose={handleCloseModal}>
        <ModalFormWrapperCSS>
          <TemplateChangesConfirmationModal
            onClose={handleCloseModal}
            onApply={handleOnApplyToModal}
          />
        </ModalFormWrapperCSS>
      </ModalWrapper>
    </CompanyTemplatesWrapper>
  );
};
