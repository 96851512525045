import { ConversationProviderEnum } from '../graphql/generated';

export const getProviderLabel = (provider?: ConversationProviderEnum) => {
  switch (provider) {
    case ConversationProviderEnum.Email:
      return 'Email';
    case ConversationProviderEnum.Sms:
      return 'Sms';
    case ConversationProviderEnum.LiveChat:
      return 'Live chat';
    default:
      return '';
  }
};
