export const messages = {
  PROMPT_MAIL: 'Enter your email address',
  PROMPT_PASSWORD: 'Enter your password',
  REQUIRE_MESSAGE: 'Required field',
  INCORRECT_SYMBOL: 'Forbidden symbol',
  MIN_SYMBOL: 'Minimum 8 characters',
  INCORRECT_EMAIL: 'Invalid email format',
  INCORRECT_URL: 'Invalid URL format',
  INCORRECT_FORMAT: 'Incorrect data entry format',
  MAX_NUMBER_VALUE: (val: string) => `Maximum value ${val}`,
  MIN_NUMBER_VALUE: (val: string) => `Minimum value ${val}`,
  MAX_LENGTH: (val: string) => `Maximum field length ${val}`,
  MIN_LENGTH: (val: string) => `Minimum field length ${val}`,

  /* Template Form */
  TEMPLATE_NAME: 'Enter template name',
  TEMPLATE_TEXT: 'Enter template text',
  TEMPLATE_SHORTCUT: 'Shortcut',
};
