import { useModalContext } from '../../../context';
import { ChatGeneral } from '../../Containers/ToChatsPage/СhatGeneral/СhatGeneral';
import {
  ConversationEntity,
  useGetConversationQuery,
  useGetCurrentConversationQuery,
  useUnassignUserMutation,
} from '../../../graphql/generated';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { GET_CONVERSATIONS } from '../../../graphql/queries/getConversations.gql';
import { START_LIMIT } from '../../../constants/constants';

export const ChatModal = () => {
  const { infoModal } = useModalContext();

  const { data: currentConversationData } = useGetCurrentConversationQuery({
    fetchPolicy: 'network-only',
  });

  const conversationId =
    currentConversationData?.getCurrentConversation.id || infoModal.content?.conversationId;

  const { data, loading, error } = useGetConversationQuery({
    fetchPolicy: 'network-only',
    variables: {
      conversationId: Number(conversationId),
    },
    skip: !conversationId || !!currentConversationData,
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [unassignUser] = useUnassignUserMutation({
    variables: {
      conversationId,
    },
    refetchQueries: [
      {
        query: GET_CONVERSATIONS,
        variables: { input: { limit: START_LIMIT, offset: 0 } },
      },
    ],
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    return () => {
      unassignUser();
    };
  }, []);

  return (
    <ChatGeneral
      data={
        (currentConversationData?.getCurrentConversation ||
          data?.getConversation) as ConversationEntity
      }
      loading={loading}
      error={error}
    />
  );
};
