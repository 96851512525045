import { TextFieldCustom } from '../../../UI';
import { Button } from '@mui/material';
import { ChatSendIcon } from '../../../Icons';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useAuthContext, useChatContext } from '../../../../context';
import { useHotkeys } from 'react-hotkeys-hook';
import { client } from '../../../../graphql/client';
import { TIME_UNTIL_MESSAGE_SENT_SECONDS } from '../../../../constants/constants';
import { toast } from 'react-toastify';
import { useHotKeysForChat, usePalette } from '../../../../hooks';
import { NewMessage } from '../../../../types';
import { TextFieldChatContentRowProps } from './types';
import { ConversationProviderEnum, useCreateMessageMutation } from '../../../../graphql/generated';

export const TextFieldChatContentRow = ({
  allData,
  conversationId,
  setMessagesTimers,
  isMessageSending,
  customerFullName,
}: TextFieldChatContentRowProps) => {
  const { palette } = usePalette();
  const textAreaFocusRef = useRef<boolean>(false);
  const { textMessage, onTypingMessage, textAreaRef } = useChatContext();
  const [isMessageHasBeenSent, setIsMessageHasBeenSent] = useState(false);
  const { userData } = useAuthContext();
  const authorId = userData?.id;
  const authorName = (userData?.firstName || '') + ' ' + (userData?.lastName || '');

  const skipStateMessageHasBeenSent = () => {
    setIsMessageHasBeenSent(false);
  };

  // этот кейс нужен для потери фокуса, когда мы переходт на другой чат "ArrowRight" и фокус должен терятся

  useEffect(() => {
    textAreaRef?.current?.blur();
  }, [conversationId]);

  const templates = allData?.shop.templates || [];

  const [createLiveChatMessage] = useCreateMessageMutation({
    onError: (error) => {
      setIsMessageHasBeenSent(false);
      toast.error(error.message);
    },
  });

  const sendMessage = () => {
    setIsMessageHasBeenSent(true);
    return createLiveChatMessage({
      variables: {
        input: {
          text: textMessage,
          conversationId: conversationId,
          provider: allData?.provider as ConversationProviderEnum,
        },
      },
    });
  };

  const handleSendMessage = () => {
    if (!textMessage) return;
    const cache = client.cache;
    const messagesIds = allData?.messages?.map((it) => it.id) || [0];
    const lastIdForSendMessage = Math.max(...messagesIds);
    const newMessageId = lastIdForSendMessage + 1;

    const newMessage: NewMessage = {
      authorId: authorId,
      authorName: authorName,
      conversationId: Number(allData?.id),
      createdAt: Date.now().toString(),
      id: newMessageId,
      text: textMessage,
      images: [],
      __typename: 'MessageEntity',
    };

    const timerId = setTimeout(() => {
      sendMessage();
      // Удаляем объект с таймером из массива состояния
      setMessagesTimers((prevMessages) => prevMessages.filter((item) => item.id !== newMessageId));
    }, TIME_UNTIL_MESSAGE_SENT_SECONDS * 1000);

    setMessagesTimers((prevState) => [
      ...prevState,
      {
        ...newMessage,
        timer: timerId,
      },
    ]);

    cache.modify({
      id: `ConversationEntity:${conversationId}`,
      fields: {
        messages(existingMessages = []) {
          return [...existingMessages, newMessage];
        },
      },
    });

    onTypingMessage('');
  };

  // * this function will be called provided that the message timer has not ended yet, and the agent has already pressed the right button
  const hotSendMesToArrowRight = () => {
    setMessagesTimers((prevState) => {
      prevState.forEach((messageEntity) => {
        createLiveChatMessage({
          variables: {
            input: {
              text: messageEntity.text,
              conversationId: conversationId,
              provider: allData?.provider as ConversationProviderEnum,
            },
          },
        });

        clearTimeout(messageEntity.timer);
      });
      return [];
    });
  };

  const handleTypingMessage = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const matchingTemplates = templates.filter((template) => {
      return template.shortcut
        ?.toLowerCase()
        .split(' ')
        .some((word) => {
          return inputValue.split(/\s+/).some((w) => w.toLowerCase() === word.toLowerCase());
        });
    });
    let modifiedText = inputValue;

    if (matchingTemplates.length > 0) {
      matchingTemplates.forEach((template) => {
        modifiedText = modifiedText.replace(
          new RegExp(`\\b${template.shortcut}\\b`),
          template.text,
        );
      });
    }

    onTypingMessage(modifiedText);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault(); // Предотвращаем переход на новую строку (который стоит по умолчанию) -
      // только при нажатии Shift+Enter
    }
  };

  const handleFocus = () => {
    textAreaFocusRef.current = true;
  };

  const handleBlur = () => {
    textAreaFocusRef.current = false;
  };

  useHotkeys(
    'shift+enter',
    () => {
      if (!textAreaFocusRef.current) return;
      handleSendMessage();
    },
    {
      enableOnFormTags: ['textarea'],
    },
  );

  useHotkeys('r', (e) => {
    textAreaRef.current?.focus();
    e.preventDefault();
  });

  useHotKeysForChat({
    conversationId,
    isMessageHasBeenSent: isMessageHasBeenSent,
    skipStateMessageHasBeenSent,
    isMessageSending,
    hotSendMesToArrowRight,
    customerFullName,
  }); // for processing hotkeys

  return (
    <>
      <TextFieldCustom
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={textAreaRef}
        value={textMessage}
        onChange={handleTypingMessage}
        placeholder={'Type your message'}
        multiline
        onKeyDown={handleKeyPress}
        maxRows={8}
        sx={{
          width: '100%',
          '.MuiInputBase-root': {
            paddingRight: 0,
            color: `${palette.blackWhite.main} !important`,
            background: `${palette.whiteBlack.main} !important`,
          },
          '.MuiInputAdornment-root': { display: 'none' },
        }}
      />
      <Button
        variant='chatSendButton'
        endIcon={<ChatSendIcon />}
        sx={{ marginBottom: '6px' }}
        onClick={handleSendMessage}
      >
        Send
      </Button>
    </>
  );
};
