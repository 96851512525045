import { Typography } from '@mui/material';
import { usePalette } from '../../../../hooks';
import { Wrapper } from './styles';

interface ChatHeaderSubjectProps {
  text?: string | null;
}

export const ChatHeaderSubject = ({ text }: ChatHeaderSubjectProps) => {
  const { palette } = usePalette();
  return (
    <Wrapper justifyContent={'center'}>
      <Typography variant={'sm4'} color={palette.ser.main}>
        Subject
      </Typography>
      <Typography variant={'sm3'} minHeight={14}>
        {text}
      </Typography>
    </Wrapper>
  );
};
