import {
  InfoFieldCSS,
  InfoFieldLabelCSS,
  InformationBlockWrapper,
  InfoRowCSS,
  InfoWrapperCSS,
  SwitchersGroupCSS,
} from './styles';
import TopContent from '../TopContent';
import { Typography } from '@mui/material';
import { Switcher } from '../Switcher';
import { InfoFieldsList } from './InfoFieldsList';

import { ShopsEditInformationForm } from '../Containers';
import { InformationBlockProps } from './types';

export const InformationBlock = ({
  infoFieldsListHorizontal,
  infoFieldsList,
  informationSwitcher,
  checked,
  onChangeChecked,
  onClick,
  isEdit,
  methods,
  listName,
  onSubmit,
  onClickCancel,
  isLoading,
}: InformationBlockProps) => {
  return (
    <InformationBlockWrapper>
      <TopContent
        isEdit={isEdit}
        title='Information'
        isHasButton
        buttonTitle='Edit'
        onClick={onClick}
      />
      <InfoWrapperCSS>
        <SwitchersGroupCSS row>
          {informationSwitcher?.map(({ id, name }) => {
            return (
              <Switcher
                isLabel
                title={name}
                key={id}
                name={name}
                onChange={onChangeChecked}
                checked={checked[name]}
              />
            );
          })}
        </SwitchersGroupCSS>
        {!isEdit ? (
          <>
            <InfoRowCSS>
              {infoFieldsListHorizontal?.map((item, index) => (
                <InfoFieldCSS key={index}>
                  <InfoFieldLabelCSS variant='caption'>{item.title}</InfoFieldLabelCSS>
                  <Typography variant='body1'>{item.content}</Typography>
                </InfoFieldCSS>
              ))}
            </InfoRowCSS>
            <InfoFieldsList infoFieldsList={infoFieldsList} />
          </>
        ) : (
          <ShopsEditInformationForm
            onSubmit={onSubmit}
            methods={methods}
            listName={listName}
            onClickCancel={onClickCancel}
            isLoading={isLoading}
          />
        )}
      </InfoWrapperCSS>
    </InformationBlockWrapper>
  );
};
