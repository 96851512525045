import { MainBlockCSS, Wrapper } from './styles';
import { LeftContentBlock, RightContentBlock } from '../Main';
import { OrderInformation } from '../../../OrderInformation/OrderInformation';
import { ConversationEntity } from '../../../../graphql/generated';

interface ChatContentProps {
  isEditOrder: boolean;
  onEditOrder: (id?: string | number) => void;
  allData?: ConversationEntity;
  isPage?: boolean;
  orderId: string;
}
export const ChatContent = ({
  isEditOrder,
  onEditOrder,
  allData,
  isPage,
  orderId,
}: ChatContentProps) => {
  return (
    <Wrapper ispage={String(isPage)} iseditorder={String(isEditOrder)}>
      {isEditOrder ? (
        <OrderInformation
          isPage={isPage}
          goBack={onEditOrder}
          orderId={orderId}
          conversationId={allData?.id}
        />
      ) : (
        <>
          <MainBlockCSS>
            <LeftContentBlock onEditOrder={onEditOrder} allData={allData} />
          </MainBlockCSS>
          <MainBlockCSS isright={'true'}>
            <RightContentBlock shopData={allData?.shop} />
          </MainBlockCSS>
        </>
      )}
    </Wrapper>
  );
};
