import * as React from 'react';
import Typography from '@mui/material/Typography';
import { ChevronBottomIcon } from '../../../Icons';
import {
  AccordionAdditionalInfo,
  AccordionCSS,
  AccordionDetailsCSS,
  AccordionSummaryCSS,
} from './styles';
import { ItemsData } from '../AccordionList/AccordionList';
import { OrderItemList } from '../../../OrderItemList/OrderItemList';
import { Stack } from '@mui/material';

interface AccordionItemProps {
  label: string;
  additionalInfo?: string | number;
  netPayment?: string | number;
  accordionItemData: ItemsData[];
}

export const AccordionItem = ({
  label,
  additionalInfo,
  accordionItemData,
  netPayment,
}: AccordionItemProps) => {
  return (
    <AccordionCSS>
      <AccordionSummaryCSS
        expandIcon={<ChevronBottomIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='body5'>{label}</Typography>
      </AccordionSummaryCSS>
      <AccordionDetailsCSS>
        {accordionItemData.map((item, index) => {
          return <OrderItemList key={index} itemList={item.items} />;
        })}

        {!!additionalInfo && (
          <AccordionAdditionalInfo>
            {netPayment && (
              <Stack>
                <Typography variant='caption'>Net Payment</Typography>
                <Typography variant='body5'>{netPayment}</Typography>
              </Stack>
            )}
            <Stack>
              <Typography variant='caption'>Total price</Typography>
              <Typography variant='body5'>{additionalInfo}</Typography>
            </Stack>
          </AccordionAdditionalInfo>
        )}
      </AccordionDetailsCSS>
    </AccordionCSS>
  );
};
