import { IconProps } from './types';

export const ChatTemplateIcon = ({ color = '#fff', bgcolor = '#848484', sx }: IconProps) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={sx}
    >
      <rect width='24' height='24' rx='12' fill={bgcolor} />
      <path
        d='M9.81874 7.02188C10.0149 6.82749 10.0163 6.51091 9.82189 6.31478C9.6275 6.11864 9.31092 6.11723 9.11479 6.31162L8.15403 7.26385C7.79658 7.6181 7.49845 7.91356 7.28589 8.17824C7.06294 8.45586 6.89336 8.74762 6.84805 9.10308C6.79276 9.53683 7.15247 9.83341 7.50105 9.83341H16.6668C16.943 9.83341 17.1668 9.60956 17.1668 9.33341C17.1668 9.05727 16.943 8.83341 16.6668 8.83341H8.04262C8.05001 8.82394 8.05766 8.81427 8.06558 8.80441C8.23831 8.58932 8.49522 8.33363 8.87849 7.95377L9.81874 7.02188Z'
        fill={color}
      />
      <path
        d='M7.3335 14.1667C7.05735 14.1667 6.8335 14.3906 6.8335 14.6667C6.8335 14.9429 7.05735 15.1667 7.3335 15.1667L16.0912 15.1667C16.0838 15.1762 16.0762 15.1859 16.0682 15.1958C15.8955 15.4108 15.6386 15.6665 15.2553 16.0464L14.3151 16.9783C14.119 17.1727 14.1175 17.4893 14.3119 17.6854C14.5063 17.8815 14.8229 17.8829 15.019 17.6885L15.9798 16.7363C16.3372 16.3821 16.6354 16.0866 16.8479 15.8219C17.0709 15.5443 17.2405 15.2525 17.2858 14.8971C17.2877 14.8816 17.2895 14.8661 17.2911 14.8505C17.3319 14.4486 17.0015 14.1667 16.6668 14.1667H7.3335Z'
        fill={color}
      />
    </svg>
  );
};
