import { FlakeIcon } from '../../../Icons/FlakeIcon';
import { Typography } from '@mui/material';
import { Switcher } from '../../../Switcher';
import { usePalette } from '../../../../hooks';
import { IconBlockCSS, Wrapper } from './styles';
import { getNewUrlWithHttps } from '../../../../utils/getNewUrlWithHttps';

export interface ModalShopHeaderSettingsProps {
  companyName?: string;
  isActive: boolean;
  onChange: () => void;
  linkUrl?: string | null;
}

export const ModalShopHeaderSettings = ({
  companyName,
  isActive,
  onChange,
  linkUrl,
}: ModalShopHeaderSettingsProps) => {
  const { palette } = usePalette();

  const handleLink = () => {
    if (!linkUrl) return;
    const url = getNewUrlWithHttps(linkUrl);
    window.open(url);
  };

  return (
    <Wrapper direction={'row'}>
      <IconBlockCSS>
        <FlakeIcon />
      </IconBlockCSS>
      <Typography
        flex={1}
        variant={'h3'}
        mr={'26px'}
        color={palette.white.main}
        {...(linkUrl && {
          onClick: handleLink,
          sx: { cursor: 'pointer' },
        })}
      >
        {companyName}
      </Typography>
      <Switcher
        label={isActive ? 'Active' : 'Deactivated'}
        checked={isActive}
        onChange={onChange}
      />
    </Wrapper>
  );
};
