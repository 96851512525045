import { useFieldArray, useFormContext } from 'react-hook-form';
import { ShippingItemRow } from '../ShippingItemRow/ShippingItemRow';
import { Wrapper } from './styles';
import { RefObject } from 'react';

export type EditQuantityActionAlias = {
  action: 'increment' | 'decrement';
  id: string | number;
};

export type EditOrderListProps = {
  shippingItemList: any;
  isOffEditItem: boolean;
  wrapperRef?: RefObject<HTMLDivElement>;
};

export const EditOrderList = ({
  shippingItemList,
  isOffEditItem,
  wrapperRef,
}: EditOrderListProps) => {
  const { control, getValues, setValue } = useFormContext();
  const { fields, remove, update } = useFieldArray({
    control,
    name: 'shippingItemList',
  });

  const handleEditQuantity = ({ action, id }: EditQuantityActionAlias) => {
    const shippingItem = getValues(`shippingItemList.${id}`);
    const value = Number(getValues(`shippingItemList.${id}.quantity`));

    const newQuantityValue = action === 'increment' ? value + 1 : value - 1;
    const maxQuantity = shippingItemList[id].quantity;

    if (newQuantityValue < 0 || newQuantityValue > maxQuantity) return;
    update(+id, { ...shippingItem, quantity: newQuantityValue });
  };

  const handleRemove = (id: number) => {
    const prevRemovedIds = getValues('removedItems');
    const shippingItemId = getValues(`shippingItemList.${id}`);

    remove(id);
    setValue('removedItems', [...prevRemovedIds, shippingItemId.id]);
  };

  return (
    <Wrapper ref={wrapperRef}>
      {fields?.map((field, index) => {
        return (
          <ShippingItemRow
            key={field.id}
            row={field}
            onRemove={handleRemove}
            index={index}
            onEditQuantity={handleEditQuantity}
            isOffEditItem={isOffEditItem}
          />
        );
      })}
    </Wrapper>
  );
};
