import { gql } from '@apollo/client';

export const GET_CURRENT_CONVERSATION = gql`
  query GetCurrentConversation {
    getCurrentConversation {
      id
      shopId
      customerId
      assignedUserId
      status
      tag
      provider
      updatedAt
      createdAt
      shop {
        id
        name
        email
        phone
        smsAvailable
        emailAvailable
        liveChatAvailable
        tone
        agentFirstName
        notes
        deactivated
        deactivatedAt
        url
        domainId
        createdAt
        verifiedSender
        templates {
          id
          name
          text
          shortcut
          type
        }
      }
      customer {
        id
        firstName
        lastName
        phone
        email
        country
      }
      assignedUser {
        id
        email
        firstName
        lastName
        role
        avatar
        blocked
        blockedAt
        refreshToken
        createdAt
        updatedAt
        assignedConversation
      }
      messages {
        id
        authorId
        authorName
        conversationId
        createdAt
        text
        images {
          filename
          id
          messageId
        }
      }
      tags
      subject
    }
  }
`;
