import dayjs from 'dayjs';

export const getFormatDate = ({
  timestamp,
  format,
}: {
  timestamp?: string | number;
  format: string;
}) => {
  if (!timestamp) return '';
  const date = dayjs(Number(timestamp));
  return date.format(format);
};
