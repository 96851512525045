import { IconProps } from './types';

export const LinkIcon = ({ color = '#fff' }: IconProps) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.7082 3.05518C15.3229 2.74964 16.0452 2.65873 16.7602 2.85032C18.3161 3.26723 19.2395 4.86655 18.8226 6.42249L16.4501 15.2768C16.0332 16.8328 14.4339 17.7561 12.8779 17.3392C11.322 16.9223 10.3986 15.323 10.8155 13.767L11.7645 10.2253C11.8717 9.8252 11.6343 9.41395 11.2342 9.30674C10.8341 9.19954 10.4228 9.43698 10.3156 9.83708L9.36663 13.3788C8.73531 15.7349 10.1335 18.1568 12.4897 18.7881C14.8458 19.4194 17.2677 18.0212 17.899 15.665L20.2715 6.81072C20.9028 4.45458 19.5046 2.03275 17.1484 1.40143C16.067 1.11165 14.9699 1.24999 14.0405 1.71198C13.4706 1.99527 12.9626 2.4007 12.5581 2.90504C12.2989 3.22815 12.3508 3.70019 12.6739 3.95936C12.997 4.21853 13.469 4.1667 13.7282 3.84358C13.9948 3.51125 14.3305 3.24292 14.7082 3.05518Z'
        fill={color}
      />
      <path
        d='M8.2228 9.2763C8.63971 7.72036 10.239 6.79699 11.795 7.21391C13.3509 7.63082 14.2743 9.23013 13.8574 10.7861L12.9084 14.3278C12.8012 14.7279 13.0386 15.1392 13.4387 15.2464C13.8388 15.3536 14.25 15.1161 14.3572 14.716L15.3063 11.1743C15.9376 8.81816 14.5393 6.39634 12.1832 5.76502C9.82706 5.13369 7.40523 6.53193 6.77391 8.88807L4.4014 17.7424C3.77007 20.0985 5.16831 22.5204 7.52445 23.1517C8.60591 23.4415 9.703 23.3031 10.6324 22.8411C11.2022 22.5578 11.7103 22.1524 12.1148 21.6481C12.374 21.325 12.3221 20.8529 11.999 20.5937C11.6759 20.3346 11.2039 20.3864 10.9447 20.7095C10.6781 21.0419 10.3423 21.3102 9.96466 21.4979C9.35003 21.8035 8.62767 21.8944 7.91268 21.7028C6.35674 21.2859 5.43337 19.6866 5.85029 18.1306L8.2228 9.2763Z'
        fill={color}
      />
    </svg>
  );
};
