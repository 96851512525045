import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
  FormLabel,
  SxProps,
  SelectChangeEvent,
} from '@mui/material';
import { Controller, FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form';
import { forwardRef, ReactNode } from 'react';
import { ChevronBottomIcon } from '../../Icons';
import { ChevronWrap } from './styles';
import { usePalette } from '../../../hooks';

export type OptionType = {
  id: string | number;
  label: string;
  value: string | number;
};

interface SelectCustomProps {
  value: string;
  onChange: (event: SelectChangeEvent<any>, child: ReactNode) => void;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl<object>>;
  options: OptionType[];
  label?: string;
  mb?: string | number;
  sx?: SxProps;
  placeholder?: string;
  size?: 'small' | 'medium' | 'big';
  multiple?: boolean;
}

interface ISelectRHFProps extends Omit<SelectCustomProps, 'value' | 'onChange'> {
  name: string;
}

export const SelectCustom = forwardRef(
  (
    {
      value,
      onChange,
      options,
      errorMessage,
      label,
      placeholder,
      mb,
      size = 'small',
      sx,
      multiple = false,
    }: SelectCustomProps,
    ref,
  ) => {
    const { palette } = usePalette();
    const isSmallSize = size === 'small';
    return (
      <FormControl fullWidth sx={{ marginBottom: mb, ...sx }}>
        {label ? (
          <FormLabel focused={false} aria-label={`select-${size}`}>
            <Typography>{label}</Typography>
          </FormLabel>
        ) : null}

        <Select
          size={size}
          value={value}
          onChange={onChange}
          label=''
          ref={ref}
          error={!!errorMessage}
          displayEmpty
          multiple={multiple}
          IconComponent={(props) => {
            return (
              <ChevronWrap {...props}>
                <ChevronBottomIcon />
              </ChevronWrap>
            );
          }}
          {...(placeholder && {
            renderValue: (value: string | (string | number)[]) => {
              if (!value || !value?.length)
                return <Typography color={palette.gray100.main}>{placeholder}</Typography>;
              if (Array.isArray(value)) {
                const filter = options
                  ?.filter((opt) => value.includes(opt?.value))
                  .map((it) => it.label);
                return filter.join(', ');
              }
              return options?.find((opt) => opt?.value === value)?.label;
            },
          })}
        >
          {options
            ? options.map(({ id, value, label }) => {
                return (
                  <MenuItem key={id} value={value} sx={{ ...(!isSmallSize && { height: '41px' }) }}>
                    {label}
                  </MenuItem>
                );
              })
            : null}
        </Select>
        {!!errorMessage && (
          <FormHelperText sx={{ marginLeft: 0 }} error>
            {String(errorMessage)}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

export const SelectRHF = ({ name, ...rest }: ISelectRHFProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        return (
          <SelectCustom
            value={field.value}
            onChange={field.onChange}
            errorMessage={formState.errors?.[name]?.message}
            {...rest}
          />
        );
      }}
    />
  );
};
