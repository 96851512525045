import { Stack, styled } from '@mui/material';

export const ChatTemplatesFormWrapper = styled(Stack)(({ theme }) => {
  return {
    padding: '15px 23px',
    gap: '16px',
    background: theme.vars.palette.silver20silver200.main,
    borderRadius: '36px',
  };
});
