import { Button, Stack, Typography } from '@mui/material';
import { ModalHeader } from '../ModalComponents';
import { Wrapper } from './styles';

interface TemplateChangesConfirmationModalProps {
  onClose?: () => void;
  onApply?: () => void;
  label?: string;
  buttonCancelLabel?: string;
  buttonApplyLabel?: string;
}

export const TemplateChangesConfirmationModal = ({
  onClose,
  onApply,
  label = ' Do you want to apply template changes for all companies?',
  buttonCancelLabel = 'Cancel',
  buttonApplyLabel = 'Apply for all companies',
}: TemplateChangesConfirmationModalProps) => {
  return (
    <Wrapper>
      <ModalHeader onClose={onClose} />
      <Stack justifyContent={'center'} alignItems={'center'} gap={'24px'}>
        <Stack gap={'6px'} alignItems={'center'} textAlign={'center'} maxWidth={'420px'}>
          <Typography variant={'lg'}>{label}</Typography>
        </Stack>
        <Stack direction={'row'} gap={'16px'}>
          <Button typeof={'support'} onClick={onClose} sx={{ minWidth: '150px' }}>
            {buttonCancelLabel}
          </Button>
          <Button onClick={onApply} sx={{ minWidth: '150px' }}>
            {buttonApplyLabel}
          </Button>
        </Stack>
      </Stack>
    </Wrapper>
  );
};
