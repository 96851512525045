import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

export const Wrapper = styled(Stack)({
  padding: '8px',
  gap: '16px',
  width: '100%',
  minWidth: '439px',
  maxWidth: '550px',
});

export const TypographyCSS = styled(Typography)(({ theme }) => ({
  color: theme.vars.palette.silver100White.main,
  textEdge: 'cap',
}));
