import { Switcher } from '../Switcher';
import { Stack } from '@mui/material';
import { useColorScheme } from '@mui/material/styles';
import { ThemeSwitcherProps } from './types';

export const ThemeSwitcher = ({ sx, ml }: ThemeSwitcherProps) => {
  const { mode, setMode } = useColorScheme();
  const isChecked = mode === 'dark';

  const handleChange = () => {
    if (mode === 'light') {
      setMode('dark');
    } else {
      setMode('light');
    }
  };

  return (
    <Stack justifyContent={'center'} sx={sx} ml={ml}>
      <Switcher defaultSwitcher checked={isChecked} onChange={handleChange} />
    </Stack>
  );
};
