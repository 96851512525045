import { OrderItemList } from '../../../OrderItemList/OrderItemList';
import { AccordionList } from '../AccordionList/AccordionList';
import { MAX_TAKE_ORDER_LIST_ITEMS, OptionsChatTabsEnum } from '../../../../constants/constants';
import {
  OrderResponseSchema,
  useGetLastOrderQuery,
  useGetOrdersQuery,
} from '../../../../graphql/generated';
import { Fragment, memo } from 'react';
import { RequestHandler } from '../../../RequestHandler/RequestHandler';
import { AccordionItemType } from '../../../../types';
import { Stack, Typography, useTheme } from '@mui/material';
import { OrderRow } from '../OrderRow/OrderRow';

// const getFieldName = (str: string) => {
//   switch (str) {
//     case 'id':
//       return 'Order ID';
//     case 'email':
//       return 'Email';
//     case 'shipment':
//       return 'Status';
//     case 'shipmentStatus':
//       return 'Shipment Status';
//     case 'fulfillmentStatus':
//       return 'Fulfillment Status';
//     case 'phone':
//       return '';
//     default:
//       return '';
//   }
// };

// const orderListCreateData = (data: OrderResponseSchema) => {
//   return Object.keys(data)
//     .map((el, i) => {
//       return {
//         id: i,
//         label: getFieldName(el),
//         content: el === 'shipment' ? data.status : data[el as keyof OrderResponseSchema],
//         ...(el === 'shipment' && { status: data.status }),
//       };
//     })
//     .filter((item) => item.label);
// };

interface OrderListProps {
  conversationId: number;
  tabValue: OptionsChatTabsEnum;
  onEditOrder: (id?: string | number) => void;
}

export const OrderList = memo(({ onEditOrder, tabValue, conversationId }: OrderListProps) => {
  const { data: lastOrderData, loading: lastOrderLoading } = useGetLastOrderQuery({
    variables: {
      conversationId: conversationId,
    },
    skip: tabValue !== OptionsChatTabsEnum.CurrentOrder,
  });

  const { data: ordersData, loading: orderDataLoading } = useGetOrdersQuery({
    variables: {
      input: {
        conversationId: conversationId,
        cursorLineItems: null,
        take: MAX_TAKE_ORDER_LIST_ITEMS,
      },
    },
    skip: tabValue !== OptionsChatTabsEnum.PreviousOrder,
  });

  const mainOrdersData = lastOrderData?.getLastOrder
    ? lastOrderData?.getLastOrder
    : ordersData?.getOrders;

  return (
    <RequestHandler
      loading={lastOrderLoading || orderDataLoading}
      sxLoading={{ overflow: 'hidden' }}
    >
      {mainOrdersData?.map((order) => {
        // const orderItemsList = orderListCreateData(order as OrderResponseSchema);
        return (
          <Fragment key={order.id}>
            {/*<OrderItemList*/}
            {/*  key={order.id}*/}
            {/*  itemId={order.link}*/}
            {/*  itemList={orderItemsList as AccordionItemType[]}*/}
            {/*  onEdit={onEditOrder}*/}
            {/*/>*/}
            <OrderRow
              itemId={order.link}
              onEdit={onEditOrder}
              orderId={order.id}
              email={order.email}
              fulfillmentStatus={order.fulfillmentStatus}
              financialStatus={order.financialStatus}
            />
            <AccordionList data={order} />
          </Fragment>
        );
      })}
    </RequestHandler>
  );
});
