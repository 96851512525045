import { useHotkeys } from 'react-hotkeys-hook';
import {
  useGetAndAssignNextConversationMutation,
  useMarkAsAnsweredMutation,
  useMarkAsBlockedMutation,
  useMarkAsNoNeedToReplyMutation,
  UserRoleEnum,
} from '../graphql/generated';
import { toast } from 'react-toastify';
import { useAuthContext, useModalContext } from '../context';
import { ERROR_MESSAGE, ROUTES } from '../constants/constants';
import { client } from '../graphql/client';
import { GET_CURRENT_CONVERSATION } from '../graphql/queries/getCurrentConversation.gql';
import { useNavigate } from 'react-router-dom';
import { GET_CONVERSATION } from '../graphql/queries/getConversation.gql';

interface useHotKeysForChatProps {
  conversationId: number;
  isMessageHasBeenSent?: boolean;
  skipStateMessageHasBeenSent: () => void;
  isMessageSending?: boolean;
  hotSendMesToArrowRight: () => void;
  customerFullName?: string;
}

export const useHotKeysForChat = ({
  conversationId,
  isMessageHasBeenSent,
  skipStateMessageHasBeenSent,
  isMessageSending,
  hotSendMesToArrowRight,
  customerFullName,
}: useHotKeysForChatProps) => {
  const { userData } = useAuthContext();
  const currentUserId = userData?.id;
  const { onCloseModal, infoModal } = useModalContext();
  const isOpenModal = infoModal.open;
  const navigate = useNavigate();

  const [getAndAssignNextConversation] = useGetAndAssignNextConversationMutation({
    refetchQueries: [GET_CURRENT_CONVERSATION, GET_CONVERSATION],
    onCompleted: (data) => {
      if (userData?.role !== UserRoleEnum.Agent && !isOpenModal) {
        navigate(`${ROUTES.chat}/${data.getAndAssignNextConversation.id}`);
      }
    },
    onError: (error) => {
      if (error.message === ERROR_MESSAGE.NO_CONVERSATION_FOUND) {
        if (isOpenModal) {
          return onCloseModal();
        }
        const cache = client.cache;
        cache.modify({
          id: `UserEntity:${currentUserId}`,
          fields: {
            assignedConversation() {
              return null;
            },
          },
        });
        navigate(ROUTES.home);
      } else {
        toast.error(error.message);
      }
    },
  });

  // blocked
  const [markAsBlocked] = useMarkAsBlockedMutation({
    onCompleted: () => {
      toast.success(`${customerFullName || 'User'} has been blocked`);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  // когда дал нужные ответы и ливаю из чата
  const [markAsAnswered] = useMarkAsAnsweredMutation({
    variables: {
      conversationId,
    },
    onCompleted: () => {
      return getAndAssignNextConversation();
    },
    onError: (error) => {
      // если мы - админ и зашли из таблицы на страницы Home
      if (isOpenModal) return onCloseModal();
    },
  });

  // ответ не требуется
  const [markAsNoNeedToReply] = useMarkAsNoNeedToReplyMutation({
    onCompleted: () => {
      toast.success('Marked as no reply needed');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  useHotkeys('shift+n', () => {
    skipStateMessageHasBeenSent();
    return markAsNoNeedToReply({
      variables: {
        conversationId,
      },
    });
  });

  useHotkeys('shift+b', () => {
    return markAsBlocked({
      variables: {
        conversationId,
      },
    });
  });

  useHotkeys(
    'ArrowRight',
    () => {
      if (isMessageSending) {
        hotSendMesToArrowRight(); // we sand message and go to next convo
        return markAsAnswered({
          variables: {
            conversationId,
          },
        });
      }
      if (isMessageHasBeenSent) {
        return markAsAnswered({
          variables: {
            conversationId,
          },
        });
      } else {
        return getAndAssignNextConversation();
      }
    },
    {
      enableOnFormTags: ['textarea'],
    },
  );

  return {};
};
