interface IconsTypes {
  width?: string;
  height?: string;
  color?: string;
  size?: string;
}

export const TrashIcon = ({ width = '16', height = '16', color = '#0C090C' }: IconsTypes) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      color='#fff'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00008 0.833008C6.25118 0.833008 4.83341 2.25077 4.83341 3.99967V4.16634H2.66675C2.39061 4.16634 2.16675 4.3902 2.16675 4.66634C2.16675 4.94248 2.39061 5.16634 2.66675 5.16634H13.3334C13.6096 5.16634 13.8334 4.94248 13.8334 4.66634C13.8334 4.3902 13.6096 4.16634 13.3334 4.16634H11.1667V3.99967C11.1667 2.25077 9.74898 0.833008 8.00008 0.833008ZM8.00008 1.83301C9.1967 1.83301 10.1667 2.80306 10.1667 3.99967V4.16634H5.83341V3.99967C5.83341 2.80306 6.80346 1.83301 8.00008 1.83301Z'
        fill={color}
      />
      <path
        d='M3.83117 5.95238C3.80505 5.67748 3.56103 5.4758 3.28612 5.50192C3.01122 5.52804 2.80954 5.77206 2.83566 6.04697C2.89864 6.70982 3.0123 7.52655 3.1584 8.57638L3.3461 9.92511C3.52554 11.2149 3.62731 11.9464 3.84748 12.5457C4.25722 13.6609 4.98812 14.5549 5.92812 14.9516C6.43857 15.167 7.02243 15.1667 7.89163 15.1664H8.10853C8.97773 15.1667 9.5616 15.167 10.072 14.9516C11.012 14.5549 11.7429 13.6609 12.1527 12.5457C12.3729 11.9464 12.4746 11.2149 12.6541 9.92507L12.8418 8.57639C12.9879 7.52655 13.1015 6.70982 13.1645 6.04697C13.1906 5.77206 12.9889 5.52804 12.714 5.50192C12.4391 5.4758 12.1951 5.67748 12.169 5.95238C12.1083 6.59102 11.9977 7.38636 11.8497 8.45042L11.6748 9.7071C11.4811 11.0988 11.3924 11.7154 11.214 12.2008C10.8701 13.1369 10.2982 13.7707 9.68326 14.0302C9.38312 14.1569 9.01953 14.1663 8.00008 14.1663C6.98064 14.1663 6.61704 14.1569 6.31691 14.0302C5.70194 13.7707 5.13006 13.1369 4.78613 12.2008C4.60779 11.7154 4.51908 11.0988 4.3254 9.7071L4.15051 8.45042C4.00243 7.38636 3.89185 6.59103 3.83117 5.95238Z'
        fill={color}
      />
      <path
        d='M7.16675 6.66634C7.16675 6.3902 6.94289 6.16634 6.66675 6.16634C6.39061 6.16634 6.16675 6.3902 6.16675 6.66634V11.9997C6.16675 12.2758 6.39061 12.4997 6.66675 12.4997C6.94289 12.4997 7.16675 12.2758 7.16675 11.9997V6.66634Z'
        fill='#0C090C'
      />
      <path
        d='M9.83341 6.66634C9.83341 6.3902 9.60956 6.16634 9.33341 6.16634C9.05727 6.16634 8.83341 6.3902 8.83341 6.66634V11.9997C8.83341 12.2758 9.05727 12.4997 9.33341 12.4997C9.60956 12.4997 9.83341 12.2758 9.83341 11.9997V6.66634Z'
        fill={color}
      />
    </svg>
  );
};
