import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
  useParams,
} from 'react-router-dom';
import { ROUTES } from '../constants/constants';
import { Dev, Home, MetricsPage, ShopsPage, UsersPage, Welcome, NotFound } from '../pages';
import { useAuthContext } from '../context';
import { Layout } from '../components';
import { HomeIcon, MetricsIcon, ShopsIcon, UsersIcon } from '../components/Icons';
import { ReactElement } from 'react';
import { ChatPage } from '../pages/ChatPage/ChatPage';

export const routes = [
  {
    path: ROUTES.home,
    element: Home,
    isHeaderNavigate: true,
    icon: HomeIcon,
    label: 'Home',
    isAuthRequired: true,
    isManagerRoute: true,
  },
  {
    path: ROUTES.welcome,
    element: Welcome,
  },
  {
    path: ROUTES.metrics,
    element: MetricsPage,
    isHeaderNavigate: true,
    icon: MetricsIcon,
    label: 'Metrics',
    isAuthRequired: true,
    isManagerRoute: true,
  },
  {
    path: ROUTES.shops,
    element: ShopsPage,
    isHeaderNavigate: true,
    icon: ShopsIcon,
    label: 'Shops',
    isSuperAdminRoute: true,
    isManagerRoute: true,
    isAuthRequired: true,
  },
  {
    path: ROUTES.users,
    element: UsersPage,
    isHeaderNavigate: true,
    icon: UsersIcon,
    label: 'Users',
    isSuperAdminRoute: true,
    isAuthRequired: true,
  },
  {
    path: ROUTES.chat,
    element: ChatPage,
    isAuthRequired: true,
  },
  {
    path: ROUTES.chatById,
    element: ChatPage,
    isAuthRequired: true,
  },
  {
    path: ROUTES.dev,
    element: Dev,
  },
];

interface RequireAuthProps {
  children: ReactElement;
  isAuthRequired?: boolean;
  path: string;
}
const RequireAuth = ({ children, isAuthRequired, path }: RequireAuthProps) => {
  const { isAuth, roles, userData } = useAuthContext();
  const location = useLocation();
  const assignedConversation = userData?.assignedConversation;
  const isChatPage = location.pathname.includes(ROUTES.chat);
  const params = useParams();
  const isChatPageById = location.pathname === `${ROUTES.chat}/${params?.id}`;

  if (!isAuthRequired && location.pathname !== ROUTES.welcome) return children;
  if (!isAuth && isAuthRequired) {
    return <Navigate to={ROUTES.welcome} />;
  } else if (isAuth && !isAuthRequired) {
    return <Navigate to={ROUTES.home} />;
  } else if (isAuth && managerRouteCheck(path) && roles?.isAdmin && !isChatPage) {
    return <Navigate to={ROUTES.home} />;
  } else if (
    isAuth &&
    roles?.isAgent &&
    (managerRouteCheck(path) || !superAdminRouteCheck(path)) &&
    !isChatPage
  ) {
    return <Navigate to={ROUTES.home} />;
  }
  // for chat page
  if (!isChatPage && isAuthRequired && isAuth && !!assignedConversation && roles?.isAgent) {
    return <Navigate to={ROUTES.chat} />;
  }
  if (isChatPage && isAuthRequired && !assignedConversation && roles?.isAgent) {
    return <Navigate to={ROUTES.home} />;
  }
  if (roles?.isAgent && isChatPageById) {
    return <Navigate to={ROUTES.home} />;
  }

  return children;
};

const superAdminRouteCheck = (path: string) => {
  const superAdminRout = routes.filter((route) => !route.isSuperAdminRoute).map((i) => i.path);
  return superAdminRout.includes(path);
};
const managerRouteCheck = (path: string) => {
  const managerRout = routes.filter((route) => !route.isManagerRoute).map((i) => i.path);
  return managerRout.includes(path);
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />}>
      {routes.map(({ path, element: Element, isAuthRequired }) => {
        return (
          <Route
            key={path}
            {...(path === '/' ? { index: true } : { path })}
            element={
              <RequireAuth isAuthRequired={isAuthRequired} path={path}>
                <Element />
              </RequireAuth>
            }
          />
        );
      })}
      <Route key={'NotFound'} path='*' element={<NotFound />} />
    </Route>,
  ),
);
