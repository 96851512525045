import { Button, Stack, styled } from '@mui/material';

export const AddNewBlockCSS = styled(Stack)(({ theme }) => {
  return {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60px',
    padding: 0,
    border: `1px dashed ${theme.vars.palette.blackWhite.main}`,
    borderRadius: '36px',
  };
});

export const AddNewButtonCSS = styled(Button)(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '60px',
    color: theme.vars.palette.blackWhite.main,
    borderRadius: '36px',
    backgroundColor: 'transparent',
    boxShadow: 'none',

    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
    },

    '& svg': {
      marginRight: '6px',

      '& path': {
        fill: theme.vars.palette.blackWhite.main,
      },
    },
  };
});
