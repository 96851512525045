import Typography from '@mui/material/Typography';
import {
  MODAL_NAME,
  OPTION_SHOPS_TABS,
  SHOPS_TABLE_OPTIONS_DEACTIVATED,
  SHOPS_TABLE_OPTIONS_ACTIVE,
} from '../../constants/constants';
import { TableRowProps, TableList } from '../../components';
import { ShopEntity, useGetShopsLazyQuery, useGetShopsQuery } from '../../graphql/generated';
import { useModalContext } from '../../context';
import { useForm } from 'react-hook-form';

import { useDebounce, usePagination, useTriggerByScrollTable } from '../../hooks';
import { ShopsPageFilterGroup } from '../../components/Containers';
import { getEndDateFormatTo23H59M } from '../../utils';
import { useEffect, useState } from 'react';

const createData = (tableRowData: ShopEntity) => {
  return {
    ...tableRowData,
    id: tableRowData.id,
    company: tableRowData.name,
    email: tableRowData.email,
    deactivationDate: tableRowData.deactivatedAt,
    active: !tableRowData.deactivated,
    shopUrl: tableRowData.url,
  };
};

type TabsAlias = 'active' | 'deactivated';

export type ShopsPageFormData = {
  tabs: TabsAlias;
  search: string;
  startDate: string | null;
  endDate: string | null;
};

export const ShopsPage = () => {
  const { onOpenModal } = useModalContext();
  const { paginationOptions, handleFetchMore, setPaginationOptions, pending } = usePagination();

  const methods = useForm<ShopsPageFormData>({
    defaultValues: {
      tabs: OPTION_SHOPS_TABS[0].id as TabsAlias,
      startDate: null,
      endDate: null,
      search: '',
    },
  });
  const { watch, reset } = methods;
  const [tabs, startDate, endDate, search] = watch(['tabs', 'startDate', 'endDate', 'search']);
  const isActive = tabs === 'active';
  const debounceInputValue = useDebounce(search);
  const isAllDateFieldFilled = startDate && endDate;

  const options = {
    isActive: isActive,
    search: debounceInputValue,
    ...(isAllDateFieldFilled && {
      dateFrom: startDate,
      dateTo: getEndDateFormatTo23H59M(endDate),
    }),
    limit: paginationOptions.limit,
    offset: paginationOptions.offset,
  };

  const {
    data: shopsData,
    loading: loadingShops,
    fetchMore,
    refetch,
  } = useGetShopsQuery({
    variables: {
      options,
    },
  });

  useEffect(() => {
    refetch({
      options: {
        ...options,
        offset: 0,
      },
    });
    setPaginationOptions((prevState) => ({
      ...prevState,
      offset: 0,
    }));
  }, [isAllDateFieldFilled, debounceInputValue, isActive, tabs, endDate && startDate]);

  const rows =
    shopsData?.getShops.rows?.map((tableRowData) => createData(tableRowData as ShopEntity)) || [];

  const handleOpen = (row: TableRowProps) => {
    onOpenModal(MODAL_NAME.SHOPS_SETTINGS, { shopId: row.id, data: row });
  };

  const handleReset = () => {
    reset();
  };

  const { tableBodyRef } = useTriggerByScrollTable({
    triggerData: [rows],
    skip: pending,
    onAction: () => {
      handleFetchMore({
        fetchMore,
        options,
        dataCount: Number(shopsData?.getShops.count),
        isInput: false,
      });
    },
  });

  return (
    <>
      <Typography variant={'big'} mt={'36px'} mb={'17px'}>
        Shops
      </Typography>
      <ShopsPageFilterGroup
        onReset={handleReset}
        startDate={startDate}
        nameTabsField={'tabs'}
        nameSearchField={'search'}
        nameEndDateField={'endDate'}
        nameStartDateField={'startDate'}
        methods={methods}
      />
      <TableList
        isLoading={loadingShops}
        headCells={tabs === 'active' ? SHOPS_TABLE_OPTIONS_ACTIVE : SHOPS_TABLE_OPTIONS_DEACTIVATED}
        rows={rows}
        isHasPagination={false}
        openButtonTitle='Open'
        handleOpen={handleOpen}
        isHasControlBadge
        tableBodyRef={tableBodyRef}
      />
    </>
  );
};
