import { IconBlockCSS, Wrapper } from './styles';
import { Button, Stack, Typography } from '@mui/material';
import { memo, ReactNode } from 'react';
import { PencilIcon } from '../../../Icons';
import { usePalette } from '../../../../hooks';
import { OrderRowItem } from '../OrderRowItem/OrderRowItem';
import { capitalizeFirstLetter } from '../../../../utils';
import { OrderInformationStatuses } from '../../../OrderInformationStatuses/OrderInformationStatuses';

interface ContentBlockTopLabelProps {
  icon: ReactNode;
  label?: string;
  mb?: string;
  onClick?: () => void;
  goBack?: () => void;
  isEdit?: boolean;
  formId?: string;
  disabled?: boolean;

  financialStatus?: string | undefined | null;
  fulfillmentStatus?: string | undefined | null;
  shipmentStatus?: string[] | undefined | null;
  isCancelled?: boolean | undefined;
}

export const ContentBlockTopLabel = memo(
  ({
    icon,
    label,
    mb = '16px',
    onClick,
    isEdit,
    formId,
    goBack,
    disabled,
    financialStatus,
    fulfillmentStatus,
    shipmentStatus,
    isCancelled,
  }: ContentBlockTopLabelProps) => {
    const { palette } = usePalette();
    const isShowEditContent = !isEdit && !formId;
    const buttonText = isShowEditContent ? 'Edit' : formId ? 'Save' : 'Back';

    return (
      <Wrapper mb={mb}>
        <IconBlockCSS>{icon}</IconBlockCSS>
        <Typography variant={'subtitle2'}>{label}</Typography>
        <OrderInformationStatuses
          financialStatus={financialStatus}
          fulfillmentStatus={fulfillmentStatus}
          shipmentStatus={shipmentStatus}
          isCancelled={isCancelled}
        />
        <Stack direction={'row'} ml={'auto'} gap={'16px'}>
          {goBack && (
            <Button variant={'edit'} onClick={goBack}>
              Back
            </Button>
          )}
          {(onClick || formId) && (
            <Button
              onClick={onClick}
              sx={{
                'svg fill': 'initial',
                ...(isEdit && {
                  background: palette.silver20silver200.main,
                  color: palette.blackWhite.main,
                  ':hover': { background: palette.silver20silver200.main },
                }),
              }}
              variant='edit'
              startIcon={isShowEditContent && <PencilIcon />}
              {...(formId && { form: formId })}
              type={formId ? 'submit' : 'button'}
              disabled={disabled}
            >
              {buttonText}
            </Button>
          )}
        </Stack>
      </Wrapper>
    );
  },
);
