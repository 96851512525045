import { DependencyList, useEffect, useRef } from 'react';

interface PaginationHookArguments {
  triggerData?: DependencyList;
  onAction?: () => void;
  skip?: boolean;
}
export const useTriggerByScrollTable = ({
  triggerData,
  onAction,
  skip,
}: PaginationHookArguments) => {
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);

  function handleScroll() {
    const tableBody = tableBodyRef.current;
    if (tableBody) {
      const { scrollTop, scrollHeight, clientHeight } = tableBody;
      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        onAction && onAction();
      }
    }
  }

  useEffect(() => {
    const tableBody = tableBodyRef.current;
    if (!tableBody || skip) return;

    tableBody.addEventListener('scroll', handleScroll);
    return () => tableBody.removeEventListener('scroll', handleScroll);
  }, [triggerData]);

  return { tableBodyRef };
};
