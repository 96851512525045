import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { TextFieldRHF } from '../UI';
import { Stack } from '@mui/material';
import { templateFormSchema } from '../../validation/schema/templateForm.schema';
import { CancelButtonCSS, CreateButtonCSS, Wrapper } from './styles';
import {
  TemplateTypeEnum,
  useCreateShopTemplateMutation,
  useEditShopTemplateMutation,
} from '../../graphql/generated';
import { toast } from 'react-toastify';
import { GET_SHOP } from '../../graphql/queries/getShop.gql';
import { TemplateFormData, TemplateFormProps } from './types';
import { CheckboxRHF } from '../UI/CheckboxCustom/CheckboxCustom';
import { ModalFormWrapperCSS, ModalWrapper } from '../Modal/ModalController/styles';
import { TemplateChangesConfirmationModal } from '../Modal';
import { useState } from 'react';

export const TemplateForm = ({ onClose, shopId, template }: TemplateFormProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const methods = useForm<TemplateFormData>({
    defaultValues: {
      templateName: template?.name || '',
      templateText: template?.text || '',
      shortcut: template?.shortcut || '',
      isAllCompanies: template?.type === TemplateTypeEnum.General,
    },
    resolver: yupResolver(templateFormSchema),
  });

  const [createTemplate] = useCreateShopTemplateMutation({
    refetchQueries: [{ query: GET_SHOP, variables: { getShopId: Number(shopId) } }],
    onCompleted: () => {
      toast.success('Template has been created');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [editTemplate] = useEditShopTemplateMutation({
    refetchQueries: [{ query: GET_SHOP, variables: { getShopId: Number(shopId) } }],
    onCompleted: () => {
      toast.success('Template has been changed');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const {
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = methods;

  const onSubmitRHF = async (value: TemplateFormData) => {
    const isAllCompanies = value.isAllCompanies;
    if (!template) {
      await createTemplate({
        variables: {
          input: {
            shopId: Number(shopId),
            name: value.templateName,
            shortcut: value.shortcut,
            text: value.templateText,
            type: isAllCompanies ? TemplateTypeEnum.General : TemplateTypeEnum.Personal,
          },
        },
      });
      onClose();
    } else {
      if (isAllCompanies) {
        return setIsOpenModal(true);
      } else {
        await editTemplate({
          variables: {
            templateId: Number(template?.id),
            input: {
              globalEdit: isAllCompanies,
              shopId: Number(shopId),
              name: value.templateName,
              shortcut: value.shortcut,
              text: value.templateText,
            },
          },
        });
        onClose();
      }
    }
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleApply = async () => {
    const value = getValues();
    if (value.isAllCompanies) {
      await editTemplate({
        variables: {
          templateId: Number(template?.id),
          input: {
            globalEdit: value.isAllCompanies,
            shopId: Number(shopId),
            name: value.templateName,
            shortcut: value.shortcut,
            text: value.templateText,
          },
        },
      });
    } else {
      await createTemplate({
        variables: {
          input: {
            shopId: Number(shopId),
            name: value.templateName,
            shortcut: value.shortcut,
            text: value.templateText,
            type: TemplateTypeEnum.Personal,
          },
        },
      });
    }

    setIsOpenModal(false);
  };

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitRHF)}>
          <TextFieldRHF
            name={'templateName'}
            label={'Template Name'}
            size={'medium'}
            placeholder={'Enter Template Name'}
            type={'text'}
            mb={2}
          />
          <TextFieldRHF
            name={'templateText'}
            label={'Template Text'}
            size={'medium'}
            placeholder={'Enter Template Text'}
            type={'text'}
            mb={2}
          />
          <TextFieldRHF
            name={'shortcut'}
            label={'Shortcut'}
            size={'medium'}
            placeholder={'Exp. shrtct'}
            type={'text'}
            mb={2}
          />
          <CheckboxRHF
            name={'isAllCompanies'}
            label={'Apply for all companies'}
            disabled={template && template?.type !== TemplateTypeEnum.General} // for edit case
            mb={'16px'}
          />
          <Stack direction={'row'} gap={'10px'} width={'100%'}>
            <CancelButtonCSS type={'button'} onClick={onClose}>
              Cancel
            </CancelButtonCSS>
            <CreateButtonCSS disabled={!isDirty} type={'submit'}>
              {!template ? 'Create' : 'Edit'}
            </CreateButtonCSS>
          </Stack>
        </form>
      </FormProvider>
      <ModalWrapper open={isOpenModal} onClose={handleCloseModal}>
        <ModalFormWrapperCSS>
          <TemplateChangesConfirmationModal onClose={handleCloseModal} onApply={handleApply} />
        </ModalFormWrapperCSS>
      </ModalWrapper>
    </Wrapper>
  );
};
