import { IconProps } from './types';

export const MetricsIcon = ({ color = '#fff' }: IconProps) => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.7029 10.1241C14.5313 10.1241 15.2195 10.8038 15.0369 11.6118C14.8554 12.415 14.5275 13.1825 14.0653 13.8742C13.3236 14.9842 12.2694 15.8494 11.036 16.3603C9.8026 16.8712 8.44539 17.0049 7.13602 16.7444C5.82665 16.484 4.62392 15.8411 3.67991 14.8971C2.73591 13.9531 2.09303 12.7503 1.83258 11.441C1.57213 10.1316 1.70581 8.77439 2.2167 7.54099C2.72759 6.30759 3.59275 5.25338 4.70278 4.51168C5.3945 4.04949 6.16194 3.72159 6.96518 3.54009C7.77323 3.3575 8.45288 4.04568 8.45288 4.8741V6.3741C8.45288 8.44517 10.1318 10.1241 12.2029 10.1241H13.7029Z'
        fill={color}
      />
      <path
        d='M10.7029 3.7748C10.7029 2.31086 11.9335 1.07769 13.286 1.63791C14.1049 1.97713 14.8491 2.47434 15.4759 3.10113C16.1026 3.72793 16.5999 4.47204 16.9391 5.29099C17.4993 6.6435 16.2661 7.8741 14.8022 7.8741H14.0779C12.2139 7.8741 10.7029 6.36306 10.7029 4.4991V3.7748Z'
        fill={color}
      />
    </svg>
  );
};
