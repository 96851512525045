import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthContextProvider, ChatProvider } from './context';
import { client } from './graphql/client';
import { router } from './router/Router';
import { theme } from './theme/theme';
import { ToastContainer } from 'react-toastify';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { RouterProvider } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'react-toastify/dist/ReactToastify.css';
import { FilterContextProvider } from './context/FilterContext';

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_TOKEN as string}>
      <ApolloProvider client={client}>
        <CssVarsProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline />
            <ToastContainer position={'bottom-right'} />
            <AuthContextProvider>
              <FilterContextProvider>
                <ChatProvider>
                  <RouterProvider router={router} />
                </ChatProvider>
              </FilterContextProvider>
            </AuthContextProvider>
          </LocalizationProvider>
        </CssVarsProvider>
      </ApolloProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
