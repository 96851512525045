import { Stack, Link, styled } from '@mui/material';

export const OrderItemCSS = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isSmallWidth',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
})<{ isSmallWidth?: boolean }>(({ isSmallWidth }) => {
  return {
    minWidth: '130px',
    flexDirection: 'row',
    marginRight: '16px',
    wordBreak: 'break-all',
    // flex: isSmallWidth ? 0 : 1,
    flex: 1,
    '&:first-of-type span': {
      display: 'none',
    },
  };
});

export const OrderItemDividerCSS = styled('span')(({ theme }) => {
  return {
    height: '100%',
    marginRight: '16px',
    border: `1px solid ${theme.vars.palette.silverMidWhite.main}`,
  };
});

export const LinkCSS = styled(Link)(({ theme }) => {
  return {
    color: theme.vars.palette.mail.main,
    textDecoration: 'none',
  };
});
