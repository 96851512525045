import { Avatar, Stack, Typography, useTheme } from '@mui/material';
import { LoginIcon } from '../Icons/LoginIcon';
import { UserIcon } from '../Icons/UserIcon';
import { Logo } from '../UI/Logo/Logo';
import { ButtonLogIn, HeroWrapper, SpanDividerContainer, UserInfoCSS, Wrapper } from './styles';
import { useAuthContext, useModalContext } from '../../context';
import { MODAL_NAME } from '../../constants/constants';
import { HeaderLinks } from '../HeaderLinks/HeaderLinks';
import { ThemeSwitcher } from '../ThemeSwitcher/ThemeSwitcher';

export const Header = () => {
  const { isAuth, signIn, signOut, userData } = useAuthContext();
  const { onOpenModal, onCloseModal } = useModalContext();
  const theme = useTheme();
  const colorLogOut = theme.vars.palette.blackWhite.main;

  const srcAvatar = userData?.avatar || '';
  const userName = userData?.firstName;
  const role = userData?.role;

  const handleLogOut = () => {
    signOut();
    onCloseModal();
  };

  const handleOpenModal = () => {
    if (isAuth) {
      onOpenModal(MODAL_NAME.ARE_YOUR_SURE, {
        callback: handleLogOut,
        title: 'Are you sure you want to log out?',
        labelBtnLeft: 'Cancel',
        labelBtnRight: 'Log out',
      });
    } else {
      signIn();
    }
  };

  return (
    <Wrapper>
      <Logo />
      <>
        {isAuth && <HeaderLinks />}
        <HeroWrapper>
          {isAuth && (
            <>
              <Stack sx={{ marginRight: '8px' }}>
                <Avatar src={srcAvatar}>
                  <UserIcon />
                </Avatar>
              </Stack>
              <UserInfoCSS>
                <Typography variant='body4' paragraph={true}>
                  {userName || 'User'}
                </Typography>
                <Typography variant='caption' paragraph={true}>
                  {role || ''}
                </Typography>
              </UserInfoCSS>
              <ButtonLogIn variant={'circle'} onClick={handleOpenModal}>
                <LoginIcon color={colorLogOut} sx={{ rotate: isAuth ? '180deg' : 'none' }} />
              </ButtonLogIn>
              <SpanDividerContainer />
            </>
          )}
          <ThemeSwitcher />
        </HeroWrapper>
      </>
    </Wrapper>
  );
};
