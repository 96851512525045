import { IconProps } from './types';

export const DeleteIcon = ({ color = '#2D264B' }: IconProps) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.99935 0.833252C6.25045 0.833252 4.83268 2.25102 4.83268 3.99992V4.16659H2.66602C2.38987 4.16659 2.16602 4.39044 2.16602 4.66659C2.16602 4.94273 2.38987 5.16659 2.66602 5.16659H13.3327C13.6088 5.16659 13.8327 4.94273 13.8327 4.66659C13.8327 4.39044 13.6088 4.16659 13.3327 4.16659H11.166V3.99992C11.166 2.25102 9.74825 0.833252 7.99935 0.833252ZM7.99935 1.83325C9.19597 1.83325 10.166 2.8033 10.166 3.99992V4.16659H5.83268V3.99992C5.83268 2.8033 6.80273 1.83325 7.99935 1.83325Z'
        fill={color}
      />
      <path
        d='M3.83044 5.95263C3.80432 5.67772 3.56029 5.47604 3.28539 5.50216C3.01048 5.52828 2.8088 5.77231 2.83492 6.04721C2.8979 6.71007 3.01157 7.52679 3.15767 8.57663L3.34537 9.92535C3.52481 11.2152 3.62658 11.9467 3.84674 12.5459C4.25648 13.6611 4.98738 14.5551 5.92739 14.9518C6.43783 15.1672 7.0217 15.167 7.8909 15.1666H8.1078C8.977 15.167 9.56086 15.1672 10.0713 14.9518C11.0113 14.5551 11.7422 13.6611 12.152 12.5459C12.3721 11.9467 12.4739 11.2152 12.6533 9.92532L12.841 8.57664C12.9871 7.5268 13.1008 6.71007 13.1638 6.04721C13.1899 5.77231 12.9882 5.52828 12.7133 5.50216C12.4384 5.47604 12.1944 5.67772 12.1683 5.95262C12.1076 6.59127 11.997 7.3866 11.8489 8.45066L11.674 9.70735C11.4803 11.0991 11.3916 11.7156 11.2133 12.201C10.8694 13.1371 10.2975 13.771 9.68252 14.0305C9.38239 14.1571 9.01879 14.1666 7.99935 14.1666C6.9799 14.1666 6.61631 14.1571 6.31617 14.0305C5.70121 13.771 5.12932 13.1371 4.7854 12.201C4.60705 11.7156 4.51835 11.0991 4.32467 9.70735L4.14978 8.45066C4.00169 7.38661 3.89112 6.59127 3.83044 5.95263Z'
        fill={color}
      />
      <path
        d='M7.16602 6.66659C7.16602 6.39044 6.94216 6.16659 6.66602 6.16659C6.38987 6.16659 6.16602 6.39044 6.16602 6.66659V11.9999C6.16602 12.2761 6.38987 12.4999 6.66602 12.4999C6.94216 12.4999 7.16602 12.2761 7.16602 11.9999V6.66659Z'
        fill={color}
      />
      <path
        d='M9.83268 6.66659C9.83268 6.39044 9.60882 6.16659 9.33268 6.16659C9.05654 6.16659 8.83268 6.39044 8.83268 6.66659V11.9999C8.83268 12.2761 9.05654 12.4999 9.33268 12.4999C9.60882 12.4999 9.83268 12.2761 9.83268 11.9999V6.66659Z'
        fill={color}
      />
    </svg>
  );
};
