import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => {
  return {
    padding: '12px 36px 26px 12px',
    borderRadius: '44px',
    backdropFilter: 'blur(4px)',
    background: theme.vars.palette.whiteSilver.main,
  };
});

export const IconBlockCSS = styled(Stack)(({ theme }) => {
  return {
    width: '56px',
    height: '56px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    background: theme.vars.palette.backgroundDef.main,
    svg: {
      path: {
        fill: theme.vars.palette.blackWhite.main,
      },
    },
  };
});

export const TypographyCSS = styled(Typography)(({ theme }) => ({
  color: theme.vars.palette.silver100White.main,
}));

export const SubtitleBlockCSS = styled(Stack)(() => ({
  height: '34px',
  alignItems: 'center',
  justifyContent: 'end',
}));
