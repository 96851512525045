import { useEffect, useState } from 'react';
import { TIME_UNTIL_MESSAGE_SENT_SECONDS } from '../constants/constants';

interface useChatTimeIntervalProps {
  isPending?: boolean;
}
export const useChatTimeInterval = ({ isPending }: useChatTimeIntervalProps) => {
  const [timer, setTimer] = useState(TIME_UNTIL_MESSAGE_SENT_SECONDS);

  useEffect(() => {
    if (!isPending) return;
    const intervalId = setInterval(() => {
      setTimer((prevState) => {
        const nextTimer = prevState - 1;
        if (nextTimer <= 0) {
          clearInterval(intervalId);
        }
        return nextTimer;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return { timer };
};
