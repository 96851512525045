interface IconsTypes {
  width?: string;
  height?: string;
  color?: string;
  size?: string;
}

export const PlusIcon = ({ width = '16', height = '16', color = '#0E0E0E' }: IconsTypes) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      color='#fff'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.50008 2.66699C8.50008 2.39085 8.27622 2.16699 8.00008 2.16699C7.72394 2.16699 7.50008 2.39085 7.50008 2.66699L7.50008 7.50033L2.66675 7.50033C2.39061 7.50033 2.16675 7.72418 2.16675 8.00033C2.16675 8.27647 2.39061 8.50033 2.66675 8.50033H7.50008L7.50008 13.3337C7.50008 13.6098 7.72394 13.8337 8.00008 13.8337C8.27622 13.8337 8.50008 13.6098 8.50008 13.3337L8.50008 8.50033L13.3334 8.50033C13.6096 8.50033 13.8334 8.27647 13.8334 8.00033C13.8334 7.72418 13.6096 7.50033 13.3334 7.50033L8.50008 7.50033L8.50008 2.66699Z'
        fill={color}
      />
    </svg>
  );
};
