import { IconProps } from './types';

export const SwipeIcon = ({ color = '#E3E3E3' }: IconProps) => {
  return (
    <svg
      width='110'
      height='12'
      viewBox='0 0 110 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 0H110L102.555 0.797661C98.2971 1.25388 94.297 3.06112 91.1402 5.95484C90.3812 6.65058 89.5697 7.28684 88.7131 7.85796L88.4774 8.01507C84.5798 10.6134 80.0004 12 75.3161 12H55H34.6839C29.9997 12 25.4202 10.6134 21.5226 8.01507L21.2869 7.85796C20.4303 7.28684 19.6188 6.65058 18.8598 5.95484C15.703 3.06112 11.7029 1.25388 7.44483 0.79766L0 0Z'
        fill={color}
      />
      <rect x='37' y='4' width='36' height='2' rx='1' fill='#0E0E0E' />
    </svg>
  );
};
