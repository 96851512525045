import { Button, IconButton, Stack } from '@mui/material';
import { DatePickerRHF, SelectRHF, TextFieldRHF } from '../../../UI';
import { OPTIONS_SORT, OPTIONS_USER_ROLE } from '../../../../constants/constants';
import { AddCircleIcon } from '../../../Icons';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { UsersPageFormData } from '../../../../pages';
import { RefreshIcon } from '../../../Icons/RefreshIcon';

interface UsersPageFiltersGroupProps {
  nameSortSelect: keyof UsersPageFormData;
  nameStartDateField: keyof UsersPageFormData;
  nameEndDateField: keyof UsersPageFormData;
  nameRoleSelect: keyof UsersPageFormData;
  nameSearchField: keyof UsersPageFormData;
  methods: UseFormReturn<UsersPageFormData>;
  onReset: () => void;
  onClickAddUsers: () => void;
  startDate?: string | null;
}

export const UsersPageFiltersGroup = ({
  methods,
  nameSortSelect,
  nameStartDateField,
  nameEndDateField,
  nameRoleSelect,
  nameSearchField,
  onReset,
  onClickAddUsers,
  startDate,
}: UsersPageFiltersGroupProps) => {
  return (
    <FormProvider {...methods}>
      <Stack component={'form'} direction={'row'} gap={'26px'} alignItems={'end'} mb={'26px'}>
        <Stack width={'180px'}>
          <SelectRHF name={nameSortSelect} options={OPTIONS_SORT} label={'Sort by:'} />
        </Stack>
        <Stack width={'160px'}>
          <SelectRHF
            name={nameRoleSelect}
            placeholder={'Role'}
            options={OPTIONS_USER_ROLE}
            label={'Role:'}
          />
        </Stack>
        <Stack direction={'row'} gap={'12px'}>
          <DatePickerRHF
            name={nameStartDateField}
            label={'Start Date'}
            placeholder={'Start Date'}
          />
          <DatePickerRHF
            name={nameEndDateField}
            label={'End Date'}
            placeholder={'End Date'}
            minDate={startDate}
          />
        </Stack>
        <Stack width={'240px'}>
          <TextFieldRHF
            name={nameSearchField}
            size={'small'}
            placeholder={'Search'}
            type={'search'}
          />
        </Stack>
        <Stack ml={'auto'} height={'40px'} justifyContent={'center'}>
          <IconButton type={'reset'} size={'medium'} onClick={onReset}>
            <RefreshIcon />
          </IconButton>
        </Stack>
        <Stack>
          <Button
            variant='actionUserButton'
            startIcon={<AddCircleIcon />}
            onClick={onClickAddUsers}
          >
            Add user
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
