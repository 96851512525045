import { HomePageFiltersGroup } from '../HomePageFiltersGroup/HomePageFiltersGroup';
import { TableRowProps, TableList } from '../../../TableList/TableList';
import {
  ADMIN_TABLE_OPTIONS,
  MODAL_NAME,
  OPTIONS_FILTER,
  QUERY_LIMIT,
} from '../../../../constants/constants';
import { useForm } from 'react-hook-form';
import { useDebounce, useFilters, useTriggerByScrollTable, usePagination } from '../../../../hooks';
import {
  ConversationEntity,
  ConversationProviderEnum,
  useFindAndCountManyUsersQuery,
  useGetConversationsQuery,
} from '../../../../graphql/generated';
import { getEndDateFormatTo23H59M, getFormatDateToTable, stringConcat } from '../../../../utils';
import { getIntlPhoneNumber } from '../../../../utils/getIntlPhoneNumber';
import { useModalContext } from '../../../../context';
import { useEffect } from 'react';
import { useFilterContext } from '../../../../context/FilterContext';

const createData = (tableRowData?: ConversationEntity) => {
  const { intlPhone } = getIntlPhoneNumber(tableRowData?.customer.phone);
  return {
    id: tableRowData?.id,
    name: stringConcat([tableRowData?.customer.firstName, tableRowData?.customer.lastName]),
    company: tableRowData?.shop.name,
    phone: intlPhone || '',
    email: tableRowData?.customer.email,
    lockedBy: tableRowData?.assignedUser?.firstName,
    messageReceived: getFormatDateToTable(tableRowData?.lastMessageReceived || ''),
    lastMessage: getFormatDateToTable(tableRowData?.lastMessage || ''),
    control: tableRowData?.provider,
    shop: tableRowData?.shop,
  };
};

export type HomePageFormData = {
  agentId: number[];
  startDate: string | null;
  endDate: string | null;
  search: string;
};

export const HomeSuperadminAndAdminMainContent = () => {
  const { onOpenModal } = useModalContext();

  const { setFilterDate } = useFilterContext();

  const { paginationOptions, handleFetchMore, setPaginationOptions, pending } = usePagination();

  const methods = useForm<HomePageFormData>({
    defaultValues: {
      agentId: [],
      startDate: null,
      endDate: null,
      search: '',
    },
  });
  const { watch, reset } = methods;
  const [agentId, startDate, endDate, searchValue] = watch([
    'agentId',
    'startDate',
    'endDate',
    'search',
  ]);

  const isAllDateFieldFilled = startDate && endDate;
  const debounceInputValue = useDebounce(searchValue);
  const { filterState, onClick: handleFilterClick } = useFilters({
    generalOption: OPTIONS_FILTER[0].id,
  });

  const { data: usersData } = useFindAndCountManyUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        limit: QUERY_LIMIT,
        offset: 0,
      },
    },
  });

  const options = {
    providers: filterState.toString().length ? (filterState as ConversationProviderEnum[]) : null,
    agentIds: agentId.length ? agentId : null,
    search: debounceInputValue,
    ...(isAllDateFieldFilled && {
      dateFrom: startDate,
      dateTo: getEndDateFormatTo23H59M(endDate),
    }),
    limit: paginationOptions.limit,
    offset: paginationOptions.offset,
  };

  const {
    data: conversationsData,
    loading: conversationsLoading,
    fetchMore,
    refetch,
  } = useGetConversationsQuery({
    variables: {
      input: options,
    },
  });

  useEffect(() => {
    refetch({
      input: {
        ...options,
        offset: 0,
      },
    });
    setPaginationOptions((prevState) => ({
      ...prevState,
      offset: 0,
    }));
  }, [isAllDateFieldFilled, debounceInputValue, filterState, agentId, endDate && startDate]);

  useEffect(() => {
    setFilterDate({
      dateFrom: startDate,
      dateTo: getEndDateFormatTo23H59M(endDate),
    });
  }, [isAllDateFieldFilled, endDate && startDate]);

  const rows =
    conversationsData?.getConversations.rows.map((tableRowData) =>
      createData(tableRowData as ConversationEntity),
    ) || [];

  const handleOpen = (row: TableRowProps) => {
    onOpenModal(MODAL_NAME.CHAT, { conversationId: row.id });
  };

  const optionAgentData = usersData?.findAndCountManyUsers.rows.map((user) => {
    return { id: user.id, label: user.firstName, value: user.id };
  });

  const handleReset = () => {
    handleFilterClick('');
    reset();
  };

  const { tableBodyRef } = useTriggerByScrollTable({
    triggerData: [rows],
    skip: pending,
    onAction: () => {
      handleFetchMore({
        fetchMore,
        options,
        dataCount: Number(conversationsData?.getConversations.count),
      });
    },
  });

  return (
    <>
      <HomePageFiltersGroup
        methods={methods}
        nameAgentSelect={'agentId'}
        nameStartDateField={'startDate'}
        nameEndDateField={'endDate'}
        nameSearchField={'search'}
        filterState={filterState}
        onFilterClick={handleFilterClick}
        optionAgentData={optionAgentData}
        onReset={handleReset}
        startDate={startDate}
      />
      <TableList
        isLoading={conversationsLoading}
        headCells={ADMIN_TABLE_OPTIONS}
        rows={rows}
        isHasPagination={false}
        openButtonTitle='Open'
        handleOpen={handleOpen}
        isHasControlBadge={false}
        tableBodyRef={tableBodyRef}
      />
    </>
  );
};
