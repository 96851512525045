import { ButtonCSS, Wrapper } from './styles';
import { DateBlock } from '../DateBlock/DateBlock';
import { CustomerNameAddress } from '../CustomerNameAddress/CustomerNameAddress';
import { LinkIcon } from '../../../Icons/LinkIcon';
import { ModalFormWrapperCSS, ModalWrapper } from '../../../Modal/ModalController/styles';
import { useState } from 'react';
import { AddUserToExistingModal } from '../../../Modal';
import { CustomerEntity } from '../../../../graphql/generated';
import { getCustomerNameAddressList } from '../../../../utils';

interface HeaderProfileProps {
  date?: string;
  time?: string;
  customer?: CustomerEntity;
}

export const HeaderProfile = ({ date, time, customer }: HeaderProfileProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const customerNameAddressList = getCustomerNameAddressList(customer);

  return (
    <Wrapper>
      <DateBlock date={date} time={time} />
      <CustomerNameAddress dataList={customerNameAddressList} />
      <ButtonCSS
        startIcon={<LinkIcon />}
        onClick={handleOpenModal}
        disabled={!customer || !!customer.email}
      >
        Add user to company
      </ButtonCSS>
      <ModalWrapper open={isOpenModal} onClose={handleCloseModal}>
        <ModalFormWrapperCSS>
          <AddUserToExistingModal onClose={handleCloseModal} customerId={Number(customer?.id)} />
        </ModalFormWrapperCSS>
      </ModalWrapper>
    </Wrapper>
  );
};
