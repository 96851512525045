import { RefObject, SyntheticEvent, useEffect, useState } from 'react';
import { ResizeCallbackData } from 'react-resizable';

interface useResizeProps {
  refParentBlock: RefObject<HTMLDivElement>;
}

export const useResize = ({ refParentBlock }: useResizeProps) => {
  const [parentBlock, setParentBlock] = useState(0);
  const [topBlock, setTopBlock] = useState(0);

  const updateHeight = () => {
    const height = refParentBlock.current?.getBoundingClientRect().height;
    setParentBlock(Number(height));
  };

  // UPDATED height parent block by resize
  useEffect(() => {
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const handleTopResize = (e: SyntheticEvent<Element, Event>, { size }: ResizeCallbackData) => {
    setTopBlock(size.height);
  };

  const height = parentBlock - (topBlock + 1);
  const maxHeight = parentBlock - (26 + 10); //10px badge
  return { handleTopResize, height, maxHeight };
};
