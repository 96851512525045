import { ContentBlockTopLabel } from '../ContentBlockTopLabel/ContentBlockTopLabel';
import { CompanyNotesIcon } from '../../../Icons';
import { Stack } from '@mui/material';
import { InnerContentBlockCSS } from '../Main/styles';
import { getFormatTextWithIndents } from '../../../../utils';
import { memo } from 'react';

interface CompanyNotesMainProps {
  notes?: string | null;
  tone?: string | null;
  preferredName?: string;
}
export const CompanyNotesMain = memo(({ notes, tone, preferredName }: CompanyNotesMainProps) => {
  const title = getFormatTextWithIndents({ content: notes || '' });

  return (
    <InnerContentBlockCSS p={'16px'} flex={1}>
      <ContentBlockTopLabel icon={<CompanyNotesIcon />} label={'Company notes'} mb={'26px'} />
      <Stack sx={{ overflow: 'auto' }} gap={'16px'}>
        {preferredName && <Stack>Preferred name: {preferredName}</Stack>}
        {tone && <Stack>Tone: {tone}</Stack>}
        {Array.isArray(title) ? (
          <>
            {title.map((content, index) => (
              <Stack key={index}>{content}</Stack>
            ))}
          </>
        ) : (
          title
        )}
      </Stack>
    </InnerContentBlockCSS>
  );
});
