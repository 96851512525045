import { Stack, styled } from '@mui/material';

export const IconBlockCSS = styled(Stack)(({ theme }) => {
  return {
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    background: theme.vars.palette.silver20.main,
    alignItems: 'center',
    justifyContent: 'center',
  };
});
