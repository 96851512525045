import { Box, BoxProps, Dialog, DialogProps, styled } from '@mui/material';
import { BoolAlias } from '../../../types';

interface WrapperProps extends DialogProps {
  isfullscreenmodal?: BoolAlias;
}

export const ModalWrapper = styled(Dialog)<WrapperProps>(({ theme, isfullscreenmodal }) => {
  const isFullScreenModal = isfullscreenmodal === 'true';
  return {
    '.MuiPaper-root': {
      borderRadius: '32px',
      maxWidth: '100%',
      position: 'relative',
      background: theme.vars.palette.whiteDarkSilver.main,
      ...(isFullScreenModal && {
        width: '100%',
        height: '100%',
        margin: '26px',
        borderRadius: 0,
        maxHeight: 'calc(100% - 52px)',
        background: 'transparent',
        boxShadow: 'none',
        overflow: 'inherit',
      }),
    },
  };
});

interface FormWrapperProps extends BoxProps {
  isfullscreenmodal?: BoolAlias;
}

export const ModalFormWrapperCSS = styled(Box)<FormWrapperProps>(({ isfullscreenmodal }) => {
  const isFullScreenModal = isfullscreenmodal === 'true';

  return {
    display: 'flex',
    padding: '16px',
    ...(isFullScreenModal && {
      width: '100%',
      padding: 0,
      flex: 1,
    }),
  };
});
