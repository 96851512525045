interface IconsTypes {
  width?: string;
  height?: string;
  color?: string;
  size?: string;
}

export const PlayIcon = ({ width = '28', height = '28', color = '#fff' }: IconsTypes) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 19 18'
      color='#fff'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.54979 6.56389C8.60867 6.5573 8.73567 6.56858 9.03769 6.71705C9.33581 6.8636 9.71705 7.09751 10.271 7.43841C10.8246 7.77915 11.2056 8.01433 11.4744 8.2167C11.7432 8.41914 11.8283 8.53822 11.8615 8.61779C11.9628 8.86036 11.9628 9.13964 11.8615 9.38221C11.8283 9.46178 11.7432 9.58086 11.4744 9.78329C11.2056 9.98567 10.8246 10.2208 10.271 10.5616C9.71705 10.9025 9.33581 11.1364 9.03769 11.283C8.73567 11.4314 8.60866 11.4427 8.54979 11.4361C8.33116 11.4116 8.12099 11.2881 7.97866 11.0793C7.9306 11.0088 7.8742 10.8647 7.84341 10.5069C7.813 10.1535 7.8125 9.68007 7.8125 9C7.8125 8.31993 7.813 7.84647 7.84341 7.4931C7.8742 7.13531 7.9306 6.99121 7.97866 6.92069C8.12099 6.71188 8.33116 6.58839 8.54979 6.56389Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.4375 9C1.4375 4.5472 5.0472 0.9375 9.5 0.9375C13.9528 0.9375 17.5625 4.5472 17.5625 9C17.5625 13.4528 13.9528 17.0625 9.5 17.0625C5.0472 17.0625 1.4375 13.4528 1.4375 9ZM10.8356 6.46489C10.3128 6.14316 9.88595 5.88046 9.53401 5.70745C9.18035 5.53359 8.81211 5.40246 8.42454 5.44589C7.86436 5.50865 7.36703 5.82057 7.04906 6.28709C6.83326 6.6037 6.75773 6.98798 6.72255 7.39664C6.68749 7.80407 6.68749 8.32626 6.6875 8.97305V9.02694C6.68749 9.67373 6.68749 10.1959 6.72255 10.6034C6.75772 11.012 6.83326 11.3963 7.04906 11.7129C7.36703 12.1794 7.86436 12.4914 8.42454 12.5541C8.81211 12.5975 9.18035 12.4664 9.53401 12.2926C9.88595 12.1195 10.3128 11.8568 10.8355 11.5351L10.8851 11.5046C11.4084 11.1826 11.8351 10.92 12.1511 10.682C12.472 10.4403 12.7503 10.1734 12.8996 9.8158C13.1168 9.29578 13.1168 8.70422 12.8996 8.1842C12.7503 7.82664 12.472 7.55973 12.1511 7.31805C11.8351 7.08 11.4083 6.8174 10.8851 6.49537L10.8356 6.46489Z'
        fill={color}
      />
    </svg>
  );
};
