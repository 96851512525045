interface IconsTypes {
  width?: string;
  height?: string;
  color?: string;
  size?: string;
}

export const ChatSendIcon = ({ width = '16', height = '14', color = '#025983' }: IconsTypes) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 14'
      color='#fff'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.4907 9.53276C16.2809 8.31867 16.2809 5.68105 14.4907 4.46697L9.35062 0.981211C8.23327 0.223471 6.7738 0.183356 5.61344 0.867289C3.29673 2.23279 3.32152 5.62289 5.694 6.91708C5.75952 6.95282 5.75952 7.0469 5.694 7.08264C3.32152 8.37685 3.29671 11.7669 5.61344 13.1325C6.7738 13.8164 8.23327 13.7763 9.35063 13.0185L14.4907 9.53276Z'
        fill={color}
      />
      <path
        d='M3.3348 7.49988C3.61094 7.49988 3.8348 7.27602 3.8348 6.99988C3.8348 6.72373 3.61094 6.49988 3.3348 6.49988L2.00147 6.49988C1.72532 6.49988 1.50147 6.72373 1.50147 6.99988C1.50147 7.27602 1.72532 7.49988 2.00147 7.49988L3.3348 7.49988Z'
        fill={color}
      />
      <path
        d='M2.50147 4.99988C2.50147 5.27602 2.27761 5.49988 2.00147 5.49988L0.668133 5.49988C0.39199 5.49988 0.168133 5.27602 0.168133 4.99988C0.168133 4.72374 0.39199 4.49988 0.668133 4.49988L2.00147 4.49988C2.27761 4.49988 2.50147 4.72373 2.50147 4.99988Z'
        fill={color}
      />
      <path
        d='M2.00147 9.49988C2.27761 9.49988 2.50147 9.27602 2.50147 8.99988C2.50147 8.72373 2.27761 8.49988 2.00147 8.49988L0.668132 8.49988C0.39199 8.49988 0.168132 8.72373 0.168132 8.99988C0.168132 9.27602 0.39199 9.49988 0.668132 9.49988L2.00147 9.49988Z'
        fill={color}
      />
    </svg>
  );
};
