import { gql } from '@apollo/client';

export const FRAGMENT_ORDER = gql`
  fragment FragmentOrder on OrderResponseSchema {
    id
    link
    email
    phone
    trackingId
    trackingUrl
    status
    financialStatus
    fulfillmentStatus
    shipmentStatus
    total
    netPayment
    item {
      id
      title
      link
      quantity
      price
    }
    shipment {
      address1
      address2
      city
      company
      country
      countryCode
      firstName
      id
      lastName
      phone
      province
      provinceCode
      zip
    }
    itemCursor
  }
`;
