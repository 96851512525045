export const RefreshIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_269_2537)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.00952 0.75251C3.0109 0.441853 2.76019 0.188892 2.44953 0.187506C2.13887 0.18612 1.88591 0.436833 1.88453 0.747491L1.87774 2.26926C1.8752 2.83542 1.87308 3.30762 1.91454 3.68726C1.95802 4.08546 2.05522 4.45246 2.30194 4.77127C2.35536 4.84029 2.41307 4.90576 2.47471 4.9673C2.53557 5.02806 2.60025 5.08498 2.66839 5.13772C2.98721 5.38445 3.3542 5.48164 3.7524 5.52513C4.13204 5.56659 4.60422 5.56447 5.17038 5.56192L6.69217 5.55513C7.00283 5.55375 7.25354 5.30079 7.25216 4.99013C7.25077 4.67947 6.99781 4.42876 6.68715 4.43015L5.19787 4.43679C4.5908 4.4395 4.18304 4.44047 3.87453 4.40678C3.85133 4.40424 3.82913 4.40155 3.80788 4.3987C5.07958 2.96482 6.93413 2.0625 9 2.0625C12.8315 2.0625 15.9375 5.16852 15.9375 9C15.9375 9.31066 16.1893 9.5625 16.5 9.5625C16.8107 9.5625 17.0625 9.31066 17.0625 9C17.0625 4.5472 13.4528 0.9375 9 0.9375C6.63543 0.9375 4.5082 1.95605 3.03416 3.57658L3.03289 3.56513C2.9992 3.25662 3.00016 2.84886 3.00287 2.24179L3.00952 0.75251Z'
          fill='#0E0E0E'
        />
        <path
          d='M2.0625 9C2.0625 8.68934 1.81066 8.4375 1.5 8.4375C1.18934 8.4375 0.9375 8.68934 0.9375 9C0.9375 13.4528 4.5472 17.0625 9 17.0625C11.3383 17.0625 13.4445 16.0665 14.9165 14.4772C14.9402 14.7727 14.9392 15.1558 14.9368 15.6979L14.9302 17.1872C14.9288 17.4978 15.1795 17.7508 15.4901 17.7522C15.8008 17.7536 16.0538 17.5029 16.0551 17.1922L16.0619 15.6704C16.0645 15.1043 16.0666 14.6321 16.0251 14.2524C15.9816 13.8542 15.8845 13.4872 15.6377 13.1684C15.5914 13.1086 15.5419 13.0514 15.4893 12.9971C15.4215 12.927 15.3486 12.8618 15.2713 12.802C14.9525 12.5552 14.5855 12.458 14.1873 12.4146C13.8076 12.3731 13.3354 12.3752 12.7693 12.3778L11.2475 12.3846C10.9368 12.3859 10.6861 12.6389 10.6875 12.9496C10.6889 13.2602 10.9419 13.5109 11.2525 13.5095L12.7418 13.5029C13.3489 13.5002 13.7566 13.4992 14.0651 13.5329C14.1274 13.5397 14.1825 13.5477 14.2314 13.5566C12.9588 15.0164 11.0873 15.9375 9 15.9375C5.16852 15.9375 2.0625 12.8315 2.0625 9Z'
          fill='#0E0E0E'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.00952 0.75251C3.0109 0.441853 2.76019 0.188892 2.44953 0.187506C2.13887 0.18612 1.88591 0.436833 1.88453 0.747491L1.87774 2.26926C1.8752 2.83542 1.87308 3.30762 1.91454 3.68726C1.95802 4.08546 2.05522 4.45246 2.30194 4.77127C2.35536 4.84029 2.41307 4.90576 2.47471 4.9673C2.53557 5.02806 2.60025 5.08498 2.66839 5.13772C2.98721 5.38445 3.3542 5.48164 3.7524 5.52513C4.13204 5.56659 4.60422 5.56447 5.17038 5.56192L6.69217 5.55513C7.00283 5.55375 7.25354 5.30079 7.25216 4.99013C7.25077 4.67947 6.99781 4.42876 6.68715 4.43015L5.19787 4.43679C4.5908 4.4395 4.18304 4.44047 3.87453 4.40678C3.85133 4.40424 3.82913 4.40155 3.80788 4.3987C5.07958 2.96482 6.93413 2.0625 9 2.0625C12.8315 2.0625 15.9375 5.16852 15.9375 9C15.9375 9.31066 16.1893 9.5625 16.5 9.5625C16.8107 9.5625 17.0625 9.31066 17.0625 9C17.0625 4.5472 13.4528 0.9375 9 0.9375C6.63543 0.9375 4.5082 1.95605 3.03416 3.57658L3.03289 3.56513C2.9992 3.25662 3.00016 2.84886 3.00287 2.24179L3.00952 0.75251Z'
          stroke='#0E0E0E'
        />
        <path
          d='M2.0625 9C2.0625 8.68934 1.81066 8.4375 1.5 8.4375C1.18934 8.4375 0.9375 8.68934 0.9375 9C0.9375 13.4528 4.5472 17.0625 9 17.0625C11.3383 17.0625 13.4445 16.0665 14.9165 14.4772C14.9402 14.7727 14.9392 15.1558 14.9368 15.6979L14.9302 17.1872C14.9288 17.4978 15.1795 17.7508 15.4901 17.7522C15.8008 17.7536 16.0538 17.5029 16.0551 17.1922L16.0619 15.6704C16.0645 15.1043 16.0666 14.6321 16.0251 14.2524C15.9816 13.8542 15.8845 13.4872 15.6377 13.1684C15.5914 13.1086 15.5419 13.0514 15.4893 12.9971C15.4215 12.927 15.3486 12.8618 15.2713 12.802C14.9525 12.5552 14.5855 12.458 14.1873 12.4146C13.8076 12.3731 13.3354 12.3752 12.7693 12.3778L11.2475 12.3846C10.9368 12.3859 10.6861 12.6389 10.6875 12.9496C10.6889 13.2602 10.9419 13.5109 11.2525 13.5095L12.7418 13.5029C13.3489 13.5002 13.7566 13.4992 14.0651 13.5329C14.1274 13.5397 14.1825 13.5477 14.2314 13.5566C12.9588 15.0164 11.0873 15.9375 9 15.9375C5.16852 15.9375 2.0625 12.8315 2.0625 9Z'
          stroke='#0E0E0E'
        />
      </g>
      <defs>
        <clipPath id='clip0_269_2537'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
