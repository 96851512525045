interface IconsTypes {
  width?: string;
  height?: string;
  color?: string;
  size?: string;
}

export const AddCircleIcon = ({ width = '14', height = '14', color = '#0C090C' }: IconsTypes) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      color='#fff'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.99998 13.6667C10.6819 13.6667 13.6666 10.6819 13.6666 7C13.6666 3.3181 10.6819 0.333336 6.99998 0.333336C3.31808 0.333336 0.333313 3.3181 0.333313 7C0.333313 10.6819 3.31808 13.6667 6.99998 13.6667ZM7.49998 5C7.49998 4.72386 7.27612 4.5 6.99998 4.5C6.72384 4.5 6.49998 4.72386 6.49998 5V6.5H4.99998C4.72384 6.5 4.49998 6.72386 4.49998 7C4.49998 7.27614 4.72384 7.5 4.99998 7.5H6.49998L6.49998 9C6.49998 9.27614 6.72384 9.5 6.99998 9.5C7.27612 9.5 7.49998 9.27614 7.49998 9L7.49998 7.5L8.99998 7.5C9.27612 7.5 9.49998 7.27614 9.49998 7C9.49998 6.72386 9.27612 6.5 8.99998 6.5L7.49998 6.5V5Z'
        fill={color}
      />
    </svg>
  );
};
