import { ModalHeader } from '../ModalComponents';
import { ButtonCSS, InfoBlockCSS, Wrapper } from './styles';
import { UsersIcon } from '../../Icons';
import { TabsCustom, TextFieldRHF } from '../../UI';
import { FormProvider, useForm } from 'react-hook-form';
import { MODAL_NAME, OPTIONS_USER_MODAL } from '../../../constants/constants';
import { addUserSchema } from '../../../validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useModalContext } from '../../../context';
import {
  useCreateUserMutation,
  useEditUserMutation,
  UserRoleEnum,
} from '../../../graphql/generated';
import { toast } from 'react-toastify';
import { FIND_AND_COUNT_MANY_USERS } from '../../../graphql/queries/findAndCountManyUsers.gql';

type FormData = {
  name: string;
  lastName: string;
  email: string;
  role: UserRoleEnum;
};

export const AddAndEditUserModal = () => {
  const { infoModal, onCloseModal, onOpenModal } = useModalContext();
  const titleModal = infoModal.content?.title;
  const isNewCreateUser = !infoModal.content?.userData?.name || !infoModal.content?.userData?.email;

  const methods = useForm<FormData>({
    defaultValues: {
      name: infoModal.content?.userData?.firstName || '',
      lastName: infoModal.content?.userData?.lastName || '',
      email: infoModal.content?.userData?.email || '',
      role: infoModal.content?.userData?.role || UserRoleEnum.Admin,
    },
    resolver: yupResolver(addUserSchema),
  });

  const [createUser, { loading: createLoading }] = useCreateUserMutation({
    refetchQueries: [FIND_AND_COUNT_MANY_USERS],
    onCompleted: (data) => {
      const userData = data.createUser;
      onOpenModal(MODAL_NAME.SIMPLE, {
        text: `Email address: ${userData.user.email}`,
        ...(userData?.password && { subText: `Password: ${userData.password}` }),
      });
      toast.success('User has been created');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [editUser, { loading: editLoading }] = useEditUserMutation({
    refetchQueries: [FIND_AND_COUNT_MANY_USERS],
    onCompleted: () => {
      toast.success('User has been changed');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const { handleSubmit, setValue, watch } = methods;
  const roleValue = watch('role');

  const setTabValue = (value: string) => {
    setValue('role', value as UserRoleEnum);
  };

  const onSubmitRHF = async (value: FormData) => {
    const fetchValue = {
      firstName: value.name,
      lastName: value.lastName,
      email: value.email,
      role: value.role,
    };

    if (isNewCreateUser) {
      await createUser({
        variables: {
          input: fetchValue,
        },
      });
    } else {
      await editUser({
        variables: {
          input: {
            ...fetchValue,
            id: infoModal?.content?.userData.id,
          },
        },
      });
      onCloseModal();
    }
  };
  return (
    <Wrapper>
      <ModalHeader icon={UsersIcon} title={titleModal} sx={{ marginBottom: '24px' }} />
      <InfoBlockCSS>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitRHF)}>
            <TextFieldRHF
              name={'name'}
              label={'Name'}
              size={'medium'}
              placeholder={'Name'}
              type={'text'}
              mb={2}
            />
            <TextFieldRHF
              name={'lastName'}
              label={'Lastname'}
              size={'medium'}
              placeholder={'Lastname'}
              type={'text'}
              mb={2}
            />
            <TextFieldRHF
              name={'email'}
              label={'Email'}
              size={'medium'}
              placeholder={'Email'}
              type={'text'}
              mb={2}
            />
            <TabsCustom
              options={OPTIONS_USER_MODAL}
              asButtonsGroup={true}
              value={roleValue}
              setValue={setTabValue}
              label={'Role'}
              sx={{ marginBottom: '16px' }}
            />
            <ButtonCSS fullWidth={true} type={'submit'} disabled={createLoading || editLoading}>
              Save
            </ButtonCSS>
          </form>
        </FormProvider>
      </InfoBlockCSS>
    </Wrapper>
  );
};
