import { Stack, Typography } from '@mui/material';
import { BadgeStatus } from '../../../UI/BadgeStatus/BadgeStatus';

type OrderRowItemProps = {
  label: string;
  data?: string | null;
  isBadge?: boolean;
};

export const OrderRowItem = ({ data, label, isBadge }: OrderRowItemProps) => {
  if (!data) return <div />;
  return (
    <Stack gap={'2px'}>
      <Typography variant='caption' color={'lightSilverWhite.main'}>
        {label}
      </Typography>
      {isBadge ? <BadgeStatus status={data} /> : <Typography variant='body4'>{data}</Typography>}
    </Stack>
  );
};
