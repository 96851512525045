import { InfoFieldCSS, InfoFieldLabelCSS, InfoRowCSS } from './styles';
import { List, ListItem, Typography } from '@mui/material';
import { getFormatTextWithIndents } from '../../utils';

export type InfoFieldListOption = { title: string; content: string };

interface InfoFieldsListProps {
  infoFieldsList: InfoFieldListOption[];
}
export const InfoFieldsList = ({ infoFieldsList }: InfoFieldsListProps) => {
  return (
    <>
      {infoFieldsList?.map((item, index) => {
        const title = getFormatTextWithIndents(item);

        return (
          <InfoRowCSS key={index}>
            <InfoFieldCSS>
              <InfoFieldLabelCSS variant='caption'>{item.title}</InfoFieldLabelCSS>
              <Typography variant='body1' component='div'>
                {Array.isArray(title) ? (
                  <List>
                    {title.map((content, index) => (
                      <ListItem key={index} disablePadding>
                        {content}
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  title
                )}
              </Typography>
            </InfoFieldCSS>
          </InfoRowCSS>
        );
      })}
    </>
  );
};

//console.log(item.content.split('\n'));
