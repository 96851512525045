import { Box, Button, IconButton, ListItem, Stack, styled } from '@mui/material';

export const CompanyTemplatesWrapper = styled(Stack)({
  overflow: 'hidden',
});

export const AddNewBlockCSS = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '90px',
  padding: 0,
  border: `1px dashed ${theme.vars.palette.blackWhite.main}`,
  borderRadius: '36px',
}));

export const ListItemCSS = styled(ListItem)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: '100px',
    padding: '26px',
    border: `1px solid ${theme.vars.palette.silver.main}`,
    borderRadius: '36px',
  };
});

export const AddNewButtonCSS = styled(Button)(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '90px',
    color: theme.vars.palette.blackWhite.main,
    borderRadius: '36px',
    backgroundColor: 'transparent',
    boxShadow: 'none',

    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
    },

    '& svg': {
      marginRight: '6px',

      '& path': {
        fill: theme.vars.palette.blackWhite.main,
      },
    },
  };
});

export const TopInfoCSS = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: '16px',
  textTransform: 'uppercase',
});

export const ButtonsBlockCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const TemplateButtonCSS = styled(IconButton)(({ theme }) => ({
  '& svg path': {
    fill: theme.vars.palette.primary.main,
  },
}));
