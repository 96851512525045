import { ITableOption } from '../TableList';
import { TableCellCSS, TableHeadCSS, TableRowCSS } from '../styles';
import Typography from '@mui/material/Typography';

interface ITableListHeader {
  headCells: Array<ITableOption>;
}

export const TableListHeader = ({ headCells }: ITableListHeader) => {
  return (
    <TableHeadCSS>
      <TableRowCSS>
        {headCells.map((column) => (
          <TableCellCSS key={column.id} width={column.minWidth}>
            <Typography variant='body2'>{column.label}</Typography>
          </TableCellCSS>
        ))}
      </TableRowCSS>
    </TableHeadCSS>
  );
};
