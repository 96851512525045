import { useModalContext } from '../../../context';
import { Button, Stack, Typography } from '@mui/material';
import { Wrapper } from '../AreYouSureModal/styles';

export const SimpleModal = () => {
  const { infoModal, onCloseModal } = useModalContext();
  const text = infoModal.content?.text;
  const subText = infoModal.content?.subText;

  const handleClose = () => {
    onCloseModal();
  };

  return (
    <Wrapper>
      <Stack justifyContent={'center'} alignItems={'center'} gap={'24px'}>
        <Stack gap={'6px'} alignItems={!subText ? 'center' : 'start'}>
          <Typography variant={'lg'} lineHeight={'100%'}>
            {text}
          </Typography>
          {subText && (
            <Typography variant={'lg'} lineHeight={'100%'}>
              {subText}
            </Typography>
          )}
        </Stack>
        <Stack direction={'row'} gap={'16px'}>
          <Button onClick={handleClose} variant={'actionUserButton'}>
            OK
          </Button>
        </Stack>
      </Stack>
    </Wrapper>
  );
};
