import { createContext, MutableRefObject, ReactNode, useContext, useRef, useState } from 'react';
import { TemplateRowItemProps } from '../components/Containers/ToChatsPage/TemplateRowItem/types';

interface ChatProviderProps {
  children: ReactNode;
}

interface ChatContextValue {
  textMessage: string;
  onTypingMessage: (str: string) => void;
  onClickToTemplate: (data: TemplateRowItemProps) => void;
  textAreaRef: MutableRefObject<HTMLInputElement | null>;
}

export const ChatContext = createContext<ChatContextValue>({
  textMessage: '',
  onTypingMessage: () => {},
  onClickToTemplate: () => {},
  textAreaRef: { current: null },
});

export function useChatContext() {
  const chatContext = useContext(ChatContext);

  if (!chatContext) {
    throw new Error('useChatContext must be used within a AlertProvider');
  }
  return chatContext;
}

export const ChatProvider = ({ children }: ChatProviderProps) => {
  const [textMessage, setTextMessage] = useState('');
  const textAreaRef = useRef<HTMLInputElement>(null);

  const handleTypingMessage = (str: string) => {
    setTextMessage(str);
  };

  const handleClickToTemplate = (data: TemplateRowItemProps) => {
    const inputElement = textAreaRef.current;

    if (inputElement) {
      // Получаем текущую позицию каретки
      const caretPosition = Number(inputElement.selectionStart);

      // Разбиваем текст на две части - до и после текущей позиции каретки
      const textBeforeCaret = textMessage.slice(0, caretPosition);
      const textAfterCaret = textMessage.slice(caretPosition);

      // Обновляем текст с добавлением выбранного текста
      setTextMessage(textBeforeCaret + data.text + textAfterCaret);

      // Добавляем небольшую задержку перед установкой новой позиции каретки
      setTimeout(() => {
        // Устанавливаем новую позицию каретки после вставки текста
        const newPosition = caretPosition + data.text.length;
        inputElement.setSelectionRange(newPosition, newPosition);
        inputElement.focus();
      }, 0);
    }
  };

  const value = {
    textMessage,
    onTypingMessage: handleTypingMessage,
    onClickToTemplate: handleClickToTemplate,
    textAreaRef,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
