import { Button, Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => {
  return {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px',
    borderRadius: '1000px',
    gap: '12px',
    background: theme.vars.palette.white.main,
  };
});

export const ButtonCSS = styled(Button)(({ theme }) => {
  return {
    height: '44px',
    padding: '0 18px 0 16px',
    whiteSpace: 'nowrap',
    '.MuiButton-startIcon': { marginRight: '10px' },
    '&:disabled': {
      color: theme.vars.palette.silver100.main + '!important',
      background: theme.vars.palette.silver20.main,
    },
  };
});
