import { ApolloClient, ApolloLink, InMemoryCache, ServerParseError, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { create } from 'zustand';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { AUTH_TOKEN } from '../constants/constants';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import {
  paginationMergeGetConversations,
  paginationMergeGetShops,
  paginationMergeFindAndCountManyUsers,
  paginationMergeGetMetrics,
} from './paginationMergeFunctions';

type ErrorType = {
  hasError: boolean;
  error: any;
  date: number | undefined | null;
};

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${process.env.REACT_APP_API_HOST_WSS}/subscriptions`,
    connectionParams: () => {
      const token = localStorage.getItem(AUTH_TOKEN);
      return {
        Authorization: `Bearer ${token}`,
      };
    },
  }),
);

export const useErrorsStore = create<ErrorType>(() => ({
  hasError: false,
  error: null,
  date: null,
}));

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError || graphQLErrors?.[0]) {
    useErrorsStore.setState({
      hasError: true,
      error: networkError || graphQLErrors?.[0],
      date: Date.now(),
    });
  }

  if (networkError && (networkError as ServerParseError)?.statusCode === 401) {
    localStorage.removeItem(AUTH_TOKEN);
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, splitLink]),
  cache: new InMemoryCache({
    addTypename: true,
    typePolicies: {
      Query: {
        fields: {
          ...paginationMergeGetConversations(false),
          ...paginationMergeGetShops(false),
          ...paginationMergeFindAndCountManyUsers(false),
          ...paginationMergeGetMetrics(false),
        },
      },
    },
  }),
});
