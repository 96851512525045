import { TextFieldCustom } from '../../../UI';
import { TemplateRowItem } from '../TemplateRowItem/TemplateRowItem';
import { ChangeEvent } from 'react';
import { TemplateSchema } from '../../../../graphql/generated';

interface ChatTemplateListProps {
  templateData?: TemplateSchema[];
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export const ChatTemplateList = ({ templateData, value, onSearch }: ChatTemplateListProps) => {
  return (
    <>
      <TextFieldCustom
        value={value}
        onChange={onSearch}
        size={'medium'}
        placeholder={'Search Template'}
        type={'search'}
      />
      {templateData?.map((template) => {
        return (
          <TemplateRowItem
            key={template.id}
            id={template.id}
            text={template.text}
            shortcut={template.shortcut}
          />
        );
      })}
    </>
  );
};
