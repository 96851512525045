import { useModalContext } from '../../../context';

export const TestModal = () => {
  const { infoModal } = useModalContext();

  return (
    <div>
      <div>{infoModal?.content?.test_value}</div>
      <button
        onClick={() => {
          infoModal?.content?.onCallBack();
        }}
      >
        action
      </button>
      <div>123</div>
      <div>123</div>
      <div>123</div> <div>123</div>
      <div>123</div>
      <div>123</div> <div>123</div>
      <div>123</div>
      <div>123</div> <div>123</div>
      <div>123</div>
      <div>123</div> <div>123</div>
      <div>123</div>
      <div>123</div> <div>123</div>
      <div>123</div>
      <div>123</div> <div>123</div>
      <div>123</div>
      <div>
        Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem
        Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem
        Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem Lorem{' '}
      </div>
    </div>
  );
};
