interface IconsTypes {
  width?: string;
  height?: string;
  color?: string;
  size?: string;
}

export const UserMessageIcon = ({ width = '16', height = '16', color = '#0C090C' }: IconsTypes) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      color='#fff'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00016 0.833252C6.25126 0.833252 4.8335 2.25102 4.8335 3.99992C4.8335 5.74882 6.25126 7.16659 8.00016 7.16659C9.74906 7.16659 11.1668 5.74882 11.1668 3.99992C11.1668 2.25102 9.74906 0.833252 8.00016 0.833252ZM5.8335 3.99992C5.8335 2.8033 6.80355 1.83325 8.00016 1.83325C9.19678 1.83325 10.1668 2.8033 10.1668 3.99992C10.1668 5.19654 9.19678 6.16659 8.00016 6.16659C6.80355 6.16659 5.8335 5.19654 5.8335 3.99992Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.00016 8.16659C4.25126 8.16659 2.8335 9.58435 2.8335 11.3333C2.8335 13.0822 4.25126 14.4999 6.00016 14.4999H10.0002C11.7491 14.4999 13.1668 13.0822 13.1668 11.3333C13.1668 9.58435 11.7491 8.16659 10.0002 8.16659H6.00016ZM3.8335 11.3333C3.8335 10.1366 4.80355 9.16659 6.00016 9.16659H10.0002C11.1968 9.16659 12.1668 10.1366 12.1668 11.3333C12.1668 12.5299 11.1968 13.4999 10.0002 13.4999H6.00016C4.80355 13.4999 3.8335 12.5299 3.8335 11.3333Z'
        fill={color}
      />
    </svg>
  );
};
