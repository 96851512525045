import { Button, Stack, Typography } from '@mui/material';
import { ModalHeader } from '../ModalComponents';
import { useModalContext } from '../../../context';
import { TypographyCSS, Wrapper } from './styles';

export const AreYouSureModal = () => {
  const { infoModal, onCloseModal } = useModalContext();
  const { callback, title, labelBtnLeft, labelBtnRight, subTitle } = infoModal.content;

  return (
    <Wrapper>
      <ModalHeader />
      <Stack justifyContent={'center'} alignItems={'center'} gap={'24px'}>
        <Stack gap={'6px'} alignItems={'center'}>
          <Typography variant={'lg'}>{title}</Typography>
          {subTitle && <TypographyCSS variant={'body5'}>{subTitle}</TypographyCSS>}
        </Stack>
        <Stack direction={'row'} gap={'16px'}>
          <Button onClick={onCloseModal}>{labelBtnLeft}</Button>
          <Button typeof={'support'} onClick={callback}>
            {labelBtnRight}
          </Button>
        </Stack>
      </Stack>
    </Wrapper>
  );
};
