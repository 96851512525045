import { Wrapper } from './styles';
import { Divider, Stack, Typography } from '@mui/material';
import { usePalette } from '../../../../hooks';
import { Fragment } from 'react';

export interface CustomerOption {
  label: string;
  title: string;
}

interface CustomerNameAddressProps {
  dataList: CustomerOption[];
}

export const CustomerNameAddress = ({ dataList }: CustomerNameAddressProps) => {
  return (
    <Wrapper>
      {dataList?.map(({ title, label }, i) => {
        return (
          <Fragment key={label}>
            <NameBlock title={title} label={label} />
            {i < dataList.length - 1 && <Divider orientation={'vertical'} />}
          </Fragment>
        );
      })}
    </Wrapper>
  );
};

export const NameBlock = ({ label, title }: CustomerOption) => {
  const { palette } = usePalette();
  return (
    <Stack gap={'4px'}>
      <Typography variant={'sm2'} color={palette.silver100.main}>
        {label}
      </Typography>
      <Typography variant={'sm3'} color={palette.black.main}>
        {title}
      </Typography>
    </Stack>
  );
};
