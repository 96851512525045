import { FC } from 'react';

export enum InfoBlockIdEnum {
  Time = 'time',
  Message = 'message',
}

export interface InfoBlockProps {
  id: InfoBlockIdEnum;
  icon: FC;
  title: string | number;
  subTitle: string | number;
}
