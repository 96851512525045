import { CSSProperties } from 'react';

export const typography = {
  fontFamily: 'Gilroy, sans-serif',

  h1: {
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '64px',
  },
  h2: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '36px',
  },
  h3: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '22px',
  },
  subtitle2: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  body1: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  body3: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '18px',
  },
  caption: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
  },
  body4: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  body5: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  body6: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  body7: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  body8: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  lg: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '28px',
  },
  big: {
    fontSize: '64px',
    fontWeight: 400,
    lineHeight: '68px',
  },
  big2: {
    fontSize: '48px',
    fontWeight: 500,
    lineHeight: '48px',
  },
  sm: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '12px',
  },
  sm2: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '12px',
  },
  sm3: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '14px',
  },
  sm4: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '12px',
  },
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1: CSSProperties;
    h2: CSSProperties;
    h3: CSSProperties;
    body1: CSSProperties;
    caption: CSSProperties;
    body4: CSSProperties;
    lg: CSSProperties;
    big: CSSProperties;
    big2: CSSProperties;
    body3: CSSProperties;
    body5: CSSProperties;
    sm: CSSProperties;
    sm2: CSSProperties;
    sm3: CSSProperties;
    body6: CSSProperties;
    body7: CSSProperties;
    sm4: CSSProperties;
    body8: CSSProperties;
  }
  interface TypographyVariantsOptions {
    h1: CSSProperties;
    h2: CSSProperties;
    h3: CSSProperties;
    body1: CSSProperties;
    caption: CSSProperties;
    body4: CSSProperties;
    lg: CSSProperties;
    big: CSSProperties;
    big2: CSSProperties;
    body3: CSSProperties;
    body5: CSSProperties;
    sm: CSSProperties;
    sm2: CSSProperties;
    sm3: CSSProperties;
    body6: CSSProperties;
    body7: CSSProperties;
    sm4: CSSProperties;
    body8: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    body1: true;
    caption: true;
    body4: true;
    lg: true;
    big: true;
    big2: true;
    body3: true;
    body5: true;
    sm: true;
    sm2: true;
    sm3: true;
    body6: true;
    body7: true;
    sm4: true;
    body8: true;
  }
}
