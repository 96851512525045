import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => {
  return {
    position: 'absolute',
    width: '100%',
    top: '78px',
    padding: '16px 4px 16px 16px',
    borderRadius: '24px',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
    background: theme.vars.palette.white.main,
    zIndex: '10',
    maxHeight: '256px',

    '::-webkit-scrollbar-thumb': {
      boxShadow: `inset 0 0 20px 20px ${theme.vars.palette.black.main}`,
      border: 'solid 6px transparent',
      borderRadius: '100px',
    },
  };
});

export const InnerWrapperCSS = styled(Stack)(({ theme }) => {
  return {
    width: '100%',
    overflow: 'auto',
    gap: '16px',
    '::-webkit-scrollbar-thumb': {
      boxShadow: `inset 0 0 20px 20px ${theme.vars.palette.black.main}`,
      border: 'solid 6px transparent',
      borderRadius: '100px',
    },
  };
});
