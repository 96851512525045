import { useEffect } from 'react';
import { client } from '../graphql/client';
import { FRAGMENT_MESSAGE } from '../graphql/fragments';
import { useSubscriptionSubscription } from '../graphql/generated';

interface useChatSubscriptionProps {
  conversationId?: number;
}

export const useChatSubscription = ({ conversationId }: useChatSubscriptionProps) => {
  const { data: subscribeData, loading } = useSubscriptionSubscription({
    variables: {
      conversationId: Number(conversationId),
    },
    skip: !conversationId,
  });

  useEffect(() => {
    if (subscribeData && subscribeData.chatMessage) {
      const cache = client.cache;
      const newMessage = subscribeData.chatMessage;
      cache.modify({
        id: cache.identify({ __typename: 'ConversationEntity', id: Number(conversationId) }),
        fields: {
          messages(existingMessages = []) {
            const newMessageRef = cache.writeFragment({
              data: newMessage,
              fragment: FRAGMENT_MESSAGE,
            });
            return [...existingMessages, newMessageRef];
          },
        },
      });
    }
  }, [subscribeData, conversationId, client.cache]);
  return { subscribeData, loading };
};
