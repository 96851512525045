import { FormProvider, UseFormReturn } from 'react-hook-form';
import { IconButton, Stack } from '@mui/material';
import {
  DatePickerRHF,
  FilterButtonsGroup,
  OptionType,
  SelectRHF,
  TextFieldRHF,
} from '../../../UI';
import { OPTIONS_FILTER } from '../../../../constants/constants';
import { RefreshIcon } from '../../../Icons/RefreshIcon';
import { ConversationProviderEnum } from '../../../../graphql/generated';
import { HomePageFormData } from '../HomeSuperadminAndAdminMainContent/HomeSuperadminAndAdminMainContent';

interface HomePageFiltersGroupProps {
  filterState: string[];
  onFilterClick: (id: string | ConversationProviderEnum) => void;
  optionAgentData?: OptionType[];
  nameAgentSelect: keyof HomePageFormData;
  nameSearchField: keyof HomePageFormData;
  methods: UseFormReturn<HomePageFormData>;
  onReset: () => void;
  nameStartDateField: string;
  nameEndDateField: string;
  startDate?: string | null;
}

export const HomePageFiltersGroup = ({
  filterState,
  onFilterClick,
  optionAgentData,
  nameAgentSelect,
  nameStartDateField,
  nameEndDateField,
  nameSearchField,
  methods,
  onReset,
  startDate,
}: HomePageFiltersGroupProps) => {
  return (
    <FormProvider {...methods}>
      <Stack component={'form'} gap={'26px'} direction={'row'} alignItems={'end'} mb={'26px'}>
        <FilterButtonsGroup
          optionsFilter={OPTIONS_FILTER}
          onClick={onFilterClick}
          activeFilter={filterState}
          label={'Channel:'}
        />
        <Stack width={'180px'}>
          <SelectRHF
            name={nameAgentSelect}
            label={'Agent:'}
            placeholder={'Agent'}
            options={optionAgentData || []}
            multiple
          />
        </Stack>
        <Stack direction={'row'} gap={'12px'}>
          <DatePickerRHF
            name={nameStartDateField}
            label={'Start Date'}
            placeholder={'Start Date'}
          />
          <DatePickerRHF
            name={nameEndDateField}
            label={'End Date'}
            placeholder={'End Date'}
            minDate={startDate}
          />
        </Stack>
        <Stack width={'240px'}>
          <TextFieldRHF
            name={nameSearchField}
            size={'small'}
            placeholder={'Search'}
            type={'search'}
          />
        </Stack>
        <Stack ml={'auto'}>
          <IconButton type={'reset'} size={'medium'} onClick={onReset}>
            <RefreshIcon />
          </IconButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
