import { Wrapper } from './styles';
import { useState } from 'react';
import { ChatModalInfoSchema } from './types';
import { usePalette, useTag } from '../../../../hooks';
import { ConversationEntity } from '../../../../graphql/generated';
import { getFormatDate } from '../../../../utils';
import { RequestHandler } from '../../../RequestHandler/RequestHandler';
import { ChatContent } from '../ChatContent/ChatContent';
import { ChatHeader } from '../ChatHeader/ChatHeader';
import { useModalContext } from '../../../../context';
import { ApolloError } from '@apollo/client';

interface ChatGeneralProps {
  isPage?: boolean;
  data?: ConversationEntity;
  loading: boolean;
  error?: ApolloError;
}
export const ChatGeneral = ({ isPage, data, loading, error }: ChatGeneralProps) => {
  const { palette } = usePalette();
  const { onCloseModal, infoModal } = useModalContext();

  const generalData = data;
  const provider = generalData?.provider;
  const shopData = generalData?.shop;
  const conversationId = Number(generalData?.id);

  const { tag, changeTag, optionsSelect } = useTag({
    conversationData: generalData as ConversationEntity,
    conversationId: conversationId,
  });

  const chatModalInfo: ChatModalInfoSchema = {
    conversationId: conversationId,
    provider,
    shopData,
  };

  const [isEditOrder, setIsEditOrder] = useState(false);
  const [orderId, setOrderId] = useState('');

  const handleSetIsEditOrder = (orderId?: string | number) => {
    setIsEditOrder((prevState) => !prevState);
    setOrderId(String(orderId));
  };

  const profileCreatedAt = generalData?.createdAt;

  const createdDate = getFormatDate({ timestamp: profileCreatedAt, format: 'DD/MM/YYYY' });
  const createdTime = getFormatDate({ timestamp: profileCreatedAt, format: 'HH:mm' });

  return (
    <RequestHandler
      variantSize='big'
      loading={loading}
      color={palette.white.main}
      error={error}
      {...(infoModal.open && { onCloseError: onCloseModal })}
    >
      <Wrapper ispage={String(isPage)}>
        <ChatHeader
          tag={tag}
          changeTag={changeTag}
          optionsTag={optionsSelect}
          profileTime={createdTime}
          profileDate={createdDate}
          chatModalInfo={chatModalInfo}
          isEditOrder={isEditOrder}
          onEditOrder={handleSetIsEditOrder}
          customer={generalData?.customer}
          subjectText={generalData?.subject}
          isPage={isPage}
          conversationId={conversationId}
        />
        <ChatContent
          allData={generalData}
          isEditOrder={isEditOrder}
          onEditOrder={handleSetIsEditOrder}
          isPage={isPage}
          orderId={orderId}
        />
      </Wrapper>
    </RequestHandler>
  );
};
