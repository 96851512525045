import { Button, Typography } from '@mui/material';
import { InnerBlockCSS, Wrapper } from './styles';
import { useAuthContext } from '../../context';

export const Welcome = () => {
  const { signIn } = useAuthContext();

  return (
    <>
      <Wrapper>
        <InnerBlockCSS>
          <Typography variant='h1' component='h1'>
            Welcome!
          </Typography>
          <Typography variant='h2' paragraph={true}>
            Log in to Superhumans to continue
          </Typography>
          <Button variant={'signin'} onClick={signIn}>
            Log In
          </Button>
        </InnerBlockCSS>
      </Wrapper>
    </>
  );
};
