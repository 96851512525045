import { gql } from '@apollo/client';
import { FRAGMENT_ORDER } from '../fragments';

export const GET_ORDER = gql`
  ${FRAGMENT_ORDER}
  query GetOrder($input: GetOrderByIdInput!) {
    getOrder(input: $input) {
      ...FragmentOrder
    }
  }
`;
