import { Button, Stack } from '@mui/material';
import { Wrapper } from './OrderRow.styled';
import { PencilIcon } from '../../../Icons';
import { OrderRowItem } from '../OrderRowItem/OrderRowItem';

interface OrderRowProps {
  itemId: string | number;
  onEdit: (id?: string | number) => void;
  orderId: string;
  email: string;
  fulfillmentStatus: string;
  financialStatus?: string | null;
}

export const OrderRow = ({
  itemId,
  onEdit,
  orderId,
  email,
  fulfillmentStatus,
  financialStatus,
}: OrderRowProps) => {
  const data = [
    { label: 'Order ID', data: orderId },
    { label: 'Email', data: email },
    { label: 'Financial Status', data: financialStatus, isBadge: true },
    { label: 'Fulfillment Status', data: fulfillmentStatus, isBadge: true },
  ];
  return (
    <Wrapper>
      {data.map((item, i) => {
        return <OrderRowItem {...item} key={i} />;
      })}
      <Stack>
        <Button
          onClick={() => onEdit(itemId)}
          variant='edit'
          size='small'
          startIcon={<PencilIcon />}
        >
          Edit
        </Button>
      </Stack>
    </Wrapper>
  );
};
