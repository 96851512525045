import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import React, { ReactNode, SyntheticEvent, useEffect } from 'react';
import { SwipeIcon } from '../Icons';
import { usePalette } from '../../hooks';

interface ResizableBlockProps {
  children: ReactNode;
  maxHeight?: number;
  height?: number;
  onResize?: (e: SyntheticEvent<Element, Event>, data: ResizeCallbackData) => void;
}

export const ResizableBlock = ({
  children,
  maxHeight,
  height = 100,
  onResize,
}: ResizableBlockProps) => {
  const { palette } = usePalette();
  useEffect(() => {
    if (onResize) {
      const syntheticEvent = {} as SyntheticEvent;
      const resizeData = {
        size: { width: 0, height },
        node: null as unknown as HTMLElement,
        handle: 's' as const,
      };
      onResize(syntheticEvent, resizeData);
    }
  }, []);
  return (
    <ResizableBox
      height={height}
      axis='y'
      resizeHandles={['s']}
      minConstraints={[1, 0]}
      {...(maxHeight && {
        maxConstraints: [1, maxHeight],
      })}
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
      onResize={onResize}
      handle={
        <span
          style={{
            position: 'absolute',
            height: '15px',
            width: 'fit-content',
            cursor: 'row-resize',
            bottom: '-20px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <SwipeIcon color={palette.silver20silver200.main} />
        </span>
      }
    >
      {children}
    </ResizableBox>
  );
};
