import { gql } from '@apollo/client';

export const GET_SHOP = gql`
  query GetShop($getShopId: Int!, $search: String) {
    getShop(id: $getShopId) {
      id
      name
      email
      phone
      smsAvailable
      emailAvailable
      liveChatAvailable
      tone
      agentFirstName
      notes
      deactivated
      deactivatedAt
      url
      domainId
      createdAt
      templates(search: $search) {
        id
        name
        text
        shortcut
        type
      }
    }
  }
`;
