interface IconsTypes {
  width?: string;
  height?: string;
  color?: string;
  size?: string;
}

export const AttachmentIcon = ({ width = '24', height = '24', color = '#FFFFFF' }: IconsTypes) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      color='#fff'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.7084 3.05518C15.323 2.74964 16.0454 2.65873 16.7604 2.85032C18.3163 3.26723 19.2397 4.86655 18.8228 6.42249L16.4503 15.2768C16.0334 16.8328 14.434 17.7561 12.8781 17.3392C11.3222 16.9223 10.3988 15.323 10.8157 13.767L11.7647 10.2253C11.8719 9.8252 11.6345 9.41395 11.2344 9.30674C10.8343 9.19954 10.423 9.43698 10.3158 9.83708L9.36682 13.3788C8.7355 15.7349 10.1337 18.1568 12.4899 18.7881C14.846 19.4194 17.2678 18.0212 17.8992 15.665L20.2717 6.81072C20.903 4.45458 19.5048 2.03275 17.1486 1.40143C16.0672 1.11165 14.9701 1.24999 14.0407 1.71198C13.4708 1.99527 12.9628 2.4007 12.5583 2.90504C12.2991 3.22815 12.3509 3.70019 12.6741 3.95936C12.9972 4.21853 13.4692 4.1667 13.7284 3.84358C13.9949 3.51125 14.3307 3.24292 14.7084 3.05518Z'
        fill={color}
      />
      <path
        d='M8.22299 9.2763C8.6399 7.72036 10.2392 6.79699 11.7952 7.21391C13.3511 7.63082 14.2745 9.23013 13.8576 10.7861L12.9086 14.3278C12.8013 14.7279 13.0388 15.1392 13.4389 15.2464C13.839 15.3536 14.2502 15.1161 14.3574 14.716L15.3064 11.1743C15.9378 8.81816 14.5395 6.39634 12.1834 5.76502C9.82725 5.13369 7.40542 6.53193 6.7741 8.88807L4.40159 17.7424C3.77026 20.0985 5.1685 22.5204 7.52465 23.1517C8.6061 23.4415 9.70319 23.3031 10.6326 22.8411C11.2024 22.5578 11.7105 22.1524 12.115 21.6481C12.3742 21.325 12.3223 20.8529 11.9992 20.5937C11.6761 20.3346 11.2041 20.3864 10.9449 20.7095C10.6783 21.0419 10.3425 21.3102 9.96485 21.4979C9.35022 21.8035 8.62787 21.8944 7.91287 21.7028C6.35693 21.2859 5.43357 19.6866 5.85048 18.1306L8.22299 9.2763Z'
        fill={color}
      />
    </svg>
  );
};
