import { Stack } from '@mui/material';
import { ResizableBlock } from '../../../../ResizableBlock/ResizableBlock';
import { MainContentHeader } from '../../MainContentHeader/MainContentHeader';
import { OPTIONS_CHAT_TABS, OptionsChatTabsEnum } from '../../../../../constants/constants';
import {
  BottomBlockWrapperCSS,
  InnerContentBlockCSS,
  TransparentDividerCSS,
  Wrapper,
} from '../styles';
import { ContentBlockTopLabel } from '../../ContentBlockTopLabel/ContentBlockTopLabel';
import { ChatIcon } from '../../../../Icons';
import { useMemo, useRef, useState } from 'react';
import { useResize } from '../../../../../hooks';
import { Chat } from '../../../../Chat/Chat';
import { ConversationEntity } from '../../../../../graphql/generated';
import { OrderList } from '../../OrderList/OrderList';
import { useHotkeys } from 'react-hotkeys-hook';

interface LeftContentBlockProps {
  onEditOrder: (id?: string | number) => void;
  allData?: ConversationEntity;
}

export const LeftContentBlock = ({ onEditOrder, allData }: LeftContentBlockProps) => {
  const targetTab = localStorage.getItem('OrderTab') as OptionsChatTabsEnum;
  const [resizebleHeight, setResizebleHeight] = useState(0);
  useHotkeys('c', () => {
    if (!resizebleHeight) {
      setResizebleHeight(295);
    } else {
      setResizebleHeight(0);
    }
  });

  const [tabValue, setTabValue] = useState<OptionsChatTabsEnum>(
    targetTab || OPTIONS_CHAT_TABS[0].id,
  );

  const refParentBlock = useRef<HTMLDivElement>(null);
  const { maxHeight, height, handleTopResize } = useResize({ refParentBlock });

  const memoizedChatIcon = useMemo(() => <ChatIcon />, []);

  const handleTabsClick = (str: string) => {
    setTabValue(str as OptionsChatTabsEnum);
    localStorage.setItem('OrderTab', str);
  };

  return (
    <Wrapper ref={refParentBlock}>
      <Stack sx={{ position: 'relative' }}>
        <ResizableBlock onResize={handleTopResize} height={resizebleHeight} maxHeight={maxHeight}>
          <>
            <MainContentHeader
              label={'Orders'}
              value={tabValue}
              setValue={handleTabsClick}
              options={OPTIONS_CHAT_TABS}
            />
            <InnerContentBlockCSS p={'16px'} flex={1}>
              <Stack sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                <OrderList
                  conversationId={Number(allData?.id)}
                  onEditOrder={onEditOrder}
                  tabValue={tabValue as any}
                />
              </Stack>
            </InnerContentBlockCSS>
          </>
        </ResizableBlock>
        <TransparentDividerCSS />
      </Stack>
      <BottomBlockWrapperCSS style={{ minHeight: `${height - resizebleHeight}px` }}>
        <ContentBlockTopLabel icon={memoizedChatIcon} label={'Chat'} />
        <Chat allData={allData} />
      </BottomBlockWrapperCSS>
    </Wrapper>
  );
};
