import { LogoWrapper } from './styles';
import { LogoIcon } from '../../Icons/LogoIcon';
import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../constants/constants';

export const Logo = () => {
  const theme = useTheme();
  const color = theme.vars.palette.primary.main;
  return (
    <LogoWrapper>
      <Link to={ROUTES.home}>
        <LogoIcon color={color} />
      </Link>
    </LogoWrapper>
  );
};
