import { Stack, StackProps, styled } from '@mui/material';

interface Wrapper extends StackProps {
  ispage?: string;
  iseditorder?: string;
}

export const Wrapper = styled(Stack)<Wrapper>(({ theme, ispage, iseditorder }) => {
  const isPage = ispage === 'true';
  const isEditOrder = iseditorder === 'true';

  return {
    flexDirection: 'row',
    flex: 1,
    borderRadius: '32px',
    ...(isPage && {
      padding: '24px 0',
    }),
    ...(!isPage && {
      padding: isEditOrder ? '0px' : '32px',
    }),

    background: theme.vars.palette.silver20silver200.main,
    gap: '26px',
    maxHeight: !isPage
      ? 'calc(100vh - 68px - 8px - 52px)'
      : 'calc(100vh - 58px - 8px - 52px + 24px)',
    overflow: 'hidden',
    position: 'relative',
  };
});

interface MainBlockCSSProps {
  isright?: 'true';
}

export const MainBlockCSS = styled(Stack)<MainBlockCSSProps>(({ theme, isright }) => {
  const isRight = isright === 'true';
  return {
    background: theme.vars.palette.silver20silver200.main,
    width: '100%',
    maxWidth: !isRight ? '100%' : '498px',
  };
});
