import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { TextFieldCustom } from '../TextFieldRHF/TextFieldRHF';
import { InputAdornment, Stack } from '@mui/material';
import { Controller, FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form';
import { COUNTRIES_OPTIONS, CountryType } from '../../../constants/constants';
import { ChevronBottomIcon } from '../../Icons';

interface CountrySelectProps {
  isAbsoluteErrorText?: boolean;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl<object>>;
  value?: any;
  onChange?: any;
}

interface CountrySelectRHFProps extends Omit<CountrySelectProps, 'value' | 'onChange'> {
  name: string;
}

export const CountrySelect = ({
  value,
  onChange,
  errorMessage,
  isAbsoluteErrorText,
}: CountrySelectProps) => {
  return (
    <Autocomplete
      sx={{
        width: '100%',
        '.MuiInputBase-input': { padding: '0 !important' },
        '.MuiButtonBase-root': { color: '#0E0E0E !important' },
        '.MuiAutocomplete-endAdornment': { display: 'flex', height: '28px', alignItems: 'center' },
      }}
      options={COUNTRIES_OPTIONS}
      value={value}
      onChange={onChange}
      autoHighlight
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => {
        return option.label === value.label;
      }}
      popupIcon={<ChevronBottomIcon color={'#0E0E0E'} />}
      renderOption={(props, option) => (
        <Box component='li' sx={{ display: 'flex', alignItems: 'center' }} {...props}>
          <Stack direction={'row'}>
            <img
              loading='lazy'
              width={30}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png`}
              alt={`Flag of ${option.label}`}
              style={{ marginRight: 8 }}
            />
            <span>{option.label}</span>
          </Stack>
        </Box>
      )}
      renderInput={(params) => {
        const filter = COUNTRIES_OPTIONS.find((obj) => obj.label === params?.inputProps?.value);
        return (
          <TextFieldCustom
            {...params}
            sx={{ '.MuiInputBase-root': { paddingLeft: '26px !important' } }}
            InputProps={{
              ...params.InputProps,
              startAdornment: filter ? (
                <InputAdornment position='start'>
                  <img
                    loading='lazy'
                    width={30}
                    srcSet={`https://flagcdn.com/w40/${filter?.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w40/${filter?.code.toLowerCase()}.png`}
                    alt={`Flag of ${filter?.label}`}
                  />
                </InputAdornment>
              ) : null,
            }}
            isAbsoluteErrorText={isAbsoluteErrorText}
            errorMessage={errorMessage}
            size={'medium'}
            label='Country'
            placeholder={'Enter Country'}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        );
      }}
    />
  );
};

export const CountrySelectRHF = ({ name, ...rest }: CountrySelectRHFProps) => {
  const { control, setValue, setError, clearErrors } = useFormContext();

  const handleChange = (e: any, newValue: CountryType | null) => {
    setValue(name, newValue as CountryType);
    clearErrors(name);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        return (
          <CountrySelect
            value={field.value}
            onChange={handleChange}
            {...rest}
            errorMessage={formState.errors?.[name]?.message}
          />
        );
      }}
      {...rest}
    />
  );
};
