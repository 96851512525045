export const CopyIcon = () => {
  return (
    <svg
      style={{ flexShrink: 0 }}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.33333 4.83325C5.05719 4.83325 4.83333 5.05711 4.83333 5.33325C4.83333 5.60939 5.05719 5.83325 5.33333 5.83325H10.6667C10.9428 5.83325 11.1667 5.60939 11.1667 5.33325C11.1667 5.05711 10.9428 4.83325 10.6667 4.83325H5.33333Z'
        fill='#0E0E0E'
      />
      <path
        d='M5.33333 7.49992C5.05719 7.49992 4.83333 7.72378 4.83333 7.99992C4.83333 8.27606 5.05719 8.49992 5.33333 8.49992H10.6667C10.9428 8.49992 11.1667 8.27606 11.1667 7.99992C11.1667 7.72378 10.9428 7.49992 10.6667 7.49992H5.33333Z'
        fill='#0E0E0E'
      />
      <path
        d='M5.33333 10.1666C5.05719 10.1666 4.83333 10.3904 4.83333 10.6666C4.83333 10.9427 5.05719 11.1666 5.33333 11.1666H10.6667C10.9428 11.1666 11.1667 10.9427 11.1667 10.6666C11.1667 10.3904 10.9428 10.1666 10.6667 10.1666H5.33333Z'
        fill='#0E0E0E'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.9757 0.915737C10.2143 0.833242 9.25452 0.833247 8.03017 0.833252H7.96983C6.74548 0.833247 5.78574 0.833242 5.02432 0.915737C4.24729 0.999923 3.61771 1.1748 3.08016 1.56535C2.75473 1.80179 2.46854 2.08798 2.2321 2.41341C1.84155 2.95096 1.66667 3.58054 1.58249 4.35757C1.49999 5.11899 1.49999 6.07873 1.5 7.30308V8.69675C1.49999 9.92111 1.49999 10.8808 1.58249 11.6423C1.66667 12.4193 1.84155 13.0489 2.2321 13.5864C2.46854 13.9119 2.75473 14.198 3.08016 14.4345C3.61771 14.825 4.24729 14.9999 5.02432 15.0841C5.78573 15.1666 6.74544 15.1666 7.96977 15.1666H8.03018C9.25451 15.1666 10.2143 15.1666 10.9757 15.0841C11.7527 14.9999 12.3823 14.825 12.9198 14.4345C13.2453 14.198 13.5315 13.9119 13.7679 13.5864C14.1585 13.0489 14.3333 12.4193 14.4175 11.6423C14.5 10.8809 14.5 9.92114 14.5 8.69682V7.30307C14.5 6.07874 14.5 5.11898 14.4175 4.35757C14.3333 3.58054 14.1585 2.95096 13.7679 2.41341C13.5315 2.08798 13.2453 1.80179 12.9198 1.56535C12.3823 1.1748 11.7527 0.999923 10.9757 0.915737ZM3.66794 2.37437C4.00661 2.12831 4.44009 1.98489 5.13203 1.90992C5.83333 1.83394 6.73895 1.83325 8 1.83325C9.26105 1.83325 10.1667 1.83394 10.868 1.90992C11.5599 1.98489 11.9934 2.12831 12.3321 2.37437C12.5726 2.54913 12.7841 2.76066 12.9589 3.00119C13.2049 3.33986 13.3484 3.77334 13.4233 4.46528C13.4993 5.16658 13.5 6.0722 13.5 7.33325V8.66658C13.5 9.92763 13.4993 10.8333 13.4233 11.5346C13.3484 12.2265 13.2049 12.66 12.9589 12.9986C12.7841 13.2392 12.5726 13.4507 12.3321 13.6255C11.9934 13.8715 11.5599 14.015 10.868 14.0899C10.1667 14.1659 9.26105 14.1666 8 14.1666C6.73895 14.1666 5.83333 14.1659 5.13203 14.0899C4.44009 14.015 4.00661 13.8715 3.66794 13.6255C3.42741 13.4507 3.21588 13.2392 3.04112 12.9986C2.79506 12.66 2.65163 12.2265 2.57667 11.5346C2.50069 10.8333 2.5 9.92763 2.5 8.66658V7.33325C2.5 6.0722 2.50069 5.16658 2.57667 4.46528C2.65163 3.77334 2.79506 3.33986 3.04112 3.00119C3.21588 2.76066 3.42741 2.54913 3.66794 2.37437Z'
        fill='#0E0E0E'
      />
    </svg>
  );
};
