import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => {
  return {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px 12px 24px',
    background: theme.vars.palette.silver20silver200.main,
    borderRadius: '32px',
    cursor: 'pointer',
  };
});
