import { createContext, ReactNode, useContext, useState } from 'react';

type DateType = {
  dateTo: string | null;
  dateFrom: string | null;
};

type FilterContextType = {
  setFilterDate: (v: DateType) => void;
  date: DateType;
};

export const FilterContext = createContext<FilterContextType>({
  setFilterDate: () => {},
  date: {
    dateTo: null,
    dateFrom: null,
  },
});

export function useFilterContext() {
  const filterContext = useContext(FilterContext);
  if (!filterContext) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }
  return filterContext;
}

type FilterContextProviderType = {
  children: ReactNode;
};

export function FilterContextProvider({ children }: FilterContextProviderType) {
  const [date, setDate] = useState<DateType>({ dateFrom: null, dateTo: null });

  const setFilterDate = (data: DateType) => {
    setDate(data);
  };

  return (
    <FilterContext.Provider
      value={{
        setFilterDate,
        date,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}
