export const UserIcon = () => {
  return (
    <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99999 3.5415c-2.18613 0-3.95833 1.77221-3.95833 3.95834 0 2.18613 1.7722 3.95836 3.95833 3.95836 2.18611 0 3.95831-1.77223 3.95831-3.95836S12.1861 3.5415 9.99999 3.5415ZM7.29166 7.49984c0-1.49577 1.21256-2.70834 2.70833-2.70834 1.49581 0 2.70831 1.21257 2.70831 2.70834 0 1.49577-1.2125 2.70836-2.70831 2.70836-1.49577 0-2.70833-1.21259-2.70833-2.70836Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99999 1.0415c-4.94755 0-8.95833 4.01079-8.95833 8.95834 0 2.54036 1.05824 4.83466 2.75625 6.46426 1.60902 1.5441 3.79526 2.4941 6.20208 2.4941 2.40681 0 4.59311-.95 6.20211-2.4941 1.698-1.6296 2.7562-3.9239 2.7562-6.46426 0-4.94755-4.0108-8.95834-8.95831-8.95834ZM2.29166 9.99984c0-4.2572 3.45113-7.70834 7.70833-7.70834 4.25721 0 7.70831 3.45114 7.70831 7.70834 0 1.82896-.6364 3.50856-1.7009 4.83056-.6617-1.2616-1.9836-2.1222-3.5074-2.1222H7.49999c-1.5238 0-2.84573.8606-3.50742 2.1222-1.06448-1.322-1.70091-3.0016-1.70091-4.83056ZM12.5 13.9582c1.1949 0 2.2101.7743 2.5693 1.8488-1.3556 1.1844-3.1282 1.9012-5.06931 1.9012-1.94115 0-3.71373-.7168-5.06928-1.9012.35921-1.0745 1.37439-1.8488 2.56928-1.8488H12.5Z'
        fill='#fff'
      />
    </svg>
  );
};
