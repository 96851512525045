import * as yup from 'yup';
import { messages as M } from '../messages';
import { REGEX_PHONE_PATTERN } from '../../constants/constants';

export const shopsEditInformationSchema = yup.object().shape({
  tone: yup.string().required(M.REQUIRE_MESSAGE),
  phone: yup
    .string()
    .required(M.REQUIRE_MESSAGE)
    .min(8, M.MIN_LENGTH('8'))
    .matches(REGEX_PHONE_PATTERN, M.INCORRECT_FORMAT),
  email: yup.string().email(M.INCORRECT_EMAIL).required(M.REQUIRE_MESSAGE),
  agentName: yup.string().required(M.REQUIRE_MESSAGE),
});
