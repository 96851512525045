import { styled } from '@mui/material/styles';
import { Button, Stack } from '@mui/material';

export const Wrapper = styled(Stack)({
  width: '490px',
});

export const InfoBlockCSS = styled(Stack)(({ theme }) => {
  return {
    padding: '24px',
    gap: '16px',
    background: theme.vars.palette.silver20silver200.main,
    borderRadius: '36px',
  };
});

export const ButtonCSS = styled(Button)(({ theme }) => {
  return {
    height: '56px',
    background: theme.vars.palette.blackWhite.main,
    color: theme.vars.palette.whiteBlack.main,
    ':hover': {
      background: theme.vars.palette.blackWhite.main,
    },
  };
});
