import { Button, Divider, Typography } from '@mui/material';
import { LetterIcon } from '../../../Icons';
import { IconBlockCSS, Wrapper } from './styles';
import { getProviderLabel } from '../../../../utils';
import { ChatModalInfoSchema } from '../../../Modal/ChatModal/types';
import { getNewUrlWithHttps } from '../../../../utils/getNewUrlWithHttps';

interface HeaderChannelProps {
  chatModalInfo: ChatModalInfoSchema;
}

export const HeaderChannel = ({ chatModalInfo }: HeaderChannelProps) => {
  const providerLabel = getProviderLabel(chatModalInfo.provider);
  const title = chatModalInfo.shopData?.name;
  const urlShop = chatModalInfo.shopData?.url;

  const handleOpenShopModal = () => {
    if (!urlShop) return;
    const url = getNewUrlWithHttps(urlShop);
    window.open(url);
  };

  return (
    <Wrapper provider={chatModalInfo.provider}>
      <IconBlockCSS>
        <LetterIcon />
      </IconBlockCSS>
      <Button variant={'text'} color={'inherit'} onClick={handleOpenShopModal}>
        <Typography variant={'body5'}>{title}</Typography>
      </Button>
      <Divider orientation={'vertical'} />
      <Typography variant={'body5'} minWidth={24}>
        {providerLabel}
      </Typography>
    </Wrapper>
  );
};
