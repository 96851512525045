import { Props } from './types';
import { TypographyCSS, ErrorMessageWrapperCSS, Wrapper } from './styles';
import CircularProgress from '@mui/material/CircularProgress';

export const RequestHandler = ({
  loading,
  error,
  children,
  height = '100%',
  color,
  onCloseError,
  variantSize,
  sxLoading,
}: Props) => {
  const isBigSize = variantSize === 'big';
  if (loading) {
    return (
      <Wrapper height={height} sx={sxLoading}>
        <CircularProgress size={isBigSize ? 100 : 30} sx={{ color }} />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <ErrorMessageWrapperCSS onClick={onCloseError}>
        <TypographyCSS variant={isBigSize ? 'h2' : 'body3'} fontWeight='700'>
          {error.message}
        </TypographyCSS>
      </ErrorMessageWrapperCSS>
    );
  }
  return <>{children}</>;
};
