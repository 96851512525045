import { useEffect, useState } from 'react';
import { ConversationEntity, ConversationTagEnum, useSetTagMutation } from '../graphql/generated';
import { toast } from 'react-toastify';
import { getLabelTag } from '../utils';

interface UseTagProps {
  conversationData?: ConversationEntity;
  conversationId: number;
}
export const useTag = ({ conversationData, conversationId }: UseTagProps) => {
  const [tag, setTag] = useState('');

  const [setTagMutation] = useSetTagMutation({
    onCompleted: () => {
      toast.success('Tag has been changed');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleTag = (e: any) => {
    setTag(e.target.value);
    setTagMutation({
      variables: {
        input: {
          conversationId,
          tag: e.target.value as ConversationTagEnum,
        },
      },
    });
  };

  const optionsSelect =
    conversationData?.tags?.map((item) => {
      return {
        id: item,
        label: getLabelTag(item),
        value: item,
      };
    }) || [];

  useEffect(() => {
    setTag(conversationData?.tag || '');
  }, [conversationData]);

  return {
    tag,
    changeTag: handleTag,
    optionsSelect,
  };
};
