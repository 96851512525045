import { IconButton, SxProps, Typography, useTheme } from '@mui/material';
import { CloseIcon } from '../../../Icons';
import { FC } from 'react';
import { useModalContext } from '../../../../context';
import { IconBlockCSS, IconButtonBlockCSS, Wrapper } from './styles';

type IconProps = {
  color: string;
};

interface ModalHeaderProps {
  icon?: FC<IconProps>;
  title?: string;
  sx?: SxProps;
  onClose?: () => void;
}

export const ModalHeader = ({ icon: Icon, onClose, title, sx }: ModalHeaderProps) => {
  const { onCloseModal } = useModalContext();
  const theme = useTheme();
  const colorIcon = theme.vars.palette.blackWhite.main;

  return (
    <Wrapper direction={'row'} sx={sx}>
      {Icon && (
        <IconBlockCSS mr={'12px'}>
          <Icon color={colorIcon} />
        </IconBlockCSS>
      )}
      {title && <Typography variant={'subtitle2'}>{title}</Typography>}
      <IconButtonBlockCSS direction={'row'}>
        <IconButton size={'small'} onClick={onClose || onCloseModal}>
          <CloseIcon color={colorIcon} />
        </IconButton>
      </IconButtonBlockCSS>
    </Wrapper>
  );
};
