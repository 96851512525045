import {
  styled,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  Button,
  Typography,
  TableCellProps,
} from '@mui/material';

export const TableListWrapper = styled(Paper)({
  padding: '16px',
  borderRadius: '42px',
});

export const TableContainerCSS = styled(TableContainer)({
  height: '750px',
  boxShadow: 'none',
});

export const TableCSS = styled(Table)({
  overflowX: 'auto',
  display: 'flex',
  flexFlow: 'column',
  width: '1428px',
  height: '100%',
  borderCollapse: 'separate',
});

export const TableHeadCSS = styled(TableHead)(({ theme }) => {
  return {
    flex: '0 0 auto',
    width: '100%',
    height: '74px',

    '& tr': {
      paddingRight: '8px',
      borderRadius: '100px',
      backgroundColor: theme.vars.palette.backgroundDef.main,
    },

    '& th': {
      height: '58px',
      backgroundColor: 'transparent',
      borderBottom: 'none',

      '& p': {
        color: theme.vars.palette.silverWhite.main,
      },
    },
  };
});

export const TableBodyCSS = styled(TableBody)(({ theme }) => {
  return {
    overflowY: 'auto',
    display: 'block',
    flex: '1 1 auto',
    '& tr': {
      '& td': {
        backgroundColor: theme.vars.palette.whiteBlack.main,
        borderBottom: `1px solid ${theme.vars.palette.silver.main}`,
      },

      'td:first-of-type': {
        borderLeft: `1px solid ${theme.vars.palette.silver.main}`,
      },

      'td:last-of-type': {
        borderRight: `1px solid ${theme.vars.palette.silver.main}`,
      },

      // Hover tr
      '&:hover': {
        '& td': {
          backgroundColor: theme.vars.palette.silverDark.main,
        },
      },
    },

    '& tr:first-of-type': {
      'td ': {
        borderTop: `1px solid ${theme.vars.palette.silver.main}`,
      },

      'td:first-of-type': {
        borderTopLeftRadius: '32px',
      },

      'td:last-of-type': {
        borderTopRightRadius: '32px',
      },
    },

    '& tr:last-of-type': {
      'td:first-of-type': {
        borderBottomLeftRadius: '32px',
      },

      'td:last-of-type': {
        borderBottomRightRadius: '32px',
      },
    },
  };
});

export const TableRowCSS = styled(TableRow)({
  display: 'table',
  tableLayout: 'fixed',
  width: '100%',
});

interface TableCellCSSProps extends TableCellProps {
  isblocked?: string;
}

export const TableCellCSS = styled(TableCell)<TableCellCSSProps>(({ theme, isblocked }) => {
  const isBlocked = isblocked === 'true';
  return {
    height: '74px',
    padding: '0 20px',
    fontWeight: 500,
    color: isBlocked ? theme.vars.palette.silver100.main : 'inherit',
  };
});

export const HandleOpenButtonCSS = styled(Button)(({ theme }) => {
  return {
    width: 'auto',
    height: '42px',
    padding: '0 24px',
    fontSize: '16px',
    color: theme.vars.palette.whiteBlack.main,
    textTransform: 'none',
    background: theme.vars.palette.blackWhite.main,
    borderRadius: '30px',

    '&:hover': {
      color: theme.vars.palette.whiteBlack.main,
      background: theme.vars.palette.blackWhite.main,
    },
  };
});

export const HandleBlockButtonCSS = styled(HandleOpenButtonCSS)(({ theme }) => {
  return {
    marginLeft: '16px',
    color: theme.vars.palette.white.main,
    background: theme.vars.palette.jasper.main,

    '&:hover': {
      color: theme.vars.palette.white.main,
      background: theme.vars.palette.jasper.main,
    },
  };
});

export const ControlBadgeCSS = styled(Typography)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    padding: '0 16px',
    height: '36px',
    background: theme.vars.palette.silverDark.main,
    borderRadius: '100px',
  };
});

export const EmptyText = styled(Typography)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: theme.vars.palette.blackWhite.main,
  };
});
