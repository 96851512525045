import { Typography } from '@mui/material';
import { MODAL_NAME, OPTIONS_SORT, USERS_TABLE_OPTIONS } from '../../constants/constants';
import { TableRowProps, TableList } from '../../components';
import { useForm } from 'react-hook-form';
import { useDebounce, usePagination, useTriggerByScrollTable } from '../../hooks';
import {
  OrderEnum,
  useEditUserMutation,
  useFindAndCountManyUsersQuery,
  UserEntity,
  UserRoleEnum,
  UserSortFieldsEnum,
} from '../../graphql/generated';
import { getEndDateFormatTo23H59M, getFormatDateToTable, stringConcat } from '../../utils';
import { UsersPageFiltersGroup } from '../../components/Containers';
import { useModalContext } from '../../context';
import { FIND_AND_COUNT_MANY_USERS } from '../../graphql/queries/findAndCountManyUsers.gql';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const createData = (tableRowData?: UserEntity) => {
  return {
    id: tableRowData?.id,
    name: stringConcat([tableRowData?.firstName, tableRowData?.lastName] as string[]),
    firstName: tableRowData?.firstName,
    lastName: tableRowData?.lastName,
    email: tableRowData?.email,
    role: tableRowData?.role,
    isBlocked: tableRowData?.blocked || false,
    control: getFormatDateToTable(tableRowData?.createdAt),
  };
};

export type UsersPageFormData = {
  sortBy: OrderEnum;
  startDate: string | null;
  endDate: string | null;
  role: UserRoleEnum | string;
  search: string;
};

export const UsersPage = () => {
  const { onOpenModal, onCloseModal } = useModalContext();
  const { paginationOptions, handleFetchMore, setPaginationOptions, pending } = usePagination();

  const methods = useForm<UsersPageFormData>({
    defaultValues: {
      sortBy: OPTIONS_SORT[1].value,
      startDate: null,
      endDate: null,
      role: '',
      search: '',
    },
  });
  const { watch, reset } = methods;
  const [sortBy, startDate, endDate, role, search] = watch([
    'sortBy',
    'startDate',
    'endDate',
    'role',
    'search',
  ]);

  const debounceInputValue = useDebounce(search);
  const isAllDateFieldFilled = startDate && endDate;

  const options = {
    search: debounceInputValue,
    ...(sortBy && {
      sortOrder: sortBy,
      sortField: UserSortFieldsEnum.CreatedAt,
    }),
    ...(role && {
      roles: [role as UserRoleEnum],
    }),
    ...(isAllDateFieldFilled && {
      dateFrom: startDate,
      dateTo: getEndDateFormatTo23H59M(endDate),
    }),
    limit: paginationOptions.limit,
    offset: paginationOptions.offset,
  };

  const {
    data: usersData,
    loading: loadingUsers,
    fetchMore,
    refetch,
  } = useFindAndCountManyUsersQuery({
    variables: {
      input: options,
    },
  });

  useEffect(() => {
    refetch({
      input: {
        ...options,
        offset: 0,
      },
    });
    setPaginationOptions((prevState) => ({
      ...prevState,
      offset: 0,
    }));
  }, [debounceInputValue, isAllDateFieldFilled, role, sortBy, endDate && startDate]);

  const [blockUser] = useEditUserMutation({
    refetchQueries: [FIND_AND_COUNT_MANY_USERS],
    onCompleted: () => {
      toast.success('User has been blocked');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const rows =
    usersData?.findAndCountManyUsers.rows?.map((tableRowData) =>
      createData(tableRowData as UserEntity),
    ) || [];

  const handleReset = () => {
    reset();
  };

  const handleOpen = (row: TableRowProps) => {
    onOpenModal(MODAL_NAME.ADD_AND_EDIT_USER, { title: 'Edit User', userData: row });
  };

  const handleUserBlock = (userId?: number | string) => {
    if (!userId) return;
    onCloseModal();
    blockUser({
      variables: {
        input: {
          blocked: true,
          id: Number(userId),
        },
      },
    });
  };

  const handleBlock = (row: TableRowProps) => {
    onOpenModal(MODAL_NAME.ARE_YOUR_SURE, {
      callback: () => handleUserBlock(row?.id),
      title: `Are you sure you want to delete user ${row.name}?`,
      subTitle: 'This action cannot be canceled',
      labelBtnLeft: 'Cancel',
      labelBtnRight: 'Delete user',
    });
  };

  const handleAddUsers = () => {
    onOpenModal(MODAL_NAME.ADD_AND_EDIT_USER, { title: 'Add Users' });
  };

  const { tableBodyRef } = useTriggerByScrollTable({
    triggerData: [rows],
    skip: pending,
    onAction: () => {
      handleFetchMore({
        fetchMore,
        options,
        dataCount: Number(usersData?.findAndCountManyUsers.count),
      });
    },
  });

  return (
    <>
      <Typography variant={'big'} mt={'36px'} mb={'51px'}>
        Users
      </Typography>
      <UsersPageFiltersGroup
        methods={methods}
        nameSearchField={'search'}
        nameRoleSelect={'role'}
        nameSortSelect={'sortBy'}
        nameStartDateField={'startDate'}
        nameEndDateField={'endDate'}
        startDate={startDate}
        onReset={handleReset}
        onClickAddUsers={handleAddUsers}
      />
      <TableList
        isLoading={loadingUsers}
        headCells={USERS_TABLE_OPTIONS}
        rows={rows}
        isHasPagination={false}
        openButtonTitle='Edit'
        handleOpen={handleOpen}
        handleBlock={handleBlock}
        isHasControlBadge={false}
        tableBodyRef={tableBodyRef}
      />
    </>
  );
};
