import { IconButton, MenuItem, Select, Stack } from '@mui/material';
import { ChevronBottomIcon } from '../../Icons';
import { copyTextToClipboard } from '../../../utils/copyTextToClipboard';
import { CopyIcon } from '../../Icons/CopyIcon';
import { usePalette } from '../../../hooks';
import { TypographyCSS } from './styles';

interface SelectOrderProps {
  option: string[];
}

export const SelectOrder = ({ option }: SelectOrderProps) => {
  const { palette } = usePalette();
  return (
    <Select
      value={option[0]}
      IconComponent={(props) => {
        return (
          <Stack {...props}>
            <ChevronBottomIcon color={palette.blackWhite.main} />
          </Stack>
        );
      }}
      variant={'outlined'}
      size={'order'}
      MenuProps={{
        PaperProps: {
          sx: {
            background: palette.silver50.main,
            '.MuiTypography-root': {
              marginRight: '8px',
            },
          },
        },
      }}
    >
      {option?.map((c, i) => (
        <MenuItem value={c} sx={{ display: 'flex' }} key={i}>
          <TypographyCSS color={'mail.main'}>{c}</TypographyCSS>
          <IconButton
            sx={{ padding: 0, marginLeft: 'auto' }}
            onClick={() => copyTextToClipboard(c)}
          >
            <CopyIcon />
          </IconButton>
        </MenuItem>
      ))}
    </Select>
  );
};
