export const SearchIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_364_5313)'>
        <path
          d='M6.22333 3.20163C6.49607 3.15843 6.68216 2.90231 6.63896 2.62957C6.59576 2.35682 6.33964 2.17074 6.0669 2.21394C4.42664 2.47373 3.14021 3.76016 2.88042 5.40041C2.83723 5.67316 3.02331 5.92928 3.29605 5.97247C3.56879 6.01567 3.82491 5.82959 3.86811 5.55685C4.06013 4.34448 5.01097 3.39365 6.22333 3.20163Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.33325 0.166748C3.7434 0.166748 0.833252 3.0769 0.833252 6.66675C0.833252 10.2566 3.7434 13.1667 7.33325 13.1667C10.9231 13.1667 13.8333 10.2566 13.8333 6.66675C13.8333 3.0769 10.9231 0.166748 7.33325 0.166748ZM1.83325 6.66675C1.83325 3.62918 4.29569 1.16675 7.33325 1.16675C10.3708 1.16675 12.8333 3.62918 12.8333 6.66675C12.8333 9.70431 10.3708 12.1667 7.33325 12.1667C4.29569 12.1667 1.83325 9.70431 1.83325 6.66675Z'
          fill='white'
        />
        <path
          d='M13.0202 11.6466C12.8249 11.4513 12.5083 11.4513 12.3131 11.6466C12.1178 11.8418 12.1178 12.1584 12.3131 12.3537L14.9797 15.0203C15.1749 15.2156 15.4915 15.2156 15.6868 15.0203C15.882 14.8251 15.882 14.5085 15.6868 14.3132L13.0202 11.6466Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_364_5313'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
