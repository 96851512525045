import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  SxProps,
  Typography,
} from '@mui/material';
import { Controller, FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form';
import { ChangeEvent, forwardRef } from 'react';

interface CheckboxCustom {
  value: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  label?: string;
  markLabel?: string;
  mb?: string | number;
  sx?: SxProps;
  disabled?: boolean;
}

interface ICheckboxRHFProps extends Omit<CheckboxCustom, 'value' | 'onChange'> {
  name: string;
}

export const CheckboxCustom = forwardRef(
  ({ value, onChange, errorMessage, label, markLabel, mb, sx, disabled }: CheckboxCustom, ref) => {
    return (
      <FormControl sx={{ marginBottom: mb, ...sx }} variant={'standard'}>
        {markLabel ? (
          <FormLabel focused={false}>
            <Typography mb={1}>{markLabel}</Typography>
          </FormLabel>
        ) : null}
        <FormControlLabel
          control={<Checkbox onChange={onChange} checked={value} disabled={disabled} />}
          label={label}
          ref={ref}
        />
        {errorMessage && <FormHelperText error>{String(errorMessage)}</FormHelperText>}
      </FormControl>
    );
  },
);

export const CheckboxRHF = ({ name, ...rest }: ICheckboxRHFProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        return (
          <CheckboxCustom
            {...rest}
            value={field.value}
            onChange={field.onChange}
            errorMessage={formState.errors?.[name]?.message}
          />
        );
      }}
    />
  );
};
