import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(() => {
  return {
    overflow: 'hidden',
    height: '100%',
  };
});

export const TransparentDividerCSS = styled(Stack)(() => {
  return {
    height: '100%',
    maxHeight: '26px',
    width: '100%',
    background: 'blue',
    position: 'relative',
    pointerEvents: 'none',
    backgroundColor: 'transparent',
  };
});

export const BottomBlockWrapperCSS = styled(Stack)(({ theme }) => {
  return {
    background: theme.vars.palette.whiteBlack.main,
    borderRadius: '32px',
    padding: '16px',
  };
});

export const InnerContentBlockCSS = styled(Stack)(({ theme }) => {
  return {
    background: theme.vars.palette.whiteBlack.main,
    borderRadius: '32px',
    overflowY: 'auto',
  };
});
