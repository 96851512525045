import { IconProps } from './types';

export const LoginIcon = ({ color = '#fff', sx }: IconProps) => {
  return (
    <svg
      style={sx}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 2.50001C10.6384 2.50001 10.8995 2.50156 11.1099 2.53489C12.3223 2.72691 13.2731 3.67775 13.4651 4.89011C13.4984 5.10055 13.5 5.36156 13.5 6.00001V10C13.5 10.6384 13.4984 10.8995 13.4651 11.1099C13.2731 12.3223 12.3223 13.2731 11.1099 13.4651C10.8995 13.4985 10.6384 13.5 10 13.5H9.33333C8.69492 13.5 8.10712 13.2895 7.63362 12.9338C7.41283 12.768 7.0994 12.8125 6.93355 13.0333C6.7677 13.2541 6.81225 13.5675 7.03304 13.7334C7.67376 14.2146 8.47094 14.5 9.33333 14.5L10.0539 14.5C10.6219 14.5001 10.9679 14.5001 11.2663 14.4528C12.9066 14.193 14.193 12.9066 14.4528 11.2663C14.5001 10.9679 14.5 10.6219 14.5 10.0539V5.94614C14.5 5.37812 14.5001 5.03213 14.4528 4.73367C14.193 3.09342 12.9066 1.80699 11.2663 1.5472C10.9679 1.49993 10.6219 1.49996 10.0539 1.5L9.33333 1.50001C8.47094 1.50001 7.67376 1.78538 7.03304 2.26665C6.81225 2.43249 6.76771 2.74592 6.93355 2.96672C7.0994 3.18751 7.41283 3.23206 7.63362 3.06621C8.10712 2.71056 8.69491 2.50001 9.33333 2.50001H10Z'
        fill={color}
      />
      <path
        d='M8.97731 4.94155C8.76093 4.76998 8.44644 4.80631 8.27488 5.02269C8.10331 5.23907 8.13964 5.55356 8.35602 5.72513L9.53133 6.65703C10.0007 7.02917 10.322 7.2849 10.5403 7.50001L2 7.50001C1.72386 7.50001 1.5 7.72386 1.5 8.00001C1.5 8.27615 1.72386 8.50001 2 8.50001L10.5403 8.50001C10.322 8.71511 10.0007 8.97084 9.53133 9.34299L8.35602 10.2749C8.13964 10.4465 8.10331 10.7609 8.27488 10.9773C8.44644 11.1937 8.76093 11.23 8.97731 11.0585L10.1765 10.1076C10.6248 9.75217 10.9948 9.45886 11.2583 9.19633C11.5288 8.92692 11.753 8.6279 11.8138 8.24579C11.8268 8.16438 11.8333 8.08226 11.8333 8.00001C11.8333 7.91775 11.8268 7.83563 11.8138 7.75422C11.753 7.37211 11.5288 7.07309 11.2583 6.80368C10.9948 6.54115 10.6248 6.24784 10.1765 5.89239L8.97731 4.94155Z'
        fill={color}
      />
      <path d='M10 2.50001C10.6384 2.50001 10.8995 2.50156 11.1099 2.53489C12.3223 2.72691 13.2731 3.67775 13.4651 4.89011C13.4984 5.10055 13.5 5.36156 13.5 6.00001V10C13.5 10.6384 13.4984 10.8995 13.4651 11.1099C13.2731 12.3223 12.3223 13.2731 11.1099 13.4651C10.8995 13.4985 10.6384 13.5 10 13.5H9.33333C8.69492 13.5 8.10712 13.2895 7.63362 12.9338C7.41283 12.768 7.0994 12.8125 6.93355 13.0333C6.7677 13.2541 6.81225 13.5675 7.03304 13.7334C7.67376 14.2146 8.47094 14.5 9.33333 14.5L10.0539 14.5C10.6219 14.5001 10.9679 14.5001 11.2663 14.4528C12.9066 14.193 14.193 12.9066 14.4528 11.2663C14.5001 10.9679 14.5 10.6219 14.5 10.0539V5.94614C14.5 5.37812 14.5001 5.03213 14.4528 4.73367C14.193 3.09342 12.9066 1.80699 11.2663 1.5472C10.9679 1.49993 10.6219 1.49996 10.0539 1.5L9.33333 1.50001C8.47094 1.50001 7.67376 1.78538 7.03304 2.26665C6.81225 2.43249 6.76771 2.74592 6.93355 2.96672C7.0994 3.18751 7.41283 3.23206 7.63362 3.06621C8.10712 2.71056 8.69491 2.50001 9.33333 2.50001H10Z' />
      <path d='M8.97731 4.94155C8.76093 4.76998 8.44644 4.80631 8.27488 5.02269C8.10331 5.23907 8.13964 5.55356 8.35602 5.72513L9.53133 6.65703C10.0007 7.02917 10.322 7.2849 10.5403 7.50001L2 7.50001C1.72386 7.50001 1.5 7.72386 1.5 8.00001C1.5 8.27615 1.72386 8.50001 2 8.50001L10.5403 8.50001C10.322 8.71511 10.0007 8.97084 9.53133 9.34299L8.35602 10.2749C8.13964 10.4465 8.10331 10.7609 8.27488 10.9773C8.44644 11.1937 8.76093 11.23 8.97731 11.0585L10.1765 10.1076C10.6248 9.75217 10.9948 9.45886 11.2583 9.19633C11.5288 8.92692 11.753 8.6279 11.8138 8.24579C11.8268 8.16438 11.8333 8.08226 11.8333 8.00001C11.8333 7.91775 11.8268 7.83563 11.8138 7.75422C11.753 7.37211 11.5288 7.07309 11.2583 6.80368C10.9948 6.54115 10.6248 6.24784 10.1765 5.89239L8.97731 4.94155Z' />
    </svg>
  );
};
