import { ShippingFormData } from '../components/OrderInformation/types';

export const getFieldNameRHF = (str: string): keyof ShippingFormData | '' => {
  switch (str) {
    case 'email':
      return 'email';
    case 'address1':
      return 'address';
    case 'lastName':
      return 'lastName';
    case 'city':
      return 'city';
    case 'country':
      return 'country';
    case 'province':
      return 'state';
    case 'zip':
      return 'zip';
    default:
      return '';
  }
};
