import { Stack, StackProps, styled } from '@mui/material';

interface WrapperProps extends StackProps {
  ispage?: string;
}

export const Wrapper = styled(Stack)<WrapperProps>(({ ispage }) => {
  const isPage = ispage === 'true';
  return {
    width: '100%',
    gap: '8px',
    ...(isPage && {
      paddingTop: '26px',
      flex: 1,
      gap: 0,
    }),
  };
});
