import { experimental_extendTheme } from '@mui/material';
import * as foundations from './foundations';
import type {} from '@mui/material/themeCssVarsAugmentation';

export const theme = experimental_extendTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          margin: '0px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          disableRipple: true,
          margin: '0px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '36px',
          height: '36px',
        },
      },
      variants: [
        {
          props: { variant: 'circular' },

          style: {
            background: '#0E0E0E',
            img: {
              objectFit: 'fill',
            },
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: { variant: 'contained' },
      styleOverrides: {
        root: {
          minWidth: '5px',
          minHeight: '5px',
          padding: '0px',
          textTransform: 'capitalize',
        },
      },
      variants: [
        {
          props: { variant: 'contained', size: 'medium' },
          style: ({ theme }) => ({
            height: '40px',
            padding: '0 16px',
            background: theme.vars.palette.black.main,
            borderRadius: '1000px',
            color: theme.vars.palette.white.main,
            '&:hover': {
              background: theme.vars.palette.black.main,
            },
          }),
        },
        {
          props: { variant: 'contained', size: 'medium', typeof: 'support' },
          style: ({ theme }) => ({
            height: '40px',
            padding: '0 16px',
            background: theme.vars.palette.midSilverWhite.main,
            color: theme.vars.palette.black.main,
            borderRadius: '1000px',
            '&:hover': {
              background: theme.vars.palette.midSilverWhite.main,
            },
          }),
        },
        {
          props: { variant: 'contained', size: 'small' },
          style: ({ theme }) => ({
            height: '36px',
            padding: '8px 22px 8px 12px',
            background: theme.vars.palette.blackWhite.main,
            borderRadius: '1000px',

            '& svg path': {
              fill: theme.vars.palette.whiteBlack.main,
            },
          }),
        },
        {
          props: { variant: 'outlined', size: 'small' },
          style: ({ theme }) => ({
            height: '36px',
            padding: '8px 22px 8px 12px',
            background: 'transparent',
            borderRadius: '1000px',
            outline: '1px solid',
            outlineColor: theme.vars.palette.midSilverWhite.main,
            border: 'none',
            '&:hover': {
              outlineColor: theme.vars.palette.midSilverWhite.main,
              border: 'none',
            },
          }),
        },
        {
          props: { variant: 'circle' },
          style: ({ theme }) => ({
            width: '36px',
            height: '36px',
            border: 'none',
            borderRadius: '50%',
            background: theme.vars.palette.black.main,
            '&:hover': {
              background: theme.vars.palette.black.main,
            },
          }),
        },
        {
          props: { variant: 'signin' },
          style: ({ theme }) => ({
            minWidth: '439px',
            minHeight: '56px',
            alignItems: 'center',
            borderRadius: '30px',
            padding: '5px 28px',
            background: theme.vars.palette.blackWhite.main,
            color: theme.vars.palette.whiteBlack.main,
            leadingTrim: 'both',
            textEdge: 'cap',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '18px',
            ':hover': {
              background: theme.vars.palette.blackWhite.main,
            },
          }),
        },
        // Action User Button
        {
          props: { variant: 'actionUserButton' },
          style: ({ theme }) => ({
            width: '112px',
            height: '40px',
            padding: '0',
            fontSize: '14px',
            fontWeight: 500,
            textTransform: 'inherit',
            color: theme.vars.palette.whiteBlack.main,
            background: theme.vars.palette.blackWhite.main,
            borderRadius: '100px',

            '& svg path': {
              fill: theme.vars.palette.whiteBlack.main,
            },

            '&:hover': {
              background: theme.vars.palette.blackWhite.main,
            },
          }),
        },
        // Chat Send Button
        {
          props: { variant: 'chatSendButton' },
          style: ({ theme }) => ({
            width: '110px',
            height: '42px',
            padding: '0',
            fontSize: '14px',
            fontWeight: 500,
            textTransform: 'inherit',
            color: theme.vars.palette.darkBlue.main,
            background: theme.vars.palette.blue.main,
            borderRadius: '100px',

            '&:hover': {
              background: theme.vars.palette.blue.main,
            },
          }),
        },
        {
          props: { variant: 'back' },
          style: ({ theme }) => ({
            height: '68px',
            padding: '18px 32px 18px 18px',
            borderRadius: '1000px',
            border: 'none',
            background: theme.vars.palette.white.main,
            color: theme.vars.palette.black.main,
            minWidth: '105px',
            '&:hover': {
              background: theme.vars.palette.white.main,
            },
          }),
        },
        {
          props: { variant: 'startMessage' },
          style: ({ theme }) => ({
            height: '60px',
            width: '100%',
            borderRadius: '30px',
            maxWidth: '349px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '18px',
            background: theme.vars.palette.blackWhite.main,
            color: theme.vars.palette.whiteBlack.main,
            disabled: {
              background: theme.vars.palette.silver50.main,
            },
            ':hover': {
              background: theme.vars.palette.blackWhite.main,
            },
            'svg path': {
              fill: theme.vars.palette.whiteBlack.main,
            },
            '&:disabled': {
              background: theme.vars.palette.silver50.main,
              color: theme.vars.palette.whiteBlack.main,
            },
          }),
        },
        {
          props: { variant: 'edit' },
          style: ({ theme }) => ({
            minWidth: '80px',
            height: '40px',
            background: theme.vars.palette.blackWhite.main,
            color: theme.vars.palette.whiteBlack.main,
            borderRadius: '1000px',
            '& svg': {
              '& path': {
                fill: theme.vars.palette.whiteBlack.main,
              },
            },
            ':hover': {
              background: theme.vars.palette.blackWhite.main,
            },
            ':disabled': {
              background: theme.vars.palette.silver20silver100.main,
            },
          }),
        },
        {
          props: { variant: 'cancelChatForm' },
          style: ({ theme }) => ({
            width: '100%',
            height: '40px',
            borderRadius: '20px',
            color: theme.vars.palette.blackWhite.main,
            background: theme.vars.palette.silver50.main,

            '&:hover': {
              color: theme.vars.palette.blackWhite.main,
              background: theme.vars.palette.silver50.main,
            },
          }),
        },
        {
          props: { variant: 'createChatForm' },
          style: ({ theme }) => ({
            width: '100%',
            height: '40px',
            borderRadius: '20px',
            color: theme.vars.palette.whiteBlack.main,
            background: theme.vars.palette.blackWhite.main,

            '&:hover': {
              color: theme.vars.palette.whiteBlack.main,
              background: theme.vars.palette.blackWhite.main,
            },
          }),
        },
        {
          props: { variant: 'orderSave' },
          style: ({ theme }) => ({
            minWidth: '135px',
            padding: '0 51px',
            height: '56px',
            borderRadius: '28px',
            color: theme.vars.palette.whiteBlack.main,
            background: theme.vars.palette.blackWhite.main,
            '&:hover': {
              color: theme.vars.palette.whiteBlack.main,
              background: theme.vars.palette.blackWhite.main,
            },
            ':disabled': {
              color: theme.vars.palette.white.main,
              background:
                'var(--silver-50, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #C4C4C4)',
            },
          }),
        },
      ],
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },
      variants: [
        {
          props: { variant: 'outlined', size: 'small' },
          style: ({ theme }) => ({
            height: '36px',
            borderRadius: '23px',
            background: theme.vars.palette.blackWhite.main,
            color: theme.vars.palette.whiteBlack.main,
            paddingRight: '10px',
            '.MuiSelect-select': {
              fontSize: '14px',
              fontWeight: 400,
              padding: '0 10px 0 16px !important',
            },
            '.MuiTypography-root': {
              fontSize: '14px',
              fontWeight: 400,
            },
            'svg path': {
              fill: 'currentColor',
            },
          }),
        },
        {
          props: { variant: 'outlined', size: 'medium' },
          style: ({ theme }) => ({
            height: '52px',
            borderRadius: '12px',
            background: theme.vars.palette.whiteSilver20.main,
            color: theme.vars.palette.black.main,
            paddingRight: '16px',
            '.MuiSelect-select': {
              fontSize: '16px',
              fontWeight: 500,
              padding: '0 0 0 24px',
            },
            '.MuiTypography-root': {
              fontSize: '16px',
              fontWeight: 500,
            },
            'svg path': {
              fill: 'currentColor',
            },
          }),
        },
        {
          props: { variant: 'outlined', size: 'big' },
          style: ({ theme }) => ({
            height: '68px',
            borderRadius: '34px',
            background: theme.vars.palette.white.main,
            color: theme.vars.palette.black.main,
            '.MuiSelect-select': {
              fontSize: '14px',
              fontWeight: 400,
              padding: '0 54px 0 32px !important',
            },
            '.MuiTypography-root': {
              fontSize: '14px',
              fontWeight: 400,
            },
            '.MuiSelect-icon': {
              marginRight: '16px',
              marginLeft: '16px',
            },
            'svg path': {
              fill: theme.vars.palette.black.main + '!important',
            },
          }),
        },
        {
          props: { variant: 'outlined', size: 'order' },
          style: () => ({
            '.MuiPaper-root': {
              background: 'red',
            },
            height: '20px',
            background: 'transparent',
            '.MuiSelect-select': {
              paddingLeft: '0 !important',
            },
            '.MuiButtonBase-root': { display: 'none' },
            fieldset: { border: 'none' },
          }),
        },
      ],
    },
    MuiFormLabel: {
      variants: [
        {
          props: { 'aria-label': 'select-small' },
          style: ({ theme }) => ({
            p: {
              color: theme.vars.palette.blackWhite.main,
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '16px',
              marginBottom: '6px',
            },
          }),
        },
        {
          props: { 'aria-label': 'select-medium' },
          style: ({ theme }) => ({
            marginLeft: '24px',
            p: {
              color: theme.vars.palette.silver100White.main,
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '11px',
              marginBottom: '8px',
            },
          }),
        },
        {
          props: { 'aria-label': 'select-big' },
          style: {
            p: {
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '16px',
              marginBottom: '12px',
            },
          },
        },
        {
          props: { 'aria-label': 'input-medium' },
          style: ({ theme }) => ({
            p: {
              color: theme.vars.palette.silver100White.main,
              marginLeft: '24px',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '12px',
              marginBottom: '8px',
            },
          }),
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.vars.palette.whiteDarkSilver.main,
          borderRadius: '24px',
          marginTop: '6px',
          '.MuiButtonBase-root': {
            '&.Mui-selected': {
              background: theme.vars.palette.silver20silver200.main,
            },
          },
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: ({ palette }) => ({
        '::-webkit-scrollbar': {
          width: '20px',
        },

        '::-webkit-scrollbar-track': {
          boxShadow: `inset 0 0 20px 20px ${palette.silverMidWhite.main}`,
          border: 'solid 6px transparent',
          borderRadius: '100px',
        },

        '::-webkit-scrollbar-thumb': {
          boxShadow: `inset 0 0 20px 20px ${palette.blackWhite.main}`,
          border: 'solid 6px transparent',
          borderRadius: '100px',
        },
      }),
    },
    MuiTextField: {
      defaultProps: { variant: 'outlined', size: 'medium' },
      variants: [
        {
          props: { variant: 'outlined', size: 'small', type: 'search' },
          style: ({ theme }) => ({
            '.MuiInputBase-root': {
              background: theme.vars.palette.white.main,
              color: theme.vars.palette.black.main,
              borderRadius: '18px',
              fieldset: { border: '1px solid', borderColor: theme.vars.palette.silver50.main },
            },
            '.MuiInputBase-input': {
              padding: '8px 12px 8px 16px',
              '::placeholder': {
                color: theme.vars.palette.gray100.main,
              },
            },
            input: {
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '16px',
              '&::placeholder': {
                color: theme.vars.palette.silver100White.main,
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '16px',
                opacity: 1,
              },
              MozAppearance: 'textfield',
              '.-webkit-search-cancel-button': {
                webkitAppearance: 'none',
              },
            },
            '.MuiInputAdornment-root': {
              'svg path': { fill: theme.vars.palette.black.main },
            },
            '.MuiInputBase-input[type="search"]::-webkit-search-cancel-button': {
              display: 'none',
            },
          }),
        },
        {
          props: { variant: 'outlined', size: 'medium', type: 'search' },
          style: ({ theme }) => ({
            '.MuiInputBase-root': {
              borderRadius: '1000px',
              background: theme.vars.palette.whiteSilver200.main,
              fieldset: { border: '1px solid', borderColor: theme.vars.palette.silver50.main },
              ':focus-visible': {
                outline: 'none',
              },
            },
            '.MuiInputBase-input': {
              padding: '16px 12px 16px 26px',
            },
            input: {
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '16px',
              '&::placeholder': {
                color: theme.vars.palette.silver100White.main,
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '16px',
                opacity: 1,
              },
              MozAppearance: 'textfield',
              '.-webkit-search-cancel-button': {
                webkitAppearance: 'none',
              },
            },
            '.MuiInputAdornment-root': {
              'svg path': { fill: theme.vars.palette.blackWhite.main },
            },
            '.MuiInputBase-input[type="search"]::-webkit-search-cancel-button': {
              display: 'none',
            },
          }),
        },
        {
          props: { variant: 'outlined', size: 'medium', type: 'text' },
          style: ({ theme }) => ({
            '.MuiInputBase-root': {
              minHeight: '52px',
              background: theme.vars.palette.whiteSilver20.main,
              borderRadius: '12px',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '16px',
              fieldset: { border: '1px solid', borderColor: theme.vars.palette.silver50.main },
              color: theme.vars.palette.black.main,
              paddingLeft: 0,
            },
            '.MuiInputBase-input': {
              padding: '0 0 0 26px',
            },
            fieldset: {
              border: 'none !important',
            },
          }),
        },
        {
          props: { variant: 'outlined', size: 'medium', type: 'number' },
          style: ({ theme }) => ({
            '.MuiInputBase-root': {
              minHeight: '52px',
              background: theme.vars.palette.whiteSilver20.main,
              borderRadius: '12px',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '16px',
              fieldset: { border: '1px solid', borderColor: theme.vars.palette.silver50.main },
              color: theme.vars.palette.black.main,
              paddingLeft: 0,
            },
            '.MuiInputBase-input': {
              padding: '0 0 0 26px',
            },
            fieldset: {
              border: 'none !important',
            },
          }),
        },
      ],
      styleOverrides: {
        root: () => ({
          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: '0',
          },
          input: {
            MozAppearance: 'textfield', // FOR MOZILA
          },
          // fieldset: {
          //   border: 'none !important',
          // },
        }),
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { size: 'medium', type: 'reset' },
          style: ({ theme }) => ({
            background: theme.vars.palette.whiteSilver200.main,
            backdropFilter: 'blur(3px)',
            width: '36px',
            height: '36px',
            'svg path': {
              stroke: 'currentColor',
              fill: 'currentColor',
            },
          }),
        },
        {
          props: { size: 'large' },
          style: ({ theme }) => ({
            background: theme.vars.palette.black.main,
            backdropFilter: 'blur(3px)',
            width: '44px',
            height: '44px',
            ':hover': {
              background: theme.vars.palette.black.main,
            },
          }),
        },
        {
          props: { size: 'sm' },
          style: ({ theme }) => ({
            width: '16px',
            height: '16px',
          }),
        },
      ],
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#0E0E0E',
        },
        backgroundDef: {
          main: '#E3E3E3',
        },
        black: {
          main: '#0E0E0E',
        },
        whiteSilver: {
          main: '#ffffff',
        },
        blackWhite: {
          main: '#0E0E0E',
        },
        whiteBlack: {
          main: '#ffffff',
        },
        whiteDarkSilver: {
          main: '#ffffff',
        },
        midSilverWhite: {
          main: '#C4C4C4',
        },
        white: {
          main: '#FFFFFF',
        },
        silver100White: {
          main: '#848484',
        },
        silver20silver200: {
          main: '#E3E3E3',
        },
        whiteSilver20: {
          main: '#FFFFFF',
        },
        silver50: {
          main: '#C4C4C4',
        },
        whiteSilver200: {
          main: '#FFFFFF',
        },
        silver: {
          main: '#E3E3E3',
        },
        midSilver: {
          main: '#C4C4C4',
        },
        silverDark: {
          main: '#E3E3E3',
        },
        silverWhite: {
          main: '#848484',
        },
        silverMidWhite: {
          main: '#E3E3E3',
        },
        lightSilverWhite: {
          main: '#909090',
        },
        lightSilverDark: {
          main: '#EDEDED',
        },
        darkWhiteSilver: {
          main: '#0E0E0E',
        },
        jasper: {
          main: '#D73737',
        },
        whiteSilver100: {
          main: '#FFFFFF',
        },
        silver100Black: {
          main: '#848484',
        },
        mail: {
          main: '#7289FF',
        },
        green: {
          main: '#06B561',
        },
        red: {
          main: '#EA4335',
        },
        ser: {
          main: '#909090',
        },
        silver100: {
          main: '#848484',
        },
        blue: {
          main: '#89CFF0',
        },
        lightBlue: {
          main: '#E0F5FF',
        },
        darkBlue: {
          main: '#025983',
        },
        orange: {
          main: '#E77A2B',
        },
        silver20: {
          main: '#E3E3E3',
        },
        silver50White: {
          main: '#C4C4C4',
        },
        yellow: {
          main: '#FBBC05',
        },
        lightSilverDarkSilver: {
          main: '#C8C8C8',
        },
        silver20silver100: {
          main: '#E3E3E3',
        },
        gray100: {
          main: '#A7A7A7',
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: '#FFFFFF',
        },
        backgroundDef: {
          main: '#0E0E0E',
        },
        black: {
          main: '#0E0E0E',
        },
        whiteSilver: {
          main: '#292929',
        },
        blackWhite: {
          main: '#ffffff',
        },
        whiteBlack: {
          main: '#0E0E0E',
        },
        whiteDarkSilver: {
          main: '#848484',
        },
        midSilverWhite: {
          main: '#FFF',
        },
        white: {
          main: '#FFFFFF',
        },
        silver100White: {
          main: '#FFFFFF',
        },
        silver20silver200: {
          main: '#292929',
        },
        whiteSilver20: {
          main: '#E3E3E3',
        },
        silver50: {
          main: '#C4C4C4',
        },
        whiteSilver200: {
          main: '#292929',
        },
        silver: {
          main: '#E3E3E3',
        },
        midSilver: {
          main: '#C4C4C4',
        },
        silverDark: {
          main: '#292929',
        },
        silverWhite: {
          main: '#FFFFFF',
        },
        silverMidWhite: {
          main: '#C4C4C4',
        },
        lightSilverWhite: {
          main: '#FFFFFF',
        },
        lightSilverDark: {
          main: '#292929',
        },
        darkWhiteSilver: {
          main: '#848484',
        },
        jasper: {
          main: '#D73737',
        },
        whiteSilver100: {
          main: '#848484',
        },
        silver100Black: {
          main: '#0E0E0E',
        },
        mail: {
          main: '#7289FF',
        },
        green: {
          main: '#06B561',
        },
        red: {
          main: '#EA4335',
        },
        ser: {
          main: '#909090',
        },
        silver100: {
          main: '#848484',
        },
        blue: {
          main: '#89CFF0',
        },
        lightBlue: {
          main: '#E0F5FF',
        },
        darkBlue: {
          main: '#025983',
        },
        orange: {
          main: '#E77A2B',
        },
        silver20: {
          main: '#E3E3E3',
        },
        silver50White: {
          main: '#FFFFFF',
        },
        yellow: {
          main: '#FBBC05',
        },
        lightSilverDarkSilver: {
          main: '#848484',
        },
        silver20silver100: {
          main: '#848484',
        },
        gray100: {
          main: '#A7A7A7',
        },
      },
    },
  },
  ...foundations,
});

declare module '@mui/material/styles' {
  interface Palette {
    backgroundDef: Palette['primary'];
    black: Palette['primary'];
    whiteSilver: Palette['primary'];
    blackWhite: Palette['primary'];
    whiteBlack: Palette['primary'];
    whiteDarkSilver: Palette['primary'];
    midSilverWhite: Palette['primary'];
    white: Palette['primary'];
    silver100White: Palette['primary'];
    silver20silver200: Palette['primary'];
    whiteSilver20: Palette['primary'];
    silver50: Palette['primary'];
    whiteSilver200: Palette['primary'];
    silver: Palette['primary'];
    midSilver: Palette['primary'];
    silverDark: Palette['primary'];
    silverWhite: Palette['primary'];
    silverMidWhite: Palette['primary'];
    lightSilverWhite: Palette['primary'];
    lightSilverDark: Palette['primary'];
    darkWhiteSilver: Palette['primary'];
    jasper: Palette['primary'];
    whiteSilver100: Palette['primary'];
    silver100Black: Palette['primary'];
    mail: Palette['primary'];
    green: Palette['primary'];
    red: Palette['primary'];
    ser: Palette['primary'];
    silver100: Palette['primary'];
    blue: Palette['primary'];
    lightBlue: Palette['primary'];
    darkBlue: Palette['primary'];
    orange: Palette['primary'];
    silver20: Palette['primary'];
    silver50White: Palette['primary'];
    yellow: Palette['primary'];
    lightSilverDarkSilver: Palette['primary'];
    silver20silver100: Palette['primary'];
    gray100: Palette['primary'];
  }

  interface PaletteOptions {
    backgroundDef: PaletteOptions['primary'];
    black: PaletteOptions['primary'];
    whiteSilver: PaletteOptions['primary'];
    blackWhite: PaletteOptions['primary'];
    whiteBlack: PaletteOptions['primary'];
    whiteDarkSilver: PaletteOptions['primary'];
    midSilverWhite: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    silver100White: PaletteOptions['primary'];
    silver20silver200: PaletteOptions['primary'];
    whiteSilver20: PaletteOptions['primary'];
    silver50: PaletteOptions['primary'];
    whiteSilver200: PaletteOptions['primary'];
    silver: PaletteOptions['primary'];
    midSilver: PaletteOptions['primary'];
    silverDark: PaletteOptions['primary'];
    silverWhite: PaletteOptions['primary'];
    silverMidWhite: PaletteOptions['primary'];
    lightSilverWhite: PaletteOptions['primary'];
    lightSilverDark: PaletteOptions['primary'];
    darkWhiteSilver: PaletteOptions['primary'];
    jasper: PaletteOptions['primary'];
    whiteSilver100: PaletteOptions['primary'];
    silver100Black: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    lightBlue: PaletteOptions['primary'];
    mail: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    red: PaletteOptions['primary'];
    ser: PaletteOptions['primary'];
    silver100: PaletteOptions['primary'];
    darkBlue: PaletteOptions['primary'];
    orange: PaletteOptions['primary'];
    silver20: PaletteOptions['primary'];
    silver50White: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
    lightSilverDarkSilver: PaletteOptions['primary'];
    silver20silver100: PaletteOptions['primary'];
    gray100: PaletteOptions['primary'];
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    hoverItem?: CSSProperties | Record<string, CSSProperties>;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    signin: true;
    actionUserButton: true;
    chatSendButton: true;
    circle: true;
    back: true;
    startMessage: true;
    edit: true;
    cancelChatForm: true;
    createChatForm: true;
    orderSave: true;
  }
}
//ButtonPropsVariantOverrides
declare module '@mui/material/Select' {
  interface SelectProps {
    size?: 'small' | 'medium' | 'big' | 'order' | undefined;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    sm: true;
  }
}
