import { Box, Button, Stack, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '26px 0',
}));

export const HeroWrapper = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    flexShrink: '2',
    borderRadius: '32px',
    background: theme.vars.palette.whiteSilver.main,
    padding: '12px 12px 12px 12px',
  };
});

export const SpanDividerContainer = styled('span')(({ theme }) => {
  return {
    border: `1px solid ${theme.vars.palette.silverMidWhite.main}`,
    height: '22px',
    marginRight: '16px',
  };
});

export const ButtonLogIn = styled(Button)(() => ({
  width: '16px',
  height: '16px',
  marginRight: '16px',
  background: 'transparent !important',
  padding: '0',
}));

export const UserInfoCSS = styled(Stack)(() => ({
  marginRight: '26px',
  gap: '2px',
}));
