/* eslint-disable no-console */
import { Button, Divider, SelectChangeEvent, Stack } from '@mui/material';
import { MODAL_NAME } from '../../constants/constants';
import { useModalContext } from '../../context';
import {
  FilterButtonsGroup,
  SelectCustom,
  TextFieldCustom,
  TextFieldRHF,
  TabsCustom,
  DatePickerRHF,
} from '../../components/UI';
import { useFilters } from '../../hooks';
import { ChangeEvent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { testValidationSchema } from '../../validation';
import examplePhoto from '../../assets/images/ChatManagerExample.svg';
import { OrderInformation } from '../../components';

const optionsTabs = [
  { id: '1', label: 'Current order' },
  { id: '2', label: 'Past orders' },
];

const optionsSelect = [
  { id: '1', label: 'label 1', value: 'value-1' },
  { id: '2', label: 'label 2', value: 'value-2' },
];

const optionsFilter = [
  { id: 'all', label: 'All' },
  { id: 'email', label: 'Email' },
  { id: 'sms', label: 'SMS' },
  { id: 'livechat', label: 'Live Chat' },
];

export const Dev = () => {
  const { onOpenModal } = useModalContext();

  const handleModalAction = () => {
    console.log('handleModalAction');
  };

  const { filterState, onClick } = useFilters({
    generalOption: optionsFilter[0].id,
  });

  const [selectValue, setSelectValue] = useState<any>('');

  const handleSelectChange = (e: SelectChangeEvent<typeof selectValue>) => {
    setSelectValue(e.target.value);
  };

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const methods = useForm({
    defaultValues: {
      test: '',
      startDate: '',
      endDate: '',
    },
    resolver: yupResolver(testValidationSchema),
  });
  const { handleSubmit, watch } = methods;

  const [startDate, endDate] = watch(['startDate', 'endDate']);

  console.log(startDate, endDate);

  const onSubmitRHF = (value: any) => {
    console.log(value);
  };

  const [tabValue, setTabValue] = useState(optionsTabs[0].id);

  const [cancelMessage, setCancelMessage] = useState(false);

  const onCancelMessage = () => {
    setCancelMessage((prevState) => prevState);
  };

  console.info(cancelMessage);

  return (
    <Stack gap={'20px'}>
      <OrderInformation isPage={false} goBack={() => {}} orderId={'1'} conversationId={1} />

      <Button
        onClick={() =>
          onOpenModal(MODAL_NAME.CHAT, { test_value: 'test_value', onCallBack: handleModalAction })
        }
      >
        open chat modal
      </Button>
      {/* Edit Order List */}
      {/*<EditOrderList />*/}

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitRHF)}>
          <Stack direction={'row'} gap={'12px'}>
            <DatePickerRHF name={'startDate'} label={'Label'} />
            <DatePickerRHF name={'endDate'} minDate={startDate} label={'Label'} />
          </Stack>
          <TextFieldRHF
            name={'test'}
            label={'text field 1'}
            size={'medium'}
            placeholder={'placeholder'}
            type={'text'}
            mb={1}
          />
          <Button type={'submit'}>Press</Button>
        </form>
      </FormProvider>
      <Button
        onClick={() =>
          onOpenModal(MODAL_NAME.SIMPLE, {
            text: ' text: text: text: text: text: text: text: text: text: text: text: text: text: text: text: text: text: text: text: text:',
            subText: 'subText subText subText',
          })
        }
      >
        open SIMPLE modal
      </Button>
      <Button onClick={() => onOpenModal(MODAL_NAME.SHOPS_SETTINGS)}>open SHOP modal</Button>
      <Button
        onClick={() =>
          onOpenModal(MODAL_NAME.TEST, { test_value: 'test_value', onCallBack: handleModalAction })
        }
      >
        open modal example
      </Button>
      <Button
        onClick={() =>
          onOpenModal(MODAL_NAME.ADD_AND_EDIT_USER, {
            title: 'Edit User',
          })
        }
      >
        EDIT_USER
      </Button>
      <Button
        onClick={() =>
          onOpenModal(MODAL_NAME.ADD_AND_EDIT_USER, {
            title: 'Add User',
          })
        }
      >
        ADD_USER
      </Button>
      <Button
        onClick={() =>
          onOpenModal(MODAL_NAME.ADD_USER_TO_EXISTING, {
            title: 'Add user to existing  ',
          })
        }
      >
        ADD_USER_TO_EXISTING
      </Button>
      <Button
        onClick={() =>
          onOpenModal(MODAL_NAME.TEMPLATE_CHANGES_CONFIRMATION, {
            title: 'Template changes confirmation modal',
          })
        }
      >
        TEMPLATE_CHANGES_CONFIRMATION
      </Button>
      <TabsCustom options={optionsTabs} value={tabValue} setValue={setTabValue} />
      <TextFieldCustom
        value={inputValue}
        onChange={handleInputChange}
        size={'medium'}
        placeholder={'medium'}
        label={'asd'}
        type={'text'}
      />

      <Divider />
      <TextFieldCustom
        value={inputValue}
        onChange={handleInputChange}
        size={'medium'}
        placeholder={'Search'}
        label={'Search'}
        type={'search'}
        offSearchIcon={true}
      />

      <TextFieldCustom
        value={inputValue}
        onChange={handleInputChange}
        size={'medium'}
        placeholder={'Search templates'}
        label={'Search'}
        type={'search'}
      />
      <TextFieldCustom
        value={inputValue}
        onChange={handleInputChange}
        size={'small'}
        placeholder={'Search'}
        type={'search'}
      />

      <Divider />

      <SelectCustom
        label={'label'}
        placeholder={'placeholder'}
        options={optionsSelect}
        value={selectValue}
        onChange={handleSelectChange}
      />

      <SelectCustom
        label={'label'}
        placeholder={'placeholder'}
        options={optionsSelect}
        value={selectValue}
        onChange={handleSelectChange}
        size={'medium'}
      />
      <SelectCustom
        label={'big'}
        placeholder={'big'}
        options={optionsSelect}
        value={selectValue}
        onChange={handleSelectChange}
        size={'big'}
      />
      <FilterButtonsGroup
        optionsFilter={optionsFilter}
        onClick={onClick}
        activeFilter={filterState}
        sx={{ marginBottom: '40px', marginTop: '40px' }}
      />
    </Stack>
  );
};
