import { gql } from '@apollo/client';

export const GET_LAST_ORDER = gql`
  query GetLastOrder($conversationId: Float!) {
    getLastOrder(conversationId: $conversationId) {
      id
      link
      email
      phone
      trackingId
      trackingUrl
      status
      financialStatus
      fulfillmentStatus
      shipmentStatus
      total
      netPayment
      item {
        id
        title
        link
        quantity
        price
      }
      shipment {
        address1
        address2
        city
        company
        country
        countryCode
        firstName
        id
        lastName
        phone
        province
        provinceCode
        zip
      }
      cursor
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
