import { Stack } from '@mui/material';
import { TextFieldRHF, TrackingMultiFieldRHF } from '../../../UI';
import { ShippingFormProps } from './types';
import { usePalette } from '../../../../hooks';

export const ShippingForm = ({
  emailFieldName,
  // phoneFieldName,
  trackingIDFieldName,
  trackingURLFieldName,
  orderIDFieldName,
}: ShippingFormProps) => {
  const { palette } = usePalette();

  return (
    <Stack direction={'row'} alignItems={'start'} gap={'16px'} mb={'16px'}>
      <TextFieldRHF
        name={emailFieldName}
        label={'Email'}
        size={'medium'}
        placeholder={'Email'}
        sx={{ width: '100%' }}
        isAbsoluteErrorText
      />
      <TrackingMultiFieldRHF
        name={trackingIDFieldName}
        label={'Tracking ID'}
        placeholder={'Tracking ID'}
      />
      <TrackingMultiFieldRHF
        name={trackingURLFieldName}
        label={'Tracking URL'}
        placeholder={'Tracking URL'}
      />
      <TextFieldRHF
        name={orderIDFieldName}
        label={'Order ID'}
        size={'medium'}
        placeholder={'Order ID'}
        sx={{
          '.MuiInputBase-root': {
            '.Mui-disabled': {
              WebkitTextFillColor: palette.black.main,
            },
          },
          width: '100%',
        }}
        disabled={true}
      />
    </Stack>
  );
};
