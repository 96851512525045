import { gql } from '@apollo/client';

export const FRAGMENT_MESSAGE = gql`
  fragment Message on MessageEntity {
    id
    authorId
    authorName
    conversationId
    createdAt
    text
    images {
      filename
      id
      messageId
    }
  }
`;
