import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)(() => {
  return {
    width: '100%',
  };
});

export const ContentBlockCSS = styled(Stack)(({ theme }) => {
  return {
    flex: 1,
    borderRadius: '32px 0 32px 32px',
    padding: '32px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.vars.palette.silverDark.main,
    gap: '26px',
    maxHeight: 'calc(100vh - 76px - 52px)',
  };
});

export const InnerCSS = styled(Stack)(({ theme }) => {
  return {
    flexDirection: 'column',
    flex: 1,
    padding: '16px',
    backgroundColor: theme.vars.palette.whiteBlack.main,
    borderRadius: '44px',
  };
});
