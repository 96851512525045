import { Accordion, AccordionSummary, Stack, styled } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';

export const AccordionCSS = styled(Accordion)(({ theme }) => ({
  margin: 0,
  background: 'transparent',
  borderBottom: `1px solid ${theme.vars.palette.lightSilverDarkSilver.main}`,
  boxShadow: 'none',

  '&.MuiPaper-root': {
    margin: 0,
    maxHeight: 'auto',
  },

  '&:before': {
    display: 'none',
  },

  '&.Mui-expanded': {
    margin: 0,
  },

  '&:first-of-type, &:last-of-type': {
    borderRadius: 0,
  },

  '&:last-of-type': {
    borderBottom: 'none',
  },
}));

export const AccordionSummaryCSS = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,

  '& .MuiAccordionSummary-content': {
    margin: '16px 0',
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    '& svg': {
      width: '24px',
      height: '24px',

      '& path': {
        fill: theme.vars.palette.blackWhite.main,
      },
    },

    '& .Mui-expanded': {
      transform: 'rotate(180deg)',
    },
  },
}));

export const AccordionDetailsCSS = styled(AccordionDetails)({
  padding: '0 0 16px 0',
});

export const AccordionAdditionalInfo = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: '16px',

  '& span': {
    minWidth: '135px',
  },
});
