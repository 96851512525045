import { ContentBlockTopLabel } from '../ContentBlockTopLabel/ContentBlockTopLabel';
import { TemplateIcon } from '../../../Icons';
import { InnerContentBlockCSS } from '../Main/styles';
import { ChangeEvent, memo, useState } from 'react';
import { Stack } from '@mui/material';
import { ChatTemplateList } from '../ChatTemplateList/ChatTemplateList';
import { ChatTemplateEditingList } from '../ChatTemplateEditingList/ChatTemplateEditingList';
import { ShopEntity } from '../../../../graphql/generated';
import { useAuthContext } from '../../../../context';

interface TemplatesMainProps {
  shopData?: ShopEntity;
}

export const TemplatesMain = memo(({ shopData }: TemplatesMainProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { roles } = useAuthContext();
  const shopId = Number(shopData?.id);

  const handleText = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const templateData = shopData?.templates.filter((template) => {
    if (!searchText) return template;

    return (
      template?.shortcut?.includes(searchText) ||
      template?.text?.includes(searchText) ||
      template?.name?.includes(searchText)
    );
  });

  const handleEdit = () => {
    setIsEdit((prevState) => !prevState);
  };

  return (
    <>
      <ContentBlockTopLabel
        icon={<TemplateIcon />}
        label={'Templates'}
        mb={'24px'}
        {...(!roles?.isAgent && { onClick: handleEdit })}
        isEdit={isEdit}
      />
      <InnerContentBlockCSS sx={{ borderRadius: 0 }}>
        <Stack gap={'16px'} mt={'2px'}>
          {!isEdit ? (
            <ChatTemplateList
              templateData={templateData}
              onSearch={handleText}
              value={searchText}
            />
          ) : (
            <ChatTemplateEditingList templateData={templateData} shopId={shopId} />
          )}
        </Stack>
      </InnerContentBlockCSS>
    </>
  );
});
