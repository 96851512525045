import {
  ChatMessageInfoCSS,
  ChatMessageLoadingButtonCSS,
  ChatMessageTopCSS,
  Wrapper,
} from './styles';
import { Avatar, Stack, Typography } from '@mui/material';
import { UserMessageIcon } from '../Icons/UserMessageIcon';
import { useChatTimeInterval, usePalette } from '../../hooks';
import { memo } from 'react';
import { ChatMessageProps } from './types';
import { MessageText } from '../Containers/ToChatsPage';

export const ChatMessage = memo(
  ({
    photo,
    name,
    position,
    date,
    messageData,
    isConsumer,
    isPending,
    onDelete,
    id,
  }: ChatMessageProps) => {
    const { palette } = usePalette();
    const { timer } = useChatTimeInterval({ isPending });

    const wrapperBgColor = isConsumer
      ? palette.lightSilverDark.main
      : isPending
      ? palette.lightBlue.main
      : palette.blue.main;

    const contentColor = isConsumer
      ? palette.blackWhite.main
      : isPending
      ? palette.silver100.main
      : palette.black.main;

    const dateColor = isConsumer
      ? palette.blackWhite.main
      : isPending
      ? palette.blackWhite.main
      : palette.black.main;

    return (
      <Wrapper
        sx={{
          bgcolor: wrapperBgColor,
          ...(!isConsumer && { marginLeft: 'auto' }),
        }}
      >
        <ChatMessageTopCSS direction='row' alignItems='center'>
          <ChatMessageInfoCSS>
            <Avatar src={photo} sx={{ bgcolor: palette.whiteBlack.main }}>
              <UserMessageIcon color={palette.blackWhite.main} />
            </Avatar>
            <Stack marginLeft='8px'>
              <Typography variant='body4' color={contentColor}>
                {name}
              </Typography>
              <Typography
                variant='caption'
                color={isPending ? palette.silver100.main : palette.black.main}
              >
                {position}
              </Typography>
            </Stack>
          </ChatMessageInfoCSS>
          <Typography variant='caption' color={dateColor}>
            <Stack>
              <Typography
                variant='caption'
                component='p'
                color={isConsumer ? palette.blackWhite.main : palette.black.main}
              >
                {isPending ? `This message will be sending in ${timer}` : date}
              </Typography>
              {onDelete && (
                <ChatMessageLoadingButtonCSS onClick={() => onDelete(id)}>
                  Cancel
                </ChatMessageLoadingButtonCSS>
              )}
            </Stack>
          </Typography>
        </ChatMessageTopCSS>
        <MessageText messageData={messageData} contentColor={contentColor} />
      </Wrapper>
    );
  },
);
