import { IconProps } from './types';

export const ClockIcon = ({ color = '#0E0E0E' }: IconProps) => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.96852 2.33568C6.29197 2.07692 6.34441 1.60495 6.08565 1.28151C5.82689 0.95806 5.35493 0.905619 5.03148 1.16438L2.53148 3.16438C2.20803 3.42313 2.15559 3.8951 2.41435 4.21855C2.67311 4.542 3.14507 4.59444 3.46852 4.33568L5.96852 2.33568Z'
        fill={color}
      />
      <path
        d='M18.9685 1.16438C18.6451 0.905619 18.1731 0.95806 17.9143 1.28151C17.6556 1.60495 17.708 2.07692 18.0315 2.33568L20.5315 4.33568C20.8549 4.59444 21.3269 4.542 21.5857 4.21855C21.8444 3.8951 21.792 3.42313 21.4685 3.16438L18.9685 1.16438Z'
        fill={color}
      />
      <path
        d='M12.75 8.50003C12.75 8.08581 12.4142 7.75003 12 7.75003C11.5858 7.75003 11.25 8.08581 11.25 8.50003L11.25 12C11.25 12.4142 10.9142 12.75 10.5 12.75H9C8.58579 12.75 8.25 13.0858 8.25 13.5C8.25 13.9142 8.58579 14.25 9 14.25H10.5C11.7426 14.25 12.75 13.2427 12.75 12L12.75 8.50003Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 1.75003C6.06294 1.75003 1.25 6.56297 1.25 12.5C1.25 18.4371 6.06294 23.25 12 23.25C17.9371 23.25 22.75 18.4371 22.75 12.5C22.75 6.56297 17.9371 1.75003 12 1.75003ZM2.75 12.5C2.75 7.39139 6.89137 3.25003 12 3.25003C17.1086 3.25003 21.25 7.39139 21.25 12.5C21.25 17.6087 17.1086 21.75 12 21.75C6.89137 21.75 2.75 17.6087 2.75 12.5Z'
        fill={color}
      />
    </svg>
  );
};
