interface IconsTypes {
  width?: string;
  height?: string;
  color?: string;
  size?: string;
}

export const NumericalStarIcon = ({
  width = '16',
  height = '16',
  color = '#0C090C',
}: IconsTypes) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      color='#fff'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.5 4.66667C8.5 4.39052 8.27614 4.16667 8 4.16667C7.72386 4.16667 7.5 4.39052 7.5 4.66667V7.07895L5.85346 5.4324C5.6582 5.23714 5.34161 5.23714 5.14635 5.4324C4.95109 5.62766 4.95109 5.94425 5.14635 6.13951L6.50684 7.5H4.66667C4.39052 7.5 4.16667 7.72386 4.16667 8C4.16667 8.27614 4.39052 8.5 4.66667 8.5H6.79289L5.14645 10.1464C4.95118 10.3417 4.95118 10.6583 5.14645 10.8536C5.34171 11.0488 5.65829 11.0488 5.85355 10.8536L7.5 9.20711V11.3333C7.5 11.6095 7.72386 11.8333 8 11.8333C8.27614 11.8333 8.5 11.6095 8.5 11.3333V9.49316L9.8604 10.8536C10.0557 11.0488 10.3722 11.0488 10.5675 10.8536C10.7628 10.6583 10.7628 10.3417 10.5675 10.1464L8.92105 8.5H11.3333C11.6095 8.5 11.8333 8.27614 11.8333 8C11.8333 7.72386 11.6095 7.5 11.3333 7.5H9.20711L10.5676 6.13951C10.7629 5.94425 10.7629 5.62766 10.5676 5.4324C10.3723 5.23714 10.0558 5.23714 9.86049 5.4324L8.5 6.79289V4.66667Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.9757 1.58249C10.2143 1.49999 9.25452 1.49999 8.03017 1.5H7.96983C6.74548 1.49999 5.78574 1.49999 5.02432 1.58249C4.24729 1.66667 3.61771 1.84155 3.08016 2.2321C2.75473 2.46854 2.46854 2.75473 2.2321 3.08016C1.84155 3.61771 1.66667 4.24729 1.58249 5.02432C1.49999 5.78574 1.49999 6.74548 1.5 7.96983V8.03017C1.49999 9.25452 1.49999 10.2143 1.58249 10.9757C1.66667 11.7527 1.84155 12.3823 2.2321 12.9198C2.46854 13.2453 2.75473 13.5315 3.08016 13.7679C3.61771 14.1585 4.24729 14.3333 5.02432 14.4175C5.78573 14.5 6.74544 14.5 7.96977 14.5H8.03018C9.25451 14.5 10.2143 14.5 10.9757 14.4175C11.7527 14.3333 12.3823 14.1585 12.9198 13.7679C13.2453 13.5315 13.5315 13.2453 13.7679 12.9198C14.1585 12.3823 14.3333 11.7527 14.4175 10.9757C14.5 10.2143 14.5 9.25456 14.5 8.03023V7.96982C14.5 6.74549 14.5 5.78573 14.4175 5.02432C14.3333 4.24729 14.1585 3.61771 13.7679 3.08016C13.5315 2.75473 13.2453 2.46854 12.9198 2.2321C12.3823 1.84155 11.7527 1.66667 10.9757 1.58249ZM3.66794 3.04112C4.00661 2.79506 4.44009 2.65163 5.13203 2.57667C5.83333 2.50069 6.73895 2.5 8 2.5C9.26105 2.5 10.1667 2.50069 10.868 2.57667C11.5599 2.65163 11.9934 2.79506 12.3321 3.04112C12.5726 3.21588 12.7841 3.42741 12.9589 3.66794C13.2049 4.00661 13.3484 4.44009 13.4233 5.13203C13.4993 5.83333 13.5 6.73895 13.5 8C13.5 9.26105 13.4993 10.1667 13.4233 10.868C13.3484 11.5599 13.2049 11.9934 12.9589 12.3321C12.7841 12.5726 12.5726 12.7841 12.3321 12.9589C11.9934 13.2049 11.5599 13.3484 10.868 13.4233C10.1667 13.4993 9.26105 13.5 8 13.5C6.73895 13.5 5.83333 13.4993 5.13203 13.4233C4.44009 13.3484 4.00661 13.2049 3.66794 12.9589C3.42741 12.7841 3.21588 12.5726 3.04112 12.3321C2.79506 11.9934 2.65163 11.5599 2.57667 10.868C2.50069 10.1667 2.5 9.26105 2.5 8C2.5 6.73895 2.50069 5.83333 2.57667 5.13203C2.65163 4.44009 2.79506 4.00661 3.04112 3.66794C3.21588 3.42741 3.42741 3.21588 3.66794 3.04112Z'
        fill={color}
      />
    </svg>
  );
};
