import { gql } from '@apollo/client';

export const FRAGMENT_SHOP = gql`
  fragment FragmentShop on ShopEntity {
    id
    name
    email
    phone
    smsAvailable
    emailAvailable
    liveChatAvailable
    tone
    agentFirstName
    notes
    deactivated
    deactivatedAt
    url
    domainId
    createdAt
    templates {
      id
      name
      text
      shortcut
      type
    }
  }
`;
