import { Box } from '@mui/material';
import { ModalWrapper, ModalFormWrapperCSS } from './styles';

import { MODAL_NAME } from '../../../constants/constants';
import {
  AreYouSureModal,
  AddAndEditUserModal,
  TestModal,
  ShopSettingsModal,
  ChatModal,
  TemplateChangesConfirmationModal,
  SimpleModal,
} from '../index';
import { useModalContext } from '../../../context';
import { BoolAlias } from '../../../types';

const FULL_SCREEN_MODAL_NAME = [MODAL_NAME.SHOPS_SETTINGS, MODAL_NAME.CHAT];

export const ModalContent = () => {
  const { infoModal, onCloseModal } = useModalContext();
  if (!infoModal.name) return null;

  const isFullScreenModal = FULL_SCREEN_MODAL_NAME.includes(infoModal.name);
  const isFullScreenModalToString = String(isFullScreenModal) as BoolAlias;

  return (
    <ModalWrapper
      open={infoModal.open}
      onClose={onCloseModal}
      isfullscreenmodal={isFullScreenModalToString}
    >
      <ModalFormWrapperCSS isfullscreenmodal={isFullScreenModalToString}>
        <TypeModal />
      </ModalFormWrapperCSS>
    </ModalWrapper>
  );
};

const TypeModal = () => {
  const { infoModal } = useModalContext();

  switch (infoModal.name) {
    case MODAL_NAME.TEST:
      return <TestModal />;
    case MODAL_NAME.ARE_YOUR_SURE:
      return <AreYouSureModal />;
    case MODAL_NAME.ADD_AND_EDIT_USER:
      return <AddAndEditUserModal />;
    case MODAL_NAME.SHOPS_SETTINGS:
      return <ShopSettingsModal />;
    case MODAL_NAME.CHAT:
      return <ChatModal />;
    case MODAL_NAME.SIMPLE:
      return <SimpleModal />;
    case MODAL_NAME.TEMPLATE_CHANGES_CONFIRMATION:
      return <TemplateChangesConfirmationModal />;
    default:
      return <Box />;
  }
};
