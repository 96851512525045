import { OrderItemListCSS, Wrapper } from './styles';
import { PencilIcon } from '../Icons';
import { Button } from '@mui/material';
import { OrderItem } from '../OrderItem/OrderItem';
import { AccordionItemType } from '../../types';

interface OrderItemListProps {
  isEditor?: boolean;
  itemId?: string | number;
  itemList: AccordionItemType[];
  onEdit?: (id?: string | number) => void;
}

export const OrderItemList = ({ itemList, onEdit, itemId, isEditor }: OrderItemListProps) => {
  return (
    <Wrapper iseditor={String(isEditor)}>
      <OrderItemListCSS>
        {itemList.map((item) => {
          return (
            <OrderItem
              key={item.id}
              label={item.label}
              content={item.content as string}
              status={item?.status || ['']}
            />
          );
        })}
      </OrderItemListCSS>
      {onEdit && (
        <Button
          onClick={() => onEdit(itemId)}
          variant='edit'
          size='small'
          startIcon={<PencilIcon />}
        >
          Edit
        </Button>
      )}
    </Wrapper>
  );
};
