import { SxProps } from '@mui/material/styles';
import { ReactNode, SyntheticEvent } from 'react';
import { ButtonCSS, Wrapper } from './styles';
import { PointCSS } from '../FilterButtonsGroup/styles';
import { FormLabel, Stack, Typography } from '@mui/material';
import { Controller, FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form';

export type TabsOptionType = {
  id: string;
  label: string;
};

interface TabsCustomProps {
  options: TabsOptionType[];
  value: string;
  setValue: (value: string) => void;
  mb?: string;
  sx?: SxProps;
  asButtonsGroup?: boolean;
  label?: string;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl<object>>;
  iconComponent?: ReactNode;
  isMoreColored?: boolean;
}

interface TabsRHFProps extends Omit<TabsCustomProps, 'value' | 'setValue'> {
  name: string;
}

export const TabsCustom = ({
  options,
  value,
  setValue,
  mb,
  sx,
  asButtonsGroup = false,
  label,
  iconComponent,
  isMoreColored,
}: TabsCustomProps) => {
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Stack>
      {label ? (
        <FormLabel focused={false} aria-label={`input-medium`}>
          <Typography>{label}</Typography>
        </FormLabel>
      ) : null}
      <Wrapper
        asbuttonsgroup={String(asButtonsGroup)}
        direction={'row'}
        sx={{ marginBottom: mb, ...sx }}
      >
        {options.map(({ id, label }) => {
          return (
            <ButtonCSS
              key={id}
              id={id}
              ismorecolored={String(isMoreColored)}
              asbuttonsgroup={String(asButtonsGroup)}
              onClick={(e: SyntheticEvent) => handleChange(e, id)}
              size={'small'}
              variant={value === id ? 'contained' : 'outlined'}
              {...(asButtonsGroup && {
                startIcon: iconComponent || (
                  <PointCSS variant={'contained'} ismorecolored={String(isMoreColored)} />
                ),
              })}
            >
              {label}
            </ButtonCSS>
          );
        })}
      </Wrapper>
    </Stack>
  );
};

export const TabsRHF = ({ name, ...rest }: TabsRHFProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        return (
          <TabsCustom
            value={field.value}
            setValue={field.onChange}
            errorMessage={formState.errors?.[name]?.message}
            {...rest}
          />
        );
      }}
    />
  );
};
