import { Stack, Typography } from '@mui/material';
import { PencilIcon, TrashIcon } from '../../../Icons';
import { ButtonsBlockCSS, TemplateButtonCSS, Wrapper } from './styles';
import { TemplateSchema } from '../../../../graphql/generated';

interface ChatTemplateEditingItemProps extends TemplateSchema {
  onEdit: (id: number) => void;
  onRemove: (id: number) => void;
}

export const ChatTemplateEditingItem = ({
  id,
  text,
  shortcut,
  onEdit,
  onRemove,
}: ChatTemplateEditingItemProps) => {
  return (
    <Wrapper>
      <Stack>
        <Typography variant='body6' lineHeight={'22px'}>
          {text}
        </Typography>
        <Typography variant='caption' lineHeight={'18px'}>
          {shortcut}
        </Typography>
      </Stack>
      <ButtonsBlockCSS>
        <TemplateButtonCSS type='button' onClick={() => onEdit(id)}>
          <PencilIcon />
        </TemplateButtonCSS>
        <TemplateButtonCSS type='button' onClick={() => onRemove(id)}>
          <TrashIcon />
        </TemplateButtonCSS>
      </ButtonsBlockCSS>
    </Wrapper>
  );
};
