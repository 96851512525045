import { IconProps } from './types';

export const LetterIcon = ({ color = '#0E0E0E' }: IconProps) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 15.25C0.585786 15.25 0.25 15.5858 0.25 16C0.25 16.4142 0.585786 16.75 1 16.75H4C4.41421 16.75 4.75 16.4142 4.75 16C4.75 15.5858 4.41421 15.25 4 15.25H1Z'
        fill={color}
      />
      <path
        d='M1 18.25C0.585786 18.25 0.25 18.5858 0.25 19C0.25 19.4142 0.585786 19.75 1 19.75H7C7.41421 19.75 7.75 19.4142 7.75 19C7.75 18.5858 7.41421 18.25 7 18.25H1Z'
        fill={color}
      />
      <path
        d='M3.10885 5.09007L3 5C3.3084 4.57553 3.6366 4.26331 4.06107 3.95491C5.3754 3 7.25027 3 11 3H13C16.7497 3 18.6246 3 19.9389 3.95491C20.3634 4.26331 20.6657 4.54132 20.9741 4.96579L20.8485 5.0907L18.5406 7.39861C16.8589 9.08027 15.6499 10.287 14.6071 11.0827C13.5816 11.8651 12.8055 12.1789 11.9999 12.1789C11.1942 12.1789 10.4181 11.8651 9.39261 11.0827C8.3498 10.287 7.14079 9.08027 5.45913 7.39861L3.51587 5.45535L3.10885 5.09007Z'
        fill={color}
      />
      <path
        d='M2 12C2 9.36008 2 7.64946 2.33322 6.40947L2.48385 6.54466L4.43904 8.49984C6.07131 10.1321 7.35049 11.4113 8.48274 12.2752C9.64167 13.1594 10.7344 13.6789 11.9999 13.6789C13.2653 13.6789 14.3581 13.1594 15.517 12.2752C16.6492 11.4113 17.9284 10.1321 19.5607 8.49986L21.6634 6.39709C22 7.63806 22 9.35129 22 12C22 15.7497 22 17.6246 21.0451 18.9389C20.7367 19.3634 20.3634 19.7367 19.9389 20.0451C18.6246 21 16.7497 21 13 21H11C9.57164 21 8.41532 21 7.45841 20.9472C8.34204 20.74 9 19.9468 9 19C9 17.8954 8.10457 17 7 17H5.73244C5.90261 16.7058 6 16.3643 6 16C6 14.8954 5.10457 14 4 14H2.00721C2 13.3989 2 12.7355 2 12Z'
        fill={color}
      />
    </svg>
  );
};
