import { Button, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import {
  HomeAgentMainContent,
  HomeSuperadminAndAdminMainContent,
  InfoBlock,
} from '../../components';
import { PlayIcon } from '../../components/Icons';
import { useAuthContext, useModalContext } from '../../context';
import { useGetAndAssignNextConversationMutation } from '../../graphql/generated';
import { useMetricAndMessage } from '../../hooks';
import { Wrapper } from './styles';
import { ERROR_MESSAGE, MODAL_NAME, ROUTES } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';

export const Home = () => {
  const { roles } = useAuthContext();
  const { onOpenModal } = useModalContext();
  const { metricAndMessageData, messageCount } = useMetricAndMessage();
  const { userData } = useAuthContext();
  const currentUserId = userData?.id;
  const navigate = useNavigate();

  const isAgent = roles?.isAgent;

  const MainContentElement = {
    isAgent: HomeAgentMainContent,
    isAdmin: HomeSuperadminAndAdminMainContent,
  }[isAgent ? 'isAgent' : 'isAdmin'];

  const [getAndAssignNextConversation] = useGetAndAssignNextConversationMutation({
    onError: (error) => {
      if (error.message === ERROR_MESSAGE.NO_CONVERSATION_FOUND) {
        onOpenModal(MODAL_NAME.SIMPLE, { text: error.message });
      } else {
        toast.error(error.message);
      }
    },
    update: (cache) => {
      cache.modify({
        id: `UserEntity:${currentUserId}`,
        fields: {
          assignedConversation() {},
        },
      });
    },
    onCompleted: (data) => {
      navigate(`${ROUTES.chat}/${data.getAndAssignNextConversation.id}`);
    },
  });

  const handleStartMessage = async () => {
    await getAndAssignNextConversation();
  };

  return (
    <>
      <Wrapper>
        <Typography variant={'big'}>Home page</Typography>
        {!roles?.isAgent && (
          <Button
            variant={'startMessage'}
            startIcon={<PlayIcon height={'18px'} width={'18px'} />}
            disabled={!messageCount}
            onClick={handleStartMessage}
            sx={{ minWidth: '270px' }}
          >
            Start Messaging ({messageCount})
          </Button>
        )}
        <Stack direction={'row'} gap={'16px'}>
          {metricAndMessageData.map((data) => (
            <InfoBlock key={data.id} {...data} />
          ))}
        </Stack>
      </Wrapper>
      <MainContentElement handleStartMessage={handleStartMessage} messageCount={messageCount} />
    </>
  );
};
