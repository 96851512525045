import * as yup from 'yup';
import { messages as M } from '../messages';
import { OPTION_ORDER_STATUS_TABS_ENUM } from '../../constants/constants';

export const shippingFormSchema = yup.object().shape({
  // trackingID: yup.string().required(M.REQUIRE_MESSAGE),
  // trackingURL: yup.array().of(yup.string()).required(M.REQUIRE_MESSAGE),
  orderID: yup.string().required(M.REQUIRE_MESSAGE),
  // orderStatus: yup.string().required(M.REQUIRE_MESSAGE),
  total: yup.number().min(0, M.MAX_NUMBER_VALUE('0')).required(M.REQUIRE_MESSAGE),

  discount: yup.number().when('orderStatus', {
    is: (orderStatus: OPTION_ORDER_STATUS_TABS_ENUM) => {
      return orderStatus === OPTION_ORDER_STATUS_TABS_ENUM.PARTIAL_REFUNDED;
    },
    then: yup.number().typeError('').min(0, M.MIN_NUMBER_VALUE('0')).required(M.REQUIRE_MESSAGE),
    otherwise: yup.number().nullable(),
  }),
});
