import { Button, Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)({
  gap: '16px',
  maxWidth: '454px',
  width: '100%',
  padding: '12px 12px 18px 12px',
  borderRadius: '26px',
});

export const ChatMessageTopCSS = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const ChatMessageInfoCSS = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
});

export const ChatMessageLoadingButtonCSS = styled(Button)(({ theme }) => ({
  alignSelf: 'self-end',
  width: '40px',
  minHeight: 'auto',
  height: 'auto',
  color: theme.vars.palette.black.main,
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '14px',
  background: 'none',
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',

  '&:hover': {
    background: 'none',
    boxShadow: 'none',
  },
}));
