import { Button, FormLabel, Stack, SxProps, Typography } from '@mui/material';
import { PointCSS } from './styles';

type optionFilter = {
  id: string;
  label: string;
};

interface FilterButtonsGroupProps {
  optionsFilter: optionFilter[];
  activeFilter: string[];
  onClick: (val: string) => void;
  sx?: SxProps;
  label?: string;
}
export const FilterButtonsGroup = ({
  optionsFilter,
  activeFilter,
  onClick,
  sx,
  label,
}: FilterButtonsGroupProps) => {
  return (
    <Stack sx={sx}>
      {label ? (
        <FormLabel focused={false} aria-label={`select-small`}>
          <Typography>{label}</Typography>
        </FormLabel>
      ) : null}
      <Stack gap={'8px'} direction={'row'}>
        {optionsFilter.map((option) => {
          const isActive = activeFilter.includes(option.id);
          const variant = isActive ? 'contained' : 'outlined';

          return (
            <Button
              onClick={() => onClick(option.id)}
              key={option.id}
              size={'small'}
              variant={variant}
              startIcon={<PointCSS variant={variant} />}
            >
              {option.label}
            </Button>
          );
        })}
      </Stack>
    </Stack>
  );
};
