import { ButtonsBlockCSS, ListItemCSS, TemplateButtonCSS, TopInfoCSS } from './styles';
import { Stack, Typography } from '@mui/material';
import { PencilIcon, TrashIcon } from '../Icons';

interface CompanyTemplateRowProps {
  onEdit: (id: number) => void;
  onRemove: (id: number) => void;
  id: number;
  name: string;
  text: string;
}
export const CompanyTemplateRow = ({
  id,
  name,
  onEdit,
  onRemove,
  text,
}: CompanyTemplateRowProps) => {
  return (
    <Stack key={id}>
      <ListItemCSS disablePadding>
        <TopInfoCSS>
          <Typography variant='h3'>{name}</Typography>
          <ButtonsBlockCSS>
            <TemplateButtonCSS type='button' onClick={() => onEdit(id)}>
              <PencilIcon />
            </TemplateButtonCSS>
            <TemplateButtonCSS type='button' onClick={() => onRemove(id)}>
              <TrashIcon />
            </TemplateButtonCSS>
          </ButtonsBlockCSS>
        </TopInfoCSS>
        <Typography variant='body2'>{text}</Typography>
      </ListItemCSS>
    </Stack>
  );
};
