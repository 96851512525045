import { usePalette } from '../../hooks';
import { IconButton, Stack } from '@mui/material';
import { ChevronBottomIcon, DeleteIcon } from '../Icons';
import Typography from '@mui/material/Typography';
import { LinkCSS, ShippingItemRowWrapperCSS } from './styles';
import { getTitleNameToShippingItem } from '../../utils';
import { ShippingItemProps, ShippingItemRowProps } from './types';

export const ShippingItemRow = ({
  row,
  onRemove,
  index,
  onEditQuantity,
  isOffEditItem,
}: ShippingItemRowProps) => {
  const { palette } = usePalette();

  return (
    <ShippingItemRowWrapperCSS>
      {Object.keys(row)
        .filter((item) => item !== '__typename' && item !== 'id')
        .sort((a, b) => {
          const order: Record<string, number> = { title: 1, link: 2, quantity: 3, price: 4 };
          return order[a] - order[b];
        })
        .map((key) => {
          return (
            <ShippingItem
              key={key}
              label={key}
              content={row[key]}
              onEditQuantity={onEditQuantity}
              index={index}
              isOffEditItem={isOffEditItem}
            />
          );
        })}
      {!isOffEditItem && (
        <IconButton onClick={() => onRemove(index)}>
          <DeleteIcon color={palette.blackWhite.main} />
        </IconButton>
      )}
    </ShippingItemRowWrapperCSS>
  );
};

export const ShippingItem = ({
  label,
  content,
  onEditQuantity,
  index,
  isOffEditItem,
}: ShippingItemProps) => {
  const { palette } = usePalette();

  const title = getTitleNameToShippingItem(label);
  const isLinkLabel = label === 'link';
  const isQuantityLabel = label === 'quantity';

  return (
    <Stack gap={'2px'} sx={{ overflowWrap: 'anywhere' }}>
      <Typography
        variant='caption'
        color={palette.lightSilverWhite.main}
        sx={{ textTransform: 'capitalize' }}
      >
        {title}
      </Typography>
      {isLinkLabel ? (
        <LinkCSS href={content} target='_blank' rel='noopener noreferrer'>
          <Typography variant='body4'>{content}</Typography>
        </LinkCSS>
      ) : isQuantityLabel ? (
        <Stack direction={'row'}>
          <Typography variant='body4' minWidth={'20px'}>
            {content}
          </Typography>
          {!isOffEditItem && (
            <Stack direction={'row'} ml={'12px'}>
              <IconButton
                size={'sm'}
                onClick={() => onEditQuantity({ action: 'decrement', id: index })}
              >
                <ChevronBottomIcon color={palette.blackWhite.main} sx={{ rotate: '90deg' }} />
              </IconButton>
              <IconButton
                size={'sm'}
                onClick={() => onEditQuantity({ action: 'increment', id: index })}
              >
                <ChevronBottomIcon color={palette.blackWhite.main} sx={{ rotate: '-90deg' }} />
              </IconButton>
            </Stack>
          )}
        </Stack>
      ) : (
        <Typography variant='body4' component='p'>
          {content}
        </Typography>
      )}
    </Stack>
  );
};
