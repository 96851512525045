import { ChatGeneral } from '../../components/Containers/ToChatsPage/СhatGeneral/СhatGeneral';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';
import {
  ConversationEntity,
  useGetConversationQuery,
  useGetCurrentConversationQuery,
} from '../../graphql/generated';
import { toast } from 'react-toastify';

export const ChatPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const conversationId = params?.id;

  const goHome = () => {
    navigate(ROUTES.home);
  };

  const { data, loading, error } = useGetCurrentConversationQuery({
    onError: () => {
      goHome();
    },
    fetchPolicy: 'network-only',
    skip: !!conversationId,
  });

  const {
    data: convoDataById,
    loading: convoLoadingById,
    error: convoErrorById,
  } = useGetConversationQuery({
    fetchPolicy: 'network-only',
    variables: {
      conversationId: Number(conversationId),
    },
    skip: !conversationId,
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return (
    <ChatGeneral
      isPage={true}
      data={(data?.getCurrentConversation || convoDataById?.getConversation) as ConversationEntity}
      loading={loading || convoLoadingById}
      error={error || convoErrorById}
    />
  );
};
