import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import {
  useGetCurrentUserQuery,
  useGoogleAuthMutation,
  UserEntity,
  UserRoleEnum,
} from '../graphql/generated';
import { toast } from 'react-toastify';
import { AUTH_TOKEN } from '../constants/constants';
import { RequestHandler } from '../components/RequestHandler/RequestHandler';
import { client, useErrorsStore } from '../graphql/client';
import { GET_CURRENT_USER } from '../graphql/queries/getCurrentUser.gql';

type AuthContextType = {
  isAuth: boolean;
  signIn: () => void;
  signOut: () => void;
  userData?: UserEntity;
  roles?: Record<'isSuperAdmin' | 'isAdmin' | 'isAgent', boolean>;
};

export const AuthContext = createContext<AuthContextType>({
  isAuth: false,
  signIn: () => {},
  signOut: () => {},
});

export function useAuthContext() {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return authContext;
}

type AuthContextProviderType = {
  children: ReactNode;
};

export function AuthContextProvider({ children }: AuthContextProviderType) {
  const [isAuth, setIsAuth] = useState(false);
  const { error } = useErrorsStore();
  const token = localStorage.getItem(AUTH_TOKEN);

  const signOut = () => {
    client.clearStore(); // for clearing cache in apollo
    localStorage.removeItem(AUTH_TOKEN);
    setIsAuth(false);
  };

  const { data: userData, loading } = useGetCurrentUserQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsAuth(true);
      if (
        data.getCurrentUser.role === UserRoleEnum.Manager ||
        data.getCurrentUser.role === UserRoleEnum.Agent
      ) {
        // Начинаем отсчет при загрузке страницы или после успешного входа пользователя:
        resetLogoutTimer();
      }
    },
    onError: () => {
      signOut();
    },
    skip: !token,
  });

  const [login] = useGoogleAuthMutation();

  useEffect(() => {
    if (token) {
      setIsAuth(true);
    }
  }, []);

  useEffect(() => {
    if (error?.message === 'Invalid Jwt Error') {
      signOut();
    }
  }, [error]);

  const googleAuth = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      login({
        variables: {
          input: {
            token: tokenResponse.access_token,
          },
        },
        onCompleted: (data) => {
          const accessToken = data.googleAuth.accessToken;
          localStorage.setItem(AUTH_TOKEN, accessToken);
          setIsAuth(true);
        },
        onError: () => {
          toast.error('Access denied');
        },
        refetchQueries: [GET_CURRENT_USER],
      });
    },
  });

  const signIn = () => {
    client.stop();
    client.clearStore();
    googleAuth();
  };

  const role = userData?.getCurrentUser.role;
  const roles = {
    isSuperAdmin: role === UserRoleEnum.Admin,
    isAdmin: role === UserRoleEnum.Manager,
    isAgent: role === UserRoleEnum.Agent,
  };

  let logoutTimer: NodeJS.Timeout;

  function resetLogoutTimer() {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(() => {
      signOut();
    }, 15 * 60 * 1000); // 15 min
  }

  function handleUserAction() {
    resetLogoutTimer();
  }

  useEffect(() => {
    if (
      userData?.getCurrentUser.role === UserRoleEnum.Agent ||
      userData?.getCurrentUser.role === UserRoleEnum.Manager
    ) {
      document.addEventListener('mousemove', handleUserAction);
      document.addEventListener('keydown', handleUserAction);
    }
    return () => {
      document.removeEventListener('mousemove', handleUserAction);
      document.removeEventListener('keydown', handleUserAction);
    };
  });

  return (
    <AuthContext.Provider
      value={{
        isAuth: isAuth,
        signIn: signIn,
        signOut: signOut,
        userData: userData?.getCurrentUser,
        roles,
      }}
    >
      <RequestHandler height={'100vh'} loading={loading}>
        {children}
      </RequestHandler>
    </AuthContext.Provider>
  );
}
