import { ItemSchema } from '../graphql/generated';
import { ORDER_LABEL_ENUM } from '../constants/constants';

export const createItemsOrderData = (data?: ItemSchema[]) => {
  return data?.map((item) => {
    return {
      items: [
        { id: 1, label: ORDER_LABEL_ENUM.Item, content: item.title },
        { id: 2, label: ORDER_LABEL_ENUM.Link, content: item.link },
        { id: 3, label: ORDER_LABEL_ENUM.Quantity, content: item.quantity },
        { id: 4, label: ORDER_LABEL_ENUM.Price, content: item.price },
      ],
    };
  });
};
