import { Stack, Typography } from '@mui/material';
import { MetricsPageFiltersGroup } from '../../components/Containers';
import { useForm } from 'react-hook-form';
import { useFilters, usePagination, useTriggerByScrollTable } from '../../hooks';
import { METRIC_TABLE_OPTIONS, OPTIONS_FILTER, QUERY_LIMIT } from '../../constants/constants';
import {
  ConversationProviderEnum,
  useFindAndCountManyUsersQuery,
  useGetMetricsQuery,
  UserMetric,
} from '../../graphql/generated';
import { TableList } from '../../components';
import { formatMilliseconds, getEndDateFormatTo23H59M, stringConcat } from '../../utils';
import { useEffect } from 'react';

export type MetricsFormData = {
  startDate: string | null;
  endDate: string | null;
  agentId: number[];
};

const createData = (tableRowData: UserMetric) => {
  return {
    name: stringConcat([tableRowData.firstName, tableRowData.lastName]),
    messagesHandled: tableRowData.quantity,
    averageResponseTime: formatMilliseconds(tableRowData.averageResponseTime),
  };
};

export const MetricsPage = () => {
  const { paginationOptions, handleFetchMore, setPaginationOptions, pending } = usePagination();

  const methods = useForm<MetricsFormData>({
    defaultValues: {
      startDate: null,
      endDate: null,
      agentId: [],
    },
  });

  const { watch, reset } = methods;

  const [agentId, startDate, endDate] = watch(['agentId', 'startDate', 'endDate']);
  const isAllDateFieldFilled = Boolean(startDate && endDate);

  const { filterState, onClick: handleFilterClick } = useFilters({
    generalOption: OPTIONS_FILTER[0].id,
  });

  const { data: usersData } = useFindAndCountManyUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        limit: QUERY_LIMIT,
        offset: 0,
      },
    },
  });

  const options = {
    ...(isAllDateFieldFilled && {
      dateFrom: startDate,
      dateTo: getEndDateFormatTo23H59M(endDate),
    }),
    ...(filterState.toString().length && {
      providers: filterState as ConversationProviderEnum[],
    }),
    agentIds: agentId.length ? agentId : null,
    limit: paginationOptions.limit,
    offset: paginationOptions.offset,
  };

  const {
    data: metricData,
    loading: metricLoading,
    fetchMore,
    refetch,
  } = useGetMetricsQuery({
    variables: {
      input: options,
    },
  });

  useEffect(() => {
    refetch({
      input: {
        ...options,
        offset: 0,
      },
    });
    setPaginationOptions((prevState) => ({
      ...prevState,
      offset: 0,
    }));
  }, [agentId, isAllDateFieldFilled, endDate && startDate, filterState]);

  const optionAgentData = usersData?.findAndCountManyUsers.rows.map((user) => {
    return { id: user.id, label: user.firstName, value: user.id };
  });

  const rows = metricData?.getMetrics.rows.map((tableRowData) => createData(tableRowData)) || [];

  const handleReset = () => {
    reset();
    handleFilterClick('');
  };

  const { tableBodyRef } = useTriggerByScrollTable({
    triggerData: [rows],
    skip: pending,
    onAction: () => {
      handleFetchMore({
        fetchMore,
        dataCount: Number(metricData?.getMetrics),
      });
    },
  });

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} mt={'36px'} mb={'36px'}>
        <Typography variant={'big'}>Metrics</Typography>
        <MetricsPageFiltersGroup
          nameAgentSelect={'agentId'}
          nameStartDateField={'startDate'}
          nameEndDateField={'endDate'}
          startDate={startDate}
          methods={methods}
          filterState={filterState}
          onFilterClick={handleFilterClick}
          optionAgentData={optionAgentData}
          onReset={handleReset}
        />
      </Stack>
      <TableList
        isLoading={metricLoading}
        headCells={METRIC_TABLE_OPTIONS}
        rows={rows}
        isHasPagination={false}
        openButtonTitle='Open'
        isHasControlBadge={false}
        tableContainerSx={{ height: 'calc(100vh - 317px)' }}
        tableBodyRef={tableBodyRef}
      />
    </>
  );
};
