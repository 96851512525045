import { TextFieldRHF } from '../TextFieldRHF/TextFieldRHF';
import { ClickAwayListener, IconButton, Stack } from '@mui/material';
import { copyTextToClipboard } from '../../../utils/copyTextToClipboard';
import { ChevronBottomIcon } from '../../Icons';
import { CopyIcon } from '../../Icons/CopyIcon';
import { TrackingMultiDropMenu } from './TrackingMultiDropMenu';
import { usePalette } from '../../../hooks';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ShippingFormData } from '../../OrderInformation/types';

interface TrackingUrlMultiFieldRHFProps {
  name: keyof ShippingFormData;
  label?: string;
  placeholder?: string;
}
export const TrackingMultiFieldRHF = ({
  name,
  label,
  placeholder,
}: TrackingUrlMultiFieldRHFProps) => {
  const { watch } = useFormContext();
  const { palette } = usePalette();

  const [data] = watch([name]);
  const isMoreTrackingUrl = data?.length > 1;
  const urlFieldTextColor = palette.mail.main;

  const [hidden, setHidden] = useState(false);

  const handleShowMore = () => {
    setHidden((prevState) => !prevState);
  };

  const isData = data.length;

  return (
    <Stack
      width={'100%'}
      position={'relative'}
      sx={{ cursor: isMoreTrackingUrl ? 'pointer' : 'initial', zIndex: 11 }}
      onClick={() => setHidden(true)}
    >
      <TextFieldRHF
        name={name}
        {...(isMoreTrackingUrl && {
          value: data[0],
        })}
        label={label}
        size={'medium'}
        placeholder={placeholder}
        sx={{
          pointerEvents: isMoreTrackingUrl ? 'none' : 'initial',
          width: '100%',
          '.MuiInputBase-root': {
            color: urlFieldTextColor,
            input: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            '.Mui-disabled': {
              WebkitTextFillColor: 'inherit',
            },
          },
        }}
        disabled={true}
        endAdornment={
          <IconButton {...(!isMoreTrackingUrl && { onClick: () => copyTextToClipboard(data) })}>
            {isMoreTrackingUrl ? (
              <ChevronBottomIcon
                color={palette.black.main}
                sx={{ rotate: hidden ? '180deg' : '0deg' }}
              />
            ) : isData ? (
              <CopyIcon />
            ) : (
              <></>
            )}
          </IconButton>
        }
      />
      {isMoreTrackingUrl && hidden && (
        <ClickAwayListener onClickAway={handleShowMore}>
          <Stack>
            <TrackingMultiDropMenu data={data} label={label} />
          </Stack>
        </ClickAwayListener>
      )}
    </Stack>
  );
};
