import { styled } from '@mui/material/styles';
import { Button, Stack } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => {
  return {
    padding: '26px',
    gap: '16px',
    background: theme.vars.palette.silver20silver200.main,
    borderRadius: '36px',
  };
});

export const CancelButtonCSS = styled(Button)(({ theme }) => {
  return {
    width: '100%',
    height: '56px',
    color: theme.vars.palette.blackWhite.main,
    background: theme.vars.palette.whiteBlack.main,

    '&:hover': {
      color: theme.vars.palette.blackWhite.main,
      background: theme.vars.palette.whiteBlack.main,
    },
  };
});

export const CreateButtonCSS = styled(Button)(({ theme }) => {
  return {
    width: '100%',
    height: '56px',
    color: theme.vars.palette.whiteBlack.main,
    background: theme.vars.palette.blackWhite.main,

    '&:hover': {
      color: theme.vars.palette.whiteBlack.main,
      background: theme.vars.palette.blackWhite.main,
    },
  };
});
