interface IconsTypes {
  width?: string;
  height?: string;
  color?: string;
  size?: string;
}

export const PencilIcon = ({ width = '16', height = '16', color = '#fff' }: IconsTypes) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      color='#fff'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6355 2.88367C10.1168 2.58416 9.6842 2.3344 9.31415 2.18231C8.92355 2.02178 8.53442 1.94004 8.11963 2.05118C7.70484 2.16233 7.40871 2.42768 7.15072 2.762C6.90629 3.07874 6.65656 3.51131 6.35709 4.03006L3.77334 8.50516C3.59963 8.80546 3.45774 9.05077 3.38602 9.31419C3.37577 9.35182 3.36696 9.38982 3.35969 9.42841C3.30153 9.73707 3.34899 10.047 3.40901 10.4389L3.42518 10.5448C3.53597 11.2724 3.62729 11.8721 3.76502 12.3269C3.90907 12.8025 4.13051 13.2142 4.56602 13.4656C5.00154 13.7171 5.46878 13.703 5.9527 13.5899C6.41541 13.4818 6.98044 13.261 7.66593 12.9932L7.76575 12.9542C8.13517 12.8103 8.42727 12.6964 8.66551 12.4917C8.90375 12.287 9.06028 12.0154 9.25823 11.6719L11.8419 7.19673C12.1414 6.67801 12.3912 6.24544 12.5433 5.87539C12.7038 5.4848 12.7855 5.09567 12.6744 4.68088C12.5633 4.26609 12.2979 3.96996 11.9636 3.71196C11.6468 3.46754 11.2143 3.21781 10.6955 2.91833L10.6355 2.88367ZM10.7086 7.15976L6.95578 4.99309L4.67349 8.94614C4.42485 9.3768 4.36503 9.49342 4.3424 9.61356C4.31976 9.7337 4.33304 9.86409 4.40791 10.3557C4.52607 11.1315 4.60769 11.6593 4.72209 12.037C4.8326 12.4019 4.94656 12.5306 5.06602 12.5996C5.18549 12.6686 5.35395 12.7029 5.7252 12.6161C6.10951 12.5264 6.60736 12.3332 7.33834 12.0476C7.80152 11.8666 7.92108 11.8129 8.01381 11.7332C8.10653 11.6536 8.17763 11.5435 8.42626 11.1128L10.7086 7.15976ZM11.2075 6.29313C11.3949 5.96296 11.53 5.71021 11.6183 5.49525C11.7363 5.20814 11.7403 5.05838 11.7085 4.9397C11.6767 4.82102 11.5984 4.69328 11.3526 4.50364C11.0954 4.30514 10.7221 4.08836 10.1655 3.76703C9.60893 3.44569 9.23453 3.23076 8.934 3.10724C8.64689 2.98924 8.49713 2.98531 8.37845 3.01711C8.25977 3.04891 8.13204 3.12719 7.9424 3.37294C7.80041 3.55693 7.64908 3.80032 7.45683 4.12767L11.2075 6.29313Z'
        fill={color}
      />
    </svg>
  );
};
