import { Stack, styled } from '@mui/material';

export const HeaderBlockCSS = styled(Stack)(() => {
  return {
    height: '76px',
    width: '100%',
    gap: '8px',
  };
});

export const RightBlockCSS = styled(Stack)(({ theme }) => {
  return {
    background: theme.vars.palette.silver20silver200.main,
    width: 'auto',
    flex: 1,
    borderRadius: '32px 32px 0 0',
    position: 'relative',
  };
});

export const TriangleIconBlockCSS = styled(Stack)(() => {
  return {
    position: 'absolute',
    bottom: '-1px',
    left: '-36.5px',
  };
});
