import { Stack, styled } from '@mui/material';

export const ChatWrapper = styled(Stack)(({ theme }) => {
  return {
    background: theme.vars.palette.whiteBlack.main,
    borderRadius: 0,
    flex: 1,
    overflow: 'hidden',
  };
});

export const ChatInnerBlockCSS = styled(Stack)(() => {
  return {
    flex: 1,
    gap: '16px',
    overflowY: 'auto',
  };
});

export const TFBlockCSS = styled(Stack)(({ theme }) => {
  return {
    flexDirection: 'row',
    borderTop: `1px solid ${theme.vars.palette.silver20silver100.main}`,
    gap: '12px',
    alignItems: 'end',
    paddingTop: '17px',
    marginTop: '5px',
  };
});
