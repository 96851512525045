import { Stack } from '@mui/material';
import { ContentBlockTopLabel } from '../Containers';
import { CompanyNotesIcon } from '../Icons';
import { FormProvider, useForm } from 'react-hook-form';
import { ItemWrapper, SubWrapperCSS, Wrapper } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { shippingFormSchema } from '../../validation';
import { ShippingFormData } from './types';
import { ShippingForm } from '../Containers/ToChatsPage/ShippingForm/ShippingForm';
import { EditOrderList } from '../EditOrderList/EditOrderList';
import { RequestHandler } from '../RequestHandler/RequestHandler';
import { useEffect, useState } from 'react';
import { TabsRHF, TextFieldRHF } from '../UI';
import {
  MAX_TAKE_ORDER_LIST_ITEMS,
  OPTION_ORDER_STATUS_TABS,
  OPTION_ORDER_STATUS_TABS_ENUM,
  TAKE_ORDER_LIMIT,
} from '../../constants/constants';
import { usePalette, useTriggerByScrollTable } from '../../hooks';
import {
  useCancelOrderMutation,
  useGetOrderQuery,
  useUpdateOrderMutation,
} from '../../graphql/generated';
import { toast } from 'react-toastify';
import { CountrySelectRHF } from '../UI/CountrySelect/CountrySelect';
import { getCountryObject, getFieldNameRHF } from '../../utils';
import { GET_ORDER } from '../../graphql/queries/getOrder.gql';
import { GET_LAST_ORDER } from '../../graphql/queries/getLastOrder.gql';
import { GET_ORDERS } from '../../graphql/queries/getOrders.gql';

interface OrderInformationProps {
  isPage?: boolean;
  goBack?: () => void;
  orderId: string;
  conversationId?: string | number;
}

export const OrderInformation = ({
  isPage,
  goBack,
  orderId,
  conversationId,
}: OrderInformationProps) => {
  const [pendingOrder, setPendingOrder] = useState(false);

  const idFormName = 'orderForm';
  const { palette } = usePalette();

  const [doCancelOrder] = useCancelOrderMutation({
    refetchQueries: [
      {
        query: GET_ORDER,
        variables: {
          input: {
            orderId: orderId,
            conversationId: Number(conversationId),
            take: TAKE_ORDER_LIMIT,
            itemCursor: null,
          },
        },
      },
      {
        query: GET_LAST_ORDER,
        variables: {
          conversationId: conversationId,
        },
      },
      {
        query: GET_ORDERS,
        variables: {
          input: {
            conversationId: conversationId,
            cursorLineItems: null,
            take: MAX_TAKE_ORDER_LIST_ITEMS,
          },
        },
      },
    ],
    onCompleted: () => {
      toast.success('Order has been changed status to canceled');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const {
    data,
    loading: orderLoading,
    fetchMore,
  } = useGetOrderQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        orderId: orderId,
        conversationId: Number(conversationId),
        take: TAKE_ORDER_LIMIT,
        itemCursor: null,
      },
    },
  });
  const orderData = data?.getOrder;

  const INITIAL_VALUES = {
    email: orderData?.email || '',
    // customerPhone: orderData?.phone || '',
    trackingID: orderData?.trackingId || [''],
    trackingURL: orderData?.trackingUrl || [''],
    // orderID: String(orderId) || '',
    orderID: orderData?.id || '',

    orderStatus: '',
    total: orderData?.total || 0,
    netPayment: orderData?.netPayment || 0,
    firstName: orderData?.shipment.firstName || '',
    lastName: orderData?.shipment.lastName || '',
    phone: orderData?.shipment.phone || '',
    address: orderData?.shipment.address1 || '',
    city: orderData?.shipment.city || '',
    country: getCountryObject(orderData?.shipment.country) || null,
    state: orderData?.shipment.province || '',
    zip: orderData?.shipment.zip || '',

    shippingItemList: orderData?.item,

    // * custom fields
    removedItems: [],
    discount: 0,
    orderStatusChanged: false,
  };

  const methods = useForm<ShippingFormData>({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(shippingFormSchema),
  });

  const {
    reset,
    watch,
    getValues,
    setError,
    setValue,
    handleSubmit,
    formState: { dirtyFields },
  } = methods;

  const [orderStatus, shippingItemList] = watch(['orderStatus', 'shippingItemList']);

  useEffect(() => {
    if (dirtyFields.orderStatus) {
      setValue('orderStatusChanged', true);
      setValue('discount', 0);
    }
  }, [orderStatus]);

  const isPartialRefunded = orderStatus === OPTION_ORDER_STATUS_TABS_ENUM.PARTIAL_REFUNDED;

  useEffect(() => {
    const removedItems = getValues('removedItems');
    const discount = getValues('discount');
    const orderStatusChanged = getValues('orderStatusChanged');
    reset({ ...INITIAL_VALUES, removedItems, discount, orderStatusChanged });
  }, [orderData]);

  const [updateOrder] = useUpdateOrderMutation({
    refetchQueries: [
      {
        query: GET_ORDER,
        variables: {
          input: {
            orderId: orderId,
            conversationId: Number(conversationId),
            take: TAKE_ORDER_LIMIT,
            itemCursor: null,
          },
        },
      },
      {
        query: GET_LAST_ORDER,
        variables: {
          conversationId: conversationId,
        },
      },
      {
        query: GET_ORDERS,
        variables: {
          input: {
            conversationId: conversationId,
            cursorLineItems: null,
            take: MAX_TAKE_ORDER_LIST_ITEMS,
          },
        },
      },
    ],
    onCompleted: (data) => {
      const orderData = data.updateOrder;
      if (orderData.userErrors.length) {
        orderData.userErrors.forEach((err) => {
          if (err?.field?.[1]) {
            setError(getFieldNameRHF(String(err?.field?.[1])) as any, {
              type: 'Random',
              message: err.message,
            });
          }
          toast.error(err.message);
        });
      } else {
        toast.success('Order has been changed');
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmitRHF = async (values: ShippingFormData) => {
    if (values.orderStatus === OPTION_ORDER_STATUS_TABS_ENUM.CANCELLED) {
      return doCancelOrder({
        variables: {
          input: {
            orderId: orderId,
            conversationId: Number(conversationId),
          },
        },
      });
    }

    const isFullRefunded = OPTION_ORDER_STATUS_TABS_ENUM.FULL_REFUNDED === values.orderStatus;
    const isPartialRefunded = OPTION_ORDER_STATUS_TABS_ENUM.PARTIAL_REFUNDED === values.orderStatus;
    setPendingOrder(true);

    await updateOrder({
      variables: {
        input: {
          conversationId: Number(conversationId),
          orderId: orderId,

          updateBody: {
            email: values.email,
            shippingAddress: {
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              address1: values.address,
              city: values.city,
              country: values.country?.label || null,
              province: values.state,
              zip: values.zip,
            },
          },

          refundBody: {
            ...(isFullRefunded && {
              fullRefund: true,
            }),
            ...(isPartialRefunded && {
              refundLineItems: values.shippingItemList.map((item) => {
                return {
                  lineItemId: item.id,
                  quantity: item.quantity,
                };
              }),
              fullRefundItems: values.removedItems.map((id) => ({ lineItemId: id })),
            }),
            transactions: [
              {
                amount: String(values.discount),
                orderId,
                kind: 'REFUND',
                gateway: 'cash',
              },
            ],
          },
        },
      },
    });
    setPendingOrder(false);
  };

  const { tableBodyRef } = useTriggerByScrollTable({
    triggerData: [data],
    onAction: () => {
      if (orderLoading || (!orderLoading && data && !orderData?.itemCursor)) return;

      fetchMore({
        variables: {
          input: {
            orderId: orderId,
            conversationId: Number(conversationId),
            take: TAKE_ORDER_LIMIT,
            itemCursor: data?.getOrder.itemCursor,
          },
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousQueryResult;
          return {
            getOrder: {
              ...fetchMoreResult.getOrder,
              email: getValues('email') || '',
              trackingId: getValues('trackingID'),
              trackingUrl: getValues('trackingURL'),
              status: getValues('orderStatus'),
              total: Number(getValues('total')),
              netPayment: Number(getValues('netPayment')),
              shipment: {
                address1: getValues('address'),
                address2: previousQueryResult.getOrder.shipment.address2,
                city: getValues('city'),
                company: previousQueryResult.getOrder.shipment.company,
                country: String(getValues('country')?.label),
                countryCode: previousQueryResult.getOrder.shipment.countryCode,
                firstName: getValues('firstName'),
                id: previousQueryResult.getOrder.shipment.id,
                lastName: getValues('lastName'),
                phone: getValues('phone'),
                province: getValues('state'),
                provinceCode: previousQueryResult.getOrder.shipment.provinceCode,
                zip: getValues('zip'),
                __typename: 'ShippingSchema',
              },
              item: [...shippingItemList, ...fetchMoreResult.getOrder.item],
            },
          };
        },
      });
    },
  });

  return (
    <Wrapper ispage={String(isPage)}>
      <SubWrapperCSS>
        <ContentBlockTopLabel
          icon={<CompanyNotesIcon />}
          label={'Shipping'}
          formId={idFormName}
          {...(isPage && { goBack })}
          disabled={orderLoading || pendingOrder}
          financialStatus={orderData?.financialStatus}
          fulfillmentStatus={orderData?.fulfillmentStatus}
          shipmentStatus={orderData?.shipmentStatus}
          isCancelled={orderData?.status === 'Cancelled'}
        />

        <RequestHandler loading={orderLoading}>
          <Stack gap={'16px'}>
            <FormProvider {...methods}>
              <Stack component={'form'} onSubmit={handleSubmit(onSubmitRHF)} id={idFormName}>
                <ItemWrapper>
                  <ShippingForm
                    emailFieldName={'email'}
                    trackingIDFieldName={'trackingID'}
                    trackingURLFieldName={'trackingURL'}
                    orderIDFieldName={'orderID'}
                  />
                  <EditOrderList
                    shippingItemList={INITIAL_VALUES.shippingItemList}
                    isOffEditItem={!isPartialRefunded}
                    wrapperRef={tableBodyRef}
                  />
                  <Stack direction={'row'} justifyContent={'space-between'} mb={'24px'}>
                    <TabsRHF
                      name={'orderStatus'}
                      options={OPTION_ORDER_STATUS_TABS}
                      asButtonsGroup
                      isMoreColored
                      label={'Order Status'}
                    />
                    <Stack direction={'row'} gap={'20px'}>
                      <Stack width={'150px'}>
                        <TextFieldRHF
                          name={'netPayment'}
                          disabled={true}
                          label={'Net Payment'}
                          size={'medium'}
                          placeholder={''}
                          type={'number'}
                          sx={{
                            '.MuiInputBase-root': {
                              background: 'initial',
                              border: '1px solid #848484',
                              '.Mui-disabled': {
                                WebkitTextFillColor: palette.blackWhite.main,
                              },
                            },
                          }}
                        />
                      </Stack>
                      <Stack width={'150px'}>
                        <TextFieldRHF
                          name={'total'}
                          disabled={true}
                          label={'Total'}
                          size={'medium'}
                          placeholder={''}
                          type={'number'}
                          sx={{
                            '.MuiInputBase-root': {
                              background: 'initial',
                              border: '1px solid #848484',
                              '.Mui-disabled': {
                                WebkitTextFillColor: palette.blackWhite.main,
                              },
                            },
                          }}
                        />
                      </Stack>
                      {isPartialRefunded && (
                        <Stack width={'150px'}>
                          <TextFieldRHF
                            isAbsoluteErrorText={true}
                            name={'discount'}
                            disabled={!isPartialRefunded}
                            label={'Discount'}
                            size={'medium'}
                            placeholder={''}
                            type={'number'}
                            sx={{
                              '.MuiInputBase-root': {
                                border: '1px solid #848484',
                                '.Mui-disabled': {
                                  WebkitTextFillColor: palette.blackWhite.main,
                                },
                              },
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>

                  <Stack direction={'row'} gap={'16px'}>
                    <Stack sx={{ flex: 1 }} gap={'16px'}>
                      <TextFieldRHF
                        name={'firstName'}
                        label={'First Name'}
                        size={'medium'}
                        placeholder={'Enter First Name'}
                        sx={{ width: '100%' }}
                        isAbsoluteErrorText
                      />
                      <TextFieldRHF
                        name={'lastName'}
                        label={'Last Name'}
                        size={'medium'}
                        placeholder={'Enter Last Name'}
                        sx={{ width: '100%' }}
                        isAbsoluteErrorText
                      />
                      <TextFieldRHF
                        name={'phone'}
                        label={'Phone Number'}
                        size={'medium'}
                        type={'number'}
                        placeholder={'Enter Phone Number'}
                        sx={{ width: '100%' }}
                        isAbsoluteErrorText
                      />
                      <TextFieldRHF
                        name={'address'}
                        label={'Address'}
                        size={'medium'}
                        placeholder={'Enter Address'}
                        sx={{ width: '100%' }}
                        isAbsoluteErrorText
                      />
                    </Stack>
                    <Stack sx={{ flex: 1 }} gap={'16px'}>
                      <TextFieldRHF
                        name={'city'}
                        label={'City'}
                        size={'medium'}
                        placeholder={'Enter City'}
                        sx={{ width: '100%' }}
                        isAbsoluteErrorText
                      />
                      <CountrySelectRHF name={'country'} isAbsoluteErrorText />
                      <TextFieldRHF
                        name={'state'}
                        label={'State/Province'}
                        size={'medium'}
                        placeholder={'Enter State/Province'}
                        sx={{ width: '100%' }}
                        isAbsoluteErrorText
                      />
                      <TextFieldRHF
                        name={'zip'}
                        label={'ZIP/Postal Code'}
                        size={'medium'}
                        type={'number'}
                        placeholder={'Enter ZIP/Postal Code'}
                        sx={{ width: '100%' }}
                        isAbsoluteErrorText
                      />
                    </Stack>
                  </Stack>
                </ItemWrapper>
              </Stack>
            </FormProvider>
          </Stack>
        </RequestHandler>
      </SubWrapperCSS>
    </Wrapper>
  );
};
