import { Box, Link, styled } from '@mui/material';

export const ShippingItemRowWrapperCSS = styled(Box)(({ theme }) => {
  return {
    paddingBottom: '16px',
    borderBottom: `1px solid ${theme.vars.palette.whiteBlack.main}`,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 200px 200px 40px',
  };
});

export const LinkCSS = styled(Link)(({ theme }) => {
  return {
    color: theme.vars.palette.mail.main,
    textDecoration: 'none',
  };
});
