import { ModalHeader } from '../ModalComponents';
import { ButtonCSS, InfoBlockCSS, Wrapper } from './styles';
import { AttachmentIcon } from '../../Icons';
import { TextFieldRHF } from '../../UI';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addUserToExistingSchema } from '../../../validation/schema/addUserToExisting.schema';
import { useEditCustomerMutation } from '../../../graphql/generated';
import { toast } from 'react-toastify';

type FormData = {
  phone: string;
  email: string;
};

interface AddUserToExistingModalProps {
  onClose: () => void;
  customerId: number;
}

export const AddUserToExistingModal = ({ onClose, customerId }: AddUserToExistingModalProps) => {
  const methods = useForm<FormData>({
    defaultValues: {
      phone: '',
      email: '',
    },
    resolver: yupResolver(addUserToExistingSchema),
  });

  const [editCustomer] = useEditCustomerMutation({
    onCompleted: () => {
      toast.success('Completed');
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const onSubmitRHF = async (values: FormData) => {
    await editCustomer({
      variables: {
        input: {
          customerId: customerId,
          email: values.email,
          phone: values.phone,
        },
      },
    });
  };

  return (
    <Wrapper>
      <ModalHeader
        icon={AttachmentIcon}
        title={'Add user to company'}
        sx={{ marginBottom: '24px' }}
        onClose={onClose}
      />
      <InfoBlockCSS>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmitRHF)}>
            <TextFieldRHF
              name={'phone'}
              label={'Phone number'}
              size={'medium'}
              placeholder={'Enter phone number'}
              type={'text'}
              mb={2}
            />
            <TextFieldRHF
              name={'email'}
              label={'Email address'}
              size={'medium'}
              placeholder={'Enter your email'}
              type={'text'}
              mb={2}
            />
            <ButtonCSS fullWidth={true} type={'submit'} disabled={!isDirty}>
              Save
            </ButtonCSS>
          </form>
        </FormProvider>
      </InfoBlockCSS>
    </Wrapper>
  );
};
