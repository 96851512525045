import * as yup from 'yup';
import { messages as M } from '../messages';
import { REGISTER_DOMAIN } from '../../constants/constants';

export const addUserSchema = yup.object().shape({
  name: yup.string().required(M.REQUIRE_MESSAGE),
  lastName: yup.string().required(M.REQUIRE_MESSAGE),
  email: yup
    .string()
    .email(M.INCORRECT_EMAIL)
    .test('is-superhumansupport', M.INCORRECT_EMAIL, (value) => {
      if (!value) return false; // Пустые значения не проходят валидацию
      const [, domain] = value.split('@'); // Разбиваем email на части

      // Проверяем, что домен равен "superhumansupport.co"
      return domain === REGISTER_DOMAIN;
    })
    .required(M.REQUIRE_MESSAGE),
  role: yup.string().required(M.REQUIRE_MESSAGE),
});
