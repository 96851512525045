import { gql } from '@apollo/client';

export const FIND_AND_COUNT_MANY_USERS = gql`
  query FindAndCountManyUsers($input: FindAndCountManyUsersInput!) {
    findAndCountManyUsers(input: $input) {
      rows {
        id
        email
        firstName
        lastName
        role
        avatar
        blocked
        blockedAt
        refreshToken
        createdAt
        updatedAt
      }
      count
    }
  }
`;
