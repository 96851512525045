import { IconProps } from './types';

export const UsersIcon = ({ color = '#fff' }: IconProps) => {
  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.20288 3.1875C7.23537 3.1875 5.64038 4.78249 5.64038 6.75C5.64038 8.71751 7.23537 10.3125 9.20288 10.3125C11.1704 10.3125 12.7654 8.71751 12.7654 6.75C12.7654 4.78249 11.1704 3.1875 9.20288 3.1875ZM6.76538 6.75C6.76538 5.40381 7.85669 4.3125 9.20288 4.3125C10.5491 4.3125 11.6404 5.40381 11.6404 6.75C11.6404 8.09619 10.5491 9.1875 9.20288 9.1875C7.85669 9.1875 6.76538 8.09619 6.76538 6.75Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.20288 0.9375C4.75008 0.9375 1.14038 4.5472 1.14038 9C1.14038 11.2864 2.0928 13.3512 3.62101 14.8178C5.06913 16.2075 7.03675 17.0625 9.20288 17.0625C11.369 17.0625 13.3366 16.2075 14.7848 14.8178C16.313 13.3512 17.2654 11.2864 17.2654 9C17.2654 4.5472 13.6557 0.9375 9.20288 0.9375ZM2.26538 9C2.26538 5.16852 5.37141 2.0625 9.20288 2.0625C13.0344 2.0625 16.1404 5.16852 16.1404 9C16.1404 10.6461 15.5676 12.1577 14.6096 13.3475C14.014 12.2121 12.8243 11.4375 11.4529 11.4375H6.95288C5.58146 11.4375 4.39173 12.2121 3.7962 13.3475C2.83817 12.1577 2.26538 10.6461 2.26538 9ZM11.4529 12.5625C12.5283 12.5625 13.4419 13.2594 13.7652 14.2264C12.5452 15.2924 10.9499 15.9375 9.20288 15.9375C7.45585 15.9375 5.86052 15.2924 4.64053 14.2264C4.96381 13.2594 5.87748 12.5625 6.95288 12.5625H11.4529Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.20288 3.1875C7.23537 3.1875 5.64038 4.78249 5.64038 6.75C5.64038 8.71751 7.23537 10.3125 9.20288 10.3125C11.1704 10.3125 12.7654 8.71751 12.7654 6.75C12.7654 4.78249 11.1704 3.1875 9.20288 3.1875ZM6.76538 6.75C6.76538 5.40381 7.85669 4.3125 9.20288 4.3125C10.5491 4.3125 11.6404 5.40381 11.6404 6.75C11.6404 8.09619 10.5491 9.1875 9.20288 9.1875C7.85669 9.1875 6.76538 8.09619 6.76538 6.75Z'
        stroke={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.20288 0.9375C4.75008 0.9375 1.14038 4.5472 1.14038 9C1.14038 11.2864 2.0928 13.3512 3.62101 14.8178C5.06913 16.2075 7.03675 17.0625 9.20288 17.0625C11.369 17.0625 13.3366 16.2075 14.7848 14.8178C16.313 13.3512 17.2654 11.2864 17.2654 9C17.2654 4.5472 13.6557 0.9375 9.20288 0.9375ZM2.26538 9C2.26538 5.16852 5.37141 2.0625 9.20288 2.0625C13.0344 2.0625 16.1404 5.16852 16.1404 9C16.1404 10.6461 15.5676 12.1577 14.6096 13.3475C14.014 12.2121 12.8243 11.4375 11.4529 11.4375H6.95288C5.58146 11.4375 4.39173 12.2121 3.7962 13.3475C2.83817 12.1577 2.26538 10.6461 2.26538 9ZM11.4529 12.5625C12.5283 12.5625 13.4419 13.2594 13.7652 14.2264C12.5452 15.2924 10.9499 15.9375 9.20288 15.9375C7.45585 15.9375 5.86052 15.2924 4.64053 14.2264C4.96381 13.2594 5.87748 12.5625 6.95288 12.5625H11.4529Z'
        stroke={color}
      />
    </svg>
  );
};
